import Moment from 'moment';

import { Decimal } from 'decimal.js';

import { sortByKey } from '../../utils/array';
import {
  minimalUnitToFiat,
  formatCrypto,
  getLocalString,
  cryptos,
} from '../../utils/numbers';

import { extendObservable, action } from 'mobx';

const TRANSACTION_DEFAULT_ORDER = 'asc';
const TRANSACTION_DEFAULT_ORDER_BY = 'created_at';

const currencies = {
  EUR: '€',
  USD: '$',
};

export default class Payment {
  constructor(payment) {
    extendObservable(this, {
      uuid: payment.uuid,
      crypto_amount: this.minimalUnitToCrypto(
        payment.crypto_amount,
        payment.crypto_currency
      ),
      crypto_currency: payment.crypto_currency,
      reference: payment.reference,
      callback_url: payment.callback_url,
      cancel_url: payment.cancel_url,
      rate: {
        created_at: payment.rate.created_at,
        to: payment.rate.to,
        from: payment.rate.from,
        source: payment.rate.source,
        value: new Decimal(payment.rate.value), // value of 1 BTC
      },
      amount: new Decimal(payment.amount),
      expires_in: payment.expires_in,
      uri: payment.uri,
      created_at: Moment.utc(payment.created_at).local(),
      payed_at: Moment.utc(payment.resolved_at).local(),
      start_dispute: Moment.utc(payment.dispute_start_date).local(),
      chargeback_date: Moment.utc(payment.chargeback_date).local(),
      expiration_time: Moment.utc(payment.resolved_at).local(),
      cancellation_date: Moment.utc(payment.resolved_at).local(),
      currency: payment.currency,
      transactions: sortByKey(
        payment.transactions.map(
          transaction =>
            new Transaction({
              ...transaction,
              crypto_currency: payment.crypto_currency,
            })
        ),
        TRANSACTION_DEFAULT_ORDER_BY,
        TRANSACTION_DEFAULT_ORDER
      ),
      required_confirmations: payment.required_confirmations,
      confirm_url: payment.confirm_url,
      resolved_at: Moment.utc(payment.resolved_at).local(),
      created_by: {
        name: payment.created_by ? payment.created_by.name : null,
        type: payment.created_by ? payment.created_by.type : null,
        uuid: payment.created_by ? payment.created_by.uuid : null,
      },
      deposit_account:
        payment.created_by && payment.created_by.deposit_account
          ? {
              name: payment.created_by.deposit_account
                ? payment.created_by.deposit_account.name
                : null,
              type: payment.created_by.deposit_account
                ? payment.created_by.deposit_account.type
                : null,
              uuid: payment.created_by.deposit_account
                ? payment.created_by.deposit_account.uuid
                : null,
            }
          : null,
      details: payment.details,
      redirect_url: payment.redirect_url,
      address: payment.address,
      // info copies
      status: payment.state.status,
      pos_name: payment.pos ? payment.pos.name : null,
      // other info
      order: TRANSACTION_DEFAULT_ORDER,
      orderBy: TRANSACTION_DEFAULT_ORDER_BY,
    });
  }

  minimalUnitToCrypto = (amount, currency) => {
    if (!currency) {
      return undefined;
    }
    return new Decimal(amount).dividedBy(
      new Decimal(cryptos[currency].exponent)
    );
  };

  get minimalUnitToCryptoDisplay() {
    return this.crypto_currency && this.crypto_amount
      ? `${this.crypto_amount.toNumber()} ${
          cryptos[this.crypto_currency].label
        }`
      : '-';
  }

  get expectedWait() {
    switch (this.required_confirmations) {
      case 2:
        return 'circa 20 minuti di attesa';
      default:
        return 'unknown';
    }
  }

  get rateApplied() {
    return getLocalString(this.rate.value);
  }

  get createdAtString() {
    const oneYearAgo = Moment().subtract(1, 'years');
    return this.created_at.isBefore(oneYearAgo)
      ? this.created_at.format('D MMM YY, H:mm')
      : this.created_at.format('D MMM, H:mm');
  }
  get amountString() {
    return this.currency + ' ' + getLocalString(this.amount);
  }

  get cryptoAmountString() {
    return formatCrypto(this.crypto_amount, this.crypto_currency);
  }

  get amountStringSymbol() {
    return (
      getLocalString(this.amount) +
      ' ' +
      (currencies[this.currency] ? currencies[this.currency] : this.currency)
    );
  }

  get currencySymbol() {
    switch (this.currency) {
      case 'EUR':
      case "'EUR'": // TODO: remove this...
        return '€';
      case 'USD':
        return '$';
      default:
        return '?';
    }
  }

  get confirmed() {
    return this.status === 'paid';
  }

  get notConfirmed() {
    return this.status === 'pending' || this.status === 'partial';
  }

  get disputed() {
    return this.status === 'network_dispute' || this.status === 'chargeback';
  }

  get otherState() {
    return !(this.confirmed || this.notConfirmed || this.disputed);
  }

  sortTransactions = action(col_id => {
    this.order =
      this.orderBy === col_id && this.order === 'desc' ? 'asc' : 'desc';
    this.orderBy = col_id;
    this.transactions = sortByKey(this.transactions, this.orderBy, this.order);
  });

  transactionFiatAmount = tx => {
    return minimalUnitToFiat(
      tx.outs_sum,
      this.rate.value,
      this.crypto_currency
    );
  };
}

export class Transaction {
  constructor(transaction) {
    extendObservable(this, {
      txid: transaction.txid,
      status: transaction.status,
      normalized_txid: transaction.normalized_txid,
      confirmed: Boolean(transaction.confirmed),
      outs_sum: new Decimal(transaction.outs_sum),
      outs: transaction.outs,
      created_at: Moment.utc(transaction.created_at).local(),
      crypto_currency: transaction.crypto_currency,
    });
  }
}
