import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Col, Row } from 'react-flexbox-grid';
import Typography from '@material-ui/core/Typography';
import Translate from 'react-translate-component';
import { ListDetail } from './ListDetail';
import { withStyles } from '@material-ui/core';
import { styles } from './styles/ListDetail';
import { ROUTE_PARAMETERS_VALUE } from '../../../constants/routes';

class BankAccountListDetail extends React.Component {
  static propTypes = {
    account: PropTypes.shape({
      name: PropTypes.string.isRequired,
      iban: PropTypes.string.isRequired,
      intestatario: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired,
      bankname: PropTypes.string.isRequired,
      bic: PropTypes.string.isRequired,
    }),
    poses: PropTypes.array,
    reloadAccount: PropTypes.func.isRequired,
    onAccountDeleted: PropTypes.func.isRequired,
    business_uuid: PropTypes.string.isRequired,
  };

  onAccountDeleted = (key, type) => {
    this.props.onAccountDeleted(key);
  };

  render() {
    const { classes, account, poses, business_uuid, index } = this.props;

    const account_type = ROUTE_PARAMETERS_VALUE.ACCOUNT.bank;
    const account_uuid = this.props.account.uuid;

    const { name } = this.props.account;
    return (
      <ListDetail
        classes={classes}
        index={index}
        account_type={account_type}
        account_uuid={account_uuid}
        business_uuid={business_uuid}
        avatar={
          <img
            className={classnames([classes.avatar, classes.accountIcon])}
            src={require('../../../img/bancario/medium.png')}
            alt="webpos"
          />
        }
        name={name}
        poses={poses}
        onAccountDeleted={this.onAccountDeleted}
      >
        <Row>
          <Col xs={12}>
            <Row className={classes.detailRow}>
              <Col xs={3}>
                <Typography variant="caption" gutterBottom>
                  <Translate content="account.detail.holder" />
                </Typography>
              </Col>
              <Col xs={9}>
                <Typography variant="body2" gutterBottom>
                  <b>{account.holder}</b>
                </Typography>
              </Col>
            </Row>
            <Row className={classes.detailRow}>
              <Col xs={3}>
                <Typography variant="caption">
                  <Translate content="account.detail.iban" />
                </Typography>
              </Col>
              <Col xs={9}>
                <Typography variant="body2" gutterBottom>
                  <b>{account.iban}</b>
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
      </ListDetail>
    );
  }
}

export default withStyles(styles)(BankAccountListDetail);
