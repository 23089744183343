import React from 'react';

import PropTypes from 'prop-types';

import Translate from 'react-translate-component';

import { Row, Col } from 'react-flexbox-grid';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  message: {
    textAlign: 'center',
    paddingTop: '75px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '10px',
  },
  viewAll: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    fontStyle: 'italic',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

const Message = withStyles(styles)(props => {
  const { classes } = props;
  const { title, subtitle, clearFilter } = props;
  return (
    <Row className={classes.message}>
      <Col xs>
        <Typography className={classes.title} variant="body2">
          {title}
        </Typography>
        <Typography variant="body2" className="color7">
          {subtitle}
          {clearFilter && (
            <span onClick={clearFilter} className={classes.viewAll}>
              <Translate content="payment.list.messages.show_all" />
            </span>
          )}
        </Typography>
      </Col>
    </Row>
  );
});

Message.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  clearFilter: PropTypes.func,
};

export { Message };
