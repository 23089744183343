import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '../../common/Button';
import AnagAPI from '../../../api/Exchanges';
import InvalidFormMessage from '../../common/InvalidFormMessage';
import { blockIfEmailNotVerified } from '../../common/BlockUnverifiedHOC';
import { withGenericErrorHandling } from '../../common/ErrorHandling';
import TextField from '../../common/Forms/TextField';
import AccountsAPI from '../../../api/Accounts';
import POSAPI from '../../../api/POS';
import Translate from 'react-translate-component';
import translator from 'counterpart';
import { ROUTE_PARAMETERS, ROUTES } from '../../../constants/routes';
import '../../../styles/style.css';
import { withRouter, Prompt } from 'react-router-dom';
import ConfirmModal from './ConfirmModal';
import { buildPath } from '../../../utils/routes';
import store from '../../../store';
import NoAccountModal from '../common/NoAccountModal';
import { FormSectionTitle } from '../../common/Forms/FormSectionTitle';
import { WebsiteLink } from '../../common/WebsiteLink';
import CredentialSection from './partial/CredentialSection';
import { AccountsSection, CurrencySection } from './partial';
import PosIsActiveOnGenerateModal from './partial/PosIsActiveOnGenerateModal';

const styles = theme => ({
  rowMargin: {
    marginBottom: '10px',
  },
  rowMarginExtra: {
    marginBottom: '20px',
  },
  containerConfirmation: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  accountContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px',
  },
  marginTopSection: {
    marginTop: '70px',
  },
  errorLabel: theme.typography.errorLabel,
  notActiveLabel: {
    backgroundColor: theme.palette.red.main,
    color: '#fff',
    fontWeight: 600,
    marginLeft: '11px',
    borderRadius: '4px',
    height: '24px',
  },
  loader: {
    marginRight: '8px',
  },
  disableItalic: {
    fontStyle: 'initial',
  },
});

class WebPOS extends React.Component {
  static propTypes = {
    newPos: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    handleChangeGeneric: PropTypes.func.isRequired,
    signalError: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    errors: PropTypes.object,
    webPOS: PropTypes.shape({
      name: PropTypes.string,
      site: PropTypes.string,
      currency: PropTypes.string,
      confirimation: PropTypes.string,
      account: PropTypes.string,
      urlSuccess: PropTypes.string,
      urlError: PropTypes.string,
      urlCallback: PropTypes.string,
    }),
    business_uuid: PropTypes.string.isRequired,
  };

  state = {
    currencies: [],
    openModal: false,
    submitted: false,
    webPosWithoutDepositAccount: false,
    prevAccountSelected: null,
    bankAccountSelected: false,
    posActiveModal: false,
  };

  componentDidMount() {
    this.loadCurrency();
    this.loadAccounts();

    if (
      !this.props.newPos &&
      !this.props.webPOS[this.props.form.ids.form$deposit_account]
    ) {
      this.checkMissingDepositAccount();
    }
  }

  loadCurrency = () => {
    AnagAPI.getCurrency(
      this.currencyLoaded,
      this.props.onError,
      'true' // only FIAT
    );
  };

  currencyLoaded = data => {
    this.setState({ currencies: data.currencies }, () => {
      if (data.currencies !== null && data.currencies.length === 1) {
        //FIXME accrocchio per mascherare l'event
        this.handleChangeCurrency({
          target: {
            value: data.currencies[0].uuid,
            id: this.props.form.ids.form$currency,
          },
        });
      }
    });
  };

  loadAccounts = () => {
    const { business_uuid } = this.props;
    AccountsAPI.getList(business_uuid, this.accountsLoaded, this.props.onError);
  };

  accountsLoaded = accounts => {
    this.setState({
      bankAccounts: accounts.bank,
      bitcoinAccounts: accounts.bitcoin,
    });
  };

  handleChangeCurrency = event => {
    this.props.handleChange(event);
    this.setState({
      currency: event.target.value,
    });
  };

  handleChangeDeposit = accountId => {
    this.props.handleChangeGeneric(this.props.form.ids.form$deposit_account)(
      accountId
    );
    this.setState({
      prevAccountSelected: accountId,
    });
  };

  getAccountList = () => {
    let accountsList = [];
    if (this.state.bankAccounts) {
      accountsList = accountsList.concat(this.state.bankAccounts);
    }
    if (this.state.bitcoinAccounts) {
      accountsList = accountsList.concat(this.state.bitcoinAccounts);
    }
    return accountsList;
  };

  onConfirm = event => {
    event.preventDefault();
    this.props.validate(isValid => {
      let depositAccount = this.props.webPOS[
        this.props.form.ids.form$deposit_account
      ];
      if (
        isValid &&
        (depositAccount || this.state.webPosWithoutDepositAccount)
      ) {
        this.setState({ openModal: true, submitted: true });
      }
    });
  };

  openAlertPosActive = () => {
    this.setState({ posActiveModal: true });
  };

  closeAlertPosActive = () => {
    this.setState({ posActiveModal: false });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false, submitted: false });
  };

  generateSecret = () => {
    this.setState(
      {
        loadingSecret: true,
      },
      () => {
        POSAPI.generateNewClientIdAndSecret(
          this.props.idPOS,
          result => {
            this.props.onResetCredentials(result.pos_id, result.secret);
            this.setState({
              loadingSecret: false,
            });
          },
          e => {
            this.setState({
              loadingSecret: false,
            });
          }
        );
      }
    );
  };

  toggleWebPosWithoutDepositAccount = () => {
    this.setState(
      {
        webPosWithoutDepositAccount: !this.state.webPosWithoutDepositAccount,
      },
      () => {
        this.checkMissingDepositAccount();
      }
    );
  };

  checkMissingDepositAccount = () => {
    let webPosAccount = this.props.webPOS[
      this.props.form.ids.form$deposit_account
    ];
    if (
      store.app.hasAccount &&
      !webPosAccount &&
      !this.state.webPosWithoutDepositAccount
    ) {
      this.state.prevAccountSelected
        ? this.handleDepositAccountChange(this.state.prevAccountSelected)
        : this.signalMissingDepositAccount();
    } else {
      this.handleDepositAccountChange(null);
    }
  };

  handleDepositAccountChange = value => {
    this.props.handleChangeGeneric(this.props.form.ids.form$deposit_account)(
      value
    );
  };

  handleNoAccountModalConfirm = () => {
    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    this.props.history.push(
      buildPath(ROUTES.ACCOUNT_NEW, {
        [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
      })
    );
  };

  signalMissingDepositAccount = () => {
    this.props.signalError(
      this.props.form.ids.form$deposit_account,
      translator('POS.edit.noAccountSelectedError')
    );
  };

  render() {
    const {
      classes,
      webPOS,
      edit,
      business_uuid,
      handleChange,
      errors,
      newPos,
    } = this.props;
    const {
      openModal,
      submitted,
      webPosWithoutDepositAccount,
      posActiveModal,
    } = this.state;

    const accountsList = this.getAccountList();

    const ids = {
      name: this.props.form.ids.form$name,
      website: this.props.form.ids.form$website,
      currency: this.props.form.ids.form$currency,
      depositAccount: this.props.form.ids.form$deposit_account,
      callBackUrl: this.props.form.ids.form$callback_url,
      confirmUrl: this.props.form.ids.form$default_continue_url,
      cancelUrl: this.props.form.ids.form$default_cancel_url,
    };

    const webPosAccount = webPOS[ids.depositAccount];
    const accountSelected =
      accountsList &&
      accountsList.filter(item => item.uuid === webPOS[ids.depositAccount])[0];
    const currencySelected =
      this.state.currencies &&
      this.state.currencies.filter(currency => {
        return currency.uuid === webPOS.currency;
      })[0];
    const hasAccounts = store.app.hasAccount;

    return (
      webPOS && (
        <React.Fragment>
          <Prompt
            message={translator('common.exitAlertMessage')}
            when={!submitted && store.app.isVerified && hasAccounts}
          />
          {!store.app.sandboxMode && !hasAccounts && store.app.isVerified && (
            <NoAccountModal
              id="no-account-modal"
              open={!hasAccounts}
              onConfirm={this.handleNoAccountModalConfirm}
            />
          )}
          <form onSubmit={this.onConfirm}>
            {edit && (
              <>
                <CredentialSection
                  onClickGenerate={
                    webPOS.active
                      ? this.openAlertPosActive
                      : this.generateSecret
                  }
                  secret={webPOS.secret}
                  clientId={webPOS.pos_id}
                  loading={this.state.loadingSecret}
                />
                <PosIsActiveOnGenerateModal
                  handleClose={this.closeAlertPosActive}
                  open={posActiveModal}
                />
              </>
            )}
            <Row>
              <Col xs={12}>
                <FormSectionTitle>
                  <Translate content="POS.web.where.title" />
                </FormSectionTitle>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Typography variant="caption" style={{ marginBottom: '25px' }}>
                  <Translate content="POS.web.where.subtitle" />
                </Typography>
              </Col>
            </Row>
            <div />
            <Row className={classes.rowMargin}>
              <Col xs={6}>
                <TextField
                  ref="testRef"
                  label={<Translate content="POS.web.name" />}
                  placeholder={translator('placeholder.nameSite')}
                  error={Boolean(errors[ids.name])}
                  helperText={errors[ids.name]}
                  value={webPOS[ids.name]}
                  id={ids.name}
                  name={ids.name}
                  onChange={handleChange}
                  fullWidth
                  validateField={this.props.validateField}
                />
              </Col>
              <Col xs={6}>
                <TextField
                  label={<Translate content="POS.web.site" />}
                  placeholder={translator('placeholder.internetSite')}
                  error={Boolean(errors[ids.website])}
                  helperText={errors[ids.website]}
                  value={webPOS[ids.website]}
                  id={ids.website}
                  name={ids.website}
                  onChange={handleChange}
                  fullWidth
                  validateField={this.props.validateField}
                />
              </Col>
            </Row>
            <AccountsSection
              hasAccounts={hasAccounts}
              business_uuid={business_uuid}
              error={errors[ids.depositAccount]}
              handleChange={this.handleChangeDeposit}
              accountConnected={webPosAccount}
              accountsList={accountsList}
              isNewPos={newPos}
              posWithoutAccount={webPosWithoutDepositAccount}
              tooglePosWithoutAccount={this.toggleWebPosWithoutDepositAccount}
            />
            <CurrencySection
              handleChange={this.handleChangeCurrency}
              currencyId={ids.currency}
              value={webPOS[ids.currency]}
              currencies={this.state.currencies}
              error={errors[ids.currency]}
            />
            <Row
              style={{
                marginTop: '40px',
              }}
            >
              <Col xs={12}>
                <FormSectionTitle>
                  <Translate content="POS.web.urls.title" />
                </FormSectionTitle>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Typography variant="caption" style={{ marginBottom: '25px' }}>
                  <Translate content="POS.web.urls.subtitle" />{' '}
                  <WebsiteLink to="devs_area_pos_urls">
                    <Translate content="POS.web.urls.more_info" />
                  </WebsiteLink>
                  .
                </Typography>
              </Col>
            </Row>
            <Row className={classes.rowMarginExtra}>
              <Col xs={12}>
                <TextField
                  label={<Translate content="POS.web.urls.continue" />}
                  placeholder={translator('placeholder.internetSite')}
                  error={Boolean(errors[ids.confirmUrl])}
                  helperText={errors[ids.confirmUrl]}
                  value={webPOS[ids.confirmUrl]}
                  id={ids.confirmUrl}
                  name={ids.confirmUrl}
                  onChange={handleChange}
                  fullWidth
                  validateField={this.props.validateField}
                />
              </Col>
            </Row>
            <Row className={classes.rowMarginExtra}>
              <Col xs={12}>
                <TextField
                  label={<Translate content="POS.web.urls.failure" />}
                  placeholder={translator('placeholder.internetSite')}
                  error={Boolean(errors[ids.cancelUrl])}
                  helperText={errors[ids.cancelUrl]}
                  value={webPOS[ids.cancelUrl]}
                  id={ids.cancelUrl}
                  name={ids.cancelUrl}
                  onChange={handleChange}
                  validateField={this.props.validateField}
                />
              </Col>
            </Row>
            <Row className={classes.rowMarginExtra}>
              <Col xs={12}>
                <TextField
                  label={<Translate content="POS.web.urls.callback" />}
                  placeholder={translator('placeholder.internetSite')}
                  error={Boolean(errors[ids.callBackUrl])}
                  helperText={errors[ids.callBackUrl]}
                  value={webPOS[ids.callBackUrl]}
                  id={ids.callBackUrl}
                  name={ids.callBackUrl}
                  onChange={handleChange}
                  fullWidth
                  validateField={this.props.validateField}
                />
              </Col>
            </Row>
            <InvalidFormMessage errors={errors} />
            <Row className={classes.rowMargin}>
              <Col xs={4}>
                <Button
                  variant="raised"
                  id="save"
                  onClick={this.onConfirm}
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={this.state.loadingSecret}
                >
                  <Translate content="common.save" />
                </Button>
              </Col>
              <Col xs={2}>
                <Button
                  variant="outlined"
                  id="back"
                  onClick={this.props.history.goBack}
                  color="primary"
                  fullWidth
                  disabled={this.state.loadingSecret}
                >
                  <Translate content="common.back" />
                </Button>
              </Col>
            </Row>

            <ConfirmModal
              id={'confirmEditPosModal'}
              type="web"
              open={openModal}
              onConfirm={this.props.onConfirm}
              handleClose={this.handleCloseModal}
              pos={webPOS}
              account={accountSelected}
              submitting={this.props.submitting}
              currencyName={currencySelected && currencySelected.name}
              ids={ids}
            />
          </form>
        </React.Fragment>
      )
    );
  }
}

export default withGenericErrorHandling(
  blockIfEmailNotVerified(withRouter(withStyles(styles)(WebPOS)))
);
