import React from 'react';

import PropTypes from 'prop-types';

import { withRouter } from 'react-router';

import Translate from 'react-translate-component';

import { withStyles } from '@material-ui/core/styles';

import Button from '../../common/Button';
import InvalidFormMessage from '../../common/InvalidFormMessage';

const styles = theme => ({
  buttons: {
    marginTop: '60px',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
});

const SaveButtons = withRouter(
  withStyles(styles)(props => {
    const { classes, loading, cancelDisabled, onClick, errors, width } = props;
    return (
      <div style={{ marginTop: '60px' }}>
        <InvalidFormMessage errors={errors} />
        <div className={classes.actionContainer} style={{ width: width + '%' }}>
          <div style={{ flexGrow: '3', marginRight: '10px' }}>
            <Button
              id="save"
              onClick={onClick}
              variant="raised"
              color="primary"
              loading={loading}
              fullWidth
              type="submit"
            >
              <Translate content="common.save" />
            </Button>
          </div>
          <div style={{ flexGrow: '1' }}>
            <Button
              id="back"
              onClick={() => {
                props.history.goBack();
              }}
              disabled={cancelDisabled}
              variant="outlined"
              color="primary"
              fullWidth
            >
              <Translate content="common.abort" />
            </Button>
          </div>
        </div>
      </div>
    );
  })
);

SaveButtons.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  cancelDisabled: PropTypes.bool,
  errors: PropTypes.object,
  width: PropTypes.number,
};

SaveButtons.defaultProps = {
  width: 100,
};

export default SaveButtons;
