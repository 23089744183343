import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { FormSectionTitle } from '../../common/Forms/FormSectionTitle';
import Address from '../../common/Address';
import SelectField from '../../common/Forms/SelectField';
import Validators from '../../../utils/validators';
import ModificaBusinessFormLiberoProfessionista from './ModificaBusinessForm_LiberoProfessionista';
import ModificaBusinessFormSrl from './ModificaBusinessForm_Srl';
import { tipi_azienda } from '../constants/tipi_azienda';
import translator from 'counterpart';
import Translate from 'react-translate-component';
import { FEATURES_DISABLED } from '../../../constants/featuresDisabled';
import BusinessFormGeneric from './BusinessFormGeneric';
import filesize from 'filesize';

const styles = theme => ({
  sectionTitle: {
    fontSize: '16px',
    fontWeight: theme.typography.weights.normal,
    color: '#444444',
    marginBottom: '10px',
  },
  select: {
    marginBottom: '30px',
  },
  consensi: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    minHeight: '140px',
  },
});

const ModificaBusinessTypes = {
  srl: ModificaBusinessFormSrl,
  freelance: ModificaBusinessFormLiberoProfessionista,
  generic: BusinessFormGeneric,
};

class ModificaBusinessForm extends React.Component {
  static propTypes = {
    business: PropTypes.object.isRequired,
    positions: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    operationalFields: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    errors: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
  };

  componentDidMount() {
    this.props.form.mergeKeys = [
      'form$contacts$legal_address',
      'form$contacts$operational_address',
    ];
    if (this.props.initField) {
      this.setState({ ...this.props.initField });
    }
  }

  state = {
    legalAddress: '',
    operationalAddress: '',
    maxSizeFile: '',
  };

  handleLegalAddress = value => {
    // retrieve the address
    if (!value || value === '') {
      this.props.handleChangeGeneric('form$contacts$legal_address')(null);
      this.props.validateField('form$contacts$legal_address');
      this.setState({ legalAddress: '' });
    } else {
      const address_obj = new Address(value);
      const addressSDK = {
        city: address_obj.comune || address_obj.locality,
        country: address_obj.country,
        lat: address_obj.latitude,
        lon: address_obj.longitude,
        street: address_obj.street,
        zip: address_obj.postal_code,
        street_number: address_obj.street_number,
      };
      this.props.handleChangeGeneric('form$contacts$legal_address')(addressSDK);
      this.props.validateField('form$contacts$legal_address');
    }
  };

  handleOperationalAddress = value => {
    // retrieve the address
    if (!value || value === '') {
      this.props.handleChangeGeneric('form$contacts$operational_address')(null);
      this.props.validateField('form$contacts$operational_address');
    } else {
      const address_obj = new Address(value);
      this.setState({ operationalAddress: address_obj.full_address }, () => {
        const addressSDK = {
          city: address_obj.comune || address_obj.locality,
          country: address_obj.country,
          lat: address_obj.latitude,
          lon: address_obj.longitude,
          street: address_obj.street,
          zip: address_obj.postal_code,
          street_number: address_obj.street_number,
        };
        this.props.handleChangeGeneric('form$contacts$operational_address')(
          addressSDK
        );
        this.props.validateField('form$contacts$operational_address');
      });
    }
  };

  handleAddressChange = name => value => {
    this.setState({
      [name]: value,
    });
  };

  toggleCheckbox = field => {
    const value = this.props.business[field];
    this.props.handleChangeGeneric(field)(!value);
  };

  validateImage = (file, callback) => {
    // checks that the extension is one of the allowed ones
    if (!file) {
      return;
    }
    Validators.getMime(file, mime => {
      const formats = ['image/png', 'image/jpeg', 'image/bmp'];
      const isImage = formats.includes(mime);
      const maxSize = this.props.form.getRules(this.props.form.ids.form$logo)[
        'max_size'
      ][0];
      let tooLarge = file.size > Number(maxSize);
      const maxSizeString = filesize(maxSize);
      if (!isImage) {
        alert(translator('errors.formatFileNotValid'));
      } else {
        if (tooLarge) {
          alert(translator('errors.fileTooLarge', { maxSize: maxSizeString }));
        } else {
          this.props.signalError(this.props.form.ids.form$logo, null);
        }
      }
      callback(isImage && !tooLarge);
    });
  };

  render() {
    const {
      validating,
      classes,
      business,
      errors,
      disabled,
      editMode,
      positions,
      operationalFields,
      validateField,
      form,
      handleChangeGeneric,
      handleChange,
    } = this.props;

    const ModificaBusinessComponent = FEATURES_DISABLED.multiBusinessType
      ? ModificaBusinessTypes.generic
      : ModificaBusinessTypes[business.tipo_azienda];
    return (
      <Row>
        <Col xs>
          <FormSectionTitle>
            <Translate content="business.businessData" />
          </FormSectionTitle>
          {!FEATURES_DISABLED.multiBusinessType && (
            <Row className={classes.select}>
              <Col xs={6}>
                <SelectField
                  id="tipo_azienda"
                  value={business.tipo_azienda}
                  onChange={handleChange('tipo_azienda')}
                  disabled={disabled || editMode}
                  fullWidth
                  label="Tipo azienda"
                  placeholder={translator('placeholder.tipo')}
                  error={Boolean(errors.tipo_azienda)}
                  helperText={errors.tipo_azienda}
                >
                  {Object.keys(ModificaBusinessTypes).map((tipo, idx) => (
                    <MenuItem key={idx} value={tipo}>
                      {tipi_azienda[tipo]}
                    </MenuItem>
                  ))}
                </SelectField>
              </Col>
            </Row>
          )}
          {ModificaBusinessComponent && (
            <ModificaBusinessComponent
              handleChange={handleChange}
              validateImage={this.validateImage}
              handleLegalAddress={this.handleLegalAddress}
              handleOperationalAddress={this.handleOperationalAddress}
              toggleCheckbox={this.toggleCheckbox}
              onError={this.props.onError}
              disabled={disabled}
              errors={errors}
              validating={validating}
              editMode={editMode}
              positions={positions}
              operationalFields={operationalFields}
              form={form}
              validateField={validateField}
              handleChangeGeneric={handleChangeGeneric}
              business={business}
              legalAddress={this.state.legalAddress}
              operationalAddress={this.state.operationalAddress}
              handleAddressChange={this.handleAddressChange}
              initField={this.props.initField}
              originalImage={this.props.originalImage}
            />
          )}
        </Col>
      </Row>
    );
  }
}

export default withStyles(styles)(ModificaBusinessForm);
