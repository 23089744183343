import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import Modal from './CustomModal';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

const styles = theme => ({
  modalContainer: {
    margin: '50px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  modalTitle: {
    fontSize: '22px',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '5px',
  },
  innerContainer: {
    paddingRight: '45px',
    paddingLeft: '45px',
    justifyContent: 'center',
  },
  loaderContainer: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '15px',
  },
});

export const LoadingModal = withStyles(styles)(props => {
  const { id, open, handleClose, classes, title, subtitle } = props;

  const onClose = () => {
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <Modal id={id} open={open} handleClose={onClose}>
      <div>
        <div className={classes.loaderContainer}>
          <CircularProgress size="40px" />
        </div>
        <Typography
          variant="subheading"
          className={classnames([classes.modalTitle, 'montserrat'])}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="body1" className="color7">
            {subtitle}
          </Typography>
        )}
      </div>
    </Modal>
  );
});

LoadingModal.PropTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subtitle: PropTypes.string,
};
