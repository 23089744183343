import React from 'react';

import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Translate from 'react-translate-component';

import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DefaultButton from '@material-ui/core/Button';
import Button from './Button';
import { ActionLink } from './Links';
import { ROUTE_PARAMETERS, ROUTES } from '../../constants/routes';
import { buildPath } from '../../utils/routes';
import SandboxModal from './SandboxModal';
import Avatar from '@material-ui/core/Avatar';
import store from '../../store';
import { withRouter } from 'react-router-dom';

import { WebsiteLink } from './WebsiteLink';
import { PRODUCTION_HOSTNAME, SANDBOX_HOSTNAME } from '../../config';

const styles = theme => ({
  title: {
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '0px 10px',
  },
  menuItemRoot: {
    height: 'auto',
    minWidth: '320px',
    padding: '20px',
    cursor: 'default',
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&:pressed': {
      backgroundColor: 'inherit',
    },
  },
  shadow: theme.shadow,
  iconCaret: {
    width: '8px',
  },
  dropDownContainer: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 2px 4px 0 rgba(181, 193, 201, 0.6)',
  },
  menuListRoot: {
    padding: '0px',
    marginTop: '2px',
  },
  menuComponentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuIcon: {
    width: '22px',
    height: '22px',
    marginRight: '10px',
  },
  titleMenu: {
    color: theme.palette.primary.main,
  },
  menuItem: {
    minWidth: '160px',
    padding: '15px',
  },
  borderBottom: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.primary.bluegreydark,
  },
  fixTransform: {
    WebkitTransform: 'inherit !important',
    MozTransform: 'inherit !important',
    OTransform: 'inherit !important',
    transform: 'inherit !important',
    top: 'inherit !important',
    left: 'inherit !important',
    right: '0px',
    width: 'fit-content',
  },
  businessContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    width: '100%',
  },
  businessLogo: {
    width: '40px',
    height: '40px',
    marginRight: '5px',
  },
  businessDetail: {
    color: theme.palette.primary.main,
  },
  businessActionContainer: {
    marginTop: '10px',
    marginBottom: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  businessDivider: {
    backgroundColor: theme.palette.primary.bg,
    borderBottom: '1px solid ' + theme.palette.primary.bluegreydark,
  },
  addBusinessSection: {
    height: '75px',
    backgroundColor: theme.palette.common.white,
    borderTop: '1px solid',
  },
  addBusinessLogo: {
    width: '32px',
    marginRight: '5px',
  },
  addBusinessText: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  sandboxIcon: {
    height: '20px',
  },
  sandboxHint: {
    color: theme.palette.primary.bluegreydark,
    fontSize: '13px',
  },
  infoSandboxLogo: {
    marginLeft: '2px',
    height: '14px',
  },
});

class HeaderDropDown extends React.Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    children: PropTypes.arrayOf(PropTypes.object),
  };
  state = {
    open: false,
    sandboxModalOpen: false,
  };

  openSandboxModal = callbackSandbox => {
    if (store.app.sandboxMode) {
      this.setState({
        sandboxModalOpen: true,
        callbackSandbox: callbackSandbox,
      });
    } else {
      callbackSandbox();
    }
  };

  handleCloseSandboxModal = () => {
    this.setState({ sandboxModalOpen: false, callbackSandbox: null });
  };

  onConfirmExitSandbox = () => {
    this.state.callbackSandbox();
    this.setState({ sandboxModalOpen: false, callbackSandbox: null });
  };

  handleToggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleClose = event => {
    if (event && this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  render() {
    const { open, sandboxModalOpen } = this.state;
    const { classes, title, children, isBusiness, activeBusiness } = this.props;
    let BusinessList = isBusiness ? (
      <span>
        {activeBusiness && (
          <span>
            <BusinessMenuComponent
              item={activeBusiness}
              handleClose={this.handleClose}
              isSandbox={store.app.sandboxMode}
              openSandboxModal={this.openSandboxModal}
              isActive
            />

            {!store.app.sandboxMode && (
              <div className={classes.businessDivider}>
                <div style={{ padding: '15px 15px 5px' }}>
                  <Typography variant="body2" className="color7 uppercase f-12">
                    <Translate content="common.otherBusiness" />
                  </Typography>
                </div>
              </div>
            )}
          </span>
        )}
        {children &&
          children.length > 0 &&
          children.map((item, k) => (
            <BusinessMenuComponentNotActive
              item={item}
              handleClose={this.handleClose}
              key={k}
              openSandboxModal={this.openSandboxModal}
            />
          ))}
        {!store.app.sandboxMode && (
          <div className={classes.addBusinessSection}>
            <div
              style={{
                padding: '20px 25px 25px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                src={require('../../img/topbar/add.png')}
                alt="add"
                className={classes.addBusinessLogo}
              />
              <Typography
                variant="body1"
                className={classnames([classes.addBusinessText, 'montserrat'])}
                onClick={event => {
                  this.openSandboxModal(() => {
                    this.props.history.push(ROUTES.REGISTER_BUSINESS);
                    this.handleClose();
                  });
                }}
              >
                <Translate content="common.addBusiness" />
              </Typography>
            </div>
          </div>
        )}
      </span>
    ) : null;
    return (
      <div style={{ height: '50px' }}>
        <SandboxModal
          open={sandboxModalOpen}
          handleClose={this.handleCloseSandboxModal}
          onConfirm={this.onConfirmExitSandbox}
          nomeAzienda={store.app.business_name}
        />
        <DefaultButton
          onClick={this.handleToggle}
          buttonRef={node => {
            this.anchorEl = node;
          }}
          style={{ height: '100%' }}
          aria-owns={open ? 'menu-list-grow' : null}
          aria-haspopup="true"
        >
          <Typography
            variant="body2"
            className={classnames([classes.title, 'montserrat'])}
          >
            {title}
          </Typography>
          <img
            src={require('../../img/topbar/caret-topbar.png')}
            alt="show"
            className={classes.iconCaret}
          />
        </DefaultButton>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          className={classes.fixTransform}
          transition
          disablePortal
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin: 'top',
              }}
            >
              <div className={classes.dropDownContainer}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList
                    classes={{
                      root: classes.menuListRoot,
                    }}
                  >
                    {children &&
                      !isBusiness &&
                      children.map((item, k) => (
                        <MenuComponent
                          item={item}
                          handleClose={this.handleClose}
                          openSandboxModal={
                            item.openSandboxModal && this.openSandboxModal
                          }
                          key={k}
                        />
                      ))}
                    {isBusiness && BusinessList}
                  </MenuList>
                </ClickAwayListener>
              </div>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

const MenuComponent = withStyles(styles)(props => {
  const { classes, item, handleClose, openSandboxModal } = props;

  return (
    <MenuItem
      onClick={event => {
        if (openSandboxModal) {
          openSandboxModal(() => {
            item.onClick();
            handleClose();
          });
        } else {
          item.onClick();
          handleClose();
        }
      }}
      className={classnames([classes.borderBottom, classes.menuItem])}
    >
      <div className={classes.menuComponentContainer}>
        <img src={item.icon} alt={item.title} className={classes.menuIcon} />
        <span>
          <Typography
            variant="body2"
            className={classnames([classes.titleMenu, 'montserrat'])}
          >
            {item.title}
          </Typography>
          <Typography variant="caption">{item.subtitle}</Typography>
        </span>
      </div>
    </MenuItem>
  );
});

const BusinessMenuComponent = withRouter(
  withStyles(styles)(
    class extends React.Component {
      state = {
        buttonHover: false,
      };

      onMouseInButton = () => {
        this.setState({ buttonHover: true });
      };

      onMouseOutButton = () => {
        this.setState({ buttonHover: false });
      };

      sendToPayments = () => {
        const business_id = store.app.business_uuid;
        if (business_id) {
          this.props.history.push(
            buildPath(ROUTES.PAYMENT_ORDER_LIST, {
              [ROUTE_PARAMETERS.BUSINESS_ID]: business_id,
            })
          );
        }
      };

      toogleSandboxMode = () => {
        this.props.openSandboxModal(() => {
          let path = window.location.origin;
          if (this.props.isSandbox) {
            path = path.replace(SANDBOX_HOSTNAME, PRODUCTION_HOSTNAME);
          } else {
            path = path.replace(PRODUCTION_HOSTNAME, SANDBOX_HOSTNAME);
            path = path.concat(
              buildPath(ROUTES.PAYMENT_ORDER_LIST, {
                [ROUTE_PARAMETERS.BUSINESS_ID]: store.app.business_uuid,
              })
            );
          }
          window.location.assign(path);
          this.props.handleClose();
        });
      };

      render() {
        const { classes, item, handleClose, isSandbox, isActive } = this.props;
        return (
          <MenuItem
            disableTouchRipple
            classes={{
              root: classnames({
                [classes.borderBottom]: true,
                [classes.menuItemRoot]: true,
                [classes.shadow]: isActive,
              }),
            }}
          >
            <div className={classes.businessContainer}>
              <div className={classes.logoContainer}>
                <ActionLink
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={this.sendToPayments}
                >
                  <Avatar
                    src={item.logo}
                    alt={item.businessName}
                    classes={{ root: classes.businessLogo }}
                  />
                  <Typography variant="body2" className="f-16">
                    {item.businessName}
                  </Typography>
                </ActionLink>
                <ActionLink
                  style={{ fontStyle: 'normal', marginLeft: '20px' }}
                  onClick={event => {
                    this.props.history.push(
                      buildPath(ROUTES.BUSINESS_VIEW, {
                        [ROUTE_PARAMETERS.BUSINESS_ID]: item.businessId,
                      })
                    );
                    handleClose();
                  }}
                >
                  <Translate content="common.topbar.businessDetail" />
                </ActionLink>
              </div>
              {!isSandbox && (
                <React.Fragment>
                  <Typography variant="caption" className={classes.sandboxHint}>
                    <Translate content="common.topbar.sandboxInfo" />
                  </Typography>{' '}
                  <WebsiteLink to="sandbox_more_info">
                    <Translate content="common.more_info" />
                  </WebsiteLink>
                </React.Fragment>
              )}
              <div className={classes.businessActionContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  small
                  onMouseEnter={this.onMouseInButton}
                  onMouseLeave={this.onMouseOutButton}
                  onClick={this.toogleSandboxMode}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      className={classes.sandboxIcon}
                      src={
                        this.state.buttonHover
                          ? require('../../img/topbar/sandbox_white.png')
                          : require('../../img/topbar/sandbox.png')
                      }
                      alt=""
                    />
                    &nbsp;
                    {isSandbox ? (
                      <Translate content="common.logoutSandbox" />
                    ) : (
                      <Translate content="common.loginSandbox" />
                    )}
                  </div>
                </Button>
              </div>
            </div>
          </MenuItem>
        );
      }
    }
  )
);

const BusinessMenuComponentNotActive = withRouter(
  withStyles(styles)(props => {
    const { classes, item, handleClose } = props;
    return (
      <MenuItem
        disableTouchRipple
        classes={{
          root: classnames({
            [classes.borderBottom]: true,
            [classes.menuItemRoot]: true,
          }),
        }}
      >
        <div className={classes.logoContainer} style={{ marginBottom: '0px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              src={item.logo}
              alt={item.businessName}
              classes={{ root: classes.businessLogo }}
            />
            <Typography variant="body2" className="f-16">
              {item.businessName}
            </Typography>
          </div>
          <Button
            variant="raised"
            color="primary"
            small
            style={{ marginLeft: '15px', minWidth: '125px' }}
            onClick={() => {
              store.app.selectBusiness(item.businessId, () => {
                handleClose();
                props.history.push(
                  buildPath(ROUTES.PAYMENT_ORDER_LIST, {
                    [ROUTE_PARAMETERS.BUSINESS_ID]: item.businessId,
                  })
                );
              });
            }}
          >
            <Translate content="common.login" />
          </Button>
        </div>
      </MenuItem>
    );
  })
);
export class CustomBusinessMenuItem {
  constructor(businessName, businessId, logo) {
    this.businessName = businessName;
    this.businessId = businessId;
    this.logo = logo;
  }
}

export class CustomMenuItem {
  constructor(title, icon, onClick, subtitle, openSandboxModal = true) {
    this.title = title;
    this.icon = icon;
    this.onClick = onClick;
    this.subtitle = subtitle;
    this.openSandboxModal = openSandboxModal;
  }
}

export default withRouter(withStyles(styles)(HeaderDropDown));
