import React from 'react';
import Translate from 'react-translate-component';
import { withStyles } from '@material-ui/core/styles';
import PageContainer from '../common/PageContainer';
import PageTitle from '../common/PageTitle';
import Typography from '@material-ui/core/Typography';
import { ROUTES } from '../../constants/routes';
import Button from '../common/Button';
import TextField from '../common/Forms/TextField';
import { withFormsValidation } from '../common/Forms/FormsCommon';
import { withRouter, Redirect } from 'react-router-dom';
import store from '../../store';
import { withGenericErrorHandling } from '../common/ErrorHandling';
import translator from 'counterpart';
import { WebsiteLink } from '../common/WebsiteLink';

const styles = theme => ({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '50%',
    marginTop: '70px',
  },
  saveAction: {
    flex: 2,
    marginRight: '20px',
  },
  goBackAction: {
    flex: 1,
  },
  goBack: {
    width: '180px',
    marginTop: '55px',
  },
  consensi: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginTop: '40px',
  },
  marginBottom: {
    marginBottom: '20px',
  },
  sectionTitle: {
    fontSize: '16px',
    marginTop: '30px',
    marginBottom: '15px',
  },
});

class DeleteAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      deleted: false,
    };
  }

  componentDidMount() {
    this.props.initObject({
      [this.props.form.ids.uri$person_uuid]: store.app.profile.uuid,
    });
  }

  save = event => {
    event.preventDefault();
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.send(
          () => {
            store.app.deleteAccountCallback(() => {
              this.setState({
                deleted: true,
              });
              store.app.snackBar.open(translator('common.deleteOk'), 'success');
            });
          },
          null,
          () => {
            this.setState({
              loading: false,
            });
          }
        );
      }
    );
  };

  render() {
    const { handleChange, errors, object, validateField } = this.props;
    const { classes } = this.props;

    const ids = {
      password: this.props.form.ids.form$password,
    };
    const { deleted } = this.state;

    if (deleted) {
      return <Redirect to="/" />;
    }
    const hasBusiness = store.app.token.hasBusiness;

    return (
      <PageContainer main white top>
        <PageTitle
          title={<Translate content="gdpr.deleteAccount.title" />}
          parent={{
            text: <Translate content="profile.titles.goBack" />,
            url: ROUTES.PROFILE_VIEW,
          }}
        />
        <Typography variant="body1">
          <Translate content="gdpr.deleteAccount.subtitle" />
        </Typography>
        {!hasBusiness && (
          <form onSubmit={this.save}>
            <Typography variant="body2" className={classes.sectionTitle}>
              <Translate content="gdpr.deleteAccount.pwdSection" />
            </Typography>
            <div style={{ width: '50%' }}>
              <TextField
                error={Boolean(errors[ids.password])}
                helperText={errors[ids.password]}
                value={object[ids.password]}
                id={ids.password}
                name={ids.password}
                onChange={handleChange}
                label={<Translate content="profile.password" />}
                type="password"
                validateField={validateField}
              />
            </div>
          </form>
        )}
        {hasBusiness && (
          <Typography variant="body1" style={{ marginTop: '15px' }}>
            <Translate content="gdpr.deleteAccount.contactUs" />
            <WebsiteLink
              withoutIcon
              to="contact_us"
              style={{ textTransform: 'lowercase' }}
            >
              <Translate content="common.contactUs" />
            </WebsiteLink>
            .
          </Typography>
        )}
        {!hasBusiness && (
          <div className={classes.actions}>
            <Button
              variant="raised"
              color="primary"
              type="submit"
              customClass={classes.saveAction}
              onClick={this.save}
              fullWidth
              loading={this.state.loading}
            >
              <Translate content="gdpr.deleteAccount.action" />
            </Button>
            <Button
              variant="outlined"
              color="primary"
              customClass={classes.goBackAction}
              fullWidth
              loading={this.state.loading}
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              <Translate content="common.abort" />
            </Button>
          </div>
        )}
        {hasBusiness && (
          <Button
            variant="outlined"
            color="primary"
            customClass={classes.goBack}
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <Translate content="common.back" />
          </Button>
        )}
      </PageContainer>
    );
  }
}
//TODO IMPELEMTNS THIS
export default withGenericErrorHandling(
  withFormsValidation('DeletePersonForm')(
    withRouter(withStyles(styles)(DeleteAccount))
  )
);
