import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { STATES } from './constants/states';
import { CallbackModal } from './common/CallbackModal';
import { ROUTES } from '../../../constants/routes';

import store from '../../../store';

import {
  ACTIONS_HANDLER,
  INVALID_CALLBACK,
  LOADED_CALLBACKS,
  OPEN_MODAL,
  REINITIALIZE,
  RESET,
  RESETTING,
  SELECT_CALLBACK,
  SENT_REQUEST,
  SOCKET_FATAL,
  SOCKET_KO,
  SOCKET_OK,
} from './constants/transition';
import PaymentAPI from '../../../api/Payments';
import { SelectCallbackLibrary } from './SelectCallbackComponent';
import Payment from '../../common/Payment';
import { SelectCallback } from './SelectCallbackComponent/SelectCallbackComponent';
import { withRouter } from 'react-router';
import { buildPath } from '../../../utils/routes';

const styles = theme => ({
  container: {
    border: '1px solid',
    borderColor: theme.palette.primary.bluegrey,
    backgroundColor: theme.palette.primary.bg,
    borderRadius: '6px',
    marginTop: '55px',
    marginBottom: '80px',
  },
  innerContainer: {
    padding: '25px 40px 35px 25px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  iconColumn: {
    width: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  icon: {
    width: '26px',
    height: '26px',
  },
  textColumn: {
    flex: 12,
  },
  title: {
    color: theme.palette.primary.main,
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
});

export const SandboxTestPayment = withRouter(
  withStyles(styles)(
    class extends React.Component {
      static propTypes = {
        payment_uuid: PropTypes.string.isRequired,
      };

      constructor(props) {
        super(props);
        this.state = {
          state: STATES.LOADING_CALLBACKS,
          openModal: false,
        };
      }

      initializeComponent = () => {
        PaymentAPI.getCallback(
          this.props.payment_uuid,
          response => {
            this.dispatchAction({
              actionType: LOADED_CALLBACKS,
              payload: {
                ...response,
                canReset: this.props.payment.status !== 'pending',
              },
            });
          },
          () => {
            this.props.history.push(buildPath(ROUTES.GENERIC_ERROR));
          }
        );
      };

      componentDidMount() {
        store.app.websocket_payments.subscribe(this.props.payment_uuid);
        this.initializeComponent();
      }

      componentWillUnmount() {
        this.switchOffSocket();
      }

      dispatchAction = ({ actionType, payload }) => {
        const newState = ACTIONS_HANDLER[actionType](this.state, payload);
        this.setState(newState);
      };

      sendRequest = () => {
        this.dispatchAction({
          actionType: OPEN_MODAL,
        });

        PaymentAPI.paymentOrderStatusUpdate(
          this.props.payment_uuid,
          this.state.selected_callback,
          () => {
            const timeoutID = setTimeout(this.launchFatalError, 60 * 1000);
            store.app.websocket_payments.on(
              'payment.callback.sent',
              this.onSocketSuccess,
              this.launchFatalError
            );
            this.dispatchAction({
              actionType: SENT_REQUEST,
              payload: {
                timeoutID: timeoutID,
              },
            });
          },
          () => {
            this.dispatchAction({
              actionType: INVALID_CALLBACK,
            });
          }
        );
      };

      closeModal = () => {
        if (this.state.paymentResponse) {
          this.props.updatePayment(this.state.paymentResponse);
        }
        this.dispatchAction({
          actionType: REINITIALIZE,
        });
        this.initializeComponent();
      };

      switchOffSocket = () => {
        store.app.websocket_payments.off('payment.callback.sent');
        clearTimeout(this.state.timeout);
      };

      launchFatalError = () => {
        PaymentAPI.reset(
          this.props.payment_uuid,
          payment => {
            this.dispatchAction({
              actionType: SOCKET_FATAL,
              payload: {
                payment: new Payment(payment),
              },
            });
          },
          () => {
            this.props.history.push(buildPath(ROUTES.GENERIC_ERROR));
          }
        );

        this.switchOffSocket();
      };

      onSocketSuccess = response => {
        const payload = {
          paymentResponse: new Payment(response.payment_order),
          request: response.request.body,
          response: response.response ? response.response.body : null,
          httpStatus: response.response ? response.response.status : null,
        };

        let actionType = SOCKET_OK;
        if (!response.response || response.response.status > 299) {
          actionType = SOCKET_KO;
        }
        this.dispatchAction({
          actionType: actionType,
          payload: payload,
        });
        this.switchOffSocket();
      };

      selectCallback = callback_selected => {
        this.dispatchAction({
          actionType: SELECT_CALLBACK,
          payload: {
            what: callback_selected,
          },
        });
      };

      resetPayment = () => {
        this.dispatchAction({
          actionType: RESETTING,
        });
        PaymentAPI.reset(
          this.props.payment_uuid,
          payment => {
            this.dispatchAction({
              actionType: RESET,
              payload: {
                payment: new Payment(payment),
              },
            });
          },
          () => {
            this.props.history.push(buildPath(ROUTES.GENERIC_ERROR));
          }
        );
      };

      render() {
        const { classes } = this.props;
        const { state, availableCallbacks } = this.state;
        const SelectComponent = SelectCallbackLibrary[state] || SelectCallback;
        return (
          <div className={classes.container}>
            <div className={classes.innerContainer}>
              {SelectComponent && (
                <SelectComponent
                  callbacks={availableCallbacks}
                  selectCallback={this.selectCallback}
                  sendRequest={this.sendRequest}
                  selected_callback={this.state.selected_callback}
                  resetPayment={this.resetPayment}
                  canReset={this.state.canReset}
                  state={state}
                />
              )}
              {this.state.openModal && (
                <CallbackModal
                  fitContent
                  open={this.state.openModal}
                  callback_name={this.state.selected_callback}
                  state={state}
                  closeModal={this.closeModal}
                  payment={this.state.paymentResponse}
                  oldPayment={this.props.payment}
                  resetPayment={this.resetPayment}
                  response={this.state.response}
                  request={this.state.request}
                  httpStatus={this.state.httpStatus}
                />
              )}
            </div>
          </div>
        );
      }
    }
  )
);
