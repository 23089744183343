import { ROUTE_PARAMETERS_VALUE } from '../../constants/routes';

export default {
  types: {
    [ROUTE_PARAMETERS_VALUE.ACCOUNT.bitcoin]: 'Bitcoin Account',
    [ROUTE_PARAMETERS_VALUE.ACCOUNT.bank]: 'Bank Account',
  },
  sandboxMode: {
    advise:
      'It is not possible to create new accounts in sandbox mode,' +
      'use the ones available.',
  },
  loading: 'Loading accounts in progress ...',
  list: {
    title: 'Your accounts',
    subtitle:
      'Receive money in your bank account with weekly payments,' +
      'or directly in your bitcoin account.',
    add: 'Create account',
    faq: {
      WHAT: 'What is an account?',
      TYPE: 'What types of accounts can I create?',
      MANY: 'Can I create multiple accounts?',
    },
    suggestion: {
      generic: {
        title: 'Create your first account',
        faq: 'Do you want more information about the accounts? ',
      },
      bank: {
        alert: 'Create your first account',
        hint:
          'Do you prefer to manage bitcoins and bank accounts you are used to?',
        title: 'Bank account',
        suggestion: 'Receive euros directly on your account!',
        faq: 'Do you want more information on bank accounts? ',
      },
      bitcoin: {
        alert: 'Create your first bitcoin account',
        hint:
          'Do you want to receive your money faster on your wallet and have more control over your funds?',
        title: 'Bitcoin Account',
        suggestion: 'Receive payments directly in your wallet!',
        faq: 'Do you want more information about bitcoin accounts? ',
      },
      faqLink: 'Check out our FAQs',
    },
  },
  detail: {
    actions: {
      viewPayment: 'Payment orders',
      edit: 'edit',
      remove: 'remove',
    },
    title: 'Details',
    publicKey: 'Public Key',
    holder: 'Accountholder',
    bankName: 'Institute',
    iban: 'IBAN',
    bic: 'BIC',
    poses: 'POS that sends money to this account',
    emptyPoses: 'None',
  },
  new: {
    titles: {
      title: 'Create a new account',
      parent: 'Back to the accounts list ',
    },
    action: 'Create account',
    ok: 'Account successfully created !',
    hint: {
      general: 'Do you want more information about the accounts? ',
      faq: 'Check out our FAQs',
    },
    subtitle:
      'An account allows you to receive money sent by your customers' +
      ' through the POS.',
    types: {
      title: 'What kind of account do you want to create?',
      disabled:
        'Dear customer, in order to complete the activation of your account, we must perform verifications ' +
        'for the anti-money laundering directive. We ask you to send an email to ',
      disabled2:
        ' attaching a copy of your chamber of commerce registration document, not' +
        ' older than 6 months from the registration date. ',
      disabled3: 'We will reply as soon as possible.',
      bank: {
        text: 'Bank account',
        commissione: '1% FEE',
        paymentTime: 'WEEKLY PAYMENTS',
        features: {
          1: 'Credit to your account by bank transfer',
          2: 'Instant exchange',
          3: 'No volatility risk',
        },
      },
      titleChoiche: 'Your account',

      bitcoin: {
        text: 'Bitcoin account',
        commissione: '0% FEE',
        paymentTime: 'IMMEDIATE PAYMENTS',
        features: {
          1: 'Instant credit in your wallet',
          2: 'Compatible with all wallets on the market',
          3: 'No commission',
        },
      },
    },
    bank: {
      title: 'Enter your bank account details',
      holder: 'Account Manager',
      bankName: 'Institute',
      iban: 'IBAN',
      bic: 'BIC',
    },
    bitcoin: {
      chainside: {
        title: 'Use Chainside Wallet (5 minutes)',
        text:
          'Install the chainsidePay app from the Google Play or Apple Store and scan the QRcode on the side.',
        text2:
          'You will be ready to get bitcoins on your new wallet right away',
      },
      other: {
        title: 'Use Other Portfolio (15-30 minutes)',
        subtitle:
          'To receive the funds on a wallet other than ChainsidePay we ' +
          ' need the extended public key of the chosen wallet. The ' +
          ' process may take some time but quiet, we will guide you step by step! ',
        subtitle_chainside_disabled:
          'To receive the funds on your bitcoin wallet we need ' +
          ' your extended public key. The process may take a while but quiet, we will guide you step by step! ',
        hintStep: 'Have trouble completing this step?',
        pastePublicKey:
          'Now that you have found your key, enter it in the field below.',
        step1: {
          title: 'Choose a wallet',
          subtitle:
            'To guarantee immediate credit on your favorite wallet, ' +
            'you will need to create an account dedicated exclusively to your ChainsidePay account. ' +
            'Follow these simple steps to find the ' +
            'extended public key of your new account.',
          subtitle2: 'Which of these wallets do you want to use?',
          warning: {
            text:
              'For a correct operation you will have to create an account ' +
              'dedicated to transactions made through ChainsidePay. ' +
              "So, don't use accounts:",
            case1:
              'on which you have already received or plan to receive transactions;',
            case2:
              'of which you have already generated (and shared with third parties) addresses' +
              'of reception.',
          },
          walletNotFound: "I can't find my wallet",
          goBack: 'Change wallet',
        },
        step2: {
          title: 'Create a dedicated bitcoin account',
          goBack: 'Read again',
          subtitle:
            'ChainsidePay must be linked to an account on your wallet %(walletName)s that is dedicated exclusively to ChainsidePay transactions.',
          firstPoint: 'If you have not yet created your wallet %(walletName)s,',
          firstPoint2: 'do it now and proceed to the next step.',
          secondPoint:
            "If you have already created a %(walletName)s wallet but haven't done " +
            'any transaction,',
          secondPoint2:
            'you can proceed to the next step but make sure you do not receive other incoming payments from sources other than Chainsidepay.',
          thirdPoint: 'If you are already using the wallet %(walletName)s ',
          thirdPoint2:
            'and you have made inbound or outbound transactions, you can create a new bitcoin account to be used exclusively for ChainsidePay. Here is how: ',
          thirdPointExplanation:
            'If you are already using the wallet %(walletName)s ',
          thirdPointExplanation2:
            'and you have made inbound or outbound transactions you have two options. ',
          thirdPoint3:
            'The first is to create a second wallet %(walletName)s from scratch (recommended)',
          thirdPoint4:
            'The second possibility is to create a new bitcoin account using a different wallet from the list',
          thirdPoint4Back: 'previous step.',
        },
        step3: {
          title: 'Enter the extended public key of your wallet',
          subtitle:
            'The extended public key will allow Chainside Pay to send' +
            'money to your wallet. To find it, follow these steps: ',
        },
        ledger: {
          step2: {
            description:
              'Leger wallet allows the creation of multiple Bitcoin accounts. It is necessary to create one dedicated to ChainsidePay on which no other incoming payments will be received. ',
            steps: {
              1: '1. Click on the Accounts menu at the bottom of the main screen, then click on the + button at the top right to create a new account ',
              2: '2. At the following screen, you can assign a name to the new account to make it recognizable ',
            },
          },
          step3: {
            description:
              'The extended public key (xpub) will allow ChainsidePay to' +
              'send money to your wallet. To find it, follow these steps: ',
            steps: {
              1: '1. From the screen that opened once the account has been created, you can find the "extended public key" field to copy ',
            },
          },
        },
        myceliumAndroid: {
          step2: {
            description:
              'Mycelium allows the creation of multiple Bitcoin accounts. It is essential to create one dedicated to ChainsidePay on which no other incoming payments will be received. ',
            steps: {
              1: '1. From the "ACCOUNT" menu, click on the "Add account" button',
              2: '2. From the screen that opens, click "Add HD Account" ',
              3: '3. If you wish, give the new account a name (for example "ChainsidePay") and select it from the "CONTI" menu',
            },
          },
          step3: {
            description:
              'The extended public key (xpub) will allow ChainsidePay to' +
              'send money to your wallet. To find it, follow these steps: ',
            steps: {
              1: '1. Select the MyCelium account dedicated exclusively to ChainsidePay ',
              2: '2. From the menu at the top right, select "Export account keys".',
            },
          },
        },
        electrum: {
          step3: {
            description:
              'WARNING: ChainsidePay to work properly must' +
              'be connected to a new dedicated wallet, to avoid' +
              'to run into problems be sure not to receive others' +
              'incoming payments on the same wallet used by' +
              'ChainsidePay. The extended public key (xpub) will allow ' +
              'ChainsidePay to send money to your wallet, to extract it' +
              'follow the following steps:',
            steps: {
              1: '1. From the "Portfolios" menu select "Information"',
              2:
                '2. Copy the string from the "Public Key" + field ' + //TODO
                'main. \n',
            },
          },
        },
        myCeliumIOS: {
          step2: {
            steps: {
              1: '1. Click on the Accounts menu at the bottom of the main screen, then click on the + button at the top right to create a new account ',
              2: '2. At the following screen, you can assign a name to the new account to make it recognizable ',
            },
          },
          step3: {
            description:
              'Mycelium allows the creation of multiple Bitcoin accounts. It is essential to create one dedicated to ChainsidePay on which no other incoming payments will be received. ',
            steps: {
              1: '1. From the screen that opened once the account has been created, you can find the public key field to copy ',
            },
          },
        },
        samourai: {
          step3: {
            description:
              'WARNING: ChainsidePay to work properly must  ' +
              'a new dedicated wallet to be connected, to avoid ' +
              "run into problems make sure you don't get more" +
              'incoming payments on the same wallet used by' +
              'ChainsidePay. The extended public key (xpub) will allow ' +
              'ChainsidePay to send money to your wallet, to extract it' +
              'follow the following steps:',
            steps: {
              1: '1. From the main menu, open "Settings" ',
              2: '2. From the Settings, open "Wallet" ',
              3: '3. From the Wallet menu select one of "View XPUB BIP44", "View YPUB Segwit" or "View ZPUB Segwit", and copy the string that is shown ',
            },
          },
        },
      },
    },
  },
  edit: {
    bitcoin: {
      titles: {
        title: 'Bitcoin account modification',
        parent: 'Return to the list of accounts',
      },
      subtitle: 'On this page you can change the name of your bitcoin account.',
      name: 'Account name',
    },
    bank: {
      titles: {
        title: 'Edit Bank Account',
        parent: 'Return to the list of accounts',
      },
      subtitle: 'On this page you can change the name of your bank account.',
      name: 'Account name',
      intestatario: 'Account holder',
      IBAN: 'IBAN',
      instituto: 'Banking Institute',
      bic: 'Bank BIC',
    },
  },
  filter: {
    all: 'All',
    bitcoin: 'Bitcoin Accounts',
    bank: 'Bank Accounts',
  },
  delete: {
    confirm: {
      title: 'Are you sure you want to remove the account?',
      subtitle:
        'Continuing you can no longer use the %(type)s "%(accountName)s" to receive money',
      buttons: {
        confirm: 'Remove',
      },
    },
    loading: {
      title: 'Removal in progress ...',
    },
    success: {
      message: 'Account successfully removed.',
    },
    notEmpty: {
      title: 'The account could not be removed',
      subtitle:
        'This account is associated with active POS, therefore it is not possible to remove it.',
      listHeading: 'Here is the list of active POS associated:',
    },
  },
};
