import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Translate from 'react-translate-component';
import Helper from '../../common/Helper';
import { withRouter } from 'react-router-dom';
import { ROUTE_PARAMETERS, ROUTES } from '../../../constants/routes';
import { buildPath } from '../../../utils/routes';
import { observer } from 'mobx-react';
import store from '../../../store';

const styles = theme => ({
  container: {
    border: '1px solid',
    borderColor: theme.palette.grey.light,
    backgroundColor: theme.palette.common.white,
    width: '650px',
    height: '110px',
    borderRadius: '6px',
    boxShadow: '0 2px 4px 0 #d7dfe5',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '2px 2px 5px #aaaaaa',
    },
  },
  title: {
    fontSize: '22px',
    fontFamily: theme.typography.fontFamilies.accent,
    fontStyle: 'italic',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  icon: {
    ...theme.icon.large,
  },
});

export const BankHelper = observer(
  withRouter(
    withStyles(styles)(props => {
      const business_uuid = props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
      const onClick = () => {
        props.history.push(
          buildPath(ROUTES.ACCOUNT_NEW, {
            [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
          })
        );
      };
      return (
        <Helper
          onClick={onClick}
          comingSoon={!store.app.hasBankAccounts}
          icon={
            <img
              src={require('../../../img/bancario/large.png')}
              alt="mobilepos"
              className={props.classes.icon}
            />
          }
          text={
            <React.Fragment>
              <Typography variant="subheading" className={props.classes.title}>
                <Translate content="account.list.suggestion.bank.title" />
              </Typography>
              <Typography variant="caption">
                <Translate content="account.list.suggestion.bank.suggestion" />
              </Typography>
            </React.Fragment>
          }
        />
      );
    })
  )
);
