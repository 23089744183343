import * as React from 'react';

import Translate from 'react-translate-component';
import translator from 'counterpart';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { RouteComponentProps } from 'react-router-dom';

import PageContainer from '../../common/PageContainer';

import AccountsAPI from '../../../api/Accounts';
import PaymentsAPI from '../../../api/Payments';
import PosAPI from '../../../api/POS';
import MobilePosAPI from '../../../api/MobilePoS';

import {
  ROUTE_FILTERS,
  ROUTE_PARAMETERS,
  ROUTE_PARAMETERS_VALUE,
  ROUTES,
} from '../../../constants/routes';

import Payment from '../../common/Payment';
import WelcomeModal, {
  WelcomeModalSandboxMode,
} from '../../common/WelcomeModal';
import LoadingComponent from './LoadingComponent';
import PosIcon from './PosIcon';
import AccountIcon from './AccountIcon';
import SubHeader, { MessageFilter, TabFilter } from '../../common/SubHeader';
import { withGenericErrorHandling } from '../../common/ErrorHandling';
import { buildPath } from '../../../utils/routes';
import {
  SortableTable,
  SortableTableHead,
  SortableTableBody,
} from '../../common/SortableTable';
import { styles } from './styles';
import { EmptyListAccount } from './EmptyListAccount';
import { EmptyListPos } from './EmptyListPos';
import { EmptyList } from './EmptyList';
import { ListaPagamentiRow } from './ListaPagamentiRow';
import { LoadMorePayments } from './LoadMorePayments';

const columns = [
  {
    name: 'created_at',
    label: 'payment.fields.created_at',
    sortable: true,
    width: 2,
  },
  {
    name: 'amount',
    label: 'payment.fields.amount',
    sortable: true,
    width: 2,
    rightAlign: true,
  },
  {
    name: 'crypto_amount',
    label: 'payment.fields.crypto_amount',
    sortable: true,
    width: 2,
    rightAlign: true,
  },
  {
    name: 'status',
    label: 'payment.fields.status',
    sortable: false,
    width: 2,
  },
  {
    name: 'pos_name',
    label: 'payment.fields.pos_name',
    sortable: false,
    width: 2,
  },
  {
    name: 'reference',
    label: 'payment.fields.reference',
    sortable: false,
    width: 2,
  },
];

enum PaymentStatusFilter {
  All = 'all',
  Completed = 'completed',
  NotCompleted = 'not_completed',
  Disputed = 'disputed',
  Other = 'other',
}

const filterPaymentsByStatus = (
  payments: Payment[],
  filter: PaymentStatusFilter
) => {
  if (filter === PaymentStatusFilter.All) {
    return payments;
  } else if (filter === PaymentStatusFilter.Completed) {
    return payments.filter(payment => payment.confirmed);
  } else if (filter === PaymentStatusFilter.NotCompleted) {
    return payments.filter(payment => payment.notConfirmed);
  } else if (filter === PaymentStatusFilter.Disputed) {
    return payments.filter(payment => payment.disputed);
  } else if (filter === PaymentStatusFilter.Other) {
    return payments.filter(payment => payment.otherState);
  } else {
    return payments;
  }
};

const filterPaymentsByPOS = (payments: Payment[], pos_uuid: string) => {
  return payments.filter(payment => payment.created_by.uuid === pos_uuid);
};

const filterPaymentByAccount = (payments: Payment[], account_uuid: string) => {
  return payments.filter(
    payment =>
      payment.deposit_account && payment.deposit_account.uuid === account_uuid
  );
};

// TODO: this is lazy, make it more precise
interface ListaPagamentiRouteParams {
  [key: string]: string;
}

interface ListaPagamentiProps
  extends RouteComponentProps<ListaPagamentiRouteParams>,
    WithStyles<typeof styles> {
  onError: () => void;
}

interface ListaPagamentiState {
  loading: boolean;
  loadingMore: boolean;
  page: number;
  totalPages?: number;
  filter_type?: string;
  filter_uuid?: string;
  business_uuid?: string;
  filterName?: string;
  selected: PaymentStatusFilter;
  order: 'asc' | 'desc';
  orderBy: string;
  data: Payment[];
  filteredData: Payment[];
}

class ListaPagamenti extends React.Component<
  ListaPagamentiProps,
  ListaPagamentiState
> {
  state = {
    loading: true,
    loadingMore: false,
    page: 0,
    totalPages: undefined,
    selected: PaymentStatusFilter.All,
    order: 'desc' as 'asc' | 'desc',
    orderBy: 'created_at',
    data: [] as Payment[],
    filteredData: [] as Payment[],
    filter_type: undefined,
    filter_uuid: undefined,
    business_uuid: undefined,
    filterName: undefined,
  };

  componentDidMount() {
    this.checkFilters(this.props);
  }

  componentDidUpdate(prevProps: ListaPagamentiProps) {
    if (
      JSON.stringify(this.props.match.params) !==
      JSON.stringify(prevProps.match.params)
    ) {
      this.checkFilters(this.props);
    }
  }

  checkFilters = (props: ListaPagamentiProps) => {
    const filter_type =
      props.match.params[ROUTE_PARAMETERS.PAYMENT_ORDER_FILTER_TYPE];
    const filter_uuid =
      props.match.params[ROUTE_PARAMETERS.PAYMENT_ORDER_FILTER_ID];
    const business_uuid = props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    this.setState(
      {
        filter_type: filter_type,
        filter_uuid: filter_uuid,
        business_uuid: business_uuid,
        loading: true,
        data: [],
      },
      () => {
        if (filter_type && filter_uuid) {
          if (filter_type === ROUTE_FILTERS.PAYMENT_ORDER.btc) {
            AccountsAPI.getBitcoinAccount(
              business_uuid,
              filter_uuid,
              (result: { name: string }) => {
                this.setState(
                  {
                    filterName: result.name,
                    loading: false,
                  },
                  this.loadPayments
                );
              },
              this.props.onError
            );
          } else if (filter_type === ROUTE_FILTERS.PAYMENT_ORDER.bank) {
            AccountsAPI.getBankAccount(
              business_uuid,
              filter_uuid,
              (result: { bankname: string }) => {
                this.setState(
                  {
                    filterName: result.bankname,
                    loading: false,
                  },
                  this.loadPayments
                );
              },
              this.props.onError
            );
          } else if (filter_type === ROUTE_FILTERS.PAYMENT_ORDER.webpos) {
            PosAPI.getDetailWeb(
              filter_uuid,
              (result: { name: string }) => {
                this.setState(
                  {
                    filterName: result.name,
                    loading: false,
                  },
                  this.loadPayments
                );
              },
              this.props.onError
            );
          } else if (filter_type === ROUTE_FILTERS.PAYMENT_ORDER.mobilepos) {
            MobilePosAPI.getDetailMobile(
              filter_uuid,
              (result: { name: string }) => {
                this.setState(
                  {
                    filterName: result.name,
                    loading: false,
                  },
                  this.loadPayments
                );
              },
              this.props.onError
            );
          }
        } else {
          this.loadPayments();
        }
      }
    );
  };

  removeFilter = () => {
    this.setState({
      filter_type: undefined,
      filter_uuid: undefined,
    });
    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    this.props.history.push(
      buildPath(ROUTES.PAYMENT_ORDER_LIST, {
        [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
      })
    );
  };

  loadPayments = () => {
    this.setState({
      loading: true,
    });
    const { page, orderBy, order } = this.state;
    PaymentsAPI.getList(
      page,
      orderBy,
      order,
      (result: any) => {
        const payments = result.paymentorders.map((p: any) => new Payment(p));
        this.setState(
          {
            data: payments,
            totalPages: result.total_pages,
          },
          this.filterPayments
        );
      },
      this.props.onError
    );
  };

  loadMorePayments = () => {
    this.setState({
      loadingMore: true,
    });
    const { page, orderBy, order, totalPages } = this.state;
    if (!totalPages || page + 1 >= totalPages) return;
    this.setState({ loadingMore: true });
    const newPage = page + 1;
    PaymentsAPI.getList(
      newPage,
      orderBy,
      order,
      (result: any) => {
        const payments = result.paymentorders.map((p: any) => {
          return new Payment(p);
        });
        this.setState(
          {
            page: newPage,
            data: this.state.data.concat(payments),
          },
          this.filterPayments
        );
      },
      this.props.onError
    );
  };

  filterPayments = () => {
    const { filter_type, filter_uuid, selected, totalPages, page } = this.state;
    let filteredData: Payment[] = this.state.data;
    const hasMorePages = totalPages && page + 1 < totalPages;

    if (filter_type && filter_uuid) {
      if (
        filter_type === ROUTE_FILTERS.PAYMENT_ORDER.bank ||
        filter_type === ROUTE_FILTERS.PAYMENT_ORDER.btc
      ) {
        filteredData = filterPaymentByAccount(filteredData, filter_uuid);
      } else if (
        filter_type === ROUTE_FILTERS.PAYMENT_ORDER.webpos ||
        filter_type === ROUTE_FILTERS.PAYMENT_ORDER.mobilepos
      ) {
        filteredData = filterPaymentsByPOS(filteredData, filter_uuid);
      } else {
        filteredData = [];
      }
    } else {
      filteredData = filterPaymentsByStatus(filteredData, selected);
    }

    if (filteredData.length === 0 && hasMorePages) {
      this.setState(
        {
          loading: false,
          loadingMore: true,
          filteredData: filteredData,
        },
        this.loadMorePayments
      );
    } else {
      this.setState({
        loading: false,
        loadingMore: false,
        filteredData: filteredData,
      });
    }
  };

  selectPayment = (payment_id: string) => {
    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    this.props.history.push(
      buildPath(ROUTES.PAYMENT_ORDER_VIEW, {
        [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
        [ROUTE_PARAMETERS.PAYMENT_ORDER_ID]: payment_id,
      })
    );
  };

  updateSort = (column_name: string) => {
    const newState: Partial<ListaPagamentiState> = {
      loading: true,
      page: 0,
    };
    if (this.state.orderBy === column_name) {
      if (this.state.order === 'asc') {
        newState.order = 'desc';
      } else {
        newState.order = 'asc';
      }
    } else {
      newState.orderBy = column_name;
      newState.order = 'desc';
    }
    this.setState(newState as ListaPagamentiState, this.loadPayments);
  };

  render() {
    const { classes } = this.props;

    const {
      filteredData: displayedData,
      data: payments,
      order,
      orderBy,
      loading,
      loadingMore,
      selected,
      filter_uuid,
      filter_type,
      filterName,
      page,
      totalPages,
    } = this.state;

    const hasMorePages = totalPages && page + 1 < totalPages;

    // let FilterComponent = SubHeader;
    let FilterComponent = TabFilter;
    let filterComponentProps: {
      onClick: (value: PaymentStatusFilter) => void;
      value: string;
      values_dict: string | null;
      message?: React.ReactNode;
      removeFilter?: () => void;
    } = {
      onClick: value => this.setState({ selected: value }, this.filterPayments),
      value: selected,
      values_dict:
        payments && payments.length === 0
          ? null
          : translator('payment.filters.standard'),
    };

    let EmptyListComponent = EmptyList;

    if (filter_type && filter_uuid) {
      FilterComponent = MessageFilter;
      if (
        filter_type === ROUTE_FILTERS.PAYMENT_ORDER.bank ||
        filter_type === ROUTE_FILTERS.PAYMENT_ORDER.btc
      ) {
        filterComponentProps.message = (
          <React.Fragment>
            {' '}
            {translator('payment.filters.' + filter_type)}:{' '}
            <AccountIcon
              className={classes.iconFilter}
              accountType={
                filter_type === ROUTE_FILTERS.PAYMENT_ORDER.btc
                  ? ROUTE_PARAMETERS_VALUE.ACCOUNT.bitcoin
                  : ROUTE_PARAMETERS_VALUE.ACCOUNT.bank
              }
            />{' '}
            {filterName}
          </React.Fragment>
        );
        EmptyListComponent = EmptyListAccount;
      } else if (filter_type === ROUTE_FILTERS.PAYMENT_ORDER.webpos) {
        filterComponentProps.message = (
          <React.Fragment>
            {translator('payment.filters.' + filter_type)}:{' '}
            <PosIcon className={classes.iconFilter} posType="webpos" />{' '}
            {filterName}
          </React.Fragment>
        );
        EmptyListComponent = EmptyListPos;
      } else if (filter_type === ROUTE_FILTERS.PAYMENT_ORDER.mobilepos) {
        filterComponentProps.message = (
          <React.Fragment>
            {translator('payment.filters.' + filter_type)}:{' '}
            <PosIcon className={classes.iconFilter} posType="mobilepos" />{' '}
            {filterName}
          </React.Fragment>
        );
        EmptyListComponent = EmptyListPos;
      }
      filterComponentProps.removeFilter = this.removeFilter;
    }

    const showLoadingComponent =
      loading || (displayedData.length === 0 && loadingMore);
    const showEmptyList =
      !loading && !loadingMore && displayedData.length === 0 && !hasMorePages;
    const showList = !loading && displayedData.length > 0;

    return (
      <React.Fragment>
        <WelcomeModal />
        <WelcomeModalSandboxMode />
        <SubHeader
          description={<Translate content="payment.list.subtitle" />}
          withoutFilter={
            loading ||
            (payments &&
              payments.length === 0 &&
              filter_type !== ROUTE_FILTERS.PAYMENT_ORDER.bank &&
              filter_type !== ROUTE_FILTERS.PAYMENT_ORDER.btc &&
              filter_type !== ROUTE_FILTERS.PAYMENT_ORDER.webpos &&
              filter_type !== ROUTE_FILTERS.PAYMENT_ORDER.mobilepos)
          }
        >
          {!loading && <FilterComponent {...filterComponentProps} />}
        </SubHeader>
        <PageContainer main grey>
          {showLoadingComponent && (
            <LoadingComponent text={translator('payment.list.loading')} />
          )}
          {showEmptyList && (
            <EmptyListComponent
              businessId={this.state.business_uuid}
              idFilter={this.state.filter_uuid}
              removeFilter={this.removeFilter}
            />
          )}
          {showList && (
            <React.Fragment>
              <SortableTable>
                <SortableTableHead
                  columns={columns}
                  order={order}
                  orderBy={orderBy}
                  sortData={this.updateSort}
                />
                <SortableTableBody>
                  {displayedData.map(payment => (
                    <ListaPagamentiRow
                      key={payment.uuid}
                      payment={payment}
                      onClick={this.selectPayment}
                    />
                  ))}
                </SortableTableBody>
              </SortableTable>
              <LoadMorePayments
                hasMorePages={hasMorePages}
                loadingMore={loadingMore}
                onClick={this.loadMorePayments}
              />
            </React.Fragment>
          )}
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default withGenericErrorHandling(withStyles(styles)(ListaPagamenti));
