import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ConfirmSensitiveActionModal } from '../../common/Confirm';
import Translate from 'react-translate-component';

const styles = theme => ({
  innerContainer: {
    textAlign: 'center',
  },
});

class RemoveAccountModal extends React.Component {
  render() {
    const {
      accountName,
      accountType,
      open,
      handleClose,
      classes,
      onConfirm,
      submitting,
      ...otherProps
    } = this.props;

    const accType = <Translate content={'account.types.' + accountType} />;

    return (
      <ConfirmSensitiveActionModal
        {...otherProps}
        open={open}
        handleClose={handleClose}
        title={<Translate content="account.delete.confirm.title" />}
        subtitle={
          <Translate
            content="account.delete.confirm.subtitle"
            with={{ accountName: accountName, type: accType }}
          />
        }
        confirmButtonText={
          <Translate content="account.delete.confirm.buttons.confirm" />
        }
        onConfirm={onConfirm}
        submitting={submitting}
      />
    );
  }
}

export default withStyles(styles)(RemoveAccountModal);
