import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import classnames from 'classnames';
import Translate from 'react-translate-component';
import { PaymentTransaction } from '../PaymentTransaction';
import { PayloadSection } from '../PayloadSection';
import Button from '../../../../common/Button';
import React from 'react';
import { modalStyles } from './style';

export const SuccessCallbackModal = withStyles(modalStyles)(props => {
  const { classes, oldPayment, payment, request, response, httpStatus } = props;
  return (
    <div className={classes.container}>
      <img
        src={require('../../../../../img/payment-testing/success.png')}
        alt=""
        className={classes.topIcon}
      />
      <Typography
        variant="title"
        className={classnames([classes.successTitle, 'f-22 monteserrat'])}
      >
        <Translate content="payment.testing.success.title" />
      </Typography>
      <Typography variant="body1">
        <Translate content="payment.testing.success.text" />
      </Typography>
      <PaymentTransaction
        oldStatus={oldPayment.status}
        status={payment.status}
        oldValue={oldPayment.cryptoAmountString}
        value={payment.cryptoAmountString}
      />
      <PayloadSection
        httpStatus={httpStatus}
        request={request}
        response={response}
      />
      <Button
        variant="raised"
        color="primary"
        small
        onClick={props.closeModal}
        style={{ marginTop: '37px', width: '220px' }}
      >
        <Translate content="common.ok" />
      </Button>
    </div>
  );
});
