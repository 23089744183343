import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Translate from 'react-translate-component';
import Button from './Button';
import { withCookies } from 'react-cookie';
import store from '../../store';
import { WebsiteLink } from './WebsiteLink';

const style = theme => ({
  container: {
    width: '360px',
    border: '1px solid',
    borderColor: theme.palette.primary.bluegreydark,
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '6px',
    ...theme.shadow,
    '@media (max-width: 800px)': {
      width: '90%',
      left: 'auto',
      maxWidth: '400px',
    },
  },
  innerContainer: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  headerRow: {
    display: 'flex',
    flex: '1 0 100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: '15px',
  },
  icon: {
    height: '12px',
    width: '12px',
    cursor: 'pointer',
  },
  description: {
    marginRight: '30px',
    '@media (max-width: 400px)': {
      marginRight: '15px',
    },
  },
  subtitle: {
    fontSize: '12px',
    color: theme.palette.grey.cookieAlert,
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '15px',
  },
  actionButton: {
    marginRight: '15px',
    width: '100px',
    height: '32px',
  },
  link: {
    fontSize: '12px',
  },
});

class CookieAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: !props.cookies.get('cookie_notice_accepted'),
    };
  }
  setCookie = () => {
    store.app.setCookiePolicy();
    this.close();
  };

  close = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    const { classes } = this.props;
    const { visible } = this.state;

    return (
      visible && (
        <div className={classes.container} id="cookie_alert">
          <div className={classes.innerContainer}>
            <div className={classes.headerRow}>
              <Typography variant="body2">
                <Translate content="common.cookieAlert.title" />
              </Typography>
              <img
                onClick={this.close}
                src={require('../../img/others/close.png')}
                alt=""
                className={classes.icon}
                id="close_cookie_alert"
              />
            </div>
            <div className={classes.description}>
              <Typography className={classes.subtitle} variant="caption">
                <Translate content="common.cookieAlert.subtitle" />
              </Typography>
            </div>
            <div className={classes.actionRow}>
              <Button
                variant="raised"
                color="primary"
                onClick={this.setCookie}
                customClass={classes.actionButton}
                small
              >
                <Translate content="common.cookieAlert.action" />
              </Button>
              <WebsiteLink to="cookie_policy">
                <Translate className={classes.link} content="common.moreInfo" />
              </WebsiteLink>
            </div>
          </div>
        </div>
      )
    );
  }
}

const DecoratedCookieAlert = withStyles(style)(withCookies(CookieAlert));

export { DecoratedCookieAlert as CookieAlert };
