export default {
  operational_field: {
    food_and_drinks_services: 'Ristorazione',
    constructions: 'Edilizia',
    education: 'Educazione',
    financial_services: 'Finanza',
    retail_trade_and_shops: 'Commercio',
    information_and_communication: 'Informazione e Comunicazione',
    technologies: 'Tecnologia',
    other: 'Altro',
  },
  position: {
    business_admin: 'Amministratore',
  },
};
