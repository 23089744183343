import React from 'react';
import { getErrorStringFromErrorsCode } from '../../../utils/validators';
import store from '../../../store';
import translator from 'counterpart';
import {
  BusinessPanelValidationError,
  BusinessPanelError,
} from 'business-panel-sdk/lib/errors';

export const withFormsValidation = formName => WrappedComponent => {
  return class extends React.Component {
    state = {
      errors: {},
      object: {},
      formSdk: store.app.getFormFactory.make(formName),
    };

    validateField = fieldName => {
      const { errors } = this.state.formSdk.validatePartial(
        fieldName,
        this.state.object
      );
      let localErrors = {};
      Object.keys(errors).forEach(key => {
        localErrors[key] = getErrorStringFromErrorsCode(errors[key]);
      });
      this.setState({ errors: localErrors });
    };

    isValid = () => {
      return Object.keys(this.state.errors).length === 0;
    };

    handleChange = event => {
      const object = this.state.object;
      object[event.target.id] = event.target.value;
      this.cleanErrorOnField(event.target.id);
      this.setState({ object: object });
    };

    cleanErrorOnField = fieldName => {
      let errors = this.state.errors;
      delete errors[fieldName];
      this.setState({ errors: errors });
    };

    validate = callback => {
      const { valid, errors } = this.state.formSdk.validate(this.state.object);
      this.handleErrorsOnSubmit(errors);
      if (callback) {
        callback(valid);
      }
    };

    send = (onSuccess, onError = () => {}, onFinally = () => {}) => {
      const { valid, errors } = this.state.formSdk.validate(this.state.object);
      if (valid) {
        this.state.formSdk
          .send(this.state.object)
          .then(onSuccess)
          .catch(error => {
            if (error instanceof BusinessPanelValidationError) {
              this.handleErrorsOnSubmit(error.errors);
            } else if (error instanceof BusinessPanelError) {
              store.app.snackBar.displayError(error);
            } else {
              store.app.snackBar.open(
                translator('messages.generic.ko'),
                'error'
              );
            }
            if (onError) {
              onError(valid, error);
            }
          })
          .finally(() => {
            if (onFinally) {
              onFinally(valid);
            }
          });
      } else {
        this.handleErrorsOnSubmit(errors);
        if (onError) {
          onError(valid);
        }
        if (onFinally) {
          onFinally();
        }
      }
    };

    initObject = (object, callback) => {
      this.setState({ object: object }, callback);
    };

    handleChangeGeneric = name => value => {
      const object = this.state.object;
      object[name] = value;
      this.cleanErrorOnField(name);
      this.setState({ object: object }, () => {
        this.validateField(name);
      });
    };

    handleErrorsOnSubmit = sdkErrors => {
      if (!sdkErrors || Object.keys(sdkErrors).length === 0) {
        return;
      }
      let errors = {};
      Object.keys(sdkErrors).forEach(key => {
        errors[key] = getErrorStringFromErrorsCode(sdkErrors[key]);
      });

      this.setState({ errors: errors });
    };

    signalError = (fieldName, error) => {
      const errors = this.state.errors;
      errors[fieldName] = error;
      this.setState({ errors: errors });
    };

    toggleCheckbox = fieldName => {
      this.handleChangeGeneric(fieldName)(!this.state.object[fieldName]);
    };

    render() {
      const { errors, object, formSdk } = this.state;
      return (
        formSdk && (
          <WrappedComponent
            errors={errors}
            object={object}
            validateField={this.validateField}
            handleChange={this.handleChange}
            handleChangeGeneric={this.handleChangeGeneric}
            initObject={this.initObject}
            validate={this.validate}
            handleErrorsOnSubmit={this.handleErrorsOnSubmit}
            send={this.send}
            form={formSdk}
            signalError={this.signalError}
            cleanErrorOnField={this.cleanErrorOnField}
            toggleCheckbox={this.toggleCheckbox}
            {...this.props}
          />
        )
      );
    }
  };
};
