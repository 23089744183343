import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import Modal from './CustomModal';
import Translate from 'react-translate-component';
import Button from '../common/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ActionLink } from './Links';
import store from '../../store';
import authAPI from '../../api/Authentication';

const styles = theme => ({
  container: {
    padding: '5px 40px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    height: '100%',
  },
  modalTitle: {
    color: theme.palette.primary.main,
    padding: '0px 10px',
    fontSize: '22px',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '20px',
  },
  loader: {
    marginRight: '8px',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  mailSended: {
    display: 'flex',
    alignItems: 'center',
  },
  successSmall: {
    marginRight: '5px',
    ...theme.icon.icon22,
  },
  succesBig: {
    ...theme.icon.medium,
    alignSelf: 'center',
    margin: '40px 0px 10px',
  },
  mailSendedText: {
    color: theme.palette.green.main,
  },
});

class EmailNotVerified extends React.Component {
  state = {
    mailSended: false,
    mailSending: false,
    mailVerified: false,
  };

  componentDidMount() {
    store.app.onEmailVerified(() => {
      this.setState({ mailVerified: true });
    });
  }

  inviaMail = () => {
    this.setState({ mailSending: true }, () => {
      authAPI.resendEmail(
        store.app.getProfile.uuid,
        () => {
          this.setState({ mailSended: true, mailSending: false });
        },
        error => {
          store.app.snackBar.displayError(error);
          this.setState({ mailSending: false });
        }
      );
    });
  };
  render() {
    const { open, goBack, classes, blocking, closeModal } = this.props;
    const { mailSending, mailSended, mailVerified } = this.state;
    return (
      <Modal open={open}>
        {!mailVerified && (
          <div className={classes.container}>
            <Typography
              variant="subheading"
              className={classnames([classes.modalTitle, 'montserrat'])}
            >
              <Translate
                content={
                  blocking
                    ? 'common.emailNotVerified.modal.titleBlocking'
                    : 'common.emailNotVerified.modal.title'
                }
              />
            </Typography>
            <Typography variant="body1" className="color7 f-16">
              <Translate content="common.emailNotVerified.modal.subtitle1" />
            </Typography>
            <Typography
              variant="body1"
              className="color7 f-16"
              style={{ marginBottom: '20px' }}
            >
              <Translate content="common.emailNotVerified.modal.subtitle2" />
            </Typography>
            <div className={classes.actionContainer}>
              {mailSended && (
                <div className={classes.mailSended}>
                  <img
                    src={require('../../img/message/success.png')}
                    alt="ok"
                    className={classes.successSmall}
                  />
                  <Typography
                    variant="caption"
                    className={classnames([classes.mailSendedText, 'f-16'])}
                  >
                    <Translate content="common.mailSended" />
                  </Typography>
                </div>
              )}
              {mailSended || (
                <ActionLink
                  customClasses="f-16"
                  onClick={this.inviaMail}
                  forward={!mailSending}
                >
                  {mailSending ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <CircularProgress
                        size="22px"
                        className={classes.loader}
                      />
                      <Translate content="common.sending" />
                    </div>
                  ) : (
                    <Translate content="common.sendAgain" />
                  )}
                </ActionLink>
              )}
            </div>
            <Button
              variant="outlined"
              color="primary"
              onClick={goBack}
              style={{ marginTop: '30px', width: '250px', alignSelf: 'center' }}
            >
              <Translate content="common.back" />
            </Button>
          </div>
        )}
        {mailVerified && (
          <div className={classes.container}>
            <img
              src={require('../../img/message/success.png')}
              alt="ok"
              className={classes.succesBig}
            />
            <Typography
              variant="caption"
              className={classnames([
                classes.mailSendedText,
                'f-22 montserrat',
              ])}
            >
              <Translate content="common.verifiedEmail" />
            </Typography>
            <Button
              variant="raised"
              color="primary"
              onClick={closeModal}
              style={{ marginTop: 'auto', width: '250px', alignSelf: 'center' }}
            >
              <Translate content="common.goOn" />
            </Button>
          </div>
        )}
      </Modal>
    );
  }
}

export default withStyles(styles)(EmailNotVerified);
