import React from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import classnames from 'classnames';

const posIconByType = {
  webpos: (
    <img src={require('../../../img/webPos/active/small.png')} alt="webPos" />
  ),
  web: (
    <img src={require('../../../img/webPos/active/small.png')} alt="webPos" />
  ),
  mobilepos: (
    <img
      src={require('../../../img/mobilePos/active/small.png')}
      alt="mobilePos"
    />
  ),
  mobile: (
    <img
      src={require('../../../img/mobilePos/active/small.png')}
      alt="mobilePos"
    />
  ),
};

const styles = theme => ({
  posIconAvatar: {
    height: '24px',
    width: '24px',
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'middle',
    marginRight: '7px',
  },
  posIcon: {
    height: '12px',
    width: '12px',
    verticalAlign: 'baseline',
  },
});

const PosIcon = withStyles(styles)(props => {
  const { className, classes, posType } = props;
  const PosIcon = posIconByType[posType];
  return (
    <React.Fragment>
      {React.cloneElement(PosIcon, {
        className: classnames([classes.posIconAvatar, className]),
      })}
    </React.Fragment>
  );
});

PosIcon.propTypes = {
  posType: PropTypes.string.isRequired,
};

export default PosIcon;
