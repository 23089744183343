import React, { FC, Fragment } from 'react';
import { CurrencySectionProps } from './propTypes';
import { Col, Row } from 'react-flexbox-grid';
import { FormSectionTitle } from '../../../../common/Forms/FormSectionTitle';
import Translate from 'react-translate-component';
import Typography from '@material-ui/core/Typography/Typography';
import SelectField from '../../../../common/Forms/SelectField';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { Currency } from '../../../../../types/general';
import translator from 'counterpart';
import styles from './theme';
import { withStyles } from '@material-ui/core';

const CurrencySection: FC<CurrencySectionProps> = ({
  currencyId,
  handleChange,
  error,
  currencies,
  value,
  classes = {},
}) => (
  <Fragment>
    <Row
      style={{
        marginTop: '40px',
      }}
    >
      <Col xs={12}>
        <FormSectionTitle>
          <Translate content="POS.currency.title" />
        </FormSectionTitle>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Typography variant="caption" style={{ marginBottom: '25px' }}>
          <Translate content="POS.currency.subtitle" />
        </Typography>
      </Col>
    </Row>
    <Row className={classes.rowMargin}>
      <Col xs={4}>
        <SelectField
          id={currencyId}
          name={currencyId}
          value={value}
          label={<Translate content="POS.mobile.currency" />}
          onChange={handleChange}
          fullWidth
          disabled={currencies.length < 2}
          error={Boolean(error)}
          helperText={error}
          placeholder={translator('placeholder.currency')}
        >
          {currencies.map((item: Currency, k: number) => {
            return (
              <MenuItem value={item.uuid} key={k}>
                {item.name}
              </MenuItem>
            );
          })}
        </SelectField>
      </Col>
    </Row>
  </Fragment>
);

export default withStyles(styles)(CurrencySection);
