export default {
  ibanEmpty: 'Insert an IBAN',
  ibanFormat: 'IBAN format invalid',
  ibanCountry: 'Invalid country code',
  ibanFormatCountry: 'IBAN format not valid for the country',
  ibanControlCode: 'Invalid control code',
  ibanGeneric: 'Insert a valid IBAN',

  intestatarioName: 'Enter the name of the account holder',
  instituteName: 'Enter the name of the institution',
  bicValid: "Enter the branch's BIC",
  requiredField: 'Required Field',
  alpha: 'The field must be alphabetic',
  alphadash: 'The field must be alphabetical with a dash',
  alphanum: 'The field must be alphanumeric',
  at_least_one_upper: 'The field must contain at least one capital letter',
  at_least_one_lower: 'The field must contain at least one lowercase letter',
  at_least_one_number: 'The field must contain at least one number',
  at_least_one_symbol:
    'The field must contain at least one symbol between %(param1)s',
  equals_to: 'The field %(param1)s must be equal to the field %(param2)s',
  between: 'The field must be between %(param1)s and %(param2)s',
  betweenlen:
    'The field must be long minimum %(param1)s and maximum %(param2)s characters',
  date_format: 'The date must be in the format %(param1)s',
  decimal: 'The field must be in decimal format',
  equals: 'The field must be equal to %(param1)s',
  hex: 'The field must be hexadecimal',
  https_url: 'The field must be an https URL', // TODO
  in: 'The field must be one of the following values %(param1)s', // TODO
  len: 'The field must be long %(param1)s characters',
  max: 'The field cannot be greater than %(param1)s',
  maxlen: 'The field must be at most% long %(param1)s characters',
  min: 'The field must be minimum %(param1)s',
  minlen: 'The field must be at least %(param1)s long',
  mergeParamRequired: 'The field must contain %(param1)s',
  nullableIf: 'Enter %(param2)s or select %(param1)s',
  only_numbers: 'The field can only be numeric', // TODO what is parameter 0 here
  regex: 'Invalid format',
  type: 'The field must be %(param1)s',
  type_error_params: {
    ISO_8601_date: 'a date in ISO 8601 format',
    array: 'an array of values',
    base58: 'a string of characters in the base58',
    base64: 'a character string in base64',
    base64_encoded_file: 'a file in base64 encoding',
    boolean: 'a true / false value',
    email: 'email',
    float: 'a number with comma',
    integer: 'an integer',
    object: 'an object',
    phone: 'a phone number',
    sequence: 'a sequence',
    string: 'a string of characters',
    url: 'a URL (must start with http: // or https: //)',
    uuid: 'a uuid',
  },
  operational_field_tmp_rule: 'Specify the sector',
  emailValid: 'Enter a valid email address',
  types: {
    new_email: 'New Email',
    new_email_confirmation: 'Confirm email',
    new_password: 'New Password',
    new_password_confirmation: 'Confirm new password',
    password: 'Password',
    password_confirmation: 'Confirm password',
    street_number: 'house number',
    zip: 'the cap',
    street: 'the street',
    country: 'the country',
    city: 'the city',
    lat: 'latitude',
    lon: 'longitude',
    name: 'Sector',
    description: 'Sector of your company',
    vat: 'VAT number',
    no_vat_available: 'the "I have no VAT number" field',
    store_address: 'Point of sale address',
    no_store_address_available:
      '"This POS field does not have a fixed address" field',
  },
  consensoRichiesto: 'Requested consent',

  passwordShort: 'The password entered is too short',
  passwordConfirm: 'Passwords do not match',

  changePasswordConfirm: 'Password changed successfully',
  changePasswordError: 'Error changing password',

  accettareTermini: 'Accept the terms',

  validAddress: 'Enter a valid address',

  fileNotValid: 'Invalid file',
  formatFileNotValid: 'The file format is invalid',
  fileTooLarge: 'The maximum file size is %(maxSize)s',
  invalidURl: 'The url must start with http: // or https: //',

  companyType: 'Select a company type',
  validPhone: 'Enter a valid phone number',
  sector: 'Select a sector',
  role: 'Select a role',
  store_address: 'Store address',
  no_store_address_available: '"This POS has no fixed address"',

  dataLoading: 'Error loading data.',

  backend: {
    '1003': 'Invalid credentials',
    '1004': 'Expired login credentials',
    '1006': 'Expired login credentials',
    '1007': 'Expired login credentials',
    '1012': 'Expired login credentials',
    '3007': 'Expired login credentials',
    '1020': 'Email already exists',
    '3014': 'Public key already exists',
    '3020': 'The extended public key format inserted is not supported for the selected wallet',
    '0003': 'Logo too big',
    '1005': 'An error has occurred. Please try again later',
    '1008': 'You do not have permission to access this business',
    '1023': 'The VAT number is already present in our systems',
    '1024':
      'It is not possible to cancel the account, as a company is already present',
    '0014': 'Invalid VAT',
    '3010': 'Invalid public key',
    '3001': 'Resource not found',
  },
  name: 'Enter a name',

  chargingData: 'There was a problem uploading data. Please try again.',
  emailInvalid: 'Enter a valid email',
  invalidForm: 'Warning: There are some errors in the form',
  404: {
    title: 'The page you are looking for does not exist!',
    utils: 'Here are some links that could be useful:',
  },
  500: {
    title: 'Something went wrong :(',
    text:
      'Try to reload the page or return to the previous page. If the problem persists, contact us. ',
    or: 'or go back to',
    problemGoOn: 'If the problem persists,',
  },
  403: {
    title: 'You do not have permission to access this page.',
  },
  error: 'Error',

  getListPos: 'Error retrieving POS list',
  noCookies: {
    title: {
      row1: 'It appears that your browser has cookies disabled.',
      row2: 'Activate them to continue using the site.',
    },
    utils: {
      row1: 'Here are some links you might find useful.',
      row2: 'If instead you think there is a problem, contact us.',
    },
    links: {
      enableCookies: {
        text: 'How to enable cookies',
        url:
          'https://www.wikihow.com/Enable-Cookies-in-Your-Internet-Web-Browser',
      },
    },
  },
  noScript: {
    title: {
      row1: 'No Javascript No Party.',
      row2: 'Enable Javascript to continue using the site.',
    },
    utils:
      "Here are some links that you might find useful. \nIf you think there's a problem, contact us.",
    links: {
      enableJavascript: 'How to enable Javascript',
    },
  },
  genericErrorPage: {
    title: 'Something went wrong :(',
    utils:
      'Try to reload the page or return to the previous page. If the problem persists, contact us. ',
  },
};
