import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { BaseRoute } from './BaseRoute';

const ConditionalRoute = ({
  component: Component,
  componentProps,
  condition,
  fallbackRoute,
  ...rest
}) => {
  return (
    <BaseRoute
      render={props => {
        if (condition) {
          return <Component {...props} {...componentProps} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: fallbackRoute,
                state: { from: props.location },
              }}
            />
          );
        }
      }}
      {...rest}
    />
  );
};

ConditionalRoute.propTypes = {
  component: PropTypes.func.isRequired,
  condition: PropTypes.bool.isRequired,
  fallbackRoute: PropTypes.string.isRequired,
};

export { ConditionalRoute };
