import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import classnames from 'classnames';
import Translate from 'react-translate-component';
import { ActionLink } from '../../../../common/Links';
import { PayloadSection } from '../PayloadSection';
import { PaymentTransaction } from '../PaymentTransaction';
import Button from '../../../../common/Button';
import React from 'react';
import { modalStyles } from './style';
import { WebsiteLink } from '../../../../common/WebsiteLink';

export const ErrorCallbackModal = withStyles(modalStyles)(props => {
  const { classes, oldPayment, payment, httpStatus, request, response } = props;
  return (
    <div className={classes.container}>
      <img
        src={require('../../../../../img/payment-testing/alert.png')}
        alt=""
        className={classes.topIcon}
      />
      <Typography
        variant="title"
        className={classnames([
          classes.invalidCallbacktitle,
          'f-22 monteserrat',
        ])}
      >
        <Translate content="payment.testing.error.title" />
      </Typography>
      <div className={classes.helperTextContainer}>
        <Typography variant="body1" s>
          <Translate content="payment.testing.error.text" />
          <ActionLink style={{ marginLeft: '2px', display: 'inline-block' }}>
            <Translate content="common.documentation" />
          </ActionLink>
        </Typography>
        <Typography variant="body1" style={{ marginRight: '2px' }}>
          <Translate content="payment.testing.error.chainsideProblem" />
        </Typography>
        <WebsiteLink withoutIcon to="contact_us">
          <Translate content="common.contactUsLowercase" />
        </WebsiteLink>
      </div>
      <PayloadSection
        isOpen
        httpStatus={httpStatus}
        request={request}
        response={response}
      />
      <PaymentTransaction
        oldStatus={oldPayment.status}
        status={payment.status}
        oldValue={oldPayment.cryptoAmountString}
        value={payment.cryptoAmountString}
      />
      <Button
        variant="raised"
        color="primary"
        small
        style={{ marginTop: '37px', width: '220px' }}
        onClick={props.closeModal}
      >
        <Translate content="common.ok" />
      </Button>
    </div>
  );
});
