import React from 'react';

import { Prompt } from 'react-router-dom';

import { Col, Row } from 'react-flexbox-grid';

import Translate from 'react-translate-component';
import translator from 'counterpart';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import TextField from '../../common/Forms/TextField';
import PhoneField from '../../common/Forms/PhoneField';

import PageContainer from '../../common/PageContainer';
import PageTitle from '../../common/PageTitle';
import { withGenericErrorHandling } from '../../common/ErrorHandling';

import store from '../../../store/index';

import { ROUTES } from '../../../constants/routes';

import SaveButtons from '../common/SaveButtons';
import { withFormsValidation } from '../../common/Forms/FormsCommon';
import { buildPath } from '../../../utils/routes';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  confirmPassword: {
    marginBottom: '15px',
    marginTop: '60px',
  },
  buttons: {
    marginTop: '60px',
  },
});

class ModificaDettagliUtente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      loading: false,
      loading_data: true,
      failed: false,
      phoneError: false,
    };
  }

  componentDidMount() {
    this.props.initObject(
      {
        [this.props.form.ids.form$name]: store.app.profile.first_name,
        [this.props.form.ids.form$surname]: store.app.profile.last_name,
        [this.props.form.ids.form$contacts$phone]: store.app.profile.phone,
        [this.props.form.ids.uri$person_uuid]: store.app.profile.uuid,
      },
      this.setState({
        loading_data: false,
      })
    );
  }

  handlePhoneChange = phoneNumber => {
    this.props.handleChange({
      target: {
        id: this.props.form.ids.form$contacts$phone,
        value: phoneNumber,
      },
    });
  };

  save = event => {
    event.preventDefault();
    this.setState({
      loading: true,
    });

    this.props.send(
      () => {
        store.app.snackBar.open(
          translator('profile.edit.submit.success'),
          'success'
        );
        this.setState(
          {
            submitted: true,
          },
          () => {
            store.app.reloadInfo(() => {
              this.props.history.push(buildPath(ROUTES.PROFILE_VIEW));
            });
          }
        );
      },
      null,
      () => {
        this.setState({
          loading: false,
        });
      }
    );
  };

  render() {
    const { errors, handleChange, object, validateField } = this.props;

    const { submitted, loading, loading_data } = this.state;

    const form_disabled = loading || loading_data;

    const ids = {
      phone: this.props.form.ids.form$contacts$phone,
      name: this.props.form.ids.form$name,
      surname: this.props.form.ids.form$surname,
    };

    return (
      <PageContainer main white style={{ paddingTop: '40px' }}>
        <Prompt
          message={translator('common.exitAlertMessage')}
          when={!submitted}
        />
        <PageTitle
          title={<Translate content="profile.titles.edit" />}
          parent={{
            text: <Translate content="profile.titles.goBack" />,
            url: ROUTES.PROFILE_VIEW,
          }}
        />
        <Typography
          variant="caption"
          style={{ marginBottom: '40px', marginTop: '-25px' }}
        >
          <Translate content="profile.edit.subtitle" />{' '}
        </Typography>
        <form onSubmit={this.save}>
          {loading_data && <CircularProgress />}
          {!loading_data && (
            <React.Fragment>
              <Row>
                <Col xs={12} sm={6} md={6}>
                  <TextField
                    disabled={form_disabled}
                    error={Boolean(errors[ids.name])}
                    helperText={errors[ids.name]}
                    value={object[ids.name]}
                    id={ids.name}
                    name={ids.name}
                    onChange={handleChange}
                    label={
                      <Translate content="profile.edit.fields.firstName.label" />
                    }
                    validateField={validateField}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={6}>
                  <TextField
                    disabled={form_disabled}
                    error={Boolean(errors[ids.surname])}
                    helperText={errors[ids.surname]}
                    value={object[ids.surname]}
                    id={ids.surname}
                    name={ids.surname}
                    onChange={handleChange}
                    label={
                      <Translate content="profile.edit.fields.lastName.label" />
                    }
                    validateField={validateField}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={6}>
                  <PhoneField
                    disabled={form_disabled}
                    error={Boolean(errors[ids.phone]) || this.state.phoneError}
                    helperText={errors[ids.phone]}
                    defaultValue={object[ids.phone]}
                    id={ids.phone}
                    name={ids.phone}
                    onChange={this.handlePhoneChange}
                    label={
                      <Translate content="profile.edit.fields.phone.label" />
                    }
                    placeholder={translator(
                      'profile.edit.fields.phone.placeholder'
                    )}
                    validateField={validateField}
                  />
                </Col>
              </Row>

              <SaveButtons
                cancelDisabled={form_disabled}
                loading={loading}
                errors={errors}
                width={50}
                onClick={this.save}
              />
            </React.Fragment>
          )}
        </form>
      </PageContainer>
    );
  }
}

export default withFormsValidation('UpdatePersonForm')(
  withGenericErrorHandling(withStyles(styles)(ModificaDettagliUtente))
);
