import React, { FC } from 'react';
import { AccountsSectionProps } from './propTypes';
import { LinkPrimary } from '../../../../../common/Links';
import { buildPath } from '../../../../../../utils/routes';
import { ROUTE_PARAMETERS, ROUTES } from '../../../../../../constants/routes';
import Translate from 'react-translate-component';

const AccountsSection: FC<AccountsSectionProps> = ({
  hasAccounts,
  business_uuid,
  pos_uuid,
  isMobile,
}) =>
  hasAccounts ? (
    <LinkPrimary
      id={'associateAccount'}
      forward
      to={buildPath(isMobile ? ROUTES.POS_EDIT_MOBILE : ROUTES.POS_EDIT_WEB, {
        [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
        [ROUTE_PARAMETERS.POS_ID]: pos_uuid,
      })}
    >
      <Translate content="POS.detail.actions.associateAccount" />
    </LinkPrimary>
  ) : (
    <div>
      <Translate content={'POS.detail.state.noAccountsAvailable'} />
      &nbsp;&nbsp;
      <LinkPrimary
        id={'createNewAccount'}
        forward
        to={buildPath(ROUTES.ACCOUNT_NEW, {
          [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
        })}
      >
        <Translate content="POS.detail.actions.createAccount" />
      </LinkPrimary>
    </div>
  );

export default AccountsSection;
