import { ROUTE_PARAMETERS_VALUE } from '../../constants/routes';

export default {
  types: {
    [ROUTE_PARAMETERS_VALUE.ACCOUNT.bitcoin]: 'Conto Bitcoin',
    [ROUTE_PARAMETERS_VALUE.ACCOUNT.bank]: 'Conto Bancario',
  },
  sandboxMode: {
    advise:
      'In un account di sandbox non è possibile creare nuovi conti,' +
      ' utilizzare quelli forniti.',
  },
  loading: 'Caricamento conti in corso...',
  list: {
    title: 'I tuoi conti',
    subtitle:
      'Ricevi il denaro in un conto bancario con pagamenti settimanali, ' +
      ' oppure direttamente in un conto bitcoin.',
    add: 'Crea conto',
    faq: {
      WHAT: "Che cos'è un conto?",
      TYPE: 'Che tipo di conto posso creare?',
      MANY: 'Posso creare conti diversi?',
    },
    suggestion: {
      generic: {
        title: 'Crea il tuo primo conto',
        faq: 'Vuoi maggiori informazioni riguardo i conti? ',
      },
      bank: {
        alert: 'Crea il tuo primo conto bancario',
        hint:
          'Non vuoi gestire i bitcoin ma usare i conti bancari a cui sei abituato?',
        title: 'Conto Bancario',
        suggestion: 'Ricevi direttamente in Euro sul tuo conto!',
        faq: 'Vuoi maggiori informazioni sui conti bancari? ',
      },
      bitcoin: {
        alert: 'Crea il tuo primo conto bitcoin',
        hint:
          'Vuoi ricevere il tuo denaro senza attese e avere maggiore controllo dei tuoi fondi?',
        title: 'Conto bitcoin',
        suggestion: 'Ricevi pagamenti direttamente nel tuo wallet!',
        faq: 'Vuoi maggiori informazioni sui conti bitcoin? ',
      },
      faqLink: 'Consulta le nostre FAQ',
    },
  },
  detail: {
    actions: {
      viewPayment: 'Ordini di pagamento',
      edit: 'modifica',
      remove: 'rimuovi',
    },
    title: 'Dettagli',
    publicKey: 'Chiave Pubblica',
    holder: 'Intestario',
    bankName: 'Istituto',
    iban: 'IBAN',
    bic: 'BIC',
    poses: 'POS che inviano denaro a questo conto',
    emptyPoses: 'Nessuno',
  },
  new: {
    titles: {
      title: 'Crea un nuovo conto',
      parent: 'Torna alla lista dei conti ',
    },
    action: 'Crea conto',
    ok: 'Conto creato con successo!',
    hint: {
      general: 'Vuoi maggiori informazioni sui conti? ',
      faq: 'Consulta le nostre FAQ',
    },
    subtitle:
      'Un conto ti permette di ricevere il denaro inviato dai tuoi clienti' +
      ' tramite i tuoi POS.',
    types: {
      title: 'Che tipo di conto vuoi creare?',
      disabled:
        "Gentile cliente,  per completare l'attivazione del suo account è necessario procedere ad alcune verifiche per la normativa anti-riciclaggio." +
        'Le chiediamo quindi di inviare una mail ad ',
      disabled2:
        " con una visura camerale dell'azienda che ha appena registrato non più antica di 6 mesi dalla data di oggi.",
      disabled3: 'Sarà nostra premura ricontattarla nel minor tempo possibile.',
      bank: {
        text: 'Conto bancario',
        commissione: '1% COMMISSIONE',
        paymentTime: 'PAGAMENTI SETTIMANALI',
        features: {
          1: 'Accredito sul tuo conto tramite bonifico',
          2: 'Cambio istantaneo',
          3: 'Nessun rischio di volatilità',
        },
      },
      titleChoiche: 'Il tuo conto',

      bitcoin: {
        text: 'Conto bitcoin',
        commissione: '0% COMMISSIONE',
        paymentTime: 'PAGAMENTI IMMEDIATI',
        features: {
          1: 'Accredito istantaneo nel tuo wallet',
          2: 'Compatibile con tutti i wallet sul mercato',
          3: 'Nessuna commissione',
        },
      },
    },
    bank: {
      title: 'Inserisci i dati del tuo conto bancario',
      holder: 'Intestario del conto',
      bankName: 'Istituto',
      iban: 'IBAN',
      bic: 'BIC',
    },
    bitcoin: {
      chainside: {
        title: 'Usa Portafoglio Chainside (5 minuti)',
        text:
          'Installa l’app chainsidePay dal Google Play o dall’Apple Store e scansiona il QRcode qui a lato.',
        text2: ' Sarai subito pronto a ricevere bitcoin sul tuo nuovo wallet',
      },
      other: {
        title: 'Usa Altro Portafoglio (15-30 minuti)',
        subtitle:
          'Per ricevere i fondi su un wallet diverso da ChainsidePay abbiamo' +
          ' bisogno della chiave pubblica estesa del wallet scelto.  Il' +
          ' processo potrebbe richiedere un po’ di tempo ma tranquillo, ti guideremo passo passo! ',
        subtitle_chainside_disabled:
          'Per ricevere i fondi sul tuo wallet bitcoin abbiamo bisogno della' +
          ' sua chiave pubblica estesa.  Il processo potrebbe richiedere un po’ di tempo ma tranquillo, ti guideremo passo passo!',
        hintStep: 'Hai problemi a completare questo step?',
        pastePublicKey:
          'Ora che hai trovato la tua chiave, inseriscila nel campo qui sotto.',
        step1: {
          title: 'Scegli un wallet',
          subtitle:
            "Per garantirti l'accredito immediato sul tuo wallet preferito," +
            ' dovrai creare un account dedicato esclusivamente al tuo conto' +
            ' ChainsidePay. Segui questi semplici passi per trovare la' +
            ' chiave pubblica estesa del tuo nuovo account.',
          subtitle2: 'Quale di questi wallet vuoi utilizzare?',
          warning: {
            text:
              'Per un corretto funzionamento dovrai creare un account' +
              ' dedicato alle transazioni fatte attraverso ChainsidePay.' +
              ' Quindi, non utilizzare account:',
            case1: 'su cui hai già ricevuto o prevedi di ricevere transazioni;',
            case2:
              'di cui hai già generato (e condiviso con terzi) indirizzi' +
              ' di ricezione.',
          },
          walletNotFound: 'Non trovo il mio wallet',
          goBack: 'Cambia wallet',
        },
        step2: {
          title: 'Crea un account bitcoin dedicato',
          goBack: 'Leggi di nuovo',
          subtitle:
            'ChainsidePay deve essere collegato ad un account del tuo wallet %(walletName)s che sia dedicato esclusivamente alle transazioni ChainsidePay.',
          firstPoint: 'Se non hai ancora creato il tuo wallet %(walletName)s,',
          firstPoint2: 'fallo adesso e procedi allo step successivo.',
          secondPoint:
            'Se hai già creato un wallet %(walletName)s ma non hai effettuato' +
            ' alcuna transazione,',
          secondPoint2:
            'puoi procedere allo step successivo ma assicurati di non ricevere altri pagamenti in entrata da fonti diverse da Chainsidepay.',
          thirdPoint: 'Se stai già usando il wallet %(walletName)s ',
          thirdPoint2:
            ' e hai effettuato delle transazioni in entrata o in uscita, puoi creare un nuovo account bitcoin da utiilizzare esclusivamente per ChainsidePay. Ecco come fare:',
          thirdPointExplanation: 'Se stai già usando il wallet %(walletName)s ',
          thirdPointExplanation2:
            ' e hai effettuato delle transazioni in entrata o in uscita hai due possibilità. ',
          thirdPoint3:
            ' La prima è creare un secondo wallet %(walletName)s da zero (ti consigliamo di usare un secondo dispositivo per non perdere la possibilità di accedere ad entrambi i portafogli contemporaneamente)',
          thirdPoint4:
            ' La seconda possibilità è quella di creare un nuovo account bitcoin usando un wallet diverso dalla lista nello',
          thirdPoint4Back: 'step precedente.',
        },
        step3: {
          title: 'Inserisci la chiave pubblica estesa del tuo wallet',
          subtitle:
            'La chiave pubblica estesa permetterà a Chainside Pay di inviare' +
            ' denaro al tuo wallet. Per trovarla, segui questi step: ',
        },
        ledger: {
          step2: {
            description:
              'Leger wallet permette la creazione di più account Bitcoin. È indispensabile crearne uno dedicato a ChainsidePay su cui non verranno ricevuti altri pagamenti in entrata.',
            steps: {
              1: '1. Clicca sul menù Accounts in basso alla schermata principale, poi clicca sul pulsante + in alto a destra per creare un nuovo account',
              2: '2. Alla seguente schermata, potrai assegnare un nome al nuovo account per renderlo riconoscibile',
            },
          },
          step3: {
            description:
              'La chiave pubblica estesa (xpub) permetterà a ChainsidePay di' +
              ' inviare denaro al tuo wallet. Per trovarla, segui questi step:',
            steps: {
              1: '1. Dalla schermata che si è aperta una volta creato l’account puoi trovare il campo  "chiave pubblica estesa" da copiare',
            },
          },
        },
        myceliumAndroid: {
          step2: {
            description:
              'Mycelium permette la creazione di più account Bitcoin. È indispensabile crearne uno dedicato a ChainsidePay su cui non verranno ricevuti altri pagamenti in entrata.',
            steps: {
              1: '1. Dal menù “CONTI”, clicca sul pulsante “Aggiungi account”',
              2: '2. Dalla schermata che si apre, clicca “Aggiungere conto HD”',
              3: '3. Se lo desideri, dai un nome al nuovo account (per esempio “ChainsidePay”) e selezionalo dal menù “CONTI”',
            },
          },
          step3: {
            description:
              'La chiave pubblica estesa (xpub) permetterà a ChainsidePay di' +
              ' inviare denaro al tuo wallet. Per trovarla, segui questi step:',
            steps: {
              1: '1. Selezionare l’account MyCelium dedicato esclusivamente a ChainsidePay',
              2: '2. Dal menù in alto a destra, seleziona “Export account keys”.',
            },
          },
        },
        electrum: {
          step3: {
            description:
              'ATTENZIONE: ChainsidePay per funzionare correttamente deve' +
              ' essere collegato ad un un wallet nuovo dedicato, per evitare' +
              ' di incorrere in problemi assicurati di non ricevere altri' +
              ' pagamenti in entrata sullo stesso wallet usato da' +
              ' ChainsidePay. La chiave pubblica estesa (xpub) permetterà a' +
              ' ChainsidePay di inviare denaro al tuo wallet, per estrarla' +
              ' segui i seguenti step:\n',
            steps: {
              1: '1. Dal menu “Portafogli” selezione “Informazioni”\n',
              2:
                '2. Copia la stringa dal campo “Chiave pubblica' +
                ' principale”.',
            },
          },
        },
        myCeliumIOS: {
          step2: {
            steps: {
              1: '1. Clicca sul menù Accounts in basso alla schermata principale, poi clicca sul pulsante + in alto a destra per creare un nuovo account',
              2: '2. Alla seguente schermata, potrai assegnare un nome al nuovo account per renderlo riconoscibile',
            },
          },
          step3: {
            description:
              'Mycelium permette la creazione di più account Bitcoin. È indispensabile crearne uno dedicato a ChainsidePay su cui non verranno ricevuti altri pagamenti in entrata.',
            steps: {
              1: '1. Dalla schermata che si è aperta una volta creato l’account puoi trovare il campo  "chiave pubblica" da copiare',
            },
          },
        },
        samourai: {
          step3: {
            description:
              'ATTENZIONE: ChainsidePay per funzionare correttamente deve' +
              ' essere collegato un un wallet nuovo dedicato, per evitare di' +
              ' incorrere in problemi assicurati di non ricevere altri' +
              ' pagamenti in entrata sullo stesso wallet usato da' +
              ' ChainsidePay. La chiave pubblica estesa (xpub) permetterà a' +
              ' ChainsidePay di inviare denaro al tuo wallet, per estrarla' +
              ' segui i seguenti step:\n',
            steps: {
              1: '1. Dal menù principale, apri “Impostazioni”',
              2: '2. Dalle Impostazioni, apri “Wallet”',
              3: '3. Dal menù Wallet seleziona uno tra “Visualizza XPUB BIP44”, "Visualizza YPUB Segwit" o "Visualizza ZPUB Segwit", e copia la stringa che viene mostrata',
            },
          },
        },
      },
    },
  },
  edit: {
    bitcoin: {
      titles: {
        title: 'Modifica conto Bitcoin',
        parent: 'Torna alla lista dei conti',
      },
      subtitle:
        'In questa pagina puoi modificare il nome del tuo conto bitcoin.',
      name: 'Nome del conto',
    },
    bank: {
      titles: {
        title: 'Modifica conto Bancario',
        parent: 'Torna alla lista dei conti',
      },
      subtitle:
        'In questa pagina puoi modificare il nome del tuo conto bancario.',
      name: 'Nome del conto',
      intestatario: 'Intestatario del conto',
      IBAN: 'IBAN',
      instituto: 'Istituto bancario',
      bic: "BIC dell'Istituto bancario",
    },
  },
  filter: {
    all: 'Tutti',
    bitcoin: 'Conti Bitcoin',
    bank: 'Conti Bancari',
  },
  delete: {
    confirm: {
      title: 'Sei sicuro di voler rimuovere il conto?',
      subtitle:
        'Proseguendo non potrai più utilizzare il %(type)s "%(accountName)s" per ricevere denaro',
      buttons: {
        confirm: 'Rimuovi',
      },
    },
    loading: {
      title: 'Rimozione in corso...',
    },
    success: {
      message: 'Conto rimosso con successo.',
    },
    notEmpty: {
      title: 'Impossibile rimuovere il conto',
      subtitle:
        'Questo conto è associato a dei POS attivi, pertanto non è possibile rimuoverlo.',
      listHeading: 'Ecco la lista dei POS attivi associati:',
    },
  },
};
