import React, { FC } from 'react';
import { CredentialSectionProps } from './propTypes';
import { Col, Row } from 'react-flexbox-grid';
import { FormSectionTitle } from '../../../../common/Forms/FormSectionTitle';
import Translate from 'react-translate-component';
import Typography from '@material-ui/core/Typography/Typography';
import { WebsiteLink } from '../../../../common/WebsiteLink';
import TextField from '../../../../common/Forms/TextField';
import { ActionLink } from '../../../../common/Links';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import styles from './theme';
import { withStyles } from '@material-ui/core';

const CredentialSection: FC<CredentialSectionProps> = ({
  onClickGenerate,
  clientId,
  secret,
  classes = {},
  loading,
}) => (
  <React.Fragment>
    <Row>
      <Col xs={12}>
        <FormSectionTitle>
          <Translate content="POS.mobile.integration.title" />
        </FormSectionTitle>
      </Col>
    </Row>
    <Typography
      variant="caption"
      gutterBottom
      style={{ marginBottom: '25px' }}
      className="f-13"
    >
      <Translate content="POS.mobile.integration.suggestion" />{' '}
      <WebsiteLink to="devs_area_pos_credentials">
        <Translate content="POS.mobile.integration.more_info" />
      </WebsiteLink>
      .
    </Typography>
    <Row className={classes.rowMargin}>
      <Col xs={6}>
        <TextField
          label={<Translate content="POS.detail.clientID" />}
          value={clientId}
          id="pos_id"
          name="pos_id"
          disabled
          fullWidth
        />
      </Col>
      <Col xs={6}>
        <TextField
          label={<Translate content="POS.detail.secret" />}
          value={secret}
          id="secret"
          name="secret"
          disabled
          fullWidth
          hidden
        />
      </Col>
    </Row>
    <ActionLink style={{ marginTop: '-25px' }} onClick={onClickGenerate}>
      {loading && <CircularProgress size="14px" className={classes.loader} />}
      <Translate content="POS.edit.generateSecret" />
    </ActionLink>
    <div className={classes.marginTopSection} />
  </React.Fragment>
);

export default withStyles(styles)(CredentialSection);
