import React from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import TextField from './TextField';
import translator from 'counterpart';
import Translate from 'react-translate-component';
import AvatarEditor from 'react-avatar-editor';
import classnames from 'classnames';
import Slider from '@material-ui/lab/Slider';
import { ActionLink } from '../Links';
import filesize from 'filesize';

const styles = theme => ({
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  logoImage: {
    width: '100px',
    height: '100px',
  },
  title: {
    fontSize: '16px',
    fontWeight: theme.typography.weights.normal,
    color: '#444444',
  },
  subtitle: {
    fontSize: '13px',
    fontWeight: theme.typography.weights.normal,
    color: '#999999',
  },
  inputRow: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  fakeInput: {
    marginTop: '4px',
    overflow: 'hidden',
    position: 'relative',
    textTransform: 'uppercase',
    lineHeight: '22px',
    backgroundColor: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: theme.typography.weights.normal,
    borderRadius: '4px',
    color: 'white',
    padding: '11px 20px',
    textAlign: 'center',
    flex: '1',
    marginRight: '15px',
    '&:hover': {
      opacity: 0.9,
    },
  },
  fileInput: {
    cursor: 'pointer',
    display: 'block',
    fontSize: '999px',
    filter_uuid: 'alpha(opacity=0)',
    minHeight: '100%',
    minWidth: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    opacity: 0,
    position: 'absolute',
    right: 0,
    textAlign: 'right',
    top: 0,
  },
  pathInput: {
    marginTop: '4px',
    marginBottom: '4px',
    flex: 2,
  },
  flex1: {
    flex: '1 1 auto',
  },
  avatarSection: {
    marginLeft: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  iconSliderSmall: {
    width: '16px',
  },
  iconSliderBig: {
    width: '24px',
    marginLeft: '10px',
  },
  sliderSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatarCanvas: {
    width: '129px',
    height: '90px',
    backgroundColor: theme.palette.grey.midDark,
    // backgroundImage: 'url('+background+')',
    border: '1px solid',
    borderColor: theme.palette.grey.midDark,
  },
  marginBottom: {
    marginBottom: '20px',
  },
  cursorAuto: {
    cursor: 'auto !important',
  },
});

class LogoWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoPath: '',
      zoomValue: 1,
    };
  }

  handleLogo = () => {
    const file = this.fileInput.files[0];
    this.props.validateImage(file, isValid => {
      if (isValid) {
        this.setState(
          {
            logoPath: file.name,
            file: file,
          },
          () => {
            //used un timeout for handling the delay on charging image in react-avatar editor
            setTimeout(this.onImageChange, 1000);
          }
        );
      } else {
        this.cancelLogo();
      }
    });
  };

  cancelLogo = () => {
    this.setState({
      file: '',
      logoPath: '',
    });
    this.fileInput.value = '';
    this.props.onChange(null);
  };

  onImageChange = () => {
    if (this.editor && this.state.file && !this.props.disabled) {
      const image = this.editor.getImageScaledToCanvas();
      this.props.onChange(image.toDataURL('image/png'));
    }
  };
  handleZoom = (event, value) => {
    this.setState({ zoomValue: value });
  };

  render() {
    const {
      classes,
      className,
      disabled,
      error,
      helperText,
      edit,
      maxSize,
    } = this.props;
    const { logoPath, zoomValue } = this.state;
    // TODO: find how to use material-ui Input
    return (
      <div className={classnames([className, classes.mainContainer])}>
        <div className={classes.flex1}>
          <Typography className={classes.subtitle}>
            <Translate
              content="business.logo.subtitle"
              with={{ maxSize: filesize(maxSize) }}
            />
            {edit && (
              <React.Fragment>
                {'. '}
                <Translate
                  content="common.or"
                  style={{ textTransform: 'capitalize' }}
                />{' '}
                <ActionLink
                  style={{ display: 'inline', fontSize: '13px' }}
                  onClick={this.props.restoreImage}
                  disabled={disabled}
                >
                  <Translate content="business.logo.restore" />
                </ActionLink>
              </React.Fragment>
            )}
          </Typography>
          <div className={classes.inputRow}>
            <div
              className={classnames({
                [classes.fakeInput]: true,
                [classes.marginBottom]: error,
              })}
            >
              <Translate content="common.choiche" />
              <input
                className={classes.fileInput}
                disabled={disabled}
                id="logoSection"
                name="logoSection"
                type="file"
                onChange={() => this.handleLogo()}
                accept="image/png, image/jpeg, image/bmp"
                ref={input => {
                  this.fileInput = input;
                }}
              />
            </div>
            <div style={{ flex: 3 }}>
              <TextField
                value={logoPath}
                disabled={disabled}
                className={classnames([classes.pathInput])}
                placeholder={translator('placeholder.logo')}
                onChange={() => {}}
                error={error}
                helperText={helperText}
                onCancel={this.cancelLogo}
              />
            </div>
          </div>
        </div>
        <div className={classes.avatarSection}>
          <AvatarEditor
            ref={ref => (this.editor = ref)}
            image={
              this.state.file ||
              require('../../../img/others/slider_bigger.png')
            }
            width={80}
            height={80}
            border={[25, 5]}
            color={[244, 247, 248, 1]} // RGBA
            rotate={0}
            scale={this.state.file ? zoomValue : 0}
            borderRadius={100}
            style={{ width: '129px', height: '90px' }}
            className={classnames({
              [classes.avatarCanvas]: true,
              [classes.cursorAuto]: !this.state.file || disabled,
            })}
            onMouseUp={this.onImageChange}
          />
          <div className={classes.sliderSection}>
            <img
              src={
                this.state.file
                  ? require('../../../img/others/slider_smaller.png')
                  : require('../../../img/others/slider_smaller_disabled.png')
              }
              alt="meno"
              className={classes.iconSliderSmall}
            />
            <div style={{ flex: '0 1 50%' }}>
              <Slider
                disabled={!this.state.file || disabled}
                onMouseUp={this.onImageChange}
                value={zoomValue}
                id="sliderLogo"
                min={0.5}
                max={3}
                onChange={this.handleZoom}
              />
            </div>
            <img
              src={
                this.state.file
                  ? require('../../../img/others/slider_bigger.png')
                  : require('../../../img/others/slider_bigger_disabled.png')
              }
              alt="meno"
              className={classes.iconSliderBig}
            />
          </div>
        </div>
      </div>
    );
  }
}

LogoWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
  logo: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(LogoWidget);
