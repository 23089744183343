import { createStyles } from '@material-ui/core';

export const style = (theme: { [key: string]: any }) =>
  createStyles({
    root: {},
    detailsArrow: {
      margin: '-5px',
      float: 'right',
      color: theme.palette.primary.bluegrey,
    },
    sortableTable: {
      marginTop: '28px',
      minWidth: '100%',
      fontSize: '13px',
      fontWeight: theme.typography.fontWeightBold,
    },
    sortableTableHead: {
      color: theme.palette.primary.dark,
    },
    sortableTableHeadRow: {},
    sortIcon: {
      verticalAlign: 'middle',
      width: '8px',
      height: '18px',
      marginLeft: '5px',
    },
    sortableTableHeadCell: {
      padding: '0 17px 10px',
      lineHeight: '24px',
      '&:first-child': {
        paddingLeft: '20px',
      },
      display: 'flex',
    },
    sortableTableHeadCellInteractive: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    sortableTableBody: {},
    sortableTableBodyRow: {
      minHeight: '50px',
      marginBottom: '7px',
      borderRadius: '6px',
      backgroundColor: theme.palette.common.white,
      border: 'solid 1px',
      borderColor: theme.palette.primary.bluegrey,
      ...theme.shadow,
    },
    sortableTableBodyCell: {
      padding: '17px',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '&:first-child': {
        paddingLeft: '20px',
      },
    },
    tableHeader: {
      color: theme.palette.primary.main,
    },
    arrowSmall: {
      float: 'right',
      ...theme.icon.arrowSmall,
    },
  });
