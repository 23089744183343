import React from 'react';
import PageContainer from '../../../common/PageContainer';
import PageTitle from '../../../common/PageTitle';
import { withStyles } from '@material-ui/core/styles';
import MobilePosAPI from '../../../../api/MobilePoS';
import store from '../../../../store/index';
import MobileForm from '../../common/MobileForm';
import Translate from 'react-translate-component';
import translator from 'counterpart';
import { withGenericErrorHandling } from '../../../common/ErrorHandling';
import Loader from '../../../common/Loader';
import { ROUTE_PARAMETERS, ROUTES } from '../../../../constants/routes';
import { withRouter } from 'react-router-dom';
import { buildPath } from '../../../../utils/routes';
import { withFormsValidation } from '../../../common/Forms/FormsCommon';
import Chip from '@material-ui/core/Chip';
import { MobileDetailProps, MobileDetailState } from './propTypes';
import styles from './theme';

class DetailPOS extends React.Component<MobileDetailProps> {
  state: MobileDetailState = {
    idPOS: undefined,
    confirmPage: false,
    loading: true,
    mobilePos: undefined,
    submitting: false,
  };

  componentDidMount() {
    let idPOS = this.props.match.params[ROUTE_PARAMETERS.POS_ID];
    MobilePosAPI.getDetailMobile(
      idPOS,
      (data: { [key: string]: any }) => {
        this.initMobilePOS(data);
      },
      this.props.onError
    );
    this.setState({ idPOS: idPOS });
  }

  initMobilePOS = (pos: { [key: string]: any }) => {
    const { ids } = this.props.form;
    const mobilePOS = {
      [ids.form$name]: pos.name,
      [ids.form$currency]: pos.currency,
      [ids.form$deposit_account]: pos.deposit_account
        ? pos.deposit_account.uuid
        : null,
      form$store_address: pos.store_address,
      [ids.uri$pos_uuid]: pos.uuid,
      [ids.form$no_store_address_available]: !pos.store_address,
    };
    this.props.initObject(mobilePOS, () => {
      this.setState({
        loading: false,
        mobilePos: {
          pos_id: pos.pos_id,
          secret: pos.secret,
          active: pos.active,
        },
      });
    });
  };

  onConfirm = () => {
    this.setState({ submitting: true });
    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];

    this.props.send(
      () => {
        this.setState({ submitting: false });
        store.app.reloadInfoAndKeepToken(() => {
          this.props.history.push(
            buildPath(ROUTES.POS_VIEW, {
              [ROUTE_PARAMETERS.POS_ID]: this.state.idPOS,
              [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
            })
          );
        });
      },
      () => {
        this.setState({ submitting: false });
      }
    );
  };

  onResetCredentials = (clientID: string, secret: string) => {
    this.setState((state: MobileDetailState) => ({
      mobilePos: {
        ...state.mobilePos,
        pos_id: clientID,
        secret: secret,
      },
    }));
  };

  render() {
    if (this.state.loading) {
      return (
        <PageContainer white main style={{ paddingTop: '50px' }}>
          <Loader />
        </PageContainer>
      );
    }

    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    const { classes } = this.props;

    const {
      object,
      errors,
      handleChange,
      signalError,
      handleChangeGeneric,
      validateField,
    } = this.props;

    const mobilePOS: { [key: string]: string | boolean } = {
      ...this.state.mobilePos,
      ...object,
    };

    let ids = { ...this.props.form.ids };

    return (
      <div id={'posFormContainer'}>
        {this.state.confirmPage === false && (
          <PageContainer main white style={{ paddingTop: '45px' }}>
            <React.Fragment>
              <PageTitle
                noMarginBottom={!mobilePOS.active}
                title={<Translate content="POS.edit.mobile.title" />}
                parent={{
                  text: <Translate content="POS.new.web.titles.parent" />,
                  url: buildPath(ROUTES.POS_LIST, {
                    [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
                    [ROUTE_PARAMETERS.POS_ID]: this.state.idPOS,
                  }),
                }}
              />
              {!mobilePOS.active && (
                <div
                  className={classes!.titleLabelContainer}
                  style={{ width: '100%' }}
                >
                  <Chip
                    style={{ width: '100%', justifyContent: 'flex-start' }}
                    label={translator('POS.edit.state.notActive')}
                    className={classes!.errorLabel}
                  />
                </div>
              )}
              <MobileForm
                business_uuid={business_uuid}
                handleChange={handleChange}
                handleChangeGeneric={handleChangeGeneric}
                signalError={signalError}
                toggleCheckbox={this.props.toggleCheckbox}
                errors={errors}
                validateField={validateField}
                mobilePOS={mobilePOS}
                onConfirm={this.onConfirm}
                submitting={this.state.submitting}
                form={this.props.form}
                validate={this.props.validate}
                onResetCredentials={this.onResetCredentials}
                idPOS={this.state.idPOS}
                formIds={ids}
                edit
              />
            </React.Fragment>
          </PageContainer>
        )}
      </div>
    );
  }
}

export default withFormsValidation('UpdateMobilePosForm')(
  withGenericErrorHandling(withRouter(withStyles(styles)(DetailPOS)))
);
