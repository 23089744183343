import { StyleRulesCallback } from '@material-ui/core';

const styles: StyleRulesCallback = ({ palette }: { [key: string]: any }) => ({
  marginBottom: {
    marginBottom: '10px',
  },
  detailTitle: {
    color: palette.primary.main,
  },
});

export default styles;
