import BusinessPanelSDK from 'business-panel-sdk';

import store from '../store';
import { onErrorNotification, onSuccessNotification } from './common';

export default {
  editEmail: (new_email, password, onSuccess, onError) => {
    const data = {
      new_email: new_email,
      password: password,
    };

    const editEmail = store.app.factory.make('EmailChangeRequestAction');
    editEmail.body_params = BusinessPanelSDK.sendables.ChangeEmailRequest.fromData(
      data
    );

    editEmail
      .run()
      .then(result => {
        store.app.refreshToken(() => {
          onSuccessNotification(onSuccess)(result);
        });
      })
      .catch(onErrorNotification(onError));
  },
  changeDetails: (
    person_uuid,
    firstName,
    lastName,
    phone,
    password,
    onSuccess,
    onError
  ) => {
    const data = {
      name: firstName,
      surname: lastName,
      // TODO: remove hardcoded terms
      terms: 'v1',
      contacts: {
        phone: phone,
      },
    };

    const changeDetails = store.app.factory.make('UpdatePersonAction');
    changeDetails.url_params = { uuid: person_uuid };
    changeDetails.body_params = BusinessPanelSDK.sendables.Person.fromData(
      data
    );

    changeDetails
      .run()
      .then(result => {
        store.app.refreshToken(() => {
          onSuccessNotification(onSuccess)(result);
        });
      })
      .catch(onErrorNotification(onError));
  },
  editPassword: (oldPassword, newPassword, onSuccess, onError) => {
    const data = {
      old_password: oldPassword,
      new_password: newPassword,
    };
    const editPassword = store.app.factory.make('PasswordChangeAction');
    editPassword.body_params = BusinessPanelSDK.sendables.ChangePasswordRequest.fromData(
      data
    );

    editPassword
      .run()
      .then(onSuccessNotification(onSuccess))
      .catch(onErrorNotification(onError));
  },
  getDetails: (person_uuid, onSuccess, onError) => {
    const getDetails = store.app.factory.make('GetPersonAction');

    getDetails.routeParams = {
      person_uuid: person_uuid,
    };

    getDetails
      .run()
      .then(onSuccess)
      .catch(onError);
  },
  delete: (person_uuid, onSuccess, onError) => {
    const deletePerson = store.app.factory.make('DeletePersonAction');
    deletePerson.url_params = { uuid: person_uuid };

    deletePerson
      .run()
      .then(onSuccessNotification(onSuccess))
      .catch(onErrorNotification(onError));
  },
};
