export const modalStyles = theme => ({
  callbackLoading: {
    color: theme.palette.primary.main,
    marginTop: '25px',
    width: '340px',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '525px',
    textAlign: 'center',
  },
  topIcon: {
    width: '40px',
    height: '40px',
  },
  invalidCallbacktitle: {
    margin: '10px 0px 20px',
    color: theme.palette.orange.main,
  },
  successTitle: {
    margin: '10px 0px 20px',
    color: theme.palette.green.main,
  },
  errorTitle: {
    margin: '10px 0px 20px',
    color: theme.palette.red.main,
  },
  helperTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: '30px',
    width: '370px',
    textAlign: 'center',
  },
});
