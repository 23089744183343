import React from 'react';
import { Row } from 'react-flexbox-grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import PageContainer from '../../common/PageContainer';
import { ACCOUNTS_TYPE } from './constants/index';
import GenericSelectable from '../../common/GenericSelectable';
import SubHeader from '../../common/SubHeader';
import BankForm from './BankForm';
import ChoiceTipoConto from './ChoiceTipoConto';
import Translate from 'react-translate-component';
import translator from 'counterpart';
import { Redirect } from 'react-router-dom';
import store from '../../../store/index';
import {
  ROUTE_PARAMETERS,
  ROUTE_PARAMETERS_VALUE,
  ROUTES,
} from '../../../constants/routes';
import { buildPath } from '../../../utils/routes';
import { WebsiteLink } from '../../common/WebsiteLink';
import { observer } from 'mobx-react';

const styles = theme => ({
  containerPOS: {
    border: '1px solid',
    borderColor: theme.palette.grey.light,
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '2px 2px 5px #aaaaaa',
    },
  },
  avatar: {
    width: '60px',
    height: '60px',
    margin: '10px',
  },
  hint: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'row',
    marginTop: '60px',
  },
  choicheTitle: {
    fontSize: '18px',
    fontWeight: '600',
    color: '#444444',
    display: 'flex',
    justifyContent: 'center',
    margin: '37px 0px 15px',
  },
});

class NewConto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
      submitted: false,
    };
  }

  componentDidMount() {
    const match = this.props.match.params[ROUTE_PARAMETERS.ACCOUNT_TYPE];
    if (
      match &&
      (match !== ROUTE_PARAMETERS_VALUE.ACCOUNT.bank ||
        store.app.hasBankAccounts)
    ) {
      this.setState({ selected: match });
    }
  }

  selectAccountType = value => {
    if (this.state.selected === value) {
      this.setState({
        selected: '',
      });
    } else {
      this.setState({
        selected: value,
      });
    }
  };

  onSuccess = result => {
    this.setState(
      {
        account_uuid: result.uuid,
        submitted: true,
      },
      () => {
        store.app.reloadInfoAndKeepToken(() => {
          store.app.snackBar.open(
            translator('messages.account.new.ok'),
            'success'
          );
        });
      }
    );
  };

  render() {
    if (store.app.sandboxMode) {
      return <Redirect to="/" />;
    }

    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];

    if (!store.app.hasBankAccounts) {
      return (
        <Redirect
          to={buildPath(ROUTES.ACCOUNT_LIST, {
            [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
          })}
        />
      );
    }

    const { account_uuid } = this.state;

    const { submitted } = this.state;
    if (submitted) {
      return (
        <Redirect
          push
          to={buildPath(ROUTES.ACCOUNT_VIEW, {
            [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
            [ROUTE_PARAMETERS.ACCOUNT_ID]: account_uuid,
          })}
        />
      );
    }

    const { classes } = this.props;

    return (
      <React.Fragment>
        <SubHeader
          description={<Translate content="account.new.subtitle" />}
          title={<Translate content="account.new.titles.title" />}
          goBack={{
            text: <Translate content="account.new.titles.parent" />,
            url: buildPath(ROUTES.ACCOUNT_LIST, {
              [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
            }),
          }}
          withoutFilter
        />
        <PageContainer main grey>
          <Row around="xs">
            {ACCOUNTS_TYPE.map((item, k) => {
              return (
                <GenericSelectable
                  value={item.type}
                  id={item.type}
                  key={k}
                  handleChange={this.selectAccountType}
                  aria-selected
                  isActive
                  fullWidth
                  withArrow
                >
                  <ChoiceTipoConto account={item} />
                </GenericSelectable>
              );
            })}
          </Row>
          <div style={{ marginTop: '10px', marginLeft: '2px' }}>
            <BankForm onSuccess={this.onSuccess} />
          </div>
          <div className={classes.hint}>
            <Typography variant="caption">
              <Translate content="account.new.hint.general" />
              <WebsiteLink to="faq_root">
                <Translate content="account.new.hint.faq" />
              </WebsiteLink>
            </Typography>
          </div>
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default observer(withRouter(withStyles(styles)(NewConto)));
