import { StyleRulesCallback } from '@material-ui/core';

const styles: StyleRulesCallback = ({ palette }: { [key: string]: any }) => ({
  container: {
    backgroundColor: palette.primary.bg,
    border: '1px solid',
    borderColor: palette.primary.bluegrey,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '205px',
  },
  text: {
    textAlign: 'center',
    padding: '10px',
  },
});

export default styles;
