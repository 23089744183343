import React, { FC } from 'react';
import { IconRowProps } from './propTypes';
import styles from './theme';
import { withStyles } from '@material-ui/core';

const IconRow: FC<IconRowProps> = ({
  icon,
  classes = {},
  children,
  ...otherProps
}) => (
  <div className={classes.container} {...otherProps}>
    <img className={classes.icon} src={icon} alt="" />
    <div className={classes.labelContainer}>{children}</div>
  </div>
);

export default withStyles(styles)(IconRow);
