import { envValidation as env } from 'env-validation-js';

const SOCKET_URL = env.get('REACT_APP_SOCKET_URL').asUrl();

const SDK_AUTH_WITH_CREDENTIALS = env
  .get('REACT_APP_SDK_AUTH_WITH_CREDENTIALS', true)
  .asBoolean();

const SDK_AUTH_SKIP_HTTPS_VALIDATION = env
  .get('REACT_APP_SDK_AUTH_SKIP_HTTPS_VALIDATION', false)
  .asBoolean();

const API_URL = env.get('REACT_APP_API_URL').asUrl();

const SANDBOX_API_URL = env.get('REACT_APP_SANDBOX_API_URL').asUrl();

const PRODUCTION_MODE =
  env
    .get('NODE_ENV', 'production')
    .asString('in:development,production,test') === 'production';

const COOKIE_POLICY_DOMAIN = env
  .get('REACT_APP_COOKIE_POLICY_DOMAIN')
  .asString();

const BLOCK_EXPLORER_VIEW_ADDRESS_URL = env
  .get(
    'REACT_APP_BLOCK_EXPLORER_VIEW_ADDRESS_URL',
    'https://blockexplorer.com/address/'
  )
  .asUrl();

const BLOCK_EXPLORER_VIEW_TX_URL = env
  .get('REACT_APP_BLOCK_EXPLORER_VIEW_TX_URL', 'https://blockexplorer.com/tx/')
  .asUrl();

const ETHERSCAN_VIEW_TX_URL = env
  .get('REACT_APP_ETHERSCAN_VIEW_TX_URL', 'https://etherscan.io/tx/')
  .asUrl();

const ETHERSCAN_VIEW_ADDRESS_URL = env
  .get('REACT_APP_ETHERSCAN_VIEW_ADDRESS_URL', 'https://etherscan.io/address/')
  .asUrl();

const SANDBOX_HOSTNAME = env.get('REACT_APP_SANDBOX_HOSTNAME').asString();

const HAS_BANK_ACCOUNTS_TIER = env
  .get('REACT_APP_HAS_BANK_ACCOUNTS_TIER', '1')
  .asString();

const PRODUCTION_HOSTNAME = env.get('REACT_APP_PRODUCTION_HOSTNAME').asString();

const VERSION = env.get('REACT_APP_VERSION').asString();

const ENVIRONMENT = env.get('REACT_APP_ENVIRONMENT', 'production').asString();

const SENTRY_DSN = env
  .get(
    'REACT_APP_SENTRY_DSN',
    'https://cdaba38b87b646639c9f7746b82dc5b5@o271473.ingest.sentry.io/4505481645654016'
  )
  .asString();

export {
  API_URL,
  BLOCK_EXPLORER_VIEW_ADDRESS_URL,
  BLOCK_EXPLORER_VIEW_TX_URL,
  COOKIE_POLICY_DOMAIN,
  PRODUCTION_MODE,
  SANDBOX_API_URL,
  SDK_AUTH_SKIP_HTTPS_VALIDATION,
  SDK_AUTH_WITH_CREDENTIALS,
  SOCKET_URL,
  SANDBOX_HOSTNAME,
  PRODUCTION_HOSTNAME,
  HAS_BANK_ACCOUNTS_TIER,
  VERSION,
  ENVIRONMENT,
  ETHERSCAN_VIEW_TX_URL,
  ETHERSCAN_VIEW_ADDRESS_URL,
  SENTRY_DSN,
};
