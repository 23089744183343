import React from 'react';

import { Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';

import Button from '../../common/Button';
import Loader from '../../common/Loader';
import TextField from '../../common/Forms/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withGenericErrorHandling } from '../../common/ErrorHandling';

import PageContainer from '../../common/PageContainer';
import PageTitle from '../../common/PageTitle';
import InvalidFormMessage from '../../common/InvalidFormMessage';

import AccountsAPI from '../../../api/Accounts';

import store from '../../../store/index';

import { ROUTE_PARAMETERS, ROUTES } from '../../../constants/routes';
import translator from 'counterpart';
import Translate from 'react-translate-component';
import { buildPath } from '../../../utils/routes';
import { withFormsValidation } from '../../common/Forms/FormsCommon';

const styles = theme => ({
  mainContainer: {
    width: '450px',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
  },
});

class EditBankAccount extends React.Component {
  static propTypes = {
    account_uuid: PropTypes.string.isRequired,
    business_uuid: PropTypes.string.isRequired,
    onError: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      account_uuid: this.props.account_uuid,
      nome: undefined,
      errors: {},
      loading: true,
      submitInProgress: false,
      submitted: false,
      valid: true,
    };
  }

  componentDidMount() {
    const { account_uuid } = this.state;
    AccountsAPI.getBankAccount(
      store.app.business_uuid,
      account_uuid,
      result => {
        this.props.initObject(
          {
            [this.props.form.ids.form$name]: result.name,
            [this.props.form.ids.uri$account_uuid]: account_uuid,
            [this.props.form.ids.uri$business_uuid]: store.app.business_uuid,
          },
          () => {
            this.setState({
              loading: false,
            });
          }
        );
      },
      error => {
        this.props.onError(error);
      }
    );
  }

  save = event => {
    event.preventDefault();
    this.setState({
      submitInProgress: true,
    });

    this.props.send(
      () => {
        store.app.reloadInfoAndKeepToken(() => {
          const { account_uuid, business_uuid } = this.props;
          this.props.history.push(
            buildPath(ROUTES.ACCOUNT_VIEW, {
              [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
              [ROUTE_PARAMETERS.ACCOUNT_ID]: account_uuid,
            })
          );
        });
      },
      null,
      () => {
        this.setState({
          submitInProgress: false,
        });
      }
    );
  };

  render() {
    if (store.app.sandboxMode) {
      return <Redirect to="/" />;
    }

    const { classes, errors, handleChange, validateField, object } = this.props;

    const { loading, submitInProgress } = this.state;

    const { account_uuid, business_uuid } = this.props;

    return (
      <PageContainer main white top>
        <PageTitle
          title={<Translate content="account.edit.bank.titles.title" />}
          parent={{
            text: <Translate content="account.edit.bank.titles.parent" />,
            url: buildPath(ROUTES.ACCOUNT_VIEW, {
              [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
              [ROUTE_PARAMETERS.ACCOUNT_ID]: account_uuid,
            }),
          }}
        />
        {loading && <Loader />}
        {!loading && (
          <React.Fragment>
            <Typography
              variant="caption"
              style={{ marginBottom: '40px', marginTop: '-25px' }}
            >
              <Translate content="account.edit.bank.subtitle" />{' '}
            </Typography>
            <form onSubmit={this.save}>
              <div className={classes.mainContainer}>
                <Typography style={{ marginBottom: '10px' }} variant="body2">
                  <Translate content="account.edit.bank.name" />
                </Typography>
                <TextField
                  id={this.props.form.ids.form$name}
                  name={this.props.form.ids.form$name}
                  type="text"
                  placeholder={translator('placeholder.nome')}
                  value={object[this.props.form.ids.form$name]}
                  onChange={handleChange}
                  error={Boolean(errors[this.props.form.ids.form$name])}
                  helperText={errors[this.props.form.ids.form$name]}
                  disabled={submitInProgress}
                  validateField={validateField}
                />
                <div style={{ marginTop: '60px' }}>
                  <InvalidFormMessage errors={errors} />
                  <div className={classes.actionContainer}>
                    <div style={{ flexGrow: '3', marginRight: '10px' }}>
                      <Button
                        id="salvaModifica"
                        variant="raised"
                        color="primary"
                        type="submit"
                        fullWidth
                        loading={submitInProgress}
                        onClick={this.save}
                      >
                        <Translate content="common.save" />
                      </Button>
                    </div>
                    <div style={{ flexGrow: '1' }}>
                      <Button
                        id="annullaModifica"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        loading={submitInProgress}
                        onClick={() => {
                          this.props.history.goBack();
                        }}
                      >
                        <Translate content="common.abort" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </React.Fragment>
        )}
      </PageContainer>
    );
  }
}

export default withFormsValidation('ModifyBanksAccountDataForm')(
  withGenericErrorHandling(withStyles(styles)(EditBankAccount))
);
