import { STATES } from '../constants/states';
import { LoaderCallbacks } from './Loader';
import { SelectCallback } from './SelectCallbackComponent';
import { ErrorLoading } from './ErrorLoading';

export const SelectCallbackLibrary = {
  [STATES.LOADING_CALLBACKS]: LoaderCallbacks,
  [STATES.NO_CALLBACK_SELECTED]: SelectCallback,
  [STATES.CALLBACK_SELECTED]: SelectCallback,
  [STATES.ERROR_LOADING]: ErrorLoading,
};
