export default {
  getCountryCode: (onSuccess, onError = () => {}) => {
    // axios
    //   .get('https://ipapi.com/country/')
    //   .then(function(response) {
    //     onSuccess(response.data.countryCode);
    //   })
    //   .catch(function(error) {
    //     onError(error);
    //   });
    const browserLocale = navigator.language.substr(3);
    onSuccess(browserLocale.length > 0 ? browserLocale : navigator.language);
  },
};
