import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { ROUTE_PARAMETERS, ROUTES } from '../../../constants/routes';
import store from '../../../store';
import Loader from '../Loader';
import PageContainer from '../PageContainer';

class DelegationComponent extends React.Component {
  static propTypes = {
    component: PropTypes.func.isRequired,
  };

  state = {
    loading: true,
    isDelegated: undefined,
  };

  componentDidMount() {
    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    if (business_uuid === store.app.business_uuid) {
      this.setState({
        loading: false,
        isDelegated: true,
      });
    } else {
      store.app
        .selectBusiness(business_uuid, () => {
          this.setState({
            loading: false,
            isDelegated: true,
          });
        })
        .catch(() => {
          this.setState({
            loading: false,
            isDelegated: false,
          });
        });
    }
  }

  render() {
    const { loading, isDelegated } = this.state;

    const { component: Component, ...rest } = this.props;

    if (loading) {
      return (
        <PageContainer main white top>
          <Loader />
        </PageContainer>
      );
    } else if (isDelegated) {
      return <Component {...rest} />;
    } else {
      return (
        <Redirect
          to={{
            pathname: ROUTES.PROFILE_VIEW,
            state: { from: this.props.location },
          }}
        />
      );
    }
  }
}

export { DelegationComponent };
