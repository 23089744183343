import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Row, Col } from 'react-flexbox-grid';
import Typography from '@material-ui/core/Typography';
import '../../styles/style.css';
import { WebsiteLink } from './WebsiteLink';

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.infoSection.backgroundColor,
    width: '100%',
  },
  innerContainer: {
    padding: '20px',
  },
  icon: {
    alignSelf: 'flex-start',
    marginRight: '5px',
    marginTop: '2px',
    width: '18px',
    height: '18px',
  },
});

class Info extends React.Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    faq: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
          .isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  };

  state = {
    visible: true,
  };

  onClose = () => {
    this.setState({ visible: false });
  };
  render() {
    const { title, classes, faq } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div style={{ marginTop: '3px' }}>
            {title && (
              <Row style={{ marginBottom: '10px' }}>
                <Col xs>
                  <Typography variant="body2">{title}</Typography>
                </Col>
              </Row>
            )}
            {faq &&
              faq.map((faq, k) => (
                <Typography
                  key={k}
                  className="f-13"
                  variant="caption"
                  style={{ marginBottom: '0.8em' }}
                >
                  <WebsiteLink to={faq.url}>{faq.text}</WebsiteLink>
                </Typography>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Info);
