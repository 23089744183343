import React from 'react';

import { Prompt } from 'react-router-dom';

import { Col, Row } from 'react-flexbox-grid';

import Translate from 'react-translate-component';
import translator from 'counterpart';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import TextField from '../../common/Forms/TextField';

import PageContainer from '../../common/PageContainer';
import PageTitle from '../../common/PageTitle';

import store from '../../../store/index';

import { ROUTES } from '../../../constants/routes';

import SaveButtons from '../common/SaveButtons';
import { withFormsValidation } from '../../common/Forms/FormsCommon';
import { buildPath } from '../../../utils/routes';

const styles = theme => ({
  confirmPassword: {
    marginTop: '40px',
    marginBottom: '15px',
  },
});

class ModificaEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      loading: false,
    };
  }

  save = event => {
    event.preventDefault();
    this.setState({
      loading: true,
    });

    this.props.send(
      () => {
        store.app.snackBar.open(
          translator('profile.editEmail.submit.success'),
          'success'
        );
        this.setState(
          {
            submitted: true,
          },
          () => {
            store.app.reloadInfo(() => {
              this.props.history.push(buildPath(ROUTES.PROFILE_VIEW));
            });
          }
        );
      },
      null,
      () => {
        this.setState({
          loading: false,
        });
      }
    );
  };

  render() {
    const { submitted, loading } = this.state;

    const { errors, object, handleChange, validateField } = this.props;

    const form_disabled = loading;

    const ids = {
      email: this.props.form.ids.form$new_email,
      emailConfirm: this.props.form.ids.form$new_email_confirmation,
      password: this.props.form.ids.form$password,
    };

    return (
      <PageContainer white main style={{ paddingTop: '40px' }}>
        <Prompt
          message={translator('common.exitAlertMessage')}
          when={!submitted}
        />
        <PageTitle
          title={<Translate content="profile.titles.editEmail" />}
          parent={{
            text: <Translate content="profile.titles.goBack" />,
            url: ROUTES.PROFILE_VIEW,
          }}
        />
        <Typography
          variant="caption"
          style={{ marginBottom: '40px', marginTop: '-25px' }}
        >
          <Translate content="profile.editEmail.subtitle" />{' '}
        </Typography>
        <form onSubmit={this.save}>
          <Row>
            <Col xs={12} sm={6} md={6}>
              <TextField
                disabled={form_disabled}
                error={Boolean(errors[ids.email])}
                helperText={errors[ids.email]}
                value={object[ids.email]}
                id={ids.email}
                name={ids.email}
                onChange={handleChange}
                label={
                  <Translate content="profile.editEmail.fields.newEmail.label" />
                }
                placeholder={translator(
                  'profile.editEmail.fields.newEmail.placeholder'
                )}
                validateField={validateField}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} md={6}>
              <TextField
                disabled={form_disabled}
                error={Boolean(errors[ids.emailConfirm])}
                helperText={errors[ids.emailConfirm]}
                value={object[ids.emailConfirm]}
                id={ids.emailConfirm}
                name={ids.emailConfirm}
                onChange={handleChange}
                label={
                  <Translate content="profile.editEmail.fields.confirmEmail.label" />
                }
                placeholder={translator(
                  'profile.editEmail.fields.confirmEmail.placeholder'
                )}
                validateField={validateField}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} md={6}>
              <TextField
                disabled={form_disabled}
                type="password"
                error={Boolean(errors[ids.password])}
                helperText={errors[ids.password]}
                value={object[ids.password]}
                id={ids.password}
                name={ids.password}
                onChange={handleChange}
                label={<Translate content="profile.confirmMessage" />}
                placeholder={translator(
                  'profile.editEmail.fields.password.placeholder'
                )}
                validateField={validateField}
              />
            </Col>
          </Row>
          <SaveButtons
            cancelDisabled={form_disabled}
            loading={loading}
            errors={errors}
            width={50}
            onClick={this.save}
          />
        </form>
      </PageContainer>
    );
  }
}

export default withFormsValidation('EmailChangeRequestForm')(
  withStyles(styles)(ModificaEmail)
);
