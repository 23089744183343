import React from 'react';
import PropTypes from 'prop-types';
import Translate from 'react-translate-component';
import translator from 'counterpart';
import { Col, Row } from 'react-flexbox-grid';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import SelectField from '../../common/Forms/SelectField';
import { AddressField } from '../../common/Forms/AddressField';
import TextField from '../../common/Forms/TextField';
import LogoWidget from '../../common/Forms/LogoWidget';
import validators from '../../../utils/validators';
import CopyButton from './CopyButton';

const styles = theme => ({
  copyVatParent: {
    position: 'relative',
  },
  formRow: {
    marginBottom: '30px',
  },
  logoWidget: {
    marginTop: '100px',
    marginBottom: '100px',
  },
});

class ModificaBusinessFormLiberoProfessionista extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    validating: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    vat: PropTypes.string.isRequired,
    codice_fiscale: PropTypes.string.isRequired,
    residency_address: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    operation_field: PropTypes.string.isRequired,
    logo: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    handleAddressSelect: PropTypes.func.isRequired,
    handleAddressBlur: PropTypes.func.isRequired,
    operationalFields: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
  };

  static requiredFields(business) {
    return ['vat', 'residency_address', 'phone', 'email', 'operation_field'];
  }

  static validate(business) {
    let errors = {};

    // TODO: should I validate the VAT?

    // TODO: il codice fiscale e' richiesto?

    if (validators.isEmptyString(business.vat)) {
      errors['vat'] = translator('errors.requiredField');
    }

    if (validators.isEmptyString(business.phone)) {
      errors['phone'] = translator('errors.requiredField');
    } else if (!validators.isValidPhoneNumber(business.phone)) {
      errors['phone'] = translator('errors.validPhone');
    }

    if (validators.isEmptyString(business.email)) {
      errors['email'] = translator('errors.requiredField');
    } else if (!validators.isValidEmail(business.email)) {
      errors['email'] = translator('errors.emailValid');
    }

    return errors;
  }

  render() {
    const { errors } = this.props;

    const {
      classes,
      disabled,
      vat,
      fiscal_code,
      residency_address,
      phone,
      email,
      operation_field,
      logo,
      editMode,
      operationalFields,
    } = this.props;

    const {
      handleChange,
      handleLogoChange,
      handleAddressSelect,
      handleAddressBlur,
      validateField,
      staticAction,
      handleChangeGeneric,
    } = this.props;

    return (
      <React.Fragment>
        <Row className={classes.formRow}>
          <Col xs={6}>
            <TextField
              disabled={disabled || editMode}
              label={<Translate content="business.piva" />}
              value={vat}
              id="vat"
              onChange={handleChange('vat')}
              error={Boolean(errors.vat)}
              helperText={errors.vat}
              placeholder={translator('placeholder.partitaIVA')}
              fullWidth
              staticAction={staticAction}
              validateField={validateField}
            />
          </Col>
          <Col xs={6} className={classes.copyVatParent}>
            <CopyButton
              onClick={() => handleChangeGeneric('fiscal_code')(vat)}
              text={translator('business.equalsToPiva') + ' >'}
            />
            <TextField
              disabled={disabled || editMode}
              label={<Translate content="business.cf" />}
              placeholder={translator('placeholder.codiceFiscale')}
              value={fiscal_code}
              id="fiscal_code"
              onChange={handleChange('fiscal_code')}
              error={Boolean(errors.fiscal_code)}
              helperText={errors.fiscal_code}
              fullWidth
              staticAction={staticAction}
              validateField={validateField}
            />
          </Col>
        </Row>
        <Row className={classes.formRow}>
          <Col xs={12}>
            <AddressField
              disabled={disabled}
              value={residency_address}
              id="residency_address"
              onChange={handleChangeGeneric('residency_address')}
              onSelect={value =>
                handleAddressSelect('residency_address', value)
              }
              onBlur={event =>
                handleAddressBlur('residency_address', event.target.value)
              }
              fullWidth
              label={<Translate content="business.address" />}
              error={Boolean(errors.residency_address)}
              helperText={errors.residency_address}
              placeholder={translator('placeholder.indirizzo')}
            />
          </Col>
        </Row>
        <Row className={classes.formRow}>
          <Col xs={6}>
            <TextField
              disabled={disabled}
              label={<Translate content="business.phone" />}
              value={phone}
              id="phone"
              onChange={handleChange('phone')}
              error={Boolean(errors.phone)}
              helperText={errors.phone}
              placeholder={translator('placeholder.telefono')}
              fullWidth
              staticAction={staticAction}
              validateField={validateField}
            />
          </Col>
          <Col xs={6}>
            <TextField
              disabled={disabled}
              label={<Translate content="business.email" />}
              value={email}
              id="email"
              onChange={handleChange('email')}
              error={Boolean(errors.email)}
              helperText={errors.email}
              placeholder={translator('placeholder.email')}
              fullWidth
              staticAction={staticAction}
              validateField={validateField}
            />
          </Col>
        </Row>
        <Row className={classes.formRow}>
          <Col xs={6}>
            <SelectField
              disabled={disabled}
              id="operation_field"
              value={operation_field}
              onChange={handleChange('operation_field')}
              fullWidth
              error={Boolean(errors.operation_field)}
              label={translator('business.sector')}
              placeholder={translator('placeholder.settore')}
              helperText={errors.operation_field}
            >
              {operationalFields.map((operation_field, idx) => (
                <MenuItem key={idx} value={operation_field.uuid}>
                  {operation_field.name}
                </MenuItem>
              ))}
            </SelectField>
          </Col>
        </Row>
        <LogoWidget
          className={classes.logoWidget}
          disabled={disabled}
          onChange={handleLogoChange}
          logo={logo}
          error={Boolean(errors.logo)}
          helperText={errors.logo}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ModificaBusinessFormLiberoProfessionista);
