import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Row, Col } from 'react-flexbox-grid';
import Button from '../../common/Button';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import {
  ROUTE_FILTERS,
  ROUTE_PARAMETERS,
  ROUTES,
} from '../../../constants/routes';
import { Link } from 'react-router-dom';
import Translate from 'react-translate-component';
import Info from '../../common/Info';
import classnames from 'classnames';
import { buildPath } from '../../../utils/routes';
import GoToDetail from '../../common/GoToDetail';
import ExchangesAPI from '../../../api/Exchanges';
import PosAPI from '../../../api/POS';
import MobilePosAPI from '../../../api/MobilePoS';
import AccountsAPI from '../../../api/Accounts';
import translator from 'counterpart';
import Deactivate from '../common/DeactivateModal';
import Activate from '../common/ActivateModal';
import { LoadingModal } from '../../common/LoadingModal';
import { withRouter } from 'react-router-dom';
import { formatAddress } from '../../../utils/formatters';
import { AccountsSection } from './Detail/partial';
import {
  AssociateDeviceContainer,
  IntegrationSection,
  DeviceAssociated,
} from './partial';
import AssociateDevice from '../common/AssociateDevice';
import store from '../../../store';

const styles = theme => ({
  container: {
    border: '1px solid',
    height: '100%',
    borderColor: theme.palette.primary.light,
    borderRadius: '6px',
    backgroundColor: theme.palette.common.white,
    marginRight: '5px',
  },
  innerContainer: {
    padding: '30px',
  },
  avatar: {
    marginRight: '15px',
  },
  button: {
    margin: theme.spacing.unit,
  },
  row: {
    marginBottom: '30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowSmallMarginBottom: {
    marginBottom: '15px',
  },
  avatarAndName: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    wordBreak: 'break-all',
  },
  divider: {
    marginBottom: '20px',
  },
  spacingDiv: {
    margin: '10px 0px',
  },
  mobilePOS: {
    backgroundColor: '#fcf9f5',
    borderLeft: 'solid 6px #f5a623',
  },
  yellow: {
    color: '#f5a623',
  },
  listIcon: {
    width: '20px',
    height: '21px',
    fontSize: '20px',
  },
  deviceIcon: {
    width: '21px',
    height: '24px',
  },
  marginBottom: {
    marginBottom: '10px',
  },
  accountIcon: theme.icon.medium,
  color4: {
    color: theme.palette.grey[4],
  },
  unactivePosLabel: {
    backgroundColor: theme.palette.red.main,
    color: '#fff',
    fontWeight: 600,
    marginRight: '11px',
    borderRadius: '4px',
    height: '24px',
  },
  warningList: {
    justifyContent: 'flex-start',
  },
  detailTitle: {
    color: theme.palette.primary.main,
  },
  actions: {
    textAlign: 'center',
  },
});

class ListDetail extends React.Component {
  static propTypes = {
    handlePosChange: PropTypes.func,
    epos: PropTypes.shape({
      name: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
    }),
    business_uuid: PropTypes.string.isRequired,
  };

  state = {
    currency: null,
    hasAccounts: false,
    activateModalOpened: false,
    deactivateModalOpened: false,
    loadingModalOpened: false,
    loadingModalMessage: null,
  };

  componentDidMount() {
    ExchangesAPI.getSingleCurrency(
      this.props.epos.currency,
      result => {
        this.setState({
          currency: result,
        });
      },
      e => {}
    );

    AccountsAPI.getList(this.props.business_uuid, this.onAccountsLoaded);
    if (this.props.epos.type === 'mobile') {
      store.app.createMobilePosSocket(this.props.epos.uuid);
    }
  }

  componentWillUnmount() {
    if (this.props.epos.type === 'mobile') {
      store.app.destroyMobilePosSocket(this.props.epos.uuid);
    }
  }

  onAccountsLoaded = result => {
    this.setState({
      hasAccounts: result.bank.length,
    });
  };

  activatePos = () => {
    if (this.props.epos.type === 'web') {
      PosAPI.activate(
        this.props.epos.uuid,
        this.posActivated,
        this.posActivateError
      );
    }
  };

  posActivated = () => {
    this.setState({
      loadingModalOpened: false,
    });

    let epos = this.props.epos;
    epos.active = true;

    if (this.props.handlePosChange)
      this.props.handlePosChange(this.props.key, epos);
  };

  posActivateError = error => {
    this.setState({
      loadingModalOpened: false,
    });
  };

  deactivatePos = () => {
    if (this.props.epos.type === 'web') {
      PosAPI.deactivate(
        this.props.epos.uuid,
        this.posDeactivated,
        this.posDeactivateError
      );
    } else {
      MobilePosAPI.deactivate(
        this.props.epos.uuid,
        this.posDeactivated,
        this.posDeactivateError
      );
    }
  };

  posDeactivated = () => {
    this.setState({
      loadingModalOpened: false,
    });

    let epos = this.props.epos;
    epos.active = false;

    if (this.props.handlePosChange)
      this.props.handlePosChange(this.props.key, epos);
  };

  posDeactivateError = error => {
    this.setState({
      loadingModalOpened: false,
    });
  };

  openActivateModal = () => {
    this.setState({
      activateModalOpened: true,
      loadingModalMessage: 'POS.activate.loading.title',
    });
  };

  handleActivateModalCancel = () => {
    this.setState({
      activateModalOpened: false,
    });
  };

  handleActivateModalConfirm = () => {
    this.setState({
      activateModalOpened: false,
    });

    if (this.props.epos.deposit_account && this.state.hasAccounts) {
      this.setState(
        {
          loadingModalOpened: true,
        },
        this.activatePos
      );
    } else if (!this.props.epos.deposit_account && !this.state.hasAccounts) {
      this.redirectTo(ROUTES.ACCOUNT_NEW, {
        [ROUTE_PARAMETERS.BUSINESS_ID]: this.props.business_uuid,
      });
    } else {
      this.redirectTo(ROUTES.POS_EDIT, {
        [ROUTE_PARAMETERS.BUSINESS_ID]: this.props.business_uuid,
        [ROUTE_PARAMETERS.POS_ID]: this.props.epos.uuid,
      });
    }
  };

  redirectTo = (route, parameters) => {
    this.props.history.push(buildPath(route, parameters));
  };

  openDeactivateModal = () => {
    this.setState({
      deactivateModalOpened: true,
      loadingModalMessage: 'POS.deactivate.loading.title',
    });
  };

  handleDeactivateModalConfirm = () => {
    this.setState(
      {
        deactivateModalOpened: false,
        loadingModalOpened: true,
      },
      this.deactivatePos
    );
  };

  handleDeactivateModalCancel = () => {
    this.setState({
      deactivateModalOpened: false,
    });
  };

  render() {
    const { classes, epos, business_uuid } = this.props;
    const {
      hasAccounts,
      currency,
      activateModalOpened,
      deactivateModalOpened,
      loadingModalOpened,
      loadingModalMessage,
    } = this.state;
    const { deposit_account, type } = epos;
    const hasWarnings = !epos.active || !deposit_account;
    const isMobile = type === 'mobile';

    return (
      <div id={this.props.id} className={classes.container}>
        <div className={classes.innerContainer}>
          <div
            className={classnames(classes.row, {
              [classes.rowSmallMarginBottom]: hasWarnings,
            })}
          >
            <div className={classes.avatarAndName}>
              <img
                className={classnames([classes.avatar, classes.accountIcon])}
                src={
                  epos.type === 'web'
                    ? epos.active
                      ? require('../../../img/webPos/active/medium.png')
                      : require('../../../img/webPos/unactive/medium.png')
                    : epos.active
                    ? require('../../../img/mobilePos/active/large.png')
                    : require('../../../img/mobilePos/unactive/large.png')
                }
                alt={epos.name + isMobile ? 'MobilePos' : ' WebPos'}
              />
              <div>
                <Typography
                  variant="title"
                  className={classnames(classes.color4, 'pos-detail-title')}
                >
                  {epos.name}
                </Typography>
                <Typography variant="caption">
                  {isMobile
                    ? epos.store_address
                      ? formatAddress(epos.store_address)
                      : null
                    : epos.website}
                </Typography>
              </div>
            </div>
            <div className={classes.actions}>
              <Button
                id="visualizzaPagamenti"
                small
                customClass={classes.button}
                color="primary"
                variant="outlined"
                component={props => (
                  <Link
                    to={buildPath(ROUTES.PAYMENT_ORDER_LIST_WITH_FILTER, {
                      [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
                      [ROUTE_PARAMETERS.PAYMENT_ORDER_FILTER_TYPE]: isMobile
                        ? ROUTE_FILTERS.PAYMENT_ORDER.mobilepos
                        : ROUTE_FILTERS.PAYMENT_ORDER.webpos,
                      [ROUTE_PARAMETERS.PAYMENT_ORDER_FILTER_ID]: epos.uuid,
                    })}
                    {...props}
                  />
                )}
              >
                <Translate content="POS.detail.actions.viewPayment" />
              </Button>
              <Button
                id="modifica"
                customClass={classes.button}
                color="primary"
                variant="outlined"
                small
                component={props => (
                  <Link
                    to={buildPath(
                      isMobile ? ROUTES.POS_EDIT_MOBILE : ROUTES.POS_EDIT_WEB,
                      {
                        [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
                        [ROUTE_PARAMETERS.POS_ID]: epos.uuid,
                      }
                    )}
                    {...props}
                  />
                )}
              >
                <Translate content="POS.detail.actions.edit" />
              </Button>
              {epos.active ? (
                <Button
                  id={'disattiva'}
                  customClass={classes.button}
                  color={'primary'}
                  variant={'outlined'}
                  small
                  onClick={this.openDeactivateModal}
                >
                  <Translate content="POS.detail.actions.deactivate" />
                </Button>
              ) : (
                !isMobile && (
                  <Button
                    id={'attiva'}
                    customClass={classes.button}
                    color={'primary'}
                    variant={'raised'}
                    small
                    onClick={this.openActivateModal}
                  >
                    <Translate content="POS.detail.actions.activate" />
                  </Button>
                )
              )}
            </div>
          </div>
          {hasWarnings && (
            <div className={classnames(classes.row, classes.warningList)}>
              {!epos.active && (
                <Chip
                  label={translator('POS.detail.state.notActive')}
                  className={classes.unactivePosLabel}
                />
              )}

              {!deposit_account && (
                <Chip
                  label={translator('POS.detail.state.noAccountAssociated')}
                  className={classes.unactivePosLabel}
                />
              )}
            </div>
          )}
          <Divider className={classes.divider} />
          <Typography
            variant="subheading"
            className={classnames(['w600', classes.detailTitle])}
            gutterBottom
          >
            <Translate content="POS.detail.detail" />
          </Typography>
          <Row>
            <Col xs={8} sm={8} md={8} lg={8}>
              <Row>
                <Col xs={4} sm={4} md={4} lg={4}>
                  <Typography variant="caption">
                    <Translate content="POS.detail.currency" />
                  </Typography>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8}>
                  <Typography variant="body2" className={classes.marginBottom}>
                    {currency ? currency.name : null}
                  </Typography>
                </Col>
              </Row>
              {deposit_account ? (
                <Row>
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <Typography variant="caption">
                      <Translate content="POS.detail.account" />
                    </Typography>
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={8}>
                    <GoToDetail
                      name={deposit_account.name}
                      id={deposit_account.uuid}
                      type={deposit_account.type}
                    />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <Typography variant="caption">
                      <Translate content="POS.detail.account" />
                    </Typography>
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={8}>
                    <Typography
                      className="f-13"
                      variant="caption"
                      style={{ marginBottom: '0.8em' }}
                    >
                      <AccountsSection
                        business_uuid={business_uuid}
                        hasAccounts={hasAccounts}
                        pos_uuid={epos.uuid}
                        isMobile={isMobile}
                      />
                    </Typography>
                  </Col>
                </Row>
              )}
            </Col>
            <Col xs={4} sm={4} md={4} lg={4}>
              <Info
                title={<Translate content="common.FAQ_SECTION" />}
                faq={[
                  {
                    text: <Translate content="POS.list.faq.WHAT" />,
                    url: 'what_is_a_pos',
                  },
                  {
                    text: <Translate content="POS.list.faq.HOW" />,
                    url: 'web_pos_integration',
                  },
                ]}
              />
            </Col>
          </Row>
          <div className={classes.spacingDiv} />
          {!isMobile && <IntegrationSection epos={epos} />}
          {isMobile && !epos.active && (
            <AssociateDeviceContainer>
              <AssociateDevice
                pos_id={epos.pos_id}
                secret={epos.secret}
                variant="warning"
              />
            </AssociateDeviceContainer>
          )}
          {isMobile && epos.active && <DeviceAssociated mobilePos={epos} />}
        </div>
        {epos.active && (
          <Deactivate
            id={'deactivatePosModal'}
            pos={epos}
            open={deactivateModalOpened}
            handleClose={this.handleDeactivateModalCancel}
            onConfirm={this.handleDeactivateModalConfirm}
            submitting={false}
          />
        )}
        {!epos.active && (
          <Activate
            id={'activatePosModal'}
            hasAccounts={hasAccounts}
            pos={epos}
            open={activateModalOpened}
            handleClose={this.handleActivateModalCancel}
            onConfirm={this.handleActivateModalConfirm}
            submitting={false}
          />
        )}
        <LoadingModal
          id={'loadingPosStateModal'}
          open={loadingModalOpened}
          title={<Translate content={loadingModalMessage} />}
        />
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ListDetail));
