import React from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Translate from 'react-translate-component';

const styles = theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '60px',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: '60px',
    textAlign: 'center',
  },
  logo: {
    width: '280px',
    height: '60px',
    marginBottom: '50px',
    alignSelf: 'center',
  },
});

class ErrorPage extends React.Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.arrayOf(PropTypes.object).isRequired,
    showLogo: PropTypes.bool,
  };

  render() {
    const { classes, title, children, errorCode, showLogo } = this.props;
    return (
      <div className={classes.mainContainer}>
        <div className={classes.innerContainer}>
          {showLogo && (
            <img
              src={require('../../img/logo/CSpayBlue.png')}
              className={classes.logo}
              alt="ChainsidePay"
            />
          )}
          <Typography
            variant="title"
            className="f-72 montserrat"
            style={{ marginBottom: '10px' }}
          >
            <Translate
              content={errorCode === 403 ? 'common.stop' : 'common.ops'}
            />
          </Typography>
          <Typography
            variant="title"
            className="f-22"
            style={{ marginBottom: '50px' }}
          >
            {title}
          </Typography>
          {React.Children.map(children, child => {
            return React.cloneElement(child);
          })}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ErrorPage);
