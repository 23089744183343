import { Col, Row } from 'react-flexbox-grid';
import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';
import { DetailsRowProps } from './propTypes';
import { withStyles } from '@material-ui/core';
import styles from './theme';

const DetailsRow: FC<DetailsRowProps> = ({ classes, name, value }) => (
  <Row className={classes!.row}>
    <Col xs={4}>
      <Typography variant="caption">{name}</Typography>
    </Col>
    <Col xs={8}>
      <Typography variant="body2">{value}</Typography>
    </Col>
  </Row>
);

const DecoratedDetailsRow = withStyles(styles)(DetailsRow);

export default DecoratedDetailsRow;
