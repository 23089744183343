import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { green } from '@material-ui/core/colors';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#1a7ab6',
      link: '#1a7ab6',
      dark: '#1a6797',
      darker: '#135985',
      light: '#7babcb',
      lighter: '#d7dfe5', //TODO check all primary-lighet and change in primary.bluegrey
      bluegrey: '#d7dfe5',
      bluegreydark: '#b5c1c9',
      bluegreylight: '#f2f3f3',
      bg: '#f4f7f8',
      bgNavBar: '#e6edf1',
      bgtopBarFooter: '#1a7ab6',
      navbarText: '#1a7ab6',
    },
    secondary: green,
    orange: {
      main: '#f5a623',
      dark: '#dc931b',
      light: '#fbd08b',
      bg: '#fcedd6',
    },
    green: {
      main: '#619823',
      dark: '#52821a',
      light: '#aac48c',
      bg: '#daecd0',
    },
    red: {
      main: '#dd4848',
      bg: '#fff5f5',
      dark: '#c23131',
    },
    error: {
      main: '#dd4848',
      bg: '#fff5f5',
    },
    grey: {
      main: '#e0e0e0',
      dark: '#888888',
      // light: '#f0f0f0'
      light: '#c5c5c5',
      background: '#fafafa',
      midDark: '#d7dfe5',
      cookieAlert: '#9b9b9b',
      4: '#444444',
      7: '#777777',
    },
    badge: {
      success: '#619823',
      error: '#c62828',
      warning: '#f5a623',
      info: '#1976d2',
    },
    alert: {
      backgroundColor: '#fcedd6',
      borderColor: '#efca8e',
      border: '1px solid',
      color: '#f5a623',
    },
    infoSection: {
      backgroundColor: '#f4f7f8',
      color: '#1a7ab6',
    },
    pos: {
      web: '#f5a623',
      mobile: '#619823',
      associateDevice: {
        bg: '#fcf9f5',
        border: '#f9f1e3',
      },
    },
    genericSelectable: {
      selected: '#f0f8e8', //FIXME non è mappata con i colori trovare un mapping
    },
    linkFooter: '#d7dfe5',
  },
  components: {
    tableRow: {
      borderRadius: '6px',
      boxShadow: '0 1px 2px 0 #d7dfe5',
      backgroundColor: '#ffffff',
      border: 'solid 1px #d7dfe5',
    },
    inputField: {
      borderRadius: '2px',
      padding: '10px 12px 11px',
      fontSize: '14px',
      color: '#444444',
      '&::placeholder': {
        fontStyle: 'italic',
        color: '#aaaaaa',
      },
      '&:disabled': {
        color: '#aaaaaa',
      },
    },
  },
  typography: {
    fontFamily: 'Lato',
    fontFamilies: {
      primary: 'Lato',
      accent: 'Montserrat',
      code: 'Courier',
    },
    fontWeightBold: 600,
    weights: {
      thin: 500,
      normal: 600,
      bold: 800,
    },
    subheading: {
      color: '#1a7ab6',
    },
    body1: {
      fontSize: '14px',
      fontWeight: '600',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#777777',
    },
    body2: {
      fontSize: '14px',
      fontWeight: '600',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#444444',
    },
    title: {
      fontSize: '24px',
      fontWeight: '600',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#444444',
    },
    caption: {
      fontSize: '14px',
      fontWeight: '600',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#999999',
    },
    //boxBody: {
    //  height: '16px',
    //  fontFamily: 'Montserrat',
    //  fontSize: '12px',
    //  fontWeight: 'normal',
    //  fontStyle: 'normal',
    //  fontStretch: 'normal',
    //  lineHeight: 'normal',
    //  letterSpacing: 'normal',
    //  textAlign: 'left',
    //  color: '#666666',
    //},
    errorLabel: {
      color: '#dd4848',
      margin: '-2px 0px 5px 2px',
      lineHeight: '1em',
      fontSize: '0.75em',
    },
    link: {
      textDecoration: 'none',
      fontSize: '14px',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 4,
        fontSize: '16px',
        paddingTop: '10px',
        paddingBottom: '10px',
        boxShadow: 'none',
      },
      raised: {
        boxShadow: 'none',
      },
      raisedPrimary: {
        '&:hover': {
          backgroundColor: '#1a6797',
        },
      },
      disabled: {
        color: 'inherit',
        backgroundColor: 'inherit',
      },
      sizeLarge: {
        padding: '13px 24px',
      },
    },
    MuiInput: {
      root: {
        borderRadius: '2px',
        border: 'solid 1px #d7dfe5',
      },
      error: {
        borderColor: '#dd4848',
      },
    },
    MuiInputLabel: {
      root: {
        padding: '0px 10px',
        zIndex: '1',
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: 'transparent',
        color: 'inherit',
      },
    },
  },
  shadow: {
    boxShadow: '0 1px 2px 0 #d7dfe5',
  },
  appBarShadow: '0 1px 0 0 #c3dbeb',
  icon: {
    small: {
      width: '24px',
      height: '24px',
    },
    medium: {
      width: '40px',
      height: '40px',
    },
    large: {
      width: '60px',
      height: '60px',
    },
    arrowSmall: {
      width: '11px',
      height: '20px',
    },
    arrowLarge: {
      width: '19px',
      height: '32px',
    },
    icon22: {
      width: '22px',
      height: '22px',
    },
  },
});
