import React from 'react';
import Typography from '@material-ui/core/Typography';
import Translate from 'react-translate-component';
import { WebPOS, MobilePOS } from './common';
import Helper from '../../common/Helper';
import { withRouter } from 'react-router-dom';
import { ROUTE_PARAMETERS, ROUTES } from '../../../constants/routes';
import { withStyles } from '@material-ui/core/styles';
import { buildPath } from '../../../utils/routes';

const style = theme => ({
  icon: theme.icon.large,
});

export const WebposHelper = withStyles(style)(
  withRouter(props => {
    const business_uuid = props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    const onClick = () => {
      props.history.push(
        buildPath(ROUTES.POS_NEW_WEB, {
          [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
        })
      );
    };
    return (
      <Helper
        icon={
          <img
            src={require('../../../img/webPos/active/large.png')}
            alt="webpos"
            className={props.classes.icon}
          />
        }
        onClick={onClick}
        text={
          <React.Fragment>
            <WebPOS fontSize={22} />
            <Typography variant="caption">
              <Translate content="POS.list.suggestion.web.subtitle" />
            </Typography>
          </React.Fragment>
        }
      />
    );
  })
);

export const MobilePOSHelper = withStyles(style)(
  withRouter(props => {
    const business_uuid = props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    const onClick = () => {
      props.history.push(
        buildPath(ROUTES.POS_NEW_MOBILE, {
          [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
        })
      );
    };
    return (
      <Helper
        icon={
          <img
            src={require('../../../img/mobilePos/active/large.png')}
            alt="mobilepos"
            className={props.classes.icon}
          />
        }
        onClick={onClick}
        text={
          <React.Fragment>
            <MobilePOS fontSize={22} />
            <Typography variant="caption">
              <Translate content="POS.list.suggestion.mobile.subtitle" />
            </Typography>
          </React.Fragment>
        }
      />
    );
  })
);
