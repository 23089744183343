import translator from 'counterpart';
import MessageTranslation from '../constants/translations_validation_errors.json';
import ParamTranslation from '../constants/translations_parameters.json';
import TypeTranslation from '../constants/translations_types.json';

function isValidPlace(place) {
  return (
    placeHasComponent(place, 'street_number') ||
    placeIsSquare(place) ||
    placeHasKilometers(place)
  );
}

function placeIsSquare(place) {
  let matches = ['piazza', 'largo', 'slargo'];

  for (let i = 0; i < matches.length; i++) {
    if (
      placeHasComponent(place, 'route') &&
      isPrefix(matches[i], getFirstPlaceComponent(place, 'route').long_name)
    ) {
      return true;
    }
  }

  return false;
}

function placeHasKilometers(place) {
  const regex = /(km)\s*([1-9]([0-9]{1,2})?)(.[1-9](00))?/i;

  return (
    placeHasComponent(place, 'route') &&
    regex.test(getFirstPlaceComponent(place, 'route').long_name)
  );
}

function isPrefix(prefix, string) {
  return string.toLowerCase().indexOf(prefix) === 0;
}

function getFirstPlaceComponent(place, component) {
  let result = getPlaceComponents(place, component);
  return result == null ? result : result[0];
}

function getPlaceComponents(place, component) {
  let result =
    place && place.address_components
      ? place.address_components.filter(e => {
          return e.types.indexOf(component) !== -1;
        })
      : [];
  if (result.length === 0) {
    return null;
  } else {
    return result;
  }
}

function placeHasComponent(place, component) {
  return place.address_components.some(function(item) {
    return item.types.indexOf(component) !== -1;
  });
}

function getMime(file, callback) {
  const filereader = new FileReader();
  filereader.onloadend = function(evt) {
    if (evt.target.readyState === FileReader.DONE) {
      const uint = new Uint8Array(evt.target.result);
      let bytes = [];
      uint.forEach(byte => {
        bytes.push(byte.toString(16));
      });
      const hex = bytes.join('').toUpperCase();

      callback(getMimetype(hex));
    }
  };
  const blob = file.slice(0, 4);
  filereader.readAsArrayBuffer(blob);
}

const getMimetype = signature => {
  switch (signature) {
    case '89504E47':
      return 'image/png';
    case 'FFD8FFDB':
    case 'FFD8FFE0':
    case 'FFD8FFE1':
      return 'image/jpeg';
    case '424D384':
      return 'image/bmp';
    default:
      return 'Unknown filetype';
  }
};
export function sdkValidate(action, param, value) {
  if (!action) {
    return null;
  }
  try {
    const errors = action.validate(param, value);
    if (errors && errors.length > 0) {
      return getErrorStringFromErrorsCode(errors);
    }
  } catch (e) {
    return null;
  }
}

export function getErrorStringFromErrorsCode(errorsCode) {
  if (!errorsCode || errorsCode.length < 1) {
    return null;
  }
  let errorsArray = [];
  for (const error of errorsCode) {
    let params = null;
    if (error.params && error.params.length > 0) {
      params = {};
      let i = 1;
      for (const param of error.params) {
        const paramName = 'param' + i;
        if (param in ParamTranslation) {
          params[paramName] = translator(ParamTranslation[param]);
        } else if (param in TypeTranslation && error.name === 'type') {
          params[paramName] = translator(TypeTranslation[param]);
        } else {
          params[paramName] = param;
        }
        i++;
      }
    }
    errorsArray.push(
      translator(MessageTranslation[error.name].error_string, params)
    );
  }
  //the returns eliminate the commas and space at the end
  return errorsArray;
}

export default {
  isValidPlace: isValidPlace,
  sdkValidate: sdkValidate,
  getErrorStringFromErrorsCode: getErrorStringFromErrorsCode,
  getMime: getMime,
};
