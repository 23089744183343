export default {
  generic: {
    ok: 'Operation completed successfully',
    ko: 'Operation failed. Please try again later ',
  },
  account: {
    new: {
      ok: 'Account created successfully',
    },
  },
};
