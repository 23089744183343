import React from 'react';

import classnames from 'classnames';

import Button from '../common/Button';
import TextField from '../common/Forms/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import UnauthenticatedPage from '../common/UnauthenticatedPage';
import HalfPageImage from '../common/HalfPageImage';
import { LinkPrimary } from '../common/Links';
import CheckboxField, { GDPRCheckbox } from '../common/Forms/CheckboxField';

import store from '../../store';

import { ROUTES } from '../../constants/routes';
import translator from 'counterpart';
import Translate from 'react-translate-component';
import InvalidFormMessage from '../common/InvalidFormMessage';
import { withFormsValidation } from '../common/Forms/FormsCommon';
import GDPRApi from '../../api/GDPR';
import { withGenericErrorHandling } from '../common/ErrorHandling';
import { buildPath } from '../../utils/routes';
import { CookieAlert } from '../common/CookieAlert';
import { WebsiteLink } from '../common/WebsiteLink';

const styles = theme => ({
  title: {
    marginTop: '20px',
    width: '100%',
  },
  consensi: {
    marginBottom: '20px',
  },
  textField: {
    width: '100%',
  },
  form: {
    maxWidth: 'inherit',
  },
  inheritMaxWidth: {
    maxWidth: 'inherit',
  },
  formControl: {
    width: '100%',
    margin: '10px 0px',
  },
  signUpButton: {
    width: '100%',
  },
  inputLabelFocused: {
    color: theme.palette.primary.main,
  },
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  account: {
    fontSize: '10px',
    height: '13px',
    fontWeight: '600',
    fontStyle: 'italic',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    padding: '20px 0px',
  },
  mailSent: {
    padding: '10px 10px',
    width: '100%',
    margin: '15px 0px',
  },
  mailSendedMessage: {
    // color: theme.palette.font.green,
    fontWeight: '600',
  },
  mailSendedInfo: {
    fontSize: '12px',
    fontWeight: 'normal',
    // color: theme.palette.font.grey,
    width: '100%',
    marginTop: '20px',
  },
  marginBottom: {
    marginBottom: '10px',
  },
});

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: false,
      submitting: false,
      submitted: false,
      loading: true,
    };
  }

  componentDidMount() {
    GDPRApi.getPersonGdpr(
      result => {
        this.setState({
          firstConsenso: result.privacy_policy.uuid,
          secondConsenso: result.data_sharing.uuid,
        });
      },
      this.props.onError,
      () => {
        this.setState({ loading: true });
      }
    );
    let formattedLanguage = 'en';
    try {
      formattedLanguage = store.app.language.substr(0, 2);
    } catch (e) {
      //handleError
    }

    this.props.initObject({
      [this.props.form.ids.form$gdpr$privacy_policy]: null,
      [this.props.form.ids.form$gdpr$data_sharing]: null,
      [this.props.form.ids.form$gdpr$updates_notifications]: false,
      [this.props.form.ids.form$lang]: formattedLanguage,
    });
  }

  toggleCheckbox = fieldName => {
    this.props.handleChangeGeneric(fieldName)(!this.props.object[fieldName]);
  };

  submit = event => {
    event.preventDefault();
    this.setState({
      submitting: true,
    });
    this.props.send(
      token => {
        this.setState(
          {
            submitting: false,
            submitted: true,
          },
          () => {
            store.app.onRegister(token, () => {
              store.app.snackBar.open(
                translator('business.register.sendedMail'),
                'mail'
              );
              this.props.history.push(buildPath(ROUTES.REGISTER_BUSINESS));
            });
          }
        );
      },
      () => {
        this.setState({
          submitting: false,
        });
      }
    );
  };

  render() {
    const { classes, errors, handleChange, validateField, object } = this.props;

    const { submitting } = this.state;

    const ids = {
      email: this.props.form.ids.form$email,
      name: this.props.form.ids.form$name,
      surname: this.props.form.ids.form$surname,
      password: this.props.form.ids.form$password,
      passwordConfirm: this.props.form.ids.form$password_confirmation,
      firstConsenso: this.props.form.ids.form$gdpr$privacy_policy,
      secondConsenso: this.props.form.ids.form$gdpr$data_sharing,
      thirdConsenso: this.props.form.ids.form$gdpr$updates_notifications,
    };
    return (
      <HalfPageImage variant="signup">
        <UnauthenticatedPage
          halfWidth
          title={<Translate content="common.signUpSection.title" />}
          align="flex-start"
        >
          <Typography
            variant="caption"
            style={{
              marginBottom: '30px',
              marginTop: '10px',
              maxWidth: 'inherit',
            }}
          >
            <Translate content="common.signUpSection.subtitle" />
            &nbsp;
            <LinkPrimary forward to={ROUTES.LOGIN}>
              <Translate content="common.login" />
            </LinkPrimary>
          </Typography>
          <form onSubmit={this.submit} className={classes.form}>
            <TextField
              fullWidth
              type="text"
              label={<Translate content="profile.firstName" />}
              disabled={submitting}
              name={ids.name}
              id={ids.name}
              onChange={handleChange}
              value={object[ids.name]}
              error={Boolean(errors[ids.name])}
              helperText={errors[ids.name]}
              validateField={validateField}
            />
            <TextField
              fullWidth
              type="text"
              label={<Translate content="profile.lastName" />}
              disabled={submitting}
              name={ids.surname}
              id={ids.surname}
              onChange={handleChange}
              value={object[ids.surname]}
              error={Boolean(errors[ids.surname])}
              helperText={errors[ids.surname]}
              validateField={validateField}
            />
            <TextField
              fullWidth
              label={<Translate content="profile.email" />}
              disabled={submitting}
              name={ids.email}
              id={ids.email}
              onChange={handleChange}
              value={object[ids.email]}
              error={Boolean(errors[ids.email])}
              helperText={errors[ids.email]}
              validateField={validateField}
            />
            <TextField
              fullWidth
              type="password"
              label={<Translate content="profile.password" />}
              disabled={submitting}
              name={ids.password}
              id={ids.password}
              onChange={handleChange}
              value={object[ids.password]}
              error={Boolean(errors[ids.password])}
              helperText={errors[ids.password]}
              validateField={validateField}
            />
            <TextField
              fullWidth
              type="password"
              label={<Translate content="profile.passwordConfirm" />}
              disabled={submitting}
              name={ids.passwordConfirm}
              id={ids.passwordConfirm}
              onChange={handleChange}
              value={object[ids.passwordConfirm]}
              error={Boolean(errors[ids.passwordConfirm])}
              helperText={errors[ids.passwordConfirm]}
              validateField={validateField}
            />

            <div
              className={classnames([
                classes.consensi,
                classes.inheritMaxWidth,
              ])}
            >
              <GDPRCheckbox
                uuid={this.state.firstConsenso}
                id={ids.firstConsenso}
                name={ids.firstConsenso}
                checked={object[ids.firstConsenso]}
                onChange={this.props.handleChangeGeneric(ids.firstConsenso)}
                error={Boolean(errors[ids.firstConsenso])}
                helperText={errors[ids.firstConsenso]}
                className={classnames([
                  classes.marginBottom,
                  classes.inheritMaxWidth,
                ])}
                label={
                  <React.Fragment>
                    <Translate
                      className="f-13"
                      content="common.trattamentoDeiDati.firstConsenso"
                    />
                    <WebsiteLink
                      style={{ fontSize: '13px' }}
                      withoutIcon
                      to="privacy_policy"
                    >
                      <Translate content="common.trattamentoDeiDati.privacyPolicy" />
                    </WebsiteLink>
                  </React.Fragment>
                }
              />
              <GDPRCheckbox
                uuid={this.state.secondConsenso}
                id={ids.secondConsenso}
                name={ids.secondConsenso}
                checked={object[ids.secondConsenso]}
                onChange={this.props.handleChangeGeneric(ids.secondConsenso)}
                error={Boolean(errors[ids.secondConsenso])}
                helperText={errors[ids.secondConsenso]}
                className={classnames([
                  classes.marginBottom,
                  classes.inheritMaxWidth,
                ])}
                label={
                  <Translate
                    className="f-13"
                    content="common.trattamentoDeiDati.secondConsenso"
                  />
                }
              />
              <CheckboxField
                id={ids.thirdConsenso}
                name={ids.thirdConsenso}
                checked={object[ids.thirdConsenso]}
                onChange={() => this.props.toggleCheckbox(ids.thirdConsenso)}
                error={Boolean(errors[ids.thirdConsenso])}
                helperText={errors[ids.thirdConsenso]}
                className={classnames([
                  classes.marginBottom,
                  classes.inheritMaxWidth,
                ])}
                label={
                  <Translate
                    className="f-13"
                    content="common.trattamentoDeiDati.thirdConsenso"
                  />
                }
              />
            </div>
            <InvalidFormMessage errors={errors} />
            <Button
              id="save"
              variant="raised"
              color="orange"
              type="submit"
              customClass={classes.signUpButton}
              loading={submitting}
              onClick={this.submit}
            >
              <Translate content="common.signUp" />
            </Button>
          </form>
          <CookieAlert />
        </UnauthenticatedPage>
      </HalfPageImage>
    );
  }
}

export default withGenericErrorHandling(
  withFormsValidation('RegisterForm')(withStyles(styles)(SignUp))
);
