import { WithStyles, withStyles } from '@material-ui/core';
import Translate from 'react-translate-component';
import { styles } from './styles';
import {
  SortableTableCell,
  SortableTableRow,
} from '../../common/SortableTable';
import Typography from '@material-ui/core/Typography';
import { PaymentStatusDot } from './PaymentStatusDot';
import { status_list } from '../common/common';
import classnames from 'classnames';
import PosIcon from './PosIcon';
import Payment from '../../common/Payment';
import * as React from 'react';

interface ListaPagamentiRowProps extends WithStyles<typeof styles> {
  payment: Payment;
  onClick: (payment_uuid: string) => void;
}

export const ListaPagamentiRow = withStyles(styles)(
  class extends React.Component<ListaPagamentiRowProps> {
    onClick = () => this.props.onClick(this.props.payment.uuid);

    render() {
      const { classes, payment } = this.props;

      return (
        <SortableTableRow
          className={classes.tableRow}
          onClick={this.onClick}
        >
          <SortableTableCell className={classes.created_at} xs={2}>
            <Typography variant="caption" className="f-13">
              {payment.createdAtString}
            </Typography>
          </SortableTableCell>
          <SortableTableCell className={classes.amount} xs={2}>
            <Typography variant="body2">
              {payment.amountStringSymbol}
            </Typography>
          </SortableTableCell>
          <SortableTableCell className={classes.amount} xs={2}>
            <Typography variant="body2">
              {payment.minimalUnitToCryptoDisplay || '-'}
            </Typography>
          </SortableTableCell>
          <SortableTableCell className={classes.status} xs={2}>
            <PaymentStatusDot status={payment.status} />
            <Typography
              variant="body2"
              className="f-13"
              style={{ minWidth: '0px', flex: '0 0 30%' }}
            >
              <Translate
                content={
                  status_list[payment.status]
                    ? status_list[payment.status].labelTranslation
                    : payment.status
                }
              />
            </Typography>
          </SortableTableCell>
          <SortableTableCell className={classes.pos_name} xs={2}>
            <Typography
              variant="caption"
              className={classnames(['f-13', classes.ellipsis])}
            >
              <PosIcon posType={payment.created_by.type} />
              {payment.created_by.name}
            </Typography>
          </SortableTableCell>
          <SortableTableCell className={classes.reference} xs={2}>
            <Typography
              variant="caption"
              className={classnames(['f-13', classes.ellipsis])}
              noWrap
            >
              {payment.reference}
            </Typography>
          </SortableTableCell>
        </SortableTableRow>
      );
    }
  }
);
