import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Col, Grid, Row } from 'react-flexbox-grid';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const styles = theme => ({
  container: {
    textAlign: 'left',
    width: '100%',
    flex: '1 1 auto',
  },
  paddingBottom: {
    paddingBottom: '50px',
  },
  paddingTop: {
    paddingTop: '45px',
  },
  backgroundWhite: {
    backgroundColor: theme.palette.common.white,
  },
  backgroundGrey: {
    backgroundColor: theme.palette.grey.background,
  },
});

const PageContainer = props => (
  <Grid
    className={classnames({
      [props.classes.container]: true,
      [props.classes.paddingBottom]: props.main,
      [props.classes.backgroundWhite]: props.white,
      [props.classes.backgroundGrey]: props.grey,
      [props.classes.paddingTop]: props.top,
    })}
  >
    <div className="container" style={props.style}>
      <Row center="sm" style={{ textAlign: 'left' }}>
        <Col xs={12} sm={10}>
          {props.children}
        </Col>
      </Row>
    </div>
  </Grid>
);

PageContainer.propTypes = {
  main: PropTypes.bool,
  white: PropTypes.bool,
  grey: PropTypes.bool,
  top: PropTypes.bool,
};

export default withStyles(styles)(PageContainer);
