import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { NoAccountModal } from '../../common/Confirm';
import Translate from 'react-translate-component';

const styles = theme => ({});

class NoAccount extends React.Component {
  handleClose = () => {
    window.history.back();
  };

  render() {
    const { open, classes, onConfirm, ...otherProps } = this.props;

    return (
      <NoAccountModal
        {...otherProps}
        open={open}
        handleClose={this.handleClose}
        title={<Translate content="POS.new.noAccount.title" />}
        subtitle={<Translate content="POS.new.noAccount.subtitle" />}
        confirmButtonText={
          <Translate content={'POS.new.noAccount.buttons.createAccount'} />
        }
        onConfirm={onConfirm}
        submitting={false}
      />
    );
  }
}

export default withStyles(styles)(NoAccount);
