import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Translate from 'react-translate-component';

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '1px solid',
    width: '650px',
    height: '110px',
    borderRadius: '6px',
    boxShadow: '0 1px 2px 0 #d7dfe5',
    borderColor: theme.palette.primary.bluegrey,
    backgroundColor: theme.palette.common.white,
    margin: '5px',
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  disabled: {
    backgroundColor: theme.palette.primary.bg,
    filter: 'grayscale(100%)',
    opacity: 0.5,
  },
  mainRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '5px 30px',
  },
  containerText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
  },
  avatar: {
    width: '60px',
    height: '60px',
    marginRight: '15px',
  },
  comingSoon: {
    textTransform: 'uppercase',
    alignSelf: 'flex-end',
  },
  arrowLarge: theme.icon.arrowLarge,
});

const Helper = withStyles(styles)(props => {
  const { classes, onClick, comingSoon } = props;
  return (
    <div
      className={classnames({
        [classes.container]: true,
        [classes.pointerCursor]: !comingSoon,
        [classes.disabled]: comingSoon,
      })}
      onClick={comingSoon ? null : onClick}
    >
      <div className={classes.mainRow}>
        <div style={{ display: 'inherit' }}>
          <Avatar className={classes.avatar}>{props.icon}</Avatar>
          <div className={classes.containerText}>{props.text}</div>
        </div>
        <div style={{ display: 'flex' }}>
          {!comingSoon && (
            <img
              src={require('../../img/arrow/large.png')}
              alt="go"
              className={classes.arrowLarge}
            />
          )}
          {comingSoon && (
            <Typography
              variant="body1"
              className={classnames([classes.comingSoon, 'f-16'])}
            >
              <Translate content="common.amlCheck" />
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
});

export default Helper;
