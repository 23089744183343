import BusinessPanelSDK from 'business-panel-sdk';

import store from '../store';
import {
  onErrorNotification,
  onSuccessNotification,
  decodeBackendField,
} from './common';

export default {
  getBusinesses: (onSuccess, onError) => {
    const getAll = store.app.factory.make('GetBusinessesAction');

    getAll
      .run()
      .then(onSuccess)
      .catch(onError);
  },
  createBusiness: (person_uuid, type, data, onSuccess, onError) => {
    let createBusiness = store.app.factory.make('CreateBusinessAction');
    createBusiness.body_params = BusinessPanelSDK.sendables.GenericBusiness.fromData(
      data
    );

    createBusiness.url_params = { uuid: person_uuid };

    createBusiness
      .run()
      .then(onSuccessNotification(onSuccess))
      .catch(onErrorNotification(onError));
  },
  getBusiness: (business_uuid, onSuccess, onError) => {
    const getBusiness = store.app.factory.make('GetBusinessAction');

    getBusiness.routeParams = {
      business_uuid: business_uuid,
    };

    getBusiness
      .run()
      .then(onSuccess)
      .catch(onError);
  },
  getBusinessDetails: (business_uuid, onSuccess, onError) => {
    const getBusinessDetails = store.app.factory.make(
      'GetBusinessDetailsAction'
    );

    getBusinessDetails.routeParams = {
      business_uuid: business_uuid,
    };

    getBusinessDetails
      .run()
      .then(onSuccess)
      .catch(onError);
  },
  updateBusiness: (business_uuid, type, data, onSuccess, onError) => {
    let updateBusiness = store.app.factory.make('BusinessUpdateAction');
    updateBusiness.body_params = BusinessPanelSDK.sendables.GenericBusiness.fromData(
      data
    );

    updateBusiness.url_params = { uuid: business_uuid };

    updateBusiness
      .run()
      .then(result => {
        store.app.refreshToken(() => {
          onSuccessNotification(onSuccess)(result);
        });
      })
      .catch(onErrorNotification(onError));
  },
  deleteBusiness: (business_uuid, onSuccess, onError) => {
    const deleteBusiness = store.app.factory.make('DeleteBusinessAction');
    deleteBusiness.url_params = { uuid: business_uuid };

    deleteBusiness
      .run()
      .then(onSuccessNotification(onSuccess))
      .catch(onErrorNotification(onError));
  },
  getPositions: (onSuccess, onError) => {
    const getPositions = store.app.factory.make('GetPositionsAction');

    getPositions
      .run()
      .then(onSuccess)
      .catch(onError);
  },
  getOperationalFields: (onSuccess, onError) => {
    const getOperationalFields = store.app.factory.make(
      'GetOperationalFieldsAction'
    );

    getOperationalFields
      .run()
      .then(result => {
        const resultDecoded = decodeBackendField(
          result.operational_fields,
          'operational_field'
        );
        onSuccess(resultDecoded);
      })
      .catch(onError);
  },

  getPersons: (business_uuid, onSuccess, onError) => {
    const getPersons = store.app.factory.make('GetBusinessPersonsAction');
    getPersons.routeParams = { business_uuid: business_uuid };

    getPersons
      .run()
      .then(onSuccess)
      .catch(onError);
  },
};
