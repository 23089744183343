import React from 'react';
import PropTypes from 'prop-types';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import { DelegationComponent } from './DelegationComponent';

const DelegatedRoute = ({ component, ...rest }) => {
  return (
    <AuthenticatedRoute
      component={DelegationComponent}
      componentProps={{
        component: component,
      }}
      {...rest}
    />
  );
};

DelegatedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export { DelegatedRoute };
