import React from 'react';

import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import { withRouter } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Payment from '../../common/Payment';
import Badge from '../../common/Badge';
import Info from '../../common/Info';
import GoToDetail from '../../common/GoToDetail';

import Divider from '@material-ui/core/Divider';

import { status_list } from '../common/common';
import Translate from 'react-translate-component';

import { SortableTable } from '../../common/SortableTable/SortableTable';

import classnames from 'classnames';
import { getPaymentDates } from './DateSection';
import { SandboxTestPayment } from '../SandboxTest';
import store from '../../../store';
import { LinkPrimary } from '../../common/Links';
import {
  BLOCK_EXPLORER_VIEW_ADDRESS_URL,
  BLOCK_EXPLORER_VIEW_TX_URL,
  ETHERSCAN_VIEW_ADDRESS_URL,
  ETHERSCAN_VIEW_TX_URL,
} from '../../../config';
import { SortableTableHead } from '../../common/SortableTable/SortableTableHead';
import { SortableTableRow } from '../../common/SortableTable/SortableTableRow';
import { SortableTableCell } from '../../common/SortableTable/SortableTableCell';
import { SortableTableBody } from '../../common/SortableTable/SortableTableBody';
import { cryptos, minimalUnitToCrypto } from '../../../utils/numbers';

const getTXLink = (txid, crypto_currency) => {
  if (crypto_currency === 'USDT-ERC20') {
    return `${ETHERSCAN_VIEW_TX_URL}${txid}`;
  }
  if (crypto_currency === 'BTC') {
    return `${BLOCK_EXPLORER_VIEW_TX_URL}${txid}`;
  }
};

const getAddressLink = (address, crypto_currency) => {
  if (crypto_currency === 'USDT-ERC20') {
    return `${ETHERSCAN_VIEW_ADDRESS_URL}${address}#tokentxns`;
  }
  if (crypto_currency === 'BTC') {
    return `${BLOCK_EXPLORER_VIEW_ADDRESS_URL}${address}`;
  }
};

const columns = [
  {
    name: 'created_at',
    label: 'payment.detail.transaction.date',
    width: 2,
    sortable: true,
  },
  {
    name: 'txid',
    label: 'payment.detail.transaction.id',
    width: 4,
    sortable: true,
  },
  {
    name: 'confirmed',
    label: 'payment.detail.transaction.state',
    width: 2,
    sortable: false,
  },
  {
    name: 'outs_sum',
    label: 'payment.detail.transaction.btc_amount',
    width: 2,
    sortable: true,
  },
  {
    name: 'outs_sum',
    label: 'payment.detail.transaction.amount',
    width: 1,
    sortable: false,
  },
  {
    name: 'outs',
    label: 'payment.detail.transaction.outs',
    width: 1,
    sortable: false,
  },
];

const styles = theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  subheading: {
    color: theme.palette.primary.main,
  },
  import: {},
  mainImport: {
    color: theme.palette.primary.main,
    fontSize: '22px',
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    alignItems: 'center',
  },
  detailImport: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    borderLeft: '2px solid',
    borderColor: theme.palette.primary.bluegrey,
  },
  divider: {
    margin: '45px 0px 40px',
    height: '2px',
    color: theme.palette.primary.bluegrey,
  },
  mainRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '12px',
  },
  mainColHeader: {
    width: '65%',
  },
  mainColHelper: {
    width: '30%',
  },
  fullCol: {
    width: '100%',
  },
  detailRow: {
    display: 'flex',
    marginBottom: '12px',
    alignItems: 'center',
  },
  detailColHeader: {
    flex: '4',
  },
  detailColContent: {
    flex: '8',
    wordBreak: 'break-all',
  },
  transactionContainer: {
    borderColor: theme.palette.primary.bluegrey,
    border: '1px solid',
    backgroundColor: theme.palette.primary.bg,
    borderRadius: '6px',
  },
  transactionDot: {
    height: '10px',
    width: '10px',
    borderRadius: '50%',
  },
  green: {
    backgroundColor: theme.palette.green.main,
  },
  orange: {
    backgroundColor: theme.palette.orange.main,
  },
  red: {
    backgroundColor: theme.palette.red.main,
  },
  primaryLight: {
    backgroundColor: theme.palette.primary.light,
  },
  iconSmall: theme.icon.small,
  arrowSmall: theme.icon.arrowSmall,
});

class PaymentView extends React.Component {
  static propTypes = {
    payment: PropTypes.instanceOf(Payment).isRequired,
  };

  render() {
    const { classes, payment } = this.props;

    const { transactions } = payment;

    const status = status_list[payment.status];
    const dates = payment ? getPaymentDates(payment) : null;
    return (
      <div>
        <div className={classes.header}>
          <div className={classes.importo}>
            <Typography variant="caption" style={{ marginBottom: '10px' }}>
              <Translate content="payment.detail.import" />
            </Typography>
            <Typography variant="subheading" className={classes.mainImport}>
              {' '}
              {payment.amountString}
              <Badge style={{ marginLeft: '25px' }} type={status.type}>
                <Translate content={status.labelTranslation} />
              </Badge>
            </Typography>
            <Typography variant="body2" className="f-16">
              {payment.crypto_currency
                ? `${cryptos[payment.crypto_currency].label} ${
                    payment.cryptoAmountString
                  }`
                : '-'}
            </Typography>
          </div>
          {status.faq && (
            <div
              className={classes.mainColHelper}
              style={{ alignSelf: 'flex-end' }}
            >
              <Info
                faq={[
                  {
                    text: <Translate content={status.faq.text} />,
                    url: status.faq.link,
                  },
                ]}
              />
            </div>
          )}
        </div>
        {store.app.sandboxMode && (
          <SandboxTestPayment
            updatePayment={this.props.updatePayment}
            payment={payment}
            payment_uuid={payment.uuid}
          />
        )}
        {!store.app.sandboxMode && <Divider className={classes.divider} />}
        <Typography
          variant="subheading"
          className={classnames(['w600', classes.subheading])}
        >
          <Translate content="payment.detail.sections.detail" />
        </Typography>
        <div className={classes.mainRow}>
          <div className={classes.mainColHeader}>
            {dates &&
              dates.map((date, key) => {
                return (
                  <div key={key} className={classes.detailRow}>
                    <Typography
                      variant="caption"
                      className={classes.detailColHeader}
                    >
                      <Translate content={date.label} />
                    </Typography>
                    <Typography
                      variant="body2"
                      className={classes.detailColContent}
                    >
                      {date.dateString}
                    </Typography>
                  </div>
                );
              })}
            {payment.created_by && (
              <div className={classes.detailRow}>
                <Typography
                  variant="caption"
                  className={classes.detailColHeader}
                >
                  <Translate content="payment.detail.createdByPOS" />
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.detailColContent}
                >
                  <GoToDetail
                    name={payment.created_by.name}
                    type={payment.created_by.type}
                    id={payment.created_by.uuid}
                  />
                </Typography>
              </div>
            )}
            {payment.created_by && payment.deposit_account && (
              <div className={classes.detailRow}>
                <Typography
                  variant="caption"
                  className={classes.detailColHeader}
                >
                  <Translate content="payment.detail.account" />
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.detailColContent}
                >
                  <GoToDetail
                    name={payment.deposit_account.name}
                    type={payment.deposit_account.type}
                    id={payment.deposit_account.uuid}
                  />
                </Typography>
              </div>
            )}
            <div className={classes.detailRow}>
              <Typography variant="caption" className={classes.detailColHeader}>
                <Translate content="payment.detail.paymentID" />
              </Typography>
              <Typography variant="body2" className={classes.detailColContent}>
                {payment.uuid}
              </Typography>
            </div>
            {payment.reference && (
              <div className={classes.detailRow}>
                <Typography
                  variant="caption"
                  className={classes.detailColHeader}
                >
                  <Translate content="payment.detail.reference" />
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.detailColContent}
                >
                  {payment.reference}
                </Typography>
              </div>
            )}
            {payment.details && (
              <div className={classes.detailRow}>
                <Typography
                  variant="caption"
                  className={classes.detailColHeader}
                >
                  <Translate content="payment.detail.description" />
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.detailColContent}
                >
                  {payment.details}
                </Typography>
              </div>
            )}
            {payment.callback_url && (
              <div className={classes.detailRow}>
                <Typography
                  variant="caption"
                  className={classes.detailColHeader}
                >
                  <Translate content="payment.detail.callback_url" />
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.detailColContent}
                >
                  {payment.callback_url}
                </Typography>
              </div>
            )}
            {payment.crypto_currency && (
              <div className={classes.detailRow}>
                <Typography
                  variant="caption"
                  className={classes.detailColHeader}
                >
                  <Translate
                    content="payment.detail.address"
                    with={{
                      currency: payment.crypto_currency,
                    }}
                  />
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.detailColContent}
                >
                  <LinkPrimary
                    external
                    to={getAddressLink(
                      payment.address,
                      payment.crypto_currency
                    )}
                  >
                    {payment.address}
                  </LinkPrimary>
                </Typography>
              </div>
            )}
            {payment.crypto_currency && (
              <div className={classes.detailRow}>
                <Typography
                  variant="caption"
                  className={classes.detailColHeader}
                >
                  <Translate content="payment.detail.changeApplied" />
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.detailColContent}
                >
                  1 {cryptos[payment.crypto_currency].label} ={' '}
                  {payment.rateApplied} EUR
                </Typography>
              </div>
            )}
          </div>
          <div className={classes.mainColHelper}>
            <Info
              faq={[
                {
                  text: <Translate content="payment.detail.faq.canAbort" />,
                  url: 'payment_order_can_i_cancel',
                },
                payment.crypto_currency === 'BTC' && {
                  text: (
                    <Translate
                      content="payment.detail.faq.whatIsAdressBTC"
                      with={{
                        currency: payment.crypto_currency
                          ? cryptos[payment.crypto_currency].label
                          : '',
                      }}
                    />
                  ),
                  url: 'bitcoin_what_is_an_address',
                },
                payment.crypto_currency === 'BTC' && {
                  text: (
                    <Translate content="payment.detail.faq.whatTransactions" />
                  ),
                  url: 'bitcoin_what_are_transactions',
                },
                {
                  text: (
                    <Translate content="payment.detail.faq.stateTransactions" />
                  ),
                  url: 'bitcoin_what_states_can_a_transaction_have',
                },
                payment.crypto_currency === 'BTC' && {
                  text: (
                    <Translate content="payment.detail.faq.howChangeWorks" />
                  ),
                  url: 'bitcoin_exchange_how_does_it_work',
                },
              ].filter(Boolean)}
            />
          </div>
        </div>
        <Typography
          variant="subheading"
          className={classnames(['w600', classes.subheading])}
          style={{ marginTop: '65px' }}
        >
          <Translate content="payment.detail.sections.transaction" />
        </Typography>
        <div className={classes.mainRow}>
          <div className={classes.fullCol}>
            <div className={classes.transactionContainer}>
              {transactions.length === 0 && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100px',
                  }}
                >
                  <Typography variant="caption" className="color7">
                    <Translate content="payment.detail.transaction.noTransaction" />
                  </Typography>
                </div>
              )}
              {transactions.length > 0 && (
                <SortableTable
                  style={{ marginTop: '0px', padding: '20px 30px' }}
                >
                  <SortableTableHead
                    order={payment.order}
                    orderBy={payment.orderBy}
                    columns={columns}
                    sortData={payment.sortTransactions}
                  />
                  <SortableTableBody>
                    {transactions.map((transaction, key) => (
                      <SortableTableRow style={{ minHeight: '10px' }} key={key}>
                        <SortableTableCell xs={2}>
                          <Typography variant="caption" className="f-13">
                            {transaction.created_at.format('D MMM, H:mm')}
                          </Typography>
                        </SortableTableCell>
                        <SortableTableCell xs={4}>
                          <Typography variant="caption" className="f-13" noWrap>
                            <LinkPrimary
                              external
                              to={getTXLink(
                                transaction.txid,
                                payment.crypto_currency
                              )}
                            >
                              {transaction.txid}
                            </LinkPrimary>
                          </Typography>
                        </SortableTableCell>
                        <SortableTableCell xs={2}>
                          <TransactionState status={transaction.status} />
                        </SortableTableCell>
                        <SortableTableCell xs={2}>
                          <Typography variant="body2" className="f-13">
                            {`${cryptos[transaction.crypto_currency].label} 
                            ${minimalUnitToCrypto(
                              transaction.outs_sum,
                              transaction.crypto_currency
                            )}`}
                          </Typography>
                        </SortableTableCell>
                        <SortableTableCell xs={1}>
                          <Typography variant="body2" className="f-13">
                            {payment.currencySymbol +
                              ' ' +
                              payment.transactionFiatAmount(transaction)}
                          </Typography>
                        </SortableTableCell>
                        {payment.crypto_currency === 'BTC' && (
                          <SortableTableCell xs={1}>
                            <Typography variant="body2" className="f-13">
                              {transaction.outs.map(o => `#${o.n}`).join(', ')}
                            </Typography>
                          </SortableTableCell>
                        )}
                      </SortableTableRow>
                    ))}
                  </SortableTableBody>
                </SortableTable>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const transaction_states = {
  mempool: {
    color: 'primaryLight',
  },
  unconfirmed: {
    color: 'primaryLight',
  },
  confirmed: {
    color: 'green',
  },
  reverted: {
    color: 'red',
  },
};

const TransactionState = withStyles(styles)(props => {
  const { classes, status } = props;
  const stat = transaction_states[status];
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        className={classnames([classes.transactionDot, classes[stat.color]])}
      />
      <Typography
        variant="body2"
        className="f-13"
        style={{ marginLeft: '5px' }}
      >
        <Translate content={'payment.detail.transaction.' + status} />
      </Typography>
    </div>
  );
});

export default withRouter(withStyles(styles)(observer(PaymentView)));
