export default {
  ibanEmpty: 'Inserire un IBAN',
  ibanFormat: 'Formato IBAN non valido',
  ibanCountry: 'Codice paese non valido',
  ibanFormatCountry: 'Formato IBAN non valido per il paese',
  ibanControlCode: 'Codice di controllo non valido',
  ibanGeneric: 'Inserire un IBAN valido',

  intestatarioName: "Inserire il nome dell'intestatario",
  instituteName: "Inserire il nome dell'istituto",
  bicValid: 'Inserire il BIC della filiale',
  requiredField: 'Campo Obbligatorio',
  alpha: "Il campo dev'essere alfabetico",
  alphadash: "Il campo dev'essere alfabetico con un dash",
  alphanum: "Il campo dev'essere alfanumerico",
  at_least_one_upper: 'Il campo deve contenere almeno una lettera maiuscola',
  at_least_one_lower: 'Il campo deve contenere almeno una lettera minuscola',
  at_least_one_number: 'Il campo deve contenere almeno un numero',
  at_least_one_symbol:
    'Il campo deve contenere almeno un simbolo tra %(param1)s',
  equals_to: "Il campo %(param1)s dev'essere uguale al campo %(param2)s",
  between: "Il campo dev'essere compreso tra %(param1)s e %(param2)s",
  betweenlen:
    "Il campo dev'essere lungo minimo %(param1)s e massimo %(param2)s caratteri",
  date_format: "La data dev'essere nel formato %(param1)s",
  decimal: "Il campo dev'essere in formato decimale",
  equals: "Il campo dev'essere uguale a %(param1)s",
  hex: "Il campo dev'essere esadecimale",
  https_url: "Il campo dev'essere un URL https", //TODO
  in: "Il campo dev'essere uno dei seguenti valori %(param1)s", //TODO
  len: "Il campo dev'essere lungo %(param1)s caratteri",
  max: 'Il campo non può essere superiore a %(param1)s ',
  maxlen: "Il campo dev'essere lungo al massimo %(param1)s caratteri",
  min: "Il campo dev'essere minimo %(param1)s",
  minlen: "Il campo dev'essere lungo almeno %(param1)s caratteri",
  mergeParamRequired: 'Il campo deve contenere %(param1)s',
  nullableIf: 'Inserire %(param2)s o selezionare %(param1)s',
  only_numbers: 'Il campo può essere solo numerico', //TODO cosa è qua il parametro 0
  regex: 'Formato non valido',
  type: "Il campo dev'essere %(param1)s",
  type_error_params: {
    ISO_8601_date: 'una data nel formato ISO 8601',
    array: 'un array di valori',
    base58: 'una stringa di caratteri in base58',
    base64: 'una stringa di caratteri in base64',
    base64_encoded_file: 'un file in codifica base64',
    boolean: 'un valore vero/falso',
    email: "un'email",
    float: 'un numero con virgola',
    integer: 'un numero intero',
    object: 'un oggetto',
    phone: 'un numero di telefono',
    sequence: 'una sequenza',
    string: 'una stringa di caratteri',
    url: 'un URL (deve iniziare con http:// o https://)',
    uuid: 'un uuid',
  },
  operational_field_tmp_rule: 'Specificare il settore',
  emailValid: "Inserire un'indirizzo email valido",
  types: {
    new_email: 'Nuova Email',
    new_email_confirmation: 'Conferma email',
    new_password: 'Nuova Password',
    new_password_confirmation: 'Conferma nuova password',
    password: 'Password',
    password_confirmation: 'Conferma password',
    street_number: 'il numero civico',
    zip: 'il cap',
    street: 'la via',
    country: 'il paese',
    city: 'la città',
    lat: 'la latitudine',
    lon: 'la longitudine',
    name: 'Settore',
    description: 'Settore della tua azienda',
    vat: 'la Partita IVA',
    no_vat_available: 'il campo "Non ho Partita IVA"',
    store_address: 'Indirizzo del punto vendita',
    no_store_address_available:
      'il campo "Questo POS non ha un indirizzo fisso"',
  },
  consensoRichiesto: 'Consenso richiesto',

  passwordShort: 'La password inserita è troppo corta',
  passwordConfirm: 'Le password non coincidono',

  changePasswordConfirm: 'Password cambiata con successo',
  changePasswordError: 'Errore nella modifica della password',

  accettareTermini: 'Accettare i termini',

  validAddress: 'Inserire un indirizzo valido',

  fileNotValid: 'File non valido',
  formatFileNotValid: 'Il formato del file non è valido',
  fileTooLarge: 'La dimensione massima per il file è %(maxSize)s ',
  invalidURl: "L'url deve iniziare con http:// o https://",

  companyType: 'Selezionare un tipo azienda',
  validPhone: 'Inserire un numero di telefono valido',
  sector: 'Selezionare un settore',
  role: 'Selezionare un ruolo',
  store_address: 'Indirizzo del punto vendita',
  no_store_address_available: '"Questo POS non ha un indirizzo fisso"',

  dataLoading: 'Errore durante il caricamento dei dati.',

  backend: {
    '1003': 'Credenziali non valide',
    '1004': 'Credenziali di accesso scadute',
    '1006': 'Credenziali di accesso scadute',
    '1007': 'Credenziali di accesso scadute',
    '1012': 'Credenziali di accesso scadute',
    '3007': 'Credenziali di accesso scadute',
    '1020': 'Email già esistente',
    '3014': 'Chiave pubblica già esistente',
    '3020': 'Formato chiave pubblica estesa inserito non è supportato per il wallet selezionato',
    '0003': 'Logo troppo grande',
    '1005': "Si è verificato un'errore. Per favore riprovare più tardi",
    '1008': 'Non hai i permessi per accedere a questo business',
    '1023': 'La partita IVA è già presente nei nostri sistemi',
    '1024':
      "Non è possibile cancellare l'account, in quanto già presente un'azienda",
    '0014': 'VAT non valido',
    '3010': 'Chiave pubblica non valida',
    '3001': 'Risorsa non trovata',
  },
  name: 'Inserisci un nome',

  chargingData:
    'Si è verificato un problema durante il caricamento dei dati.Riprova.',
  emailInvalid: "Inserire un'email valida",
  invalidForm: 'Attenzione: Ci sono alcuni errori nel form',
  404: {
    title: 'La pagina che stai cercando non esiste!',
    utils: 'Ecco alcuni link che potrebbero essere utili:',
  },
  500: {
    title: 'Qualcosa è andato storto :(',
    text:
      'Prova a ricaricare la pagina o torna alla pagina precedente. Se il problema persiste, contattaci.',
    or: 'oppure torna alla ',
    problemGoOn: 'Se il problema persiste, ',
  },
  403: {
    title: 'Non hai i permessi per accedere a questa pagina.',
  },
  error: 'Errore ',

  getListPos: 'Errore nel recupero della lista dei POS',
  noCookies: {
    title: {
      row1: 'Sembra che il tuo browser abbia i cookie disabilitati.',
      row2: 'Attivali per continuare ad utilizzare il sito.',
    },
    utils: {
      row1: 'Ecco alcuni link che potrebbero esserti utili.',
      row2: 'Se invece pensi ci sia un problema, contattaci.',
    },
    links: {
      enableCookies: {
        text: 'Come abilitare i cookie',
        url:
          'https://www.wikihow.it/Abilitare-i-Cookie-nel-Tuo-Browser-Internet',
      },
    },
  },
  noScript: {
    title: {
      row1: 'No Javascript No Party.',
      row2: 'Abilita Javascript per continuare ad utilizzare il sito.',
    },
    utils:
      'Ecco alcuni link che potrebbero esserti utili.\nSe invece pensi ci sia un problema, contattaci.',
    links: {
      enableJavascript: 'Come abilitare Javascript',
    },
  },
  genericErrorPage: {
    title: 'Qualcosa è andato storto :(',
    utils:
      'Prova a ricaricare la pagina o torna alla pagina precedente. Se il problema persiste, contattaci.',
  },
};
