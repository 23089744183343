import { extendObservable, action } from 'mobx';

import jwt from 'jsonwebtoken';
import { HAS_BANK_ACCOUNTS_TIER } from '../config';
import * as Sentry from '@sentry/browser';

export default class Token {
  constructor() {
    extendObservable(this, {
      tokenType: undefined,
      expiresDate: undefined,
      idToken: undefined,
      get hasBankAccounts() {
        return this.idToken ? this.idToken.hasBankAccounts : false;
      },
      get isLoggedIn() {
        return this.idToken ? this.idToken.isLoggedIn : false;
      },
      get isDelegated() {
        return this.idToken ? this.idToken.isDelegated : false;
      },
      get isVerified() {
        return this.idToken ? this.idToken.isVerified : false;
      },
      get user_name() {
        return this.idToken ? this.idToken.user_name : undefined;
      },
      get user_fullName() {
        return this.idToken
          ? this.idToken.user_name + ' ' + this.idToken.surname
          : undefined;
      },
      get user_uuid() {
        return this.idToken ? this.idToken.user_uuid : undefined;
      },
      get business_name() {
        return this.idToken ? this.idToken.business_name : undefined;
      },
      get business_uuid() {
        return this.idToken ? this.idToken.business_uuid : undefined;
      },
      get session_id() {
        return this.idToken ? this.idToken.session_id : undefined;
      },
      get hasBusiness() {
        return this.idToken ? this.idToken.hasBusiness : false;
      },
      get position() {
        return this.idToken ? this.idToken.position : undefined;
      },
    });
  }

  expired = () => {
    return this.idToken ? this.idToken.expired() : true;
  };

  setToken = action(token => {
    this.tokenType = token.token_type;
    this.expiresDate = token.expires_in;
    this.idToken = token.id_token ? new IdToken(token.id_token) : undefined;
  });

  resetToken = action(() => {
    this.tokenType = undefined;
    this.expiresDate = undefined;
    this.idToken = undefined;
  });

  isEqual = other_token => {
    if (!other_token) {
      return this.idToken === undefined;
    }
    return this.idToken && other_token.id_token
      ? this.idToken.isEqual(other_token.id_token)
      : other_token.id_token === this.idToken;
  };

  isEquivalent = other_token => {
    if (!other_token) {
      return this.idToken === undefined;
    }
    return this.idToken && other_token.id_token
      ? this.idToken.isEquivalent(other_token.id_token)
      : other_token.id_token === this.idToken;
  };
}

// {
//   "user_uuid": "cdf96f26-095c-49c5-b3e7-c6e5f8648e9a",
//   "user_name": "John",
//   "user_client_id": "abc",
//   "session_id": "cdf96f26-095c-49c5-b3e7-c6e5f8648e9a",
//   "verified": true,
//   "surname": "John",
//   "email": "john@gmail.com",
//   "businesses_number": 1,
//   "business_uuid": "cdf96f26-095c-49c5-b3e7-c6e5f8648e9a",
//   "business_name": "John",
//   "position": "John"
// }

class IdToken {
  constructor(id_token) {
    const id_token_decoded = jwt.decode(id_token);
    extendObservable(this, {
      user_uuid: id_token_decoded.user_uuid,
      user_name: id_token_decoded.user_name,
      user_client_id: id_token_decoded.user_client_id,
      session_id: id_token_decoded.session_id,
      verified: id_token_decoded.verified,
      surname: id_token_decoded.surname,
      email: id_token_decoded.email,
      businesses_number: id_token_decoded.businesses_number,
      business_uuid: id_token_decoded.business_uuid,
      business_name: id_token_decoded.business_name,
      position: id_token_decoded.position,
      tier: id_token_decoded.tier,
      exp: id_token.exp,
      get hasBankAccounts() {
        return this.isDelegated && this.tier === HAS_BANK_ACCOUNTS_TIER;
      },
      get isLoggedIn() {
        return Boolean(this.user_uuid);
      },
      get isDelegated() {
        return Boolean(this.business_uuid);
      },
      get isVerified() {
        return this.verified;
      },
      get hasBusiness() {
        return this.businesses_number > 0;
      },
    });

    Sentry.configureScope(scope => {
      scope.setUser({
        id: id_token_decoded.user_uuid,
        email: id_token_decoded.email,
        username: id_token_decoded.user_name,
      });

      scope.setExtras({
        ...id_token_decoded,
      });
    });
  }

  compareToken = (other_token, to_compare) => {
    const other_token_decoded = jwt.decode(other_token);
    return to_compare.reduce((acc, field) => {
      return acc && this[field] === other_token_decoded[field];
    }, true);
  };

  isEqual = other_token => {
    const to_compare = [
      'user_uuid',
      'user_name',
      'user_client_id',
      'session_id',
      'verified',
      'surname',
      'email',
      'businesses_number',
      'business_uuid',
      'business_name',
      'position',
      'tier',
    ];
    return this.compareToken(other_token, to_compare);
  };

  isEquivalent = other_token => {
    const to_compare = ['user_uuid', 'user_client_id', 'business_uuid'];
    return this.compareToken(other_token, to_compare);
  };

  expired = () => {
    return Date.now() / 1000 > this.exp;
  };
}
