import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Button from './Button';
import PageContainer from './PageContainer';
import CircularProgressbar from 'react-circular-progressbar';
import translator from 'counterpart';
import Translate from 'react-translate-component';
import { withRouter } from 'react-router-dom';
import { ROUTE_PARAMETERS, ROUTES } from '../../constants/routes';
import EmailNotVerifiedModal from './EmailNotVerifiedModal';
import { observer } from 'mobx-react';
import { buildPath, matchesRoute } from '../../utils/routes';
import authAPI from '../../api/Authentication';
import store from '../../store';

const getStep = (store, goTo, inviaMail) => {
  return [
    new StepProfile(0, null, true),
    new StepProfile(
      1,
      translator('profile.stepperProfile.business'),
      store.app.hasBusiness,
      translator('profile.stepperProfile.firstBusinessAction'),
      () => {
        goTo(ROUTES.REGISTER_BUSINESS);
      },
      translator('profile.stepperProfile.firstBusiness')
    ),
    new StepProfile(
      2,
      translator('profile.stepperProfile.mail'),
      store.app.isVerified,
      translator('profile.verifyMail'),
      inviaMail
    ),
    new StepProfile(
      3,
      translator('profile.stepperProfile.account'),
      store.app.hasAccount,
      translator('account.list.add'),
      () => {
        goTo(
          buildPath(ROUTES.ACCOUNT_NEW, {
            [ROUTE_PARAMETERS.BUSINESS_ID]: store.app.business_uuid,
          })
        );
      }
    ),
    new StepProfile(
      4,
      translator('profile.stepperProfile.pos'),
      store.app.hasPos,
      translator('POS.list.add'),
      () => {
        goTo(
          buildPath(ROUTES.POS_NEW, {
            [ROUTE_PARAMETERS.BUSINESS_ID]: store.app.business_uuid,
          })
        );
      }
    ),
  ];
};

const styles = theme => ({
  container: {
    width: '100%',
    borderTop: '1px solid',
    borderColor: theme.palette.primary.bluegrey,
    height: '180px',
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    bottom: '0',
  },
  innerContainer: {
    padding: '20px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  progressBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: '65px',
    width: '120px',
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: '100px',
  },
  divider: {
    width: '1px',
    height: '140px',
    backgroundColor: theme.palette.primary.bluegrey,
    marginRight: '30px',
  },
  nextStep: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  progressBarComponent: { width: '75px', height: '75px' },
  pbPath: {
    stroke: theme.palette.green.main,
  },
  pbTrail: {
    stroke: '#f2f2f2',
  },
  pbText: {
    dominantBaseline: 'middle',
    textAnchor: 'middle',
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '10px',
  },
  stepperLogo: {
    height: '20px',
    width: '20px',
    marginRight: '10px',
  },
  green: {
    color: theme.palette.green.main,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  firstStepButton: {
    maxWidth: '290px',
  },
});

class ProfileAdvance extends React.Component {
  state = {
    openModal: false,
    notVisibleRoute: true,
    allCompleted: false,
    sendingMail: false,
  };

  shouldComponentUpdate(nextProps) {
    //if everything completed don't need to update the component
    return !this.state.allCompleted;
  }

  getData = steps => {
    const arrayLength = steps.length;
    const uncompleted = steps.filter(step => {
      return !step.completed;
    });
    const uncompletedLength = uncompleted.length;
    const firstStep = steps.filter(step => {
      return step.index === 1;
    })[0].completed;
    if (uncompletedLength > 0) {
      return {
        percentage: 100 - (uncompletedLength / arrayLength) * 100,
        nextStep: uncompleted.sort((a, b) => {
          return a.index - b.index;
        })[0].nextStep,
        visible: true,
        firstStep: firstStep,
      };
    } else {
      this.setState({ allCompleted: true });
      return {
        visible: false,
      };
    }
  };

  toogleModal = () => {
    this.setState({ openModal: !this.state.openModal });
  };

  inviaMail = () => {
    this.setState({ mailSending: true }, () => {
      authAPI.resendEmail(
        store.app.getProfile.uuid,
        () => {
          this.setState({ mailSending: false });
          store.app.snackBar.open(translator('common.mailSended'), 'success');
        },
        error => {
          store.app.snackBar.displayError(error);
          this.setState({ mailSending: false });
        }
      );
    });
  };

  goTo = path => {
    this.props.history.push(path);
  };

  isVisible = () => {
    // do not show if store or profile advance is missing
    if (!this.props.store) {
      return false;
    }
    //do not show if not delegated and have business
    if (!this.props.store.app.isDelegated && this.props.store.app.hasBusiness) {
      return false;
    }
    // do not show in sandbox
    if (this.props.store.app.sandboxMode) {
      return false;
    }
    //if has POS and account
    if (this.props.store.app.hasPos && this.props.store.app.hasAccount) {
      return false;
    }
    const currentLocation = this.props.location.pathname;
    // show on profile page if no business registered
    if (
      matchesRoute(ROUTES.PROFILE_VIEW, currentLocation) &&
      !this.props.store.hasBusiness
    ) {
      return true;
    }

    // finally, show only on list pages
    const validRoute = [
      ROUTES.POS_LIST,
      ROUTES.ACCOUNT_LIST,
      ROUTES.PAYMENT_ORDER_LIST,
    ];
    return validRoute.some(route => matchesRoute(route, currentLocation));
  };

  render() {
    const { classes, store } = this.props;
    const { openModal } = this.state;

    if (!this.isVisible()) {
      return null;
    }

    const steps = getStep(store, this.goTo, this.inviaMail).sort((a, b) => {
      return a.index - b.index;
    });

    const data = this.getData(steps);

    if (!data || !data.visible) {
      return null;
    }

    return (
      <div className={classes.container} id="profile_advance">
        <PageContainer>
          <div className={classes.innerContainer}>
            <div className={classes.flexRow}>
              <div className={classes.progressBar}>
                <CircularProgressbar
                  percentage={data.percentage}
                  text={`${data.percentage}%`}
                  className={classes.progressBarComponent}
                  strokeWidth={12}
                  classes={{
                    path: classes.pbPath,
                    trail: classes.pbTrail,
                    text: classnames([
                      classes.pbText,
                      'f-18 montserrat w600 color7',
                    ]),
                  }}
                />
                <Typography
                  variant="caption"
                  style={{ textAlign: 'center', marginTop: '10px' }}
                >
                  <Translate content="profile.stepperProfile.configurationState" />
                </Typography>
              </div>
              {data.firstStep && (
                <div className={classes.step}>
                  {steps &&
                    steps.map((step, k) => {
                      return step.text && <StepComponent step={step} key={k} />;
                    })}
                </div>
              )}
            </div>
            <div className={classes.flexRow}>
              <div className={classes.divider} />
              <div className={classes.nextStep}>
                {data.firstStep && (
                  <React.Fragment>
                    <Typography
                      variant="body2"
                      className="f-16"
                      style={{ marginBottom: '10px' }}
                    >
                      <Translate content="profile.stepperProfile.nextStep" />
                    </Typography>
                    <Button
                      color="orange"
                      variant="raised"
                      onClick={data.nextStep.action}
                    >
                      {data.nextStep.label}
                    </Button>
                  </React.Fragment>
                )}
                {!data.firstStep && (
                  <React.Fragment>
                    <Typography
                      variant="body2"
                      className="f-16"
                      style={{ marginBottom: '10px' }}
                    >
                      <Translate content="profile.stepperProfile.completeSignUp" />
                    </Typography>
                    {data.nextStep.actionText && (
                      <Typography
                        variant="caption"
                        style={{ marginBottom: '20px' }}
                      >
                        {data.nextStep.actionText}
                      </Typography>
                    )}
                    <Button
                      color="primary"
                      variant="raised"
                      small
                      customClass={classes.firstStepButton}
                      onClick={data.nextStep.action}
                      loading={this.state.sendingMail}
                    >
                      {data.nextStep.label}
                    </Button>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <EmailNotVerifiedModal
            open={openModal}
            goBack={this.toogleModal}
            closeModal={() => {
              this.setState({ openModal: false });
            }}
          />
        </PageContainer>
      </div>
    );
  }
}

const StepComponent = withStyles(styles)(props => {
  const { classes, step } = props;
  return (
    <div className={classes.stepContainer}>
      <img
        src={
          step.completed
            ? require('../../img/others/progress-done.png')
            : require('../../img/others/progress-to-complete.png')
        }
        className={classes.stepperLogo}
        alt={step.completed ? 'Ok' : 'TODO'}
      />
      <Typography
        variant="caption"
        className={classnames({
          [classes.green]: step.completed,
        })}
      >
        {step.text}
      </Typography>
    </div>
  );
});

class StepProfile {
  constructor(
    index,
    text,
    completed,
    nextStepLabel,
    nextStepAction,
    actionText
  ) {
    this.index = index;
    this.text = text;
    this.completed = completed;
    this.nextStep = {
      label: nextStepLabel,
      action: nextStepAction,
      actionText: actionText,
    };
  }
}

export default withStyles(styles)(withRouter(observer(ProfileAdvance)));
