import store from '../store';
import { GuestLoginAction } from 'business-panel-sdk/src/generated/actions/guest_login';

async function guestLoginAndRetry(action, cache, context, response) {
  if (action.guest_login_ran) {
    return response;
  } else if (
    response.data.error_code === '3007' ||
    response.data.error_code === '1007' ||
    response.data.error_code === '1004'
  ) {
    const guest_login_action = store.app.factory.make('GuestLoginAction');
    guest_login_action.requestBody = GuestLoginAction.requestClass.fromJson({
      username: 'guest',
      password: 'guest',
      grant_type: 'password',
      scope: '*',
    });
    try {
      await guest_login_action.run();
      action.guest_login_ran = true;
    } catch (err) {
      return response;
    }
    return action.getResponse();
  } else {
    return response;
  }
}

export default {
  getPersonGdpr: (onSuccess, onError) => {
    const getGdprAction = store.app.factory.make('GetPersonGdprAction');

    getGdprAction.failureHooks = [
      ...getGdprAction.failureHooks,
      guestLoginAndRetry,
    ];

    getGdprAction
      .run()
      .then(onSuccess)
      .catch(onError);
  },
  getBusinessGdpr: (onSuccess, onError) => {
    const getGdprAction = store.app.factory.make('GetBusinessGdprAction');

    getGdprAction
      .run()
      .then(onSuccess)
      .catch(onError);
  },
};
