import React from 'react';
import Button from '../common/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import TextField from '../common/Forms/TextField';
import { ROUTES } from '../../constants/routes';
import store from '../../store/index';
import { LinkPrimary } from '../common/Links';
import UnauthenticatedPage from '../common/UnauthenticatedPage';
import translator from 'counterpart';
import Translate from 'react-translate-component';
import { Redirect } from 'react-router';
import { withFormsValidation } from '../common/Forms/FormsCommon';

const styles = theme => ({
  textField: {
    width: '100%',
  },
  formControl: {
    width: '100%',
    margin: '10px 0px 20px',
  },
  sendMailButton: {
    flex: 1,
  },
  inputLabelFocused: {
    color: theme.palette.primary.main,
  },
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  formDiv: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  mailSent: {
    padding: '10px 10px',
    width: '100%',
    margin: '15px 0px',
  },
  mailSendedMessage: {
    // color: theme.palette.font.green,
    fontWeight: '600',
  },
  login: {
    flex: 1,
    textAlign: 'left',
    marginLeft: '30px',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
});

class RequestResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendingMail: false,
    };
  }

  sendMail = event => {
    event.preventDefault();
    this.setState({
      sendingMail: true,
    });

    this.props.send(
      () => {
        this.setState({
          sendingMail: false,
        });
      },
      () => {
        this.setState({
          sendingMail: false,
        });
      },
      valid => {
        if (valid) {
          store.app.snackBar.open(
            translator('business.register.sendedMail'),
            'mail'
          );
        }
      }
    );
  };

  render() {
    const isAuthenticated = store.app.isLoggedIn;

    if (isAuthenticated) {
      return <Redirect to="/" />;
    }

    const { classes, object, validateField, handleChange, errors } = this.props;
    const { sendingMail } = this.state;

    const ids = {
      email: this.props.form.ids.form$email,
    };

    return (
      <UnauthenticatedPage
        title={
          <Translate content="common.recoveryPasswordSection.forgottenPassword.title" />
        }
        width={450}
      >
        <Typography
          variant="caption"
          style={{ margin: '10px 0px 45px', textAlign: 'center' }}
        >
          <Translate content="common.recoveryPasswordSection.forgottenPassword.subtitle" />
        </Typography>
        <form onSubmit={this.sendMail} className={classes.formDiv}>
          <TextField
            id={ids.email}
            type="email"
            label={<Translate content="profile.email" />}
            disabled={sendingMail}
            onChange={handleChange}
            value={object[ids.email]}
            error={Boolean(errors[ids.email])}
            helperText={errors[ids.email]}
            validateField={validateField}
          />
          <div className={classes.actionContainer}>
            <Button
              id="save"
              onClick={this.sendMail}
              variant="raised"
              color="primary"
              type="submit"
              loading={sendingMail}
              customClass={classes.sendMailButton}
            >
              <Translate content="common.send" />
            </Button>

            <Typography variant="caption" className={classes.login}>
              <LinkPrimary to={ROUTES.LOGIN} forward>
                <Translate content="common.recoveryPasswordSection.forgottenPassword.back" />
              </LinkPrimary>
            </Typography>
          </div>
        </form>
      </UnauthenticatedPage>
    );
  }
}

export default withFormsValidation('PasswordRecoveryRequestForm')(
  withStyles(styles)(RequestResetPassword)
);
