import * as React from 'react';
import {
  CircularProgress,
  createStyles,
  withStyles,
  Typography,
} from '@material-ui/core';
import Translate from 'react-translate-component';

const style = (theme: { [key: string]: any }) =>
  createStyles({
    loadingComponent: {
      textAlign: 'center',
      marginTop: '14px',
    },
    circularProgress: {
      width: '40px',
      height: '40px',
      marginBottom: '15px',
    },
    text: {
      fontSize: '14px',
      fontWeight: 500,
      color: '#777777',
    },
  });

export const LoadingMorePayments = withStyles(style)(({ classes }) => (
  <div className={classes.loadingComponent}>
    <CircularProgress className={classes.circularProgress} />
    <Typography className={classes.text}>
      <Translate content="payment.list.loading" />
    </Typography>
  </div>
));
