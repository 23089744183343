import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import '../../../styles/style.css';

const styles = theme => ({
  container: {
    border: '1px solid',
    width: '100%',
    height: '100%',
    borderColor: theme.palette.primary.bluegrey,
    backgroundColor: theme.palette.common.white,
    borderRadius: '6px',
    ...theme.shadow,
    cursor: 'pointer',
  },
  row: {
    margin: '5px 15px 5px 15px',
  },
  phantomAvatar: {
    backgroundColor: theme.palette.grey.light,
    margin: '5px 0px 5px 10px',
  },
  selected: {
    backgroundColor: theme.palette.primary.bg,
    borderColor: theme.palette.primary.light,
  },
  phantomText: {
    width: '70px',
    height: '10px',
    backgroundColor: theme.palette.grey.light,
  },
  avatar: {
    marginRight: '10px',
    ...theme.icon.medium,
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 20px',
    maxWidth: '100%',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: 'inherit',
    whiteSpace: 'nowrap',
  },
  arrowSmall: theme.icon.arrowSmall,
  accountNameContainer: {
    maxWidth: '80%',
  },
  accountName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const icons = {
  bank: require('../../../img/bancario/medium.png'),
};

class ListComponent extends React.Component {
  static propTypes = {
    accounts: PropTypes.shape({
      name: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
    }),
    logo: PropTypes.string,
    selected: PropTypes.bool,
  };

  render() {
    const { classes, account, logo, selected } = this.props;
    const iban_trunked = account.iban ? account.iban.slice(-5) : '';
    return (
      <div
        className={classnames({
          [classes.container]: true,
          [classes.selected]: selected,
        })}
      >
        <div className={classes.innerContainer}>
          <div className={classes.avatarContainer}>
            <Avatar className={classes.avatar} src={logo ? logo : icons.bank} />
            <div className={classes.accountNameContainer}>
              <Typography
                className={classnames([
                  'listComponentText',
                  classes.accountName,
                ])}
                variant="body1"
              >
                <b>{account.name}</b>
              </Typography>
              <Typography variant="caption" noWrap>
                <b>{`IBAN: ... ${iban_trunked}`}</b>
              </Typography>
            </div>
          </div>
          {!selected && (
            <div>
              <img
                src={require('../../../img/arrow/small.png')}
                alt="detail"
                className={classes.arrowSmall}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

ListComponent = withStyles(styles)(ListComponent);

export { ListComponent };
