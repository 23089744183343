import React, { FC, useCallback, useEffect, useState } from 'react';
import { LanguageRowProps, StateLanguageRow } from './propTypes';
import { Col, Row } from 'react-flexbox-grid';
import Typography from '@material-ui/core/Typography';
import { CircularProgress, MenuItem, withStyles } from '@material-ui/core';
import styles from './theme';
import Translate from 'react-translate-component';
import { ActionLink } from '../../Links';
import SelectField from '../../Forms/SelectField';
import { withFormsValidation } from '../../Forms/FormsCommon';
import { languagesList } from '../../../../constants/supportedLanguages';
import translator from 'counterpart';
import store from '../../../../store';

const LanguageRow: FC<LanguageRowProps> = ({
  value,
  person_uuid,
  classes = {},
  initObject,
  form,
  object,
  handleChange,
  send,
}) => {
  const [stateForm, setStateForm] = useState(StateLanguageRow.INIT);
  const ids = {
    lang: form.ids.form$lang,
  };
  useEffect(() => {
    initObject({
      [ids.lang]: value,
      [form.ids.uri$person_uuid]: person_uuid,
    });
  }, [form.ids.uri$person_uuid, ids.lang, value, person_uuid, initObject]);

  const onClickChange = useCallback(() => {
    if (stateForm === StateLanguageRow.INIT) {
      setStateForm(StateLanguageRow.EDITING);
    } else if (stateForm === StateLanguageRow.EDITING) {
      setStateForm(StateLanguageRow.WAITING);
      send(
        () => {
          store.app.reloadInfo(() => {
            setStateForm(StateLanguageRow.INIT);
          });
        },
        (valid, error) => {
          alert(error);
        }
      );
    }
  }, [stateForm, send]);

  const valueFromObject = object[ids.lang];

  useEffect(() => {
    if (valueFromObject !== value) {
      setStateForm(StateLanguageRow.EDITING);
    } else {
      setStateForm(StateLanguageRow.INIT);
    }
  }, [valueFromObject, value ]);
  return (
    <Row middle="xs" className={classes!.row}>
      <Col xs={8}>
        <SelectField
          label={translator('profile.mailLanguage')}
          value={object[ids.lang]}
          onChange={handleChange}
          id={ids.lang}
          placeholder={ids.lang}
          fullWidth
        >
          {languagesList.map(language => (
            <MenuItem key={language.value} value={language.value}>
              <div className={classes.selectContainer}>
                <img
                  src={language.flag}
                  alt={language.value}
                  style={{ marginRight: '10px' }}
                />
                <Typography variant="body1">
                  <Translate content={language.translation} />
                </Typography>
              </div>
            </MenuItem>
          ))}
        </SelectField>
      </Col>
      <Col xs={4}>
        {stateForm !== StateLanguageRow.WAITING && (
          <ActionLink onClick={onClickChange}>
            {stateForm === StateLanguageRow.EDITING && (
              <Translate content="common.confirm" />
            )}
          </ActionLink>
        )}
        {stateForm === StateLanguageRow.WAITING && <CircularProgress />}
      </Col>
    </Row>
  );
};

const DecoratedLanguageRow = withFormsValidation(
  'UpdatePersonPreferredLanguageForm'
)(withStyles(styles)(LanguageRow));

export default DecoratedLanguageRow;
