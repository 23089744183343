import React from 'react';
import { Typography } from '@material-ui/core';

const Copyright = props => {
  const copyrightYears = `2018-${new Date().getFullYear()}`;

  return (
    <Typography variant="caption" {...props}>
      Copyright &copy; {copyrightYears}
    </Typography>
  );
};

export { Copyright };
