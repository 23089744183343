import React from 'react';

import classNames from 'classnames';

import PropTypes from 'prop-types';

import MaterialButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Translate from 'react-translate-component';

const styles = theme => ({
  button: {
    borderRadius: '4px',
    fontWeight: theme.typography.weights.normal,
    textAlign: 'center',
  },
  progress: {
    color: '#fff',
    width: '20px !important',
    height: '21px !important',
    marginRight: '10px',
  },
  text: {
    fontSize: '16px',
    textTransform: 'lowercase !important',
  },
  raisedPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white + '!important',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  raisedOrange: {
    backgroundColor: theme.palette.orange.main,
    color: theme.palette.common.white + '!important',
    '&:hover': {
      backgroundColor: theme.palette.orange.dark,
    },
  },
  raisedGreen: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.common.white + '!important',
    '&:hover': {
      backgroundColor: theme.palette.green.dark,
    },
  },
  raisedRed: {
    backgroundColor: theme.palette.red.main,
    color: theme.palette.common.white + '!important',
    '&:hover': {
      backgroundColor: theme.palette.red.dark,
    },
  },
  outlinedPrimary: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    border: 'solid 1px',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
  },
  disabled: {
    opacity: 0.5,
    color: '#ffffff',
  },
  filled: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  outline: {
    color: theme.palette.primary.main,
    backgroundColor: '#ffffff',
    border: 'solid 1px',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#ffffff',
      borderColor: theme.palette.primary.light,
      color: theme.palette.primary.light,
    },
    '&:disabled': {
      borderColor: theme.palette.grey.main,
    },
  },
  normal: {
    minHeight: '50px',
    padding: '14px',
    fontSize: '16px',
  },
  small: {
    height: '36px',
    padding: '8px 12px',
    fontSize: '14px',
  },
});

const Button = withStyles(styles)(props => {
  const {
    customClass,
    classes,
    filled,
    small,
    color,
    disabled,
    variant,
    children,
    loading,
    ...otherProps
  } = props;

  const typeButton = variant + '-' + color;
  return (
    <MaterialButton
      className={classNames({
        [classes.button]: true,
        [classes.small]: small,
        [classes.normal]: !small,

        [classes.raisedPrimary]: typeButton === 'raised-primary',
        [classes.raisedGreen]: typeButton === 'raised-green',
        [classes.raisedOrange]: typeButton === 'raised-orange',
        [classes.outlinedPrimary]: typeButton === 'outlined-primary',
        [classes.raisedRed]: typeButton === 'raised-red',
        [classes.disabled]: loading || disabled,
        [customClass]: true,
      })}
      {...otherProps}
      disabled={loading || disabled}
      children={
        loading ? (
          <React.Fragment>
            <CircularProgress className={classes.progress} />
            <span className={classes.text}>
              <Translate content="common.inCorso" />
            </span>
          </React.Fragment>
        ) : (
          children
        )
      }
    />
  );
});

Button.propTypes = {
  customClass: PropTypes.string,
  filled: PropTypes.bool,
  small: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'orange', 'green', 'red']),
  variant: PropTypes.oneOf(['raised', 'outlined']),
};

export default Button;
