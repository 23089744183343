import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Loader from './Loader';
import './Terms/style/style.css';
import counterpart from 'counterpart';
import Bowser from 'bowser';
import { terms as ItTerms } from './Terms/italian';
import { terms as EnTerms } from './Terms/english';
import Translate from 'react-translate-component';
const allowedBrowser = {
  chrome: '>45',
  firefox: '>1',
  opera: '>=32',
};

const browser = Bowser.getParser(window.navigator.userAgent);
const isValidBrowser = browser.satisfies(allowedBrowser);

const styles = theme => ({
  mainContainer: {
    backgroundColor: theme.palette.primary.bg,
    border: '1px solid',
    borderColor: theme.palette.primary.bluegrey,
  },
  scrollableContainer: {
    height: '200px',
    overflowY: 'scroll',
    padding: '20px',
  },
  scrollContainer: {
    padding: '20px',
    borderTop: '1px solid',
    borderTopColor: theme.palette.primary.bluegrey,
  },
  scroll: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  success: {
    color: theme.palette.green.main,
    textTransform: 'uppercase',
  },
  iconScroll: {
    width: '16px',
    height: '16px',
    marginRight: '8px',
    verticalAlign: 'text-bottom',
  },
});
class ReadableTextArea extends React.Component {
  static propTypes = {
    onComplete: PropTypes.func.isRequired,
  };
  state = {
    completed: false,
  };

  isBottom(el) {
    return el.offsetHeight + el.scrollTop + 40 >= el.scrollHeight;
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById('scroller');
    if (this.isBottom(wrappedElement)) {
      this.setState(
        {
          completed: true,
        },
        this.props.onComplete
      );
    }
  };

  onPressScroll = () => {
    const scroller = document.getElementById('scroller');
    if (isValidBrowser) {
      scroller.scrollBy({
        top: 200,
        behavior: 'smooth',
      });
    } else {
      scroller.scrollTop = scroller.scrollTop + 160;
    }

    this.trackScrolling();
  };

  render() {
    const { classes, loading } = this.props;
    const { completed } = this.state;
    if (loading) {
      return <Loader />;
    }
    return (
      <div className={classes.mainContainer}>
        <div
          className={classes.scrollableContainer}
          onScroll={completed ? null : this.trackScrolling}
          onWheel={completed ? null : this.trackScrolling}
          id="scroller"
        >
          <Typography
            component="div"
            id="text-terms"
            className="nested-numbered-list"
          >
            {counterpart.getLocale() === 'it' ? ItTerms() : EnTerms()}
          </Typography>
        </div>

        <div className={classes.scrollContainer}>
          {!this.state.completed && (
            <Typography
              varian="subheading"
              className={classes.scroll}
              onClick={this.onPressScroll}
            >
              <img
                src={require('../../img/scroll/scroll-down.png')}
                className={classes.iconScroll}
                alt={''}
              />
              <Translate content="common.scroll" />
            </Typography>
          )}
          {this.state.completed && (
            <Typography varian="subheading" className={classes.success}>
              <img
                src={require('../../img/scroll/scroll-completed.png')}
                className={classes.iconScroll}
                alt={''}
              />
              <Translate content="common.readingCompleted" />
            </Typography>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ReadableTextArea);
