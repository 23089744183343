import React from 'react';
import Button from '../common/Button';
import TextField from '../common/Forms/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import store from '../../store/index';
import { ROUTES } from '../../constants/routes';
import UnauthenticatedPage from '../common/UnauthenticatedPage';
import HalfPageImage from '../common/HalfPageImage';
import { LinkPrimary } from '../common/Links';
import translator from 'counterpart';
import Translate from 'react-translate-component';
import { withFormsValidation } from '../common/Forms/FormsCommon';
import { CookieAlert } from '../common/CookieAlert';

const styles = theme => ({
  textField: {
    width: '100%',
  },
  formControl: {
    width: '100%',
    margin: '10px 0px',
  },
  loginButton: {
    color: '#FFF',
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  inputLabelFocused: {
    color: theme.palette.primary.main,
  },
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  inputInkbarError: {
    '&:after': {
      backgroundColor: theme.palette.error.main,
    },
  },
  inputCustomClass: {
    minWidth: '370px',
    '@media (max-width: 800px)': {
      width: '100%',
      minWidth: '300px',
    },
  },
  formFullWidthMobile: {
    '@media (max-width: 800px)': {
      width: '100%',
    },
  },
  restorePassword: {
    marginTop: '40px',
  },
  errorPassword: {
    color: theme.palette.error.main,
  },
  remember: {
    width: '50%',
    // color: theme.palette.font.grey,
    fontSize: '10px',
    display: 'inline-block',
    textAlign: 'left',
  },
  account: {
    margin: '10px 0px 40px',
  },
  button: {
    width: '370px',
    '@media (max-width: 800px)': {
      width: '100%',
    },
  },
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.props.initObject({
      form$grant_type: 'password',
      form$scope: '*',
    });
  }

  confirm = event => {
    event.preventDefault();
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.send(
          result => {
            store.app.onLogin(result, () => {
              const { from } = this.props.location.state || {
                from: { pathname: '/' },
              };
              this.props.history.push(from);
            });
          },
          () => {
            this.setState({
              loading: false,
            });
          }
        );
      }
    );
  };

  render() {
    // const { from } = this.props.location.state || { from: { pathname: '/' } };
    //
    // const isAuthenticated = store.app.isLoggedIn;
    //
    // if (isAuthenticated) {
    //   return <Redirect to={from} />;
    // }

    const { classes, errors, handleChange, validateField, object } = this.props;

    const { loading } = this.state;

    const form_disabled = loading;
    const ids = {
      user: this.props.form.ids.form$username,
      password: this.props.form.ids.form$password,
    };

    return (
      <React.Fragment>
        <HalfPageImage variant="login">
          <UnauthenticatedPage
            halfWidth
            title={<Translate content="common.loginSection.title" />}
            align="flex-start"
          >
            <Typography
              variant="caption"
              className={classes.account}
              gutterBottom
            >
              {!store.app.sandboxMode && (
                <React.Fragment>
                  <Translate content="common.loginSection.noAccount" />
                  <LinkPrimary forward to={ROUTES.REGISTER}>
                    <Translate content="common.loginSection.signUp" />
                  </LinkPrimary>
                </React.Fragment>
              )}
            </Typography>
            <form
              onSubmit={this.confirm}
              className={classes.formFullWidthMobile}
            >
              <TextField
                label={<Translate content="profile.email" />}
                placeholder={translator('placeholder.email')}
                customClasses={classes.inputCustomClass}
                name={ids.user}
                id={ids.user}
                type="text"
                onChange={handleChange}
                value={object[ids.user]}
                error={Boolean(errors[ids.user])}
                helperText={errors[ids.user]}
                disabled={form_disabled}
                validateField={validateField}
              />
              <TextField
                label={<Translate content="profile.password" />}
                placeholder={translator('placeholder.password')}
                customClasses={classes.inputCustomClass}
                name={ids.password}
                id={ids.password}
                onChange={handleChange}
                value={object[ids.password]}
                error={Boolean(errors[ids.password])}
                helperText={errors[ids.password]}
                type="password"
                disabled={form_disabled}
                validateField={validateField}
              />

              <Button
                variant="raised"
                color="primary"
                id="loginButton"
                type="submit"
                customClass={classes.button}
                loading={loading}
                onClick={this.confirm}
              >
                <Translate content="common.login" />
              </Button>
            </form>
            <Typography variant="caption" className={classes.restorePassword}>
              {!store.app.sandboxMode && (
                <LinkPrimary to={ROUTES.RESET_PASSWORD_REQUEST} forward>
                  <Translate content="common.loginSection.passwordForget" />
                </LinkPrimary>
              )}
            </Typography>
          </UnauthenticatedPage>
        </HalfPageImage>
        <CookieAlert />
      </React.Fragment>
    );
  }
}

export default withFormsValidation('CookiePasswordLoginForm')(
  withStyles(styles)(Login)
);
