const LINKS = {
  terms: 'en/terms/',
  privacy_policy: 'en/privacy-policy/',
  cookie_policy: 'en/cookie-policy/',
  faq_root: 'en/faq/',
  documentation: 'en/developers/',
  contact_us: 'en/contact-us/',
  web_pos_integration: 'en/faq/chainsidepay/#faq-HowcanIactivateaPOS',
  web_pos_sdk: 'en/developers/webpos-api/#api-sdks',
  chainside_pay_how_does_it_work:
    'en/faq/chainsidepay/#faq-HowdoesChainsidePaywork',
  how_to_receive_bitcoin_website:
    'en/faq/chainsidepay/#faq-HowcanIreceivebitcoinonmywebsite',
  how_to_receive_bitcoin_point_of_sale:
    'en/faq/chainsidepay/#faq-HowcanIreceivebitcoinonmyphysicalstore',
  test_account_functionalities:
    'en/faq/chainsidepay/#faq-WhatcanIdowithinthesandboxenvironment',
  test_account_user_impact:
    'en/faq/chainsidepay/#faq-Doesthetestingactivityimpactsonmyrealusers',
  what_is_a_pos: 'en/faq/chainsidepay/#faq-WhatisaPOS',
  devs_area_pos_urls: 'en/developers/webpos-api/#api-urls',
  devs_area_pos_credentials: 'en/developers/webpos-api/#api-authentication',
  payment_order_how_to_receive_first_payment:
    'en/faq/chainsidepay/#faq-HowdoesChainsidePaywork',
  payment_order_can_i_cancel:
    'en/faq/chainsidepay/#faq-CanIcancelarequestofpayment',
  payment_order_network_dispute: 'en/faq/chainsidepay/#faq-WhatdoesDisputemean',
  payment_order_chargeback: 'en/faq/chainsidepay/#faq-WhatdoesChargebackmean',
  bitcoin_what_is_an_address: 'en/faq/payments/#faq-Whatisabitcoinaddress',
  bitcoin_exchange_how_does_it_work:
    'en/faq/bitcoin/#faq-Howisthevalueofbitcoincalculated',
  bitcoin_what_are_transactions:
    'en/faq/bitcoin/#faq-WhatisaBitcointransaction',
  bitcoin_what_states_can_a_transaction_have:
    'en/faq/chainsidepay/#faq-Inwhichstatescanapaymentbe',
  account_wallet_not_found: 'en/faq/chainsidepay/#faq-Ican8217tfindmywallet',
  exchange_more_info: 'en/faq/bitcoin/#faq-Howisthevalueofbitcoincalculated',
  transaction_confirmations_more_info:
    'en/faq/blockchain/#faq-Whatareconfirmations',
  sandbox_more_info: 'en/faq/chainsidepay/#faq-Whatisthesandboxenvironment',
};

export { LINKS };
