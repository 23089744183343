import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  badgeContainer: {
    minWidth: '100px',
    height: '24px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  warning: {
    backgroundColor: theme.palette.orange.main,
  },
  info: {
    backgroundColor: theme.palette.primary.bluegreydark,
  },
  success: {
    backgroundColor: theme.palette.green.main,
  },
  primaryLight: {
    backgroundColor: theme.palette.primary.light,
  },
  error: {
    backgroundColor: theme.palette.red.main,
  },
  grey: {
    backgroundColor: theme.palette.primary.bluegreydark,
  },
  label: {
    color: theme.palette.common.white,
    margin: '0px 10px',
  },
});

const types = ['warning', 'info', 'success', 'primaryLight', 'error', 'grey'];

const Badge = props => {
  const { type, children, classes, ...otherProps } = props;
  return (
    <div
      {...otherProps}
      className={classnames([classes.badgeContainer, classes[type]])}
    >
      <Typography variant="caption" className={classes.label}>
        {children}
      </Typography>
    </div>
  );
};

Badge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.oneOf(types),
};

export default withStyles(styles)(Badge);
