import store from '../store';
import { onErrorNotification, onSuccessNotification } from './common';
import translator from 'counterpart';

export default {
  getListWeb: (onSuccess, onError) => {
    const getAll = store.app.factory.make('GetWebPosesAction');

    getAll
      .run()
      .then(onSuccess)
      .catch(onError);
  },
  getDetailWeb: (uuid, onSuccess, onError) => {
    const getWebPos = store.app.factory.make('GetWebPosAction');
    getWebPos.routeParams = { pos_uuid: uuid };

    getWebPos
      .run()
      .then(onSuccess)
      .catch(onError);
  },
  deactivate: (idPos, onSuccess, onError) => {
    const deactivateWebPos = store.app.factory.make('WebPosDeactivationAction');
    deactivateWebPos.routeParams = {
      pos_uuid: idPos,
    };

    deactivateWebPos
      .run()
      .then(
        onSuccessNotification(
          onSuccess,
          translator('POS.deactivate.success.message')
        )
      )
      .catch(onErrorNotification(onError));
  },
  activate: (idPos, onSuccess, onError) => {
    const activateWebPos = store.app.factory.make('ActivateWebPosAction');
    activateWebPos.routeParams = {
      pos_uuid: idPos,
    };

    activateWebPos
      .run()
      .then(
        onSuccessNotification(
          onSuccess,
          translator('POS.activate.success.message')
        )
      )
      .catch(onErrorNotification(onError));
  },
  generateNewClientIdAndSecret: (uuid, onSuccess, onError) => {
    const generateNewClientIdAndSecret = store.app.factory.make(
      'PosCredentialsResetAction'
    );
    generateNewClientIdAndSecret.routeParams = {
      pos_uuid: uuid,
    };

    generateNewClientIdAndSecret
      .run()
      .then(onSuccessNotification(onSuccess))
      .catch(onErrorNotification(onError));
  },
};
