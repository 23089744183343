import React from 'react';

import PropTypes from 'prop-types';

import { Row, Col } from 'react-flexbox-grid';

import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import '../../../styles/style.css';
import classnames from 'classnames';
import Translate from 'react-translate-component';
import { observer } from 'mobx-react';

const styles = theme => ({
  container: {},
  row: {
    width: '100%',
    padding: '20px',
  },
  done: {
    color: '#619823',
    width: '20px',
    height: '20px',
    marginRight: '5px',
  },
  payment: {
    display: 'flex',
    alignItems: 'center',
  },
  rowAround: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'row',
    marginTop: '25px',
  },
  comingSoonText: {
    textTransform: 'uppercase',
    marginRight: 'auto',
  },
  iconCol: {
    paddingLeft: '0px',
  },
  iconLarge: theme.icon.large,
});

const ChoiceTipoConto = observer(
  withStyles(styles)(props => {
    const { account, classes } = props;
    return (
      <React.Fragment>
        <Row top="xs" className={classes.row}>
          <Col xs={2} className={classes.iconCol}>
            {React.cloneElement(account.icon, { className: classes.iconLarge })}
          </Col>
          <Col xs={10}>
            <div className={classes.container}>
              <Typography
                variant="subheading"
                gutterBottom
                className="w600 f-22 montserrat"
              >
                {account.text}
              </Typography>
              {account.features.map((item, k) => (
                <Typography
                  variant="caption"
                  gutterBottom
                  className={classes.payment}
                  key={k}
                >
                  <Icon className={classes.done}>done</Icon>
                  {item}
                </Typography>
              ))}
              <div className={classes.rowAround}>
                {!(account.comingSoon && account.comingSoon()) &&
                  account.commissione && (
                    <React.Fragment>
                      <Typography className="f-13" variant="body2">
                        {account.commissione}
                      </Typography>

                      <Typography
                        variant="body2"
                        className={classnames([classes.payment, 'f-13'])}
                      >
                        {account.paymentTime}
                      </Typography>
                    </React.Fragment>
                  )}
                {account.comingSoon && account.comingSoon() && (
                  <Typography
                    className={classes.comingSoonText}
                    variant="body1"
                  >
                    <Translate content="common.comingSoon" />
                  </Typography>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  })
);

ChoiceTipoConto.propTypes = {
  account: PropTypes.shape({
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    commissione: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      .isRequired,
    paymentTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      .isRequired,
    features: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    ),
  }),
};

export default ChoiceTipoConto;
