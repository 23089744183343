import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Typography, withStyles } from '@material-ui/core';

const styles = theme => ({
  sectionTitle: {
    marginBottom: '20px',
    paddingBottom: '10px',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.primary.bluegreydark,
  },
});

const FormSectionTitle = withStyles(styles)(props => {
  const { classes, children } = props;
  return (
    <Typography
      variant="body2"
      className={classnames(classes.sectionTitle, 'f-16')}
    >
      {children}
    </Typography>
  );
});

FormSectionTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export { FormSectionTitle };
