import React from 'react';

import { Redirect, Prompt } from 'react-router-dom';

import { Col, Row } from 'react-flexbox-grid';

import Translate from 'react-translate-component';
import translator from 'counterpart';

import { withStyles } from '@material-ui/core/styles';

import TextField from '../../common/Forms/TextField';

import PageContainer from '../../common/PageContainer';
import PageTitle from '../../common/PageTitle';

import store from '../../../store/index';

import { ROUTES } from '../../../constants/routes';

import SaveButtons from '../common/SaveButtons';
import { withFormsValidation } from '../../common/Forms/FormsCommon';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  oldPassword: {
    marginBottom: '40px',
  },
});

class ModificaPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      loading: false,
    };
  }

  save = event => {
    event.preventDefault();
    this.setState({ loading: true });
    this.props.send(
      () => {
        store.app.snackBar.open(
          translator('profile.editPassword.submit.success'),
          'success'
        );
        this.setState({
          submitted: true,
        });
      },
      null,
      () => {
        this.setState({ loading: false });
      }
    );
  };

  render() {
    const { submitted, loading } = this.state;

    if (submitted) {
      return <Redirect push to={ROUTES.PROFILE_VIEW} />;
    }

    const { classes, errors, handleChange, object, validateField } = this.props;

    const form_disabled = loading;

    const ids = {
      newPassword: this.props.form.ids.form$new_password,
      confirmPassword: this.props.form.ids.form$new_password_confirmation,
      password: this.props.form.ids.form$old_password,
    };

    return (
      <PageContainer main white top>
        <Prompt
          message={translator('common.exitAlertMessage')}
          when={!submitted}
        />
        <PageTitle
          title={<Translate content="profile.titles.editPassword" />}
          parent={{
            text: <Translate content="profile.titles.goBack" />,
            url: ROUTES.PROFILE_VIEW,
          }}
        />
        <Typography
          variant="caption"
          style={{ marginBottom: '40px', marginTop: '-25px' }}
        >
          <Translate content="profile.editPassword.subtitle" />{' '}
        </Typography>
        <form onSubmit={this.save}>
          <Row className={classes.oldPassword}>
            <Col xs={12} sm={6} md={6}>
              <TextField
                disabled={form_disabled}
                error={Boolean(errors[ids.password])}
                helperText={errors[ids.password]}
                value={object[ids.password]}
                id={ids.password}
                name={ids.password}
                onChange={handleChange}
                label={
                  <Translate content="profile.editPassword.fields.oldPassword.label" />
                }
                placeholder={translator(
                  'profile.editPassword.fields.oldPassword.placeholder'
                )}
                type="password"
                validateField={validateField}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} md={6}>
              <TextField
                disabled={form_disabled}
                error={Boolean(errors[ids.newPassword])}
                helperText={errors[ids.newPassword]}
                value={object[ids.newPassword]}
                id={ids.newPassword}
                name={ids.newPassword}
                onChange={handleChange}
                label={
                  <Translate content="profile.editPassword.fields.newPassword.label" />
                }
                placeholder={translator(
                  'profile.editPassword.fields.newPassword.placeholder'
                )}
                type="password"
                validateField={validateField}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} md={6}>
              <TextField
                disabled={form_disabled}
                error={Boolean(errors[ids.confirmPassword])}
                helperText={errors[ids.confirmPassword]}
                value={object[ids.confirmPassword]}
                id={ids.confirmPassword}
                name={ids.confirmPassword}
                onChange={handleChange}
                label={
                  <Translate content="profile.editPassword.fields.confirmPassword.label" />
                }
                placeholder={translator(
                  'profile.editPassword.fields.confirmPassword.placeholder'
                )}
                type="password"
                validateField={validateField}
              />
            </Col>
          </Row>
          <SaveButtons
            cancelDisabled={form_disabled}
            errors={errors}
            width={50}
            loading={loading}
            onClick={this.save}
          />
        </form>
      </PageContainer>
    );
  }
}

export default withFormsValidation('PasswordChangeForm')(
  withStyles(styles)(ModificaPassword)
);
