import { WithStyles, withStyles } from '@material-ui/core';
import { style } from './styles';
import { Col, ColProps } from 'react-flexbox-grid';
import classNames from 'classnames';
import React from 'react';

interface SortableTableCellProps extends WithStyles<typeof style>, ColProps {
  className?: string;
  children: React.ReactNode;
}

const SortableTableCell = withStyles(style)((props: SortableTableCellProps) => {
  const { children, className, classes, ...otherProps } = props;
  return (
    <Col
      xs
      className={classNames(classes.sortableTableBodyCell, className)}
      {...otherProps}
    >
      {children}
    </Col>
  );
});

export { SortableTableCell };
