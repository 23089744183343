import classnames from 'classnames';
import React, { FC, MouseEvent } from 'react';
import { ActionLinkProps } from './propTypes';
import {withStyles} from "@material-ui/core";
import styles from '../theme';

export const ActionLink: FC<ActionLinkProps> = ({
  classes = {},
  children,
  back,
  forward,
  customClasses = '',
  onClick,
  disabled,
  ...otherProps
}) => (
  <div
    {...otherProps}
    onClick={(event: MouseEvent) => {
      if (!disabled) {
        onClick(event);
      }
    }}
    className={classnames({
      [classes.link as string]: true,
      [customClasses as string]: true,
      [classes.disabled as string]: disabled,
    })}
  >
    {back && <React.Fragment>&lt;&nbsp;</React.Fragment>}
    {children}
    {forward && <React.Fragment>&nbsp;&gt;</React.Fragment>}
  </div>
);

const DecoratedActionLink = withStyles(styles)(ActionLink);

export default DecoratedActionLink;