import React from 'react';

import { observer } from 'mobx-react';

import PageContainer from '../../common/PageContainer';
import PageTitle from '../../common/PageTitle';

import PaymentsAPI from '../../../api/Payments';

import Payment from '../../common/Payment';
import Loader from '../../common/Loader';
import PaymentView from './PaymentView';

import { ROUTE_PARAMETERS, ROUTES } from '../../../constants/routes';
import Translate from 'react-translate-component';
import { buildPath } from '../../../utils/routes';
import { withGenericErrorHandling } from '../../common/ErrorHandling';

class DettagliPagamento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payment_uuid: this.props.match.params[ROUTE_PARAMETERS.PAYMENT_ORDER_ID],
      loading: false,
      payment: null,
    };
  }

  componentDidMount() {
    this.loadPayment();
  }

  loadPayment = () => {
    this.setState({
      loading: true,
    });
    const { payment_uuid } = this.state;
    PaymentsAPI.get(
      payment_uuid,
      payment => {
        this.setState({
          loading: false,
          payment: new Payment(payment),
        });
      },
      this.props.onError
    );
  };

  updatePayment = payment => {
    this.setState({ payment: payment });
  };
  render() {
    const { loading } = this.state;
    const { payment } = this.state;

    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];

    const parent = {
      text: <Translate content="payment.detail.titles.parent" />,
      url: buildPath(ROUTES.PAYMENT_ORDER_LIST, {
        [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
      }),
    };

    return (
      <PageContainer main white top>
        <PageTitle
          title={<Translate content="payment.detail.titles.title" />}
          parent={parent}
        />
        {loading && <Loader />}
        {!loading && payment && (
          <PaymentView updatePayment={this.updatePayment} payment={payment} />
        )}
      </PageContainer>
    );
  }
}

export default observer(withGenericErrorHandling(DettagliPagamento));
