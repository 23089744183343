import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.common.white,
    display: 'block',
    border: '1px solid',
    borderColor: theme.palette.primary.bluegrey,
    height: '50px',
    marginBottom: '10px',
    borderRadius: '6px',
    cursor: 'pointer',
    textDecoration: 'none',
    ...theme.shadow,
  },
  innerContainer: {
    padding: '15px 20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoText: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  icon: {
    width: '20px',
    height: '20px',
    marginRight: '10px',
  },
  arrowSmall: theme.icon.arrowSmall,
});

const FaqLink = props => {
  const { text, url, width, classes } = props;

  return (
    <a
      className={classes.container}
      style={{ width: width + '%' }}
      target="_blank"
      rel="noopener noreferrer"
      href={url}
    >
      <div className={classes.innerContainer}>
        <div className={classes.logoText}>
          <img
            src={require('../../img/help/faq.png')}
            alt="faq"
            className={classes.icon}
          />
          <Typography variant="body2" className="f-16">
            {text}
          </Typography>
        </div>
        <img
          src={require('../../img/arrow/small.png')}
          alt="faq"
          className={classes.arrowSmall}
        />
      </div>
    </a>
  );
};

FaqLink.propTypes = {
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  url: PropTypes.string.isRequired,
  width: PropTypes.number,
};

FaqLink.defaultProps = {
  width: 100,
};

export default withRouter(withStyles(styles)(FaqLink));
