import React from 'react';
import { withStyles } from '@material-ui/core';
import Translate from 'react-translate-component';
import Typography from '@material-ui/core/Typography';
import Badge from '../../../common/Badge';
import { status_list } from '../../common/common';

const styles = theme => ({
  container: {
    border: '1px solid',
    borderColor: theme.palette.primary.bluegrey,
    borderRadius: '6px',
    width: '100%',
    marginTop: '20px',
    ...theme.shadow,
  },
  innerContainer: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  paymentRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '15px',
  },
  iconArrow: {
    width: '13px',
    height: '13px',
    margin: '0px 25px',
  },
});

export const PaymentTransaction = withStyles(styles)(props => {
  const { classes, oldStatus, status, oldValue, value } = props;
  const oldStatusObj = status_list[oldStatus];
  const statusObj = status_list[status];
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.paymentRow}>
          <Typography variant="caption" style={{ flex: 3, textAlign: 'start' }}>
            <Translate content="payment.testing.status" />
          </Typography>
          <Badge type={oldStatusObj.type} style={{ flex: 3 }}>
            <Translate content={oldStatusObj.labelTranslation} />
          </Badge>
          <img
            src={require('../../../../img/payment-testing/sandbox-payment-changes.png')}
            alt=""
            className={classes.iconArrow}
          />
          <Badge type={statusObj.type} style={{ flex: 3 }}>
            <Translate content={statusObj.labelTranslation} />
          </Badge>
        </div>
        <div className={classes.paymentRow}>
          <Typography variant="caption" style={{ flex: 3, textAlign: 'start' }}>
            <Translate content="payment.testing.importReceived" />
          </Typography>
          <Typography variant="body2" style={{ flex: 3 }}>
            {'BTC ' + oldValue}
          </Typography>
          <img
            src={require('../../../../img/payment-testing/sandbox-payment-changes.png')}
            alt=""
            className={classes.iconArrow}
          />
          <Typography variant="body2" style={{ flex: 3 }}>
            {'BTC ' + value}
          </Typography>
        </div>
      </div>
    </div>
  );
});
