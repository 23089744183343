import counterpart from 'counterpart';
import translations_it from '../languages/it';
import translation_en from '../languages/en';

export const supportedLanguages: { [key: string]: string } = {
  it: 'it',
  'it-IT': 'it-IT',
  en: 'en',
  'en-US': 'en-US',
};

export const languagesList: { [key: string]: string }[] = [
  {
    value: 'it',
    translation: 'footer.lingua.italiano',
      flag: require('../img/flags/flags-iso/flat/24/IT.png')
  },
  {
    value: 'en',
    translation: 'footer.lingua.inglese',
      flag: require('../img/flags/flags-iso/flat/24/GB.png')

  },
];

export const initDictionaries = () => {
  counterpart.registerTranslations('it', translations_it);
  counterpart.registerTranslations('it-IT', translations_it);
  counterpart.registerTranslations('en', translation_en);
  counterpart.registerTranslations('en-US', translation_en);
};
