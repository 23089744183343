import React from 'react';
import store from '../../store';
import EmailNotVerifiedModal from './EmailNotVerifiedModal';

export const blockIfEmailNotVerified = (WrappedComponent, goBack) => {
  return class extends React.Component {
    state = {
      modalOpen: true,
    };
    render() {
      const { modalOpen } = this.state;

      const emailNotVerified = !store.app.isVerified;
      return (
        <React.Fragment>
          <EmailNotVerifiedModal
            blocking
            open={emailNotVerified && modalOpen}
            goBack={() => {
              window.history.back();
            }}
            closeModal={() => {
              this.setState({ openModal: false });
            }}
          />
          <WrappedComponent {...this.props} />
        </React.Fragment>
      );
    }
  };
};
