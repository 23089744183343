import React, { FC } from 'react';

import styles from './theme';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Translate from 'react-translate-component';

const DeactivatedMap: FC<StyledComponentProps> = ({ classes = {} }) => (
  <div data-testid="maps_disabled" className={classes.container}>
    <Typography variant="body1" className={classes.text}>
      <Translate content="POS.mobile.placeholderMap" />
    </Typography>
  </div>
);

export default withStyles(styles)(DeactivatedMap);
