import React from 'react';

import MaterialTextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { ActionLink } from '../Links';
import Translate from 'react-translate-component';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const styles = theme => ({
  root: {
    marginBottom: '15px',
    zIndex: '0',
  },
  input: {
    ...theme.components.inputField,
    fontWeight: theme.typography.weights.normal,
  },
  inputLabel: {
    fontSize: '14px',
    transform: 'translate(-10px, -5px) scale(1)',
    fontWeight: theme.typography.weights.normal,
    color: '#777777',
  },
  error: {
    backgroundColor: theme.palette.red.bg + ' !important',
    borderColor: theme.palette.red.main,
  },
  inputRoot: {
    padding: '1px',
    backgroundColor: theme.palette.primary.bg,
  },

  cancelLink: {
    alignSelf: 'flex-end',
    textTransform: 'uppercase',
  },
  moveLinkNormal: {
    transform: 'translate(-10px, -35px)',
  },
  moveLinkError: {
    transform: 'translate(-10px, -55px)',
  },
  visible: {
    height: '14px',
    width: '14px',
    marginRight: '10px',
    cursor: 'pointer',
    alignSelf: 'flex-end',
    transform: 'translate(-10px, -45px)',
  },
  paddingIcon: {
    paddingRight: '45px',
  },
});

class TextField extends React.Component {
  static propTypes = {
    validateField: PropTypes.func,
    value: PropTypes.string,
  };
  static defaultProps = {
    value: '',
  };
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  toogleVisibility = () => {
    this.setState({ visible: !this.state.visible });
  };

  getHiddenCamp = value => {
    if (!this.props.hidden || this.state.visible) {
      return value;
    }

    return '**********';
  };

  onBlur = () => {
    if (this.props.validateField) {
      this.props.validateField(this.props.id);
    }
  };

  render() {
    const {
      classes,
      InputProps,
      hidden,
      value,
      error,
      helperText,
      fullWidth,
      validateField,
      customClasses,
      ...otherProps
    } = this.props;
    const { visible } = this.state;
    const valueString = this.getHiddenCamp(value);
    let htmlError = null;
    if (error && helperText) {
      htmlError = (
        <React.Fragment>
          {helperText.map((text, k) => {
            return (
              <span key={k}>
                {text}
                <br />
              </span>
            );
          })}
        </React.Fragment>
      );
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <MaterialTextField
          InputProps={{
            disableUnderline: true,
            classes: {
              root: classes.inputRoot,
              input: classnames({
                [classes.input]: true,
                [classes.paddingIcon]: hidden,
              }),
              error: classes.error,
            },
            onBlur: this.onBlur,
            value: valueString,
            ...InputProps,
          }}
          InputLabelProps={{
            shrink: true,
            className: classes.inputLabel,
          }}
          classes={{
            root: classnames([classes.root, customClasses]),
          }}
          value={valueString}
          error={error}
          helperText={htmlError}
          {...otherProps}
        />
        {hidden && (
          <img
            onClick={this.toogleVisibility}
            className={classes.visible}
            alt={visible ? 'hide' : 'show'}
            src={
              visible
                ? require('../../../img/others/hide.png')
                : require('../../../img/others/show.png')
            }
          />
        )}

        {this.props.onCancel && valueString && (
          <ActionLink
            onClick={this.props.onCancel}
            disabled={otherProps.disabled}
            customClasses={classnames({
              [classes.cancelLink]: true,
              [classes.moveLinkNormal]: !error,
              [classes.moveLinkError]: error,
            })}
          >
            <Translate content="common.cancel" />
          </ActionLink>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(TextField);
