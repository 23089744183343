import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  loader: {
    color: theme.palette.primary.dark,
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  loaderLabel: {
    fontSize: '14px',
    fontWeight: '500',
    color: theme.palette.grey[7],
  },
});
const Loader = props => {
  const { children, classes, variant } = props;
  return (
    <div className={classes.row} style={{ justifyContent: variant }}>
      <div className={classes.column}>
        <CircularProgress className={classes.loader} />
        {children && (
          <Typography variant="caption" className={classes.loaderLabel}>
            {children}
          </Typography>
        )}
      </div>
    </div>
  );
};

Loader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variant: PropTypes.oneOf(['center', 'flex-start', 'flex-end']),
};

Loader.defaultProps = {
  variant: 'center',
};

export default withStyles(styles)(Loader);
