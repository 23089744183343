import { StyleRulesCallback } from '@material-ui/core';

const styles: StyleRulesCallback = ({
  typography,
  palette,
}: {
  [key: string]: any;
}) => ({
  qrCode: {
    border: '10px solid',
    borderColor: palette.common.white,
    outline: '1px solid',
    outlineColor: palette.primary.bluegrey,
    width: '190px',
    height: '190px',
    margin: '15px 0px 15px 100px',
  },
  sectionTitle: {
    fontSize: '22px',
    fontWeight: typography.fontWeightBold,
    marginBottom: '20px',
  },
  colorPrimary: {
    color: palette.primary.main,
  },
  colorYellow: {
    color: palette.orange.main,
  },
});

export default styles;
