import { StyleRulesCallback } from '@material-ui/core';

const styles: StyleRulesCallback = ({
  typography,
  palette,
}: {
  [key: string]: any;
}) => ({
  accountContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px',
  },
  errorLabel: typography.errorLabel,
  disableItalic: {
    fontStyle: 'initial',
  },
  notActiveLabel: {
    backgroundColor: palette.red.main,
    color: '#fff',
    fontWeight: 600,
    marginLeft: '11px',
    borderRadius: '4px',
    height: '24px',
  },
});

export default styles;
