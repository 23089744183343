import React, { FC } from 'react';
import { LinkPrimaryProps } from './propTypes';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import {withStyles} from "@material-ui/core";
import styles from '../theme';

const LinkPrimary: FC<LinkPrimaryProps> = ({
  classes = {},
  children,
  back,
  customClasses,
  targetBlank,
  external,
  to,
  forward,
  ...otherProps
}) => {
  if (external) {
    return (
      <a
        {...otherProps}
        className={classnames([classes.link, customClasses])}
        target="_blank"
        href={to}
        rel="noopener noreferrer"
      >
        {back && <React.Fragment>&lt;&nbsp;</React.Fragment>}
        {children}
      </a>
    );
  }
  return (
    <Link
      {...otherProps}
      className={classnames([classes.link, customClasses])}
      target={targetBlank ? '_blank' : undefined}
      to={to}
    >
      {back && <React.Fragment>&lt;&nbsp;</React.Fragment>}
      {children}
    </Link>
  );
};

const DecoratedLinkPrimary = withStyles(styles)(LinkPrimary)


export default DecoratedLinkPrimary;