import { StyleRulesCallback } from '@material-ui/core';

const styles: StyleRulesCallback = ({ palette }: { [key: string]: any }) => ({
  title: {
    marginBottom: '15px',
  },
  errorLabel: {
    backgroundColor: palette.red.main,
    color: '#fff',
    fontWeight: 600,
    borderRadius: '4px',
    height: '24px',
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  titleLabelContainer: {
    width: '100%',
    marginBottom: '45px',
    marginTop: '15px',
  },
});

export default styles;
