import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import 'moment/locale/it';
import Translate from 'react-translate-component';
import { Copyright } from './Copyright';
import { WebsiteLink } from './WebsiteLink';
import store from '../../store';
import classnames from 'classnames';
import { ActionLink } from './Links';

const styles = theme => ({
  container: {
    width: '100%',
    backgroundColor: theme.palette.primary.dark,
    height: '200px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'stretch',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      position: 'fixed',
      bottom: '0px',
    },
  },
  innerContainer: {
    marginTop: '28px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  section: {
    margin: '0px 20px',
  },
  link: {
    fontSize: '13px',
    color: theme.palette.linkFooter,
    marginBottom: '10px',
  },
  headerLink: {
    color: theme.palette.common.white,
    marginBottom: '10px',
  },
  copyright: {
    fontSize: '12px',
    color: theme.palette.primary.bluegrey,
    fontWeight: 'normal',
  },
  businessInfo: {
    fontSize: '10px',
    color: theme.palette.common.white,
    margin: '12px',
    fontWeight: 'normal',
    textAlign: 'center',
  },
  logoLive: {
    width: '167.4px',
    height: '38px',
  },
  logoSandbox: {
    width: '167.4px',
    height: '48px',
    marginBottom: '5px',
  },
});

class Footer extends React.Component {
  setLanguage = language => () => {
    store.app.setLanguage(language);
    window.location.reload();
  };

  render() {
    const { classes } = this.props;
    return (
      <footer className={classes.container}>
        <div className={classes.innerContainer}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <img
              src={
                store.app.sandboxMode
                  ? require('../../img/logo/CSpaySandboxWhite.png')
                  : require('../../img/logo/CSpayWhite.png')
              }
              className={classnames({
                [classes.logoLive]: !store.app.sandboxMode,
                [classes.logoSandbox]: store.app.sandboxMode,
              })}
              alt={'Chainside-pay'}
            />
            <Copyright className={classes.copyright} />
          </div>
          <div className={classes.textContainer}>
            <div className={classes.section}>
              <Typography variant="body2" className={classes.headerLink}>
                <Translate content="footer.supporto.title" />
              </Typography>
              <WebsiteLink withoutIcon to="faq_root">
                <Typography variant="body1" className={classes.link}>
                  <Translate content="footer.supporto.faqs" />
                </Typography>
              </WebsiteLink>
              <WebsiteLink withoutIcon to="documentation">
                <Typography variant="body1" className={classes.link}>
                  <Translate content="footer.supporto.documentazione" />
                </Typography>
              </WebsiteLink>
            </div>
            <div className={classes.section}>
              <Typography variant="body2" className={classes.headerLink}>
                <Translate content="footer.condizioni.title" />
              </Typography>
              <WebsiteLink withoutIcon to="terms">
                <Typography variant="body1" className={classes.link}>
                  <Translate content="footer.condizioni.termini" />
                </Typography>
              </WebsiteLink>
              <WebsiteLink withoutIcon to="privacy_policy">
                <Typography variant="body1" className={classes.link}>
                  <Translate content="footer.condizioni.privacy" />
                </Typography>
              </WebsiteLink>
            </div>
            <div className={classes.section}>
              <Typography variant="body2" className={classes.headerLink}>
                <Translate content="footer.lingua.title" />
              </Typography>
              <ActionLink
                variant="body1"
                onClick={this.setLanguage('it')}
                customClasses={classes.link}
              >
                <Translate content="footer.lingua.italiano" />
              </ActionLink>
              <ActionLink
                variant="body1"
                onClick={this.setLanguage('en')}
                customClasses={classes.link}
              >
                <Translate content="footer.lingua.inglese" />
              </ActionLink>
            </div>
          </div>
        </div>
        <Typography variant="body1" className={classes.businessInfo}>
          Chainside srl - Via della Stazione di San Pietro n. 65 - 00165 Roma
          P.IVA / CF 14154691001 PEC chainside-srl@pec.it - SDI M5UXCR1
          Iscrizione al registro delle imprese di Roma n° 1500988
          <br />
          Fornitore di servizi per il pagamento in valuta virtuale e cambio
          istantanteo, soggetto all'iscrizione nella sezione speciale dei
          cambiavalute tenuto da OAM - Organismo previsto dall'art 128 undecies,
          del D.Lgs. 1° settembre 1993, n. 385 (o Testo Unico Bancario), con
          sede in Roma, Via Galilei, 3 - 00185.
        </Typography>
      </footer>
    );
  }
}

export default withStyles(styles)(Footer);
