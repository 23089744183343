import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Row, Col } from 'react-flexbox-grid';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { MobilePOS, WebPOS } from './common';
import '../../../styles/style.css';

const styles = theme => ({
  container: {
    border: '1px solid',
    width: '100%',
    height: '100%',
    borderColor: theme.palette.primary.bluegrey,
    backgroundColor: theme.palette.common.white,
    borderRadius: '6px',
    cursor: 'pointer',
    ...theme.shadow,
  },
  phantomAvatar: {
    backgroundColor: theme.palette.grey.light,
    margin: '5px 0px 5px 10px',
  },
  selected: {
    backgroundColor: theme.palette.primary.bg,
    borderColor: theme.palette.primary.light,
  },
  phantomText: {
    width: '70px',
    height: '10px',
    backgroundColor: theme.palette.grey.light,
  },
  avatar: {
    marginRight: '10px',
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 20px',
    maxWidth: '100%',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: 'inherit',
    whiteSpace: 'nowrap',
  },
  posIcon: theme.icon.medium,
  arrowSmall: theme.icon.arrowSmall,
  color4: {
    color: theme.palette.grey[4],
  },
  posNameContainer: {
    maxWidth: '80%',
  },
  posName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const ListComponent = withStyles(styles)(
  class extends React.Component {
    static propTypes = {
      epos: PropTypes.shape({
        name: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
      }),
    };

    render() {
      const { classes, epos, selected } = this.props;
      return (
        <React.Fragment>
          <div
            className={classnames({
              [classes.container]: true,
              [classes.selected]: selected,
            })}
          >
            <div className={classes.innerContainer}>
              <div className={classes.avatarContainer}>
                <img
                  className={classnames([classes.avatar, classes.posIcon])}
                  src={
                    epos.type === 'web'
                      ? epos.active
                        ? require('../../../img/webPos/active/medium.png')
                        : require('../../../img/webPos/unactive/medium.png')
                      : epos.active
                      ? require('../../../img/mobilePos/active/medium.png')
                      : require('../../../img/mobilePos/unactive/medium.png')
                  }
                  alt="webpos"
                />
                <div className={classes.posNameContainer}>
                  <Typography
                    className={classnames([
                      classes.color4,
                      'listComponentText',
                      classes.posName,
                    ])}
                    variant="body1"
                  >
                    <b>{epos.name}</b>
                  </Typography>
                  {epos.type === 'web' ? <WebPOS /> : <MobilePOS />}
                </div>
              </div>
              {!selected && (
                <div>
                  <img
                    src={require('../../../img/arrow/small.png')}
                    alt="detail"
                    className={classes.arrowSmall}
                  />
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
);

export const PhantomListComponent = withStyles(styles)(props => {
  const { classes } = props;
  return (
    <div className={classes.container}>
      <Row around="xs" middle="xs">
        <Col xs={2}>
          <Avatar className={classes.phantomAvatar} />
        </Col>
        <Col xs={4}>
          <div className={classes.phantomText} />
        </Col>
        <Col xs={4} />
      </Row>
    </div>
  );
});
