import React, { FC } from 'react';
import { AccountsSectionProps } from './propTypes';
import { Col, Row } from 'react-flexbox-grid';
import { FormSectionTitle } from '../../../../common/Forms/FormSectionTitle';
import Translate from 'react-translate-component';
import Chip from '@material-ui/core/Chip/Chip';
import translator from 'counterpart';
import Typography from '@material-ui/core/Typography/Typography';
import { LinkPrimary } from '../../../../common/Links';
import { buildPath } from '../../../../../utils/routes';
import { ROUTE_PARAMETERS, ROUTES } from '../../../../../constants/routes';
import GenericSelectable from '../../../../common/GenericSelectable';
import Avatar from '@material-ui/core/Avatar/Avatar';
import Loader from '../../../../common/Loader';
import CheckboxField from '../../../../common/Forms/CheckboxField';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core';
import styles from './theme';

const AccountsSection: FC<AccountsSectionProps> = ({
  isNewPos,
  business_uuid,
  accountsList,
  hasAccounts,
  tooglePosWithoutAccount,
  posWithoutAccount,
  handleChange,
  error,
  classes = {},
  accountConnected,
}) => (
  <React.Fragment>
    <Row
      style={{
        marginTop: '40px',
      }}
    >
      <Col xs={12}>
        <FormSectionTitle>
          <Translate content="POS.account" />
          {!isNewPos && !accountConnected && (
            <Chip
              label={translator('POS.edit.state.noDepositAccount')}
              className={classes.notActiveLabel}
            />
          )}
        </FormSectionTitle>
      </Col>
    </Row>
    {!accountConnected && (
      <Typography variant="caption" style={{ marginTop: '10px' }}>
        <Translate
          content={'POS.noDepositNewAccount'}
          with={{
            link: (
              <LinkPrimary
                id={'createNewAccount'}
                customClasses={classes.disableItalic}
                to={buildPath(ROUTES.ACCOUNT_NEW, {
                  [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
                })}
              >
                <Translate content={'POS.newAccountLiteral'} />
              </LinkPrimary>
            ),
          }}
        />
      </Typography>
    )}
    {accountsList && accountsList.length > 0 && (
      <div id={'depositsList'}>
        {' '}
        {accountsList
          .sort((a1, a2) =>
            a1.name === a2.name ? 0 : a1.name < a2.name ? -1 : 1
          )
          .map((account, k) => {
            const isBitcoin = account && account.purpose;
            const iban_trunked = account.iban ? account.iban.slice(-5) : '';
            const xpub_trunked = account.extended_pubkey
              ? account.extended_pubkey.slice(-8)
              : '';

            const icon = isBitcoin
              ? require('../../../../../img/bitcoin/small.png')
              : require('../../../../../img/bancario/small.png');

            return (
              <GenericSelectable
                disabled={posWithoutAccount}
                value={account.uuid}
                id={account.uuid}
                name={account.uuid}
                isActive={account.uuid === accountConnected}
                isInvalid={Boolean(error)}
                handleChange={handleChange}
                width={30}
                noMarginLeft
                key={k}
              >
                <div className={classes.accountContainer}>
                  <Avatar src={icon} />
                  <div style={{ marginLeft: '12px' }}>
                    <Typography variant="body2" className="f-18">
                      {account.name}
                    </Typography>
                    <Typography variant="caption" noWrap>
                      {isBitcoin
                        ? 'xpub: ...' + xpub_trunked
                        : 'IBAN: ...' + iban_trunked}
                    </Typography>
                  </div>
                </div>
              </GenericSelectable>
            );
          })}{' '}
      </div>
    )}
    {hasAccounts && accountsList && accountsList.length === 0 && (
      <Loader variant="flex-start" />
    )}
    {error && (
      <Typography variant="caption" className={classes.errorLabel}>
        {error}
      </Typography>
    )}
    {!hasAccounts && (
      <Typography variant="caption" gutterBottom className="f-13">
        <Translate
          content={'POS.newAccountEmptyAccounts'}
          with={{
            link: (
              <LinkPrimary
                customClasses={classnames(classes.disableItalic)}
                to={buildPath(ROUTES.ACCOUNT_NEW, {
                  [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
                })}
              >
                <Translate content={'POS.newAccountLiteral'} />
              </LinkPrimary>
            ),
          }}
        />
      </Typography>
    )}
    {accountConnected && (
      <Typography variant="caption" gutterBottom className="f-13">
        <Translate
          content={'POS.newAccount'}
          with={{
            link: (
              <LinkPrimary
                to={buildPath(ROUTES.ACCOUNT_NEW, {
                  [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
                })}
                forward
              >
                <Translate content={'common.addNow'} />
              </LinkPrimary>
            ),
          }}
        />
      </Typography>
    )}
    {!isNewPos && hasAccounts && !accountConnected && (
      <Col xs={12} style={{ marginTop: '10px' }}>
        <CheckboxField
          id="mobilepos_without_deposit_account"
          name="mobilepos_without_deposit_account"
          checked={posWithoutAccount}
          onChange={tooglePosWithoutAccount}
          label={<Translate content="POS.edit.toggleWebPosWithoutDeposit" />}
        />
      </Col>
    )}
  </React.Fragment>
);

export default withStyles(styles)(AccountsSection);
