import { withStyles } from '@material-ui/core';
import { styles } from './styles';
import * as React from 'react';

export const PaymentStatusDot = withStyles(styles)(props => {
  const { classes } = props;
  const { status } = props;
  return (
    <div className={[classes.paymentStatusDot, classes[status]].join(' ')} />
  );
});
