import { extendObservable } from 'mobx';

export default class Profile {
  constructor(user) {
    extendObservable(this, {
      uuid: user.uuid,
      first_name: user.name,
      last_name: user.surname,
      terms: user.terms,
      email: user.contacts.email,
      phone: user.contacts.phone || null,
      lang: user.lang,
      socket: user.contacts.socket,
      consensi: {
        dataSharing: user.gdpr ? user.gdpr.data_sharing : null,
        privacyPolicy: user.gdpr ? user.gdpr.privacyPolicy : null,
        updatesNotifications: user.gdpr
          ? user.gdpr.updates_notifications
          : null,
      },
      get fullName() {
        return this.first_name + ' ' + this.last_name;
      },
    });
  }
}
