/* eslint-disable-next-line */
/*global google*/
import { withStyles } from '@material-ui/core';

import React from 'react';
import { MapProps } from './propTypes';
import styles from './theme';

declare const google: any;

class Map extends React.Component<MapProps> {
  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps: MapProps) {
    if (
      this.props.position.lat !== prevProps.position.lat ||
      this.props.position.lon !== prevProps.position.lon
    ) {
      this.initialize();
    }
  }

  initialize = (position = this.props.position) => {
    const { mapId } = this.props;
    const map = new google.maps.Map(document.getElementById(`map_${mapId}`), {
      center: new google.maps.LatLng(position.lat, position.lon),
      zoom: 15,
      draggable: true,
      scrollwheel: true,
      disableDoubleClickZoom: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
    });

    new google.maps.Marker({
      position: new google.maps.LatLng(position.lat, position.lon),
      map: map,
    });
  };
  render() {
    const { mapId, classes = {} } = this.props;
    return (
      <div
        data-testid="maps"
        id={`map_${mapId}`}
        className={classes.containerMap}
      />
    );
  }
}

export default withStyles(styles)(Map);
