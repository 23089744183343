import { StyleRulesCallback } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: { [key: string]: any }) => ({
  loader: {
    marginRight: '8px',
  },
  marginTopSection: {
    marginTop: '70px',
  },
});

export default styles;
