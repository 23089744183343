const LINKS = {
  terms: 'termini/',
  privacy_policy: 'privacy/',
  cookie_policy: 'cookie/',
  faq_root: 'domande-frequenti',
  documentation: 'area-sviluppatori/',
  contact_us: 'contattaci',
  web_pos_integration: 'domande-frequenti/chainsidepay/#faq-ComeattivareunPOS',
  web_pos_sdk: 'area-sviluppatori/webpos-api/#api-sdks',
  chainside_pay_how_does_it_work:
    'domande-frequenti/chainsidepay/#faq-ComefunzionaChainsidePay',
  how_to_receive_bitcoin_website:
    'domande-frequenti/chainsidepay/#faq-Comefareperaccettarebitcoinnelmiositoweb',
  how_to_receive_bitcoin_point_of_sale:
    'domande-frequenti/chainsidepay/#faq-Comefareperriceverebitcoinnelmiopuntovendita',
  test_account_functionalities:
    'domande-frequenti/chainsidepay/#faq-Cosapossofareconl8217ambientedisandbox',
  test_account_user_impact:
    'domande-frequenti/chainsidepay/#faq-Itestchefaccionell8217ambientedisandboximpattanosuimieiutenti',
  what_is_a_pos: 'domande-frequenti/chainsidepay/#faq-ChecosaunPOS',
  devs_area_pos_urls: 'area-sviluppatori/webpos-api/#api-urls',
  devs_area_pos_credentials: 'area-sviluppatori/webpos-api/#api-authentication',
  payment_order_how_to_receive_first_payment:
    'domande-frequenti/#faq-ComefunzionaChainsidePay',
  payment_order_can_i_cancel:
    'domande-frequenti/chainsidepay/#faq-Possoannullareunordinedipagamento',
  payment_order_network_dispute:
    'domande-frequenti/chainsidepay/#faq-CosasignificaDisputa',
  payment_order_chargeback:
    'domande-frequenti/chainsidepay/#faq-CosasignificaChargeback',
  bitcoin_what_is_an_address:
    'domande-frequenti/pagamenti/#faq-CosunIndirizzobitcoin',
  bitcoin_exchange_how_does_it_work:
    'domande-frequenti/bitcoin/#faq-Comcalcolatoilprezzodibitcoin',
  bitcoin_what_are_transactions:
    'domande-frequenti/bitcoin/#faq-CosasonoletransazioniBitcoin',
  bitcoin_what_states_can_a_transaction_have:
    'domande-frequenti/chainsidepay/#faq-Qualisonoidiversistatichepuassumereunatransazione',
  account_wallet_not_found:
    'domande-frequenti/chainsidepay/#faq-Nontrovoilmioportafoglio',
  exchange_more_info:
    'domande-frequenti/bitcoin/#faq-Comcalcolatoilprezzodibitcoin',
  transaction_confirmations_more_info:
    'domande-frequenti/blockchain/#faq-CosasonoleconfermedellareteBitcoin',
  sandbox_more_info:
    'domande-frequenti/chainsidepay/#faq-Cosal8217ambientedisandbox',
};

export { LINKS };
