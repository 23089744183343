import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Mouse from '@material-ui/icons/Mouse';
import Translate from 'react-translate-component';

export const confermeRichiesteString = {
  0: <Translate content={'POS.confirmation.0'} />,
  1: <Translate content={'POS.confirmation.1'} />,
  3: <Translate content={'POS.confirmation.3'} />,
  6: <Translate content={'POS.confirmation.6'} />,
};

const styles = theme => ({
  common: {
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    fontFamily: theme.typography.fontFamilies.accent,
  },
  web: {
    fontWeight: theme.typography.fontWeightBold,
    fontStyle: 'italic',
    color: theme.palette.pos.web,
  },
  mobile: {
    fontWeight: theme.typography.fontWeightBold,
    fontStyle: 'italic',
    color: theme.palette.pos.mobile,
  },
  POS: {
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 'normal',
    color: '#777777',
  },
  deviceIcon: {
    width: '15px',
    height: '15px',
    display: 'inline-block',
    marginLeft: '5px',
  },
});

const WebPOS = withStyles(styles)(props => {
  const { classes, fontSize } = props;
  return (
    <Typography
      variant="body1"
      className={classes.common}
      style={{ fontSize: fontSize + 'px' }}
    >
      <span className={classes.web}>web</span>
      <span className={classes.POS}>POS</span>
    </Typography>
  );
});

WebPOS.propTypes = {
  fontSize: PropTypes.number,
};

WebPOS.defaultProps = {
  fontSize: 13,
};

export { WebPOS };

const MobilePOS = withStyles(styles)(props => {
  const { classes, fontSize, showIcon } = props;
  return (
    <Typography
      variant="body1"
      className={classes.common}
      style={{ fontSize: fontSize + 'px' }}
    >
      <span className={classes.mobile}>mobile</span>
      <span className={classes.POS}>POS</span>
      {showIcon && (
        <Avatar className={classes.deviceIcon}>
          <Mouse />
        </Avatar>
      )}
    </Typography>
  );
});

MobilePOS.propTypes = {
  fontSize: PropTypes.number,
};

MobilePOS.defaultProps = {
  fontSize: 13,
};

export { MobilePOS };
