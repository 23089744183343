import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ConfirmModal, DetailRow } from '../../common/Confirm';
import Translate from 'react-translate-component';
import { formatAddress } from '../../../utils/formatters';

const styles = theme => ({
  containerDetail: {
    minWidth: '500px',
    marginRight: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  detailRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    marginBottom: '12px',
  },

  avatarAccount: {
    marginRight: '5px',
    ...theme.icon.small,
  },
  accountRow: {
    display: 'flex',
    alignItems: 'center',
  },
});

const ConfirmModalMobilePos = withStyles(styles)(props => {
  const { pos, classes, account, currencyName, ids } = props;

  return (
    <div className={classes.containerDetail}>
      <DetailRow
        text={<Translate content="POS.resume.mobile.name" />}
        value={pos[ids.name]}
      />
      <DetailRow
        text={<Translate content="POS.resume.mobile.address" />}
        value={
          pos[ids.store_address] ? (
            formatAddress(pos[ids.store_address])
          ) : (
            <Translate content="POS.resume.mobile.missingAddress" />
          )
        }
      />
      {currencyName && (
        <DetailRow
          text={<Translate content="POS.resume.currency" />}
          value={currencyName}
        />
      )}
      <DetailRow
        text={<Translate content="POS.resume.account" />}
        value={<AccountField account={account} />}
      />
    </div>
  );
});

const ConfirmModalWebPos = withStyles(styles)(props => {
  const { pos, classes, account, currencyName, ids } = props;

  return (
    <div className={classes.containerDetail}>
      <DetailRow
        text={<Translate content="POS.resume.web.name" />}
        value={pos[ids.name]}
      />
      <DetailRow
        text={<Translate content="POS.resume.web.site" />}
        value={pos[ids.website]}
      />
      {currencyName && (
        <DetailRow
          text={<Translate content="POS.resume.currency" />}
          value={currencyName}
        />
      )}
      <DetailRow
        text={<Translate content="POS.resume.account" />}
        value={<AccountField account={account} />}
      />
      <DetailRow
        text={<Translate content="POS.resume.urlSuccess" />}
        value={pos[ids.confirmUrl]}
      />
      <DetailRow
        text={<Translate content="POS.resume.urlError" />}
        value={pos[ids.cancelUrl]}
      />
      <DetailRow
        text={<Translate content="POS.resume.urlCallback" />}
        value={pos[ids.callBackUrl]}
      />
    </div>
  );
});

const AccountField = withStyles(styles)(props => {
  const { account, classes } = props;
  const isBitcoin = account && account.purpose;

  const iban_trunked = account && account.iban ? account.iban.slice(-5) : '';
  const xpub_trunked =
    account && account.extended_pubkey ? account.extended_pubkey.slice(-8) : '';
  if (!account) {
    return <div />;
  }
  return (
    <div className={classes.accountRow}>
      <img
        src={
          isBitcoin
            ? require('../../../img/bitcoin/small.png')
            : require('../../../img/bancario/small.png')
        }
        className={classes.avatarAccount}
        alt={'account'}
      />
      {isBitcoin ? account.name : account.bankname}
      {isBitcoin ? '- xpub: ...' + xpub_trunked : '- IBAN: ...' + iban_trunked}
    </div>
  );
});

const libraryComponents = {
  web: ConfirmModalWebPos,
  mobile: ConfirmModalMobilePos,
};

class Confirm extends React.Component {
  render() {
    const {
      id,
      pos,
      open,
      handleClose,
      classes,
      onConfirm,
      submitting,
      type,
      ...otherProps
    } = this.props;
    const DetailComponent = libraryComponents[type];
    return (
      <ConfirmModal
        id={id}
        open={open}
        handleClose={handleClose}
        title={
          type === 'mobile' ? (
            <Translate content="POS.new.confirm.mobile.titles.title" />
          ) : (
            <Translate content="POS.new.confirm.web.titles.title" />
          )
        }
        subtitle={<Translate content="POS.new.confirm.web.titles.subtitle" />}
        onConfirm={onConfirm}
        submitting={submitting}
      >
        <DetailComponent pos={pos} {...otherProps} />
      </ConfirmModal>
    );
  }
}

export default withStyles(styles)(Confirm);
