/**
 * This file contains the BP back end error codes, grouped according to
 * the BP behaviour that is expected when a certain error code is received.
 */

// error codes that trigger a logout
const LOGOUT = ['1004', '1006', '1007', '1012', '3007'];

export default {
  LOGOUT,
};
