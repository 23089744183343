import React from 'react';

import PropTypes from 'prop-types';

import Translate from 'react-translate-component';
import translator from 'counterpart';

import { Col, Row } from 'react-flexbox-grid';

import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import SelectField from '../../common/Forms/SelectField';
import CheckboxField from '../../common/Forms/CheckboxField';
import TextField from '../../common/Forms/TextField';
import PhoneField from '../../common/Forms/PhoneField';
import VatField from '../../common/Forms/VatField';

import EditLogo from '../../common/Forms/EditLogo';
import Collapse from '@material-ui/core/Collapse';
import { AddressField } from '../../common/Forms/AddressField';
import { FormSectionTitle } from '../../common/Forms/FormSectionTitle';
import classnames from 'classnames';

const styles = theme => ({
  sectionTitle: {
    fontSize: '16px',
    fontWeight: theme.typography.weights.normal,
    color: '#444444',
    marginBottom: '10px',
  },
  copyVatParent: {
    position: 'relative',
  },
  checkBoxRow: {
    marginTop: '0px',
    marginBottom: '10px',
  },
  logoRow: {
    marginTop: '50px',
  },
  autoOverflow: {
    overflow: 'inherit',
  },
  excludeVatSelected: {
    position: 'relative',
    top: '2px',
  },
  heightFix: {
    position: 'relative',
    top: '-9px',
  },
  heightSelectedFix: {
    position: 'relative',
    top: '-7px',
  },
});

class BusinessFormGeneric extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    errors: PropTypes.object.isRequired,
    nome_azienda: PropTypes.string,
    nome_azienda_comunicazioni: PropTypes.string,
    nome_azienda_comunicazioni_presente: PropTypes.bool,
    vat: PropTypes.string,
    indirizzo_primario: PropTypes.string,
    indirizzo_secondario: PropTypes.string,
    telefono: PropTypes.string,
    email: PropTypes.string,
    settore: PropTypes.string,
    ruolo: PropTypes.string,
    logo: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    validateImage: PropTypes.func.isRequired,
    toggleCheckbox: PropTypes.func.isRequired,
    positions: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    operationalFields: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
        name: PropTypes.string.isRequired,
      }).isRequired
    ),
  };

  state = {
    indirizzo_secondario_presente: false,
    nome_azienda_comunicazioni_presente: false,
  };

  componentDidMount() {
    if (this.props.initField) {
      this.setState({ ...this.props.initField });
    }
  }
  toggleIndirizzoSecondario = () => {
    this.setState(
      {
        indirizzo_secondario_presente: !this.state
          .indirizzo_secondario_presente,
      },
      () => {
        if (!this.state.indirizzo_secondario_presente) {
          this.props.handleAddressChange('operationalAddress')(null);
          this.props.handleOperationalAddress(null);
        }
      }
    );
  };

  toggleExcludeVat = event => {
    this.props.handleChange({
      target: {
        value: event.target.checked,
        id: this.props.form.ids.form$no_vat_available,
      },
    });
    if (event.target.checked) {
      let ev = {
        target: {
          value: '',
          id: this.props.form.ids.form$vat,
        },
      };
      this.handleChangeVat(ev);
    }
  };

  handleChangeVat = e => {
    this.props.handleChange(e);
  };

  handleSectorChange = e => {
    this.props.handleChange(e);
    if (e.target.value !== 'other') {
      this.props.handleChange({
        target: {
          value: null,
          id: this.props.form.ids.form$operational_field$description,
        },
      });
    }
  };

  handlePhoneChange = number => {
    this.props.handleChange({
      target: {
        id: this.props.form.ids.form$contacts$phone,
        value: number,
      },
    });
  };

  toggleNomeComunicazioni = () => {
    this.setState(
      {
        nome_azienda_comunicazioni_presente: !this.state
          .nome_azienda_comunicazioni_presente,
      },
      () => {
        if (!this.state.nome_azienda_comunicazioni_presente) {
          this.props.handleChange({
            target: {
              id: this.props.form.ids.form$communications_name,
            },
          });
        }
      }
    );
  };

  render() {
    const { errors } = this.props;
    const {
      classes,
      disabled,
      business,
      editMode,
      operationalFields,
    } = this.props;
    const {
      handleChange,
      validateImage,
      handleAddressChange,
      handleChangeGeneric,
      validateField,
    } = this.props;
    const {
      nome_azienda_comunicazioni_presente,
      indirizzo_secondario_presente,
    } = this.state;
    const ids = {
      communicationName: this.props.form.ids.form$communications_name,
      email: this.props.form.ids.form$contacts$email,
      phone: this.props.form.ids.form$contacts$phone,
      logo: this.props.form.ids.form$logo,
      operationalFields: {
        name: this.props.form.ids.form$operational_field$name,
        description: this.props.form.ids.form$operational_field$description,
      },
      legalRepresentative: this.props.form.ids
        .form$gdpr$is_legal_representative,
      vat: editMode ? 'vat' : this.props.form.ids.form$vat,
      no_vat_available: editMode
        ? 'no_vat_available'
        : this.props.form.ids.form$no_vat_available,
      name: editMode ? 'nome' : this.props.form.ids.form$name,
    };
    return (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <TextField
              style={{ marginTop: '10px' }}
              disabled={disabled || editMode}
              label={<Translate content="business.businessName" />}
              placeholder={translator('placeholder.aziendaSrl')}
              error={Boolean(errors[ids.name])}
              helperText={errors[ids.name]}
              value={business[ids.name]}
              id={ids.name}
              name={ids.name}
              onChange={handleChange}
              fullWidth
            />
          </Col>
        </Row>
        <Row className={classes.checkBoxRow}>
          <Col xs={12}>
            <CheckboxField
              disabled={disabled}
              id="nome_azienda_comunicazioni_presente"
              name="nome_azienda_comunicazioni_presente"
              checked={nome_azienda_comunicazioni_presente}
              onChange={this.toggleNomeComunicazioni}
              label={<Translate content="business.differentNameMessage" />}
            />
          </Col>
        </Row>
        <Collapse in={nome_azienda_comunicazioni_presente}>
          <Row>
            <Col xs={12}>
              <Typography variant="caption" style={{ marginBottom: '12px' }}>
                <Translate content="business.hintDifferentName" />
              </Typography>
              <TextField
                disabled={disabled}
                placeholder={translator('placeholder.nomeComunicazioni')}
                error={Boolean(errors[ids.communicationName])}
                helperText={errors[ids.communicationName]}
                value={business[ids.communicationName]}
                id={ids.communicationName}
                name={ids.communicationName}
                onChange={handleChange}
                fullWidth
              />
            </Col>
          </Row>
        </Collapse>
        <Row
          style={{
            marginTop: '20px',
            marginBottom: '15px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Col xs={6}>
            <VatField
              disabled={disabled || editMode || business[ids.no_vat_available]}
              label={<Translate content="business.pivaCf" />}
              placeholder={translator('placeholder.partitaIVA')}
              error={Boolean(errors[ids.vat])}
              helperText={errors[ids.vat]}
              value={business[ids.vat]}
              id={ids.vat}
              name={ids.vat}
              onChange={this.handleChangeVat}
              fullWidth
            />
          </Col>
          <Col
            xs={6}
            className={classnames({
              [classes.excludeVatSelected]: business[ids.no_vat_available],
              [classes.heightFix]:
                Boolean(errors[ids.vat]) && !business[ids.no_vat_available],
              [classes.heightSelectedFix]:
                Boolean(errors[ids.vat]) && business[ids.no_vat_available],
            })}
          >
            <CheckboxField
              disabled={disabled || editMode}
              id={ids.no_vat_available}
              name={ids.no_vat_available}
              checked={
                editMode ? !business[ids.vat] : business[ids.no_vat_available]
              }
              onChange={this.toggleExcludeVat}
              label={<Translate content="business.excludeVat" />}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <AddressField
              disabled={disabled}
              visible
              value={business.form$contacts$legal_address}
              id="form$contacts$legal_addres"
              name="form$contacts$legal_addres"
              handleChange={this.props.handleLegalAddress}
              fullWidth
              label={<Translate content="business.legalAddress" />}
              placeholder={translator('placeholder.indirizzo')}
              error={Boolean(errors.form$contacts$legal_address)}
              helperText={errors.form$contacts$legal_address}
            />
          </Col>
        </Row>
        <Row className={classes.checkBoxRow}>
          <Col xs={12}>
            <CheckboxField
              disabled={disabled}
              id="indirizzo_secondario_presente"
              name="indirizzo_secondario_presente"
              checked={indirizzo_secondario_presente}
              onChange={this.toggleIndirizzoSecondario}
              label={<Translate content="business.actualAddressMessage" />}
            />
          </Col>
        </Row>
        <Collapse
          in={indirizzo_secondario_presente}
          classes={{
            entered: classes.autoOverflow,
          }}
          onExiting={() => {
            handleAddressChange('operationalAddress')('');
            this.props.handleOperationalAddress(null);
          }}
        >
          <Row>
            <Col xs={12}>
              <AddressField
                visible={indirizzo_secondario_presente}
                disabled={disabled}
                value={business.form$contacts$operational_address}
                id="form$contacts$operational_address"
                name="form$contacts$operational_address"
                handleChange={this.props.handleOperationalAddress}
                fullWidth
                label={
                  <Translate
                    style={{ marginBottom: '12px' }}
                    content="business.actualAddress"
                  />
                }
                placeholder={translator('placeholder.indirizzo')}
                error={Boolean(errors.form$contacts$operational_address)}
                helperText={errors.form$contacts$operational_address}
              />
            </Col>
          </Row>
        </Collapse>
        <Row style={{ marginTop: '20px' }}>
          <Col xs={6}>
            <PhoneField
              disabled={disabled}
              error={Boolean(errors[ids.phone]) || this.state.phoneError}
              helperText={errors[ids.phone]}
              defaultValue={business[ids.phone]}
              id={ids.phone}
              name={ids.phone}
              onChange={this.handlePhoneChange}
              label={<Translate content="business.phone" />}
              placeholder={translator('placeholder.telefono')}
              validateField={validateField}
            />
          </Col>
          <Col xs={6}>
            <TextField
              disabled={disabled}
              label={<Translate content="business.email" />}
              placeholder={translator('placeholder.email')}
              error={Boolean(errors[ids.email])}
              helperText={errors[ids.email]}
              value={business[ids.email]}
              id={ids.email}
              name={ids.email}
              onChange={handleChange}
              fullWidth
            />
          </Col>
        </Row>
        <Row
          style={{
            marginTop: '15px',
          }}
        >
          <Col xs={6}>
            <SelectField
              disabled={disabled}
              error={Boolean(errors[ids.operationalFields.name])}
              helperText={errors[ids.operationalFields.name]}
              value={business[ids.operationalFields.name]}
              id={ids.operationalFields.name}
              name={ids.operationalFields.name}
              onChange={this.handleSectorChange}
              fullWidth
              label={translator('business.sector')}
              placeholder={translator('placeholder.settore')}
            >
              {operationalFields.map((settore, idx) => (
                <MenuItem key={idx} value={settore.id}>
                  {settore.name}
                </MenuItem>
              ))}
            </SelectField>
          </Col>
          {business[ids.operationalFields.name] === 'other' && (
            <Col xs={6}>
              <TextField
                label={<Translate content="business.sector_description" />}
                placeholder={translator('placeholder.settore_description')}
                error={Boolean(errors[ids.operationalFields.description])}
                helperText={errors[ids.operationalFields.description]}
                value={business[ids.operationalFields.description]}
                id={ids.operationalFields.description}
                name={ids.operationalFields.description}
                onChange={handleChange}
                fullWidth
              />
            </Col>
          )}
        </Row>
        <Row className={classes.logoRow}>
          <Col xs={12}>
            <FormSectionTitle>
              <Translate content="business.logo.title" />
            </FormSectionTitle>
            <EditLogo
              disabled={disabled}
              error={errors[ids.logo]}
              logo={business[ids.logo]}
              id={ids.logo}
              name={ids.logo}
              edit={editMode}
              onChange={handleChangeGeneric(ids.logo)}
              originalImage={this.props.originalImage}
              validateImage={validateImage}
              maxSize={
                this.props.form.getRules(this.props.form.ids.form$logo)[
                  'max_size'
                ][0]
              }
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(BusinessFormGeneric);
