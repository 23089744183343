import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import classnames from 'classnames';

const styles = theme => ({
  paper: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    ...theme.shadow,

    display: 'flex',
    outlineWidth: '0px !important',
  },
  minDimensions: {
    minWidth: '600px',
    minHeight: '200px',
  },
  backgroundWhite: {
    backgroundColor: theme.palette.common.white,
  },
  backgroundBlueGrey: {
    backgroundColor: theme.palette.primary.bg,
  },
  innerContainer: {
    padding: '45px 80px 50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  title: {
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '10px',
  },
});

class CustomModal extends React.Component {
  render() {
    const {
      classes,
      open,
      handleClose,
      title,
      subtitle,
      children,
      bluegrey,
      fitContent,
      ...otherProps
    } = this.props;

    return (
      <Modal
        open={open}
        onClose={handleClose}
        disableBackdropClick={!handleClose}
        disableEscapeKeyDown={!handleClose}
        role="alert"
        {...otherProps}
      >
        <div
          className={classnames({
            [classes.paper]: true,
            [classes.backgroundWhite]: !bluegrey,
            [classes.backgroundBlueGrey]: bluegrey,
            [classes.minDimensions]: !fitContent,
          })}
        >
          <div className={classes.innerContainer}>
            {title && (
              <Typography
                variant="subheading"
                className={classnames([classes.title, 'montserrat'])}
              >
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography
                variant="caption"
                className="montserrat color7"
                style={{ marginBottom: '30px', width: '70%' }}
              >
                {subtitle}
              </Typography>
            )}
            {children}
          </div>
        </div>
      </Modal>
    );
  }
}

CustomModal.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  bluegrey: PropTypes.bool,
  fitContent: PropTypes.bool,
  id: PropTypes.string,
};

export default withStyles(styles)(CustomModal);
