import React from 'react';

import { observer } from 'mobx-react';

import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Col, Row } from 'react-flexbox-grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Button from '../../common/Button';

import store from '../../../store/index';

import { ROUTE_PARAMETERS, ROUTES } from '../../../constants/routes';

import Translate from 'react-translate-component';

import { BusinessSimplified } from '../../../store/Business';
import { Link } from 'react-router-dom';
import { buildPath } from '../../../utils/routes';

const styles = theme => ({
  azienda: {
    padding: '15px',
    margin: '10px 0',
    ...theme.components.tableRow,
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  avatarTextContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 3,
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
  },
  avatar: {
    width: '50px',
    height: '50px',
    marginRight: '10px',
  },
  piva: {
    fontSize: '13px',
    fontWeight: theme.typography.weights.normal,
    color: '#999999',
  },
  businessName: {
    fontSize: '18px',
    fontWeight: theme.typography.weights.normal,
    color: '#444444',
  },
  myBusinesses: {
    marginTop: '70px',
    fontSize: '16px',
    fontWeight: theme.typography.weights.normal,
    color: '#444444',
  },
  button: {
    marginTop: '6px',
  },
});

const ListaAziende = props => {
  const { classes } = props;
  return (
    <Row>
      <Col xs>
        <Typography className={classes.myBusinesses}>
          <Translate content="profile.myCompanies" />
        </Typography>
        <Aziende {...props} />
      </Col>
    </Row>
  );
};

export default withStyles(styles)(observer(ListaAziende));

class Aziende extends React.Component {
  static propTypes = {
    aziende: PropTypes.object.isRequired, // a mobx observable array is not an array
    history: PropTypes.object.isRequired,
  };

  state = {
    loading: false,
  };

  selectAzienda = uuid => {
    this.setState({ loading: true });
    store.app
      .selectBusiness(uuid, () => {
        this.props.history.push(
          buildPath(ROUTES.PAYMENT_ORDER_LIST, {
            [ROUTE_PARAMETERS.BUSINESS_ID]: uuid,
          })
        );
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { aziende } = this.props;
    const { loading } = this.state;

    if (!aziende || aziende.length === 0) {
      return (
        <Typography variant="caption" style={{ marginTop: '20px' }}>
          <Translate content="profile.noCompanyAvailable" />
        </Typography>
      );
    } else {
      return aziende.map(azienda => (
        <Azienda
          key={azienda.uuid}
          onClick={this.selectAzienda}
          azienda={azienda}
          disabled={loading}
        />
      ));
    }
  }
}

Aziende = withRouter(withStyles(styles)(observer(Aziende)));

const Azienda = withStyles(styles)(
  observer(props => {
    const { classes } = props;

    const { azienda } = props;
    const { disabled } = props;
    const { onClick } = props;

    const id = azienda.uuid;
    const logo =
      azienda.logo || require('../../../img/topbar/logo_default.png');
    const nome = azienda.name;
    const partita_iva = azienda.vat;
    const business_uuid = store.app.business_uuid;
    return (
      <div id={'azienda_' + id} className={classes.azienda}>
        <div className={classes.innerContainer}>
          <div className={classes.avatarTextContainer}>
            <Avatar className={classes.avatar} src={logo} alt="Azienda" />
            <div>
              <Typography variant="body2" className="f-18">
                {nome || <Translate content="business.missingName" />}
              </Typography>
              {partita_iva && (
                <Typography variant="caption">
                  <Translate content="profile.piva" />: {partita_iva}
                </Typography>
              )}
            </div>
          </div>
          <div className={classes.actionContainer}>
            {business_uuid === id && (
              <Button
                customClass={classes.button}
                id={'business_edit_details_' + id}
                component={props => <Link to={'/'} {...props} />}
                disabled={disabled}
                variant="raised"
                color="primary"
                small
              >
                <Translate content="common.goToPayments" />
              </Button>
            )}

            {business_uuid !== id && (
              <Button
                customClass={classes.button}
                id={'business_delegate_' + id}
                onClick={() => onClick(id)}
                disabled={disabled}
                small
                variant="raised"
                color="primary"
              >
                <Translate content="profile.actions.login" />
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  })
);

Azienda.propTypes = {
  azienda: PropTypes.instanceOf(BusinessSimplified).isRequired,
};
