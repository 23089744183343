export default {
  list: {
    title: 'I tuoi POS',
    subtitle:
      'Ogni POS ti permette di ricevere pagamenti in bitcoin da un tuo sito' +
      ' web e-commerce o negozio fisico. Puoi creare tutti i POS che la tua azienda richiede.',
    add: 'Crea POS',
    faq: {
      WHAT: "Che cos'è un POS?",
      HOW: 'Come integrare un POS?',
      MORE_SITES: 'Hai più siti web o negozi fisici?',
      goToDocumentation: 'Vai alla documentazione',
    },
    suggestion: {
      generic: {
        title: 'Crea il tuo primo POS',
        faq: 'Vuoi maggiori informazioni riguardo i POS? ',
      },
      web: {
        hint: 'Accettare bitcoin sul tuo sito web è semplice. Inizia ora.',
        title: 'Crea il tuo primo webPOS',
        subtitle:
          'Accetta pagamenti in bitcoin sul tuo sito o sul tuo e-commerce ',
        faq: 'Vuoi maggiori informazioni sui webPOS? ',
      },
      mobile: {
        hint:
          'Accettare bitcoin nei tuoi punti vendita è semplice. Inizia ora.',
        title: 'Crea il tuo primo mobilePOS',
        subtitle: 'Ricevi pagamenti in bitcoin nei tuoi negozi',
        faq: 'Vuoi maggiori informazioni sui mobilePOS? ',
      },
      faqLink: 'Consulta le nostre FAQ',
    },
  },
  detail: {
    actions: {
      viewPayment: 'Ordini di pagamento',
      edit: 'modifica',
      deactivate: 'disattiva',
      activate: 'attiva',
      associateAccount: 'Associa conto',
      createAccount: 'Crea un conto',
    },
    detail: 'Dettagli',
    posID: 'POS ID',
    clientID: 'Client ID',
    secret: 'Secret',
    currency: 'Valuta',
    exchange: 'Exchange',
    noExchange: 'Nessun exchange associato',
    account: 'Conto ricezione',
    confirmations: 'Conferme richieste',
    device: 'Dispositivo',
    activationDate: 'Data attivazione',
    urls: {
      title: 'Integrazione',
      continue: 'URL attesa di conferma',
      failure: 'URL cancellazione',
      callback: 'URL callback',
    },
    devices: {
      title: 'Associa un dispositivo',
      1: 'Scansiona il QR code a lato usando l’app Chainside Pay Merchant',
      2: 'Oppure inserisci manualmente Client ID e Secret',
      3: '3. Il pos è funzionante! Puoi ricevere pagamenti',
      suggestion:
        'Nel caso tu non possa effettuare una scansione dal tuo smartphone o tablet inserisci manualmente il CLIENT ID e SECRET che trovi più in alto',
    },
    state: {
      notActive: 'Questo POS non è attivo',
      noAccountAssociated: 'Nessun conto associato',
      noAccountsAvailable: 'Nessun conto disponibile',
    },
  },
  new: {
    noAccount: {
      title: 'Ops... non hai ancora creato nessun conto',
      subtitle:
        'Prima di creare un POS per gestire pagamenti è necessario' +
        ' configurare un conto dove si vuole ricevere il denaro',
      buttons: {
        createAccount: 'Crea un conto',
      },
    },
    titles: {
      parent: 'Torna alla lista dei POS',
      title: 'Crea un nuovo POS',
      suggestion:
        'Un POS ti permette di accettare pagamenti in bitcoin. Seleziona la tipologia del negozio e ti guideremo nel processo di creazione. ',
    },
    types: {
      title: 'Per che tipo di negozio vuoi creare questo POS?',
      hint:
        'Seleziona la tipologia del negozio e ti mostreremo il POS più adatto da creare',
      store: {
        name: 'Negozio Fisico',
        title: 'Il POS più adatto a te:',
        detail: 'mobilePOS',
        suggestion: 'Ricevi pagamenti in bitcoin nei tuoi negozi',
      },
      webstore: {
        name: 'Website / e-commerce',
        title: 'Il POS più adatto a te:',
        detail: 'webPOS',
        suggestion:
          'Accetta pagamenti in bitcoin dal tuo sito o dal tuo e-commerce ',
      },
    },
    mobile: {
      titles: {
        title: 'Nuovo mobile POS',
        parent: 'POS',
      },
      1: {
        text: 'Crea il tuo mobilePOS e configuralo in pochi click',
        hint: 'Crea ora',
      },
      2: {
        text:
          'Dopo averlo creato, connetti il tuo mobilePOS con uno smartphone o tablet   ',
        hint: 'Di cosa ho bisogno? ',
      },
      3: {
        text:
          'Accetta pagamenti in bitcoin dai tuoi clienti in pochi secondi  ',
        hint: 'Inizia subito ',
      },
    },
    web: {
      titles: {
        title: 'Crea un webPOS',
        parent: 'Torna alla lista dei POS',
      },
      1: {
        text: 'Crea il tuo webPOS e configuralo in pochi click',
        hint: 'Crea ora ',
      },
      2: {
        text: 'Procedi all’integrazione del webPOS sul tuo sito internet',
        hint: 'Di cosa ho bisogno? ',
      },
      3: {
        text:
          'Monitora comodamente tutti i pagamenti bitcoin in entrata dal tuo sito',
        hint: 'Inizia subito ',
      },
    },
    confirm: {
      web: {
        titles: {
          title: 'Conferma i dati del webPOS',
          subtitle:
            'Conferma i dati inseriti e procederemo alla creazione del POS',
        },
      },
      mobile: {
        titles: {
          title: 'Conferma i dati del mobilePOS',
        },
      },
    },
  },
  deactivate: {
    confirm: {
      web: {
        titles: {
          title: 'Sei sicuro di voler disattivare questo POS?',
          subtitle:
            'Disattivando il POS "%(posName)s" non sarai più in grado di creare ordini di pagamento sul sito "%(website)s"',
        },
      },
      mobile: {
        titles: {
          subtitle:
            'Disattivando il POS "%(posName)s" non sarai più in grado di creare ordini di pagamento sul dispositivo "%(device)s"',
        },
      },
    },

    loading: {
      title: 'Disattivazione in corso...',
    },
    success: {
      message: 'POS disattivato con successo!',
    },
  },
  activate: {
    confirm: {
      web: {
        withAccount: {
          titles: {
            title: 'Attivazione del POS',
            subtitle:
              'Una volta attivato il POS "%(posName)s" potrai procedere all\'integrazione sul tuo sito "%(website)s" e iniziare a ricevere pagamenti bitcoin',
          },
          buttons: {
            confirm: 'Attiva',
          },
        },
        noAssignedAccount: {
          titles: {
            title: 'Conto non assegnato',
            subtitle:
              'Non è possibile attivare il POS senza un conto associato per la ricezione del denaro. Assegna ora un conto per poter attivare il POS',
          },
          buttons: {
            confirm: 'Assegna un conto',
          },
        },
        noAccountsCreated: {
          titles: {
            title: 'Nessun conto disponibile',
            subtitle:
              "Non è possibile attivare il POS senza un conto associato per la ricezione del denaro. Crea ora un Conto e associalo al POS per proseguire con l'attivazione",
          },
          buttons: {
            confirm: 'Crea un conto',
          },
        },
      },
    },
    loading: {
      title: 'Attivazione in corso...',
    },
    success: {
      message: 'POS attivato con successo!',
    },
  },
  edit: {
    parent: 'Torna alla lista dei POS',
    web: {
      title: 'Modifica webPOS',
    },
    mobile: {
      title: 'Modifica mobilePOS',
    },
    generateSecret: 'Genera nuove credenziali',
    state: {
      notActive:
        'Questo POS non è attivo. Per utilizzare questo POS con la configurazione prescelta ricorda di attivare il POS dopo aver salvato le modifiche.',
      noDepositAccount: 'Attenzione! Nessun conto associato',
    },
    toggleWebPosWithoutDeposit:
      'Per ora preferisco non associare questo POS ad alcun conto. Comprendo che con questa scelta il POS non può essere attivato.',
    noAccountSelectedError:
      'Nessun conto selezionato. Per procedere seleziona un conto dalla lista oppure spunta la casella qui sotto.',
  },
  currency: {
    title: 'In quale valuta stai vendendo?',
    subtitle: 'Scegli la valuta usata sul tuo sito',
  },
  exchange: {
    title: 'Scegli la fonte del tasso di cambio ',
    suggestion:
      'Un exchange è un servizio che si occupa di convertire il prezzo da' +
      ' bitcoin in valuta tradizionale. ',
  },
  account: 'Su quale conto vuoi ricevere i pagamenti?',
  newAccount: 'Non trovi il conto dove vorresti ricevere il denaro? %(link)s',
  newAccountEmptyAccounts:
    'Non ci sono conti da poter associare a questo POS. Crea subito un %(link)s su cui ricevere il denaro.',
  newAccountLiteral: 'Creane uno',
  noDepositNewAccount:
    'Questo POS non è ancora associato a nessun conto. %(link)s' +
    ' oppure seleziona subito quello su cui ricevere il denaro fra quelli' +
    ' disponibili:',
  mobile: {
    where: {
      title: 'Dove intendi usare questo mobilePOS?',
      subtitle:
        'Specifica il nome e l’indirizzo del tuo negozio dove vuoi' +
        ' usare il POS',
    },
    integration: {
      title: 'Le credenziali del tuo POS',
      suggestion:
        'Questi dati permettono a ChainsidePay di identificare il' +
        ' tuo POS. Tienili al sicuro.',
      more_info: 'Maggiori informazioni',
    },
    name: 'Nome riconoscibile del punto vendita',
    address: 'Indirizzo del Punto vendita',
    placeholderMap: 'Inserire indirizzo per visualizzare la mappa',
    disableAddress: 'Questo POS non ha un indirizzo fisso',
    currency: 'Seleziona la valuta',
    generateSecretModal: {
      title: 'Attenzione: Il pos è attivo',
      subtitle:
        "Non è possibile generare nuove credenziali. Disattivare il POS prima di procedere all'operazione",
    },
    transaction: {
      title: 'Quanto sicure e veloci vuoi che siano le transazioni?',
      suggestion:
        'Per vendita di merce di valore ingente si consiglia di impostare almeno una conferma. Negli altri casi può anche essere accettabile lasciare zero conferme',
      counter: {
        top: 'Considera un pagamento completato dopo',
        bottom: 'conferme dalla rete bitcoin',
      },

      confirmations: {
        moreSpeed: 'Aumenta Velocità',
        moreSecurity: 'Aumenta Sicurezza',
        0: {
          title: '0 conferme',
          text: 'Livello di sicurezza: Basso',
          text2: ' Velocità massima',
        },
        1: {
          title: '1 conferma',
          text: 'Livello di sicurezza: Medio ',
          text2: ' Velocità alta (circa 10 minuti)',
        },
        3: {
          title: '3 conferme',
          text: 'Livello di sicurezza: Alto',
          text2: ' Velocità media (circa 30 minuti)',
        },
        6: {
          title: '6 conferme',
          text: 'Livello di sicurezza: Massimo',
          text2: ' Velocità bassa (circa 60 minuti)',
        },
      },
    },
    urls: {
      title: "Quali sono gli URL predefiniti per l'integrazione?",
      subtitle:
        'Questi URL sono il punto di incontro tra ChainsidePay e il' +
        ' tuo sistema, e saranno assegnati di default a tutti gli ordini di' +
        ' pagamento creati da questo POS. Alla creazione di ogni ordine' +
        ' potrai comunque specificare degli URL diversi.',
      continue: 'URL di destinazione per attesa di conferma',
      failure: 'URL di destinazione in caso di cancellazione',
      callback: 'URL dove saranno inviate le callback',
      more_info: "Approfondisci nell'area sviluppatori",
    },
  },
  web: {
    where: {
      title: 'Dove intendi usare questo  webPOS?',
      subtitle:
        'Specifica il nome e l’url del tuo sito web nel quale vuoi integrare il POS',
    },
    integration: {
      title: 'Le credenziali del tuo POS',
      suggestion:
        'Questi dati permettono a ChainsidePay di identificare il' +
        ' tuo webPOS. Tienili al sicuro.',
      more_info: 'Maggiori informazioni',
    },
    name: 'Nome del sito web',
    site: 'Indirizzo del sito web',
    currency: 'Seleziona la valuta',
    transaction: {
      title: 'Quanto sicure e veloci vuoi che siano le transazioni?',
      suggestion:
        'Minore è il numero di conferme richieste per una transazione' +
        ' bitcoin, più velocemente sarà confermato il tuo ordine. Ricorda' +
        ' che un numero troppo basso di conferme può esporre a' +
        ' rischi di sicurezza.',
      counter: {
        top: 'Considera un pagamento completato dopo',
        bottom: 'conferme dalla rete bitcoin',
      },

      confirmations: {
        moreSpeed: 'Aumenta Velocità',
        moreSecurity: 'Aumenta Sicurezza',
        0: {
          title: '0 conferme',
          text: 'Livello di sicurezza: Basso',
          text2: ' Velocità massima',
        },
        1: {
          title: '1 conferma',
          text: 'Livello di sicurezza: Medio ',
          text2: ' Velocità alta (circa 10 minuti)',
        },
        3: {
          title: '3 conferme',
          text: 'Livello di sicurezza: Alto',
          text2: ' Velocità media (circa 30 minuti)',
        },
        6: {
          title: '6 conferme',
          text: 'Livello di sicurezza: Massimo',
          text2: ' Velocità bassa (circa 60 minuti)',
        },
      },
    },
    urls: {
      title: "Quali sono gli URL predefiniti per l'integrazione?",
      subtitle:
        'Questi URL sono il punto di incontro tra ChainsidePay e il' +
        ' tuo sistema, e saranno assegnati di default a tutti gli ordini di' +
        ' pagamento creati da questo POS. Alla creazione di ogni ordine' +
        ' potrai comunque specificare degli URL diversi.',
      continue: 'URL di destinazione per attesa di conferma',
      failure: 'URL di destinazione in caso di cancellazione',
      callback: 'URL dove saranno inviate le callback',
      more_info: "Approfondisci nell'area sviluppatori",
    },
  },
  filter: {
    all: 'Tutti',
    web: 'webPOS',
    mobile: 'mobilePOS',
  },
  confirmation: {
    0: '0 conferme (livello di sicurezza basso, velocità massima)',
    1: '1 conferma (livello di sicurezza medio, velocità alta)',
    3: '3 conferme (livello di sicurezza alto, velocità media)',
    6: '6 conferme (livello di sicurezza massimo, velocità bassa)',
  },
  resume: {
    web: {
      name: 'Nome del sito',
      site: 'URL del sito',
    },
    mobile: {
      name: 'Nome del Punto Vendita',
      address: 'Indirizzo del Punto Vendita',
      missingAddress: 'Non specificato',
    },
    currency: 'Valuta',
    account: 'Conto di ricezione',
    exchange: 'Bitcoin Exchange',
    confirm: 'Numero di conferme',
    urlSuccess: 'URL Successo',
    urlError: 'URL Annullamento',
    urlCallback: 'URL Callback',
  },
  confirmPage: {
    web: {
      title: 'Il webPOS "%(posName)s" è stato creato con successo!',
      subtitle:
        'Procedi all’integrazione sul tuo sito web. Ecco i dati e la documentazione di cui avrai bisogno:',
      faq: {
        0: 'Come integrare un webPOS sul mio sito web?',
        1: "SDK per l'integrazione del webPOS",
      },
    },
    mobile: {
      title: 'Il mobilePOS "%(posName)s" è stato creato con successo!',
      subtitle:
        'Ora che il mobilePOS è stato creato, connettilo ad un dispositivo:',
      merchant:
        'Scansiona il QR code a lato usando l’app ChainsidePay Merchant disponibile su ',
      manual: 'Oppure inserisci manualmente Client ID e Secret',
    },
    integrationTitle: 'Le credenziali del tuo POS',
    associationTitle: 'Associa un dispositivo',
  },
};
