import * as React from 'react';
import Observer from '@researchgate/react-intersection-observer';
import Translate from 'react-translate-component';
import { LoadingMorePayments } from './LoadingMorePayments';

interface LoadMorePaymentsProps {
  onClick: () => void;
  loadingMore?: boolean;
  hasMorePages?: boolean;
}

export class LoadMorePayments extends React.Component<LoadMorePaymentsProps> {
  onChange = (event: IntersectionObserverEntry) => {
    if (event.isIntersecting) {
      this.props.onClick();
    }
  };

  render() {
    const { loadingMore, hasMorePages } = this.props;

    return (
      <Observer
        disabled={loadingMore || !hasMorePages}
        onChange={this.onChange}
      >
        {loadingMore ? (
          <LoadingMorePayments />
        ) : (
          <div
            style={{
              marginTop: '14px',
              fontSize: '14px',
              fontWeight: 500,
              color: '#777777',
              textAlign: 'center',
            }}
          >
            {hasMorePages ? (
              '...'
            ) : (
              <Translate content="payment.list.no_more" />
            )}
          </div>
        )}
      </Observer>
    );
  }
}
