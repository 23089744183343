import React from 'react';
import { withStyles } from '@material-ui/core';
import Translate from 'react-translate-component';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { JSONPretty } from './JSONPretty';
import Badge from '../../../common/Badge';
import { ActionLink } from '../../../common/Links';
import STATUS_LIBRARY from 'http-status';

const styles = theme => ({
  rowResponse: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: '30px',
  },
});
export const PayloadSection = withStyles(styles)(
  class extends React.Component {
    static propTypes = {
      request: PropTypes.string.isRequired,
      response: PropTypes.string.isRequired,
      isOpen: PropTypes.bool,
    };
    constructor(props) {
      super(props);
      this.state = {
        open: props.isOpen,
      };
    }

    viewPayload = () => {
      this.setState({ open: true });
    };
    render() {
      const { classes, request, response, httpStatus } = this.props;
      const { open } = this.state;
      let badgeStatus = 'warning';
      if (!httpStatus) {
        badgeStatus = 'grey';
      } else if (httpStatus >= 200 && httpStatus < 299) {
        badgeStatus = 'success';
      }

      if (!open) {
        return (
          <ActionLink style={{ marginTop: '20px' }} onClick={this.viewPayload}>
            <Translate content="payment.testing.seeRequests" />
          </ActionLink>
        );
      }
      return (
        <React.Fragment>
          <Typography
            variant="body1"
            style={{ alignSelf: 'flex-start', marginTop: '20px' }}
          >
            <Translate content="payment.testing.success.requestSend" />
          </Typography>
          <JSONPretty
            id="request"
            style={{ marginTop: '10px' }}
            body={request}
          />
          <div className={classes.rowResponse}>
            <Typography variant="body1">
              <Translate content="payment.testing.success.response" />
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {httpStatus && (
                <Typography variant="body1" style={{ marginRight: '5px' }}>
                  <Translate content="payment.testing.success.httpCode" />
                </Typography>
              )}
              <Badge type={badgeStatus}>
                {httpStatus ? (
                  <span>
                    {httpStatus +
                      (STATUS_LIBRARY[httpStatus] &&
                        ' ' + STATUS_LIBRARY[httpStatus])}
                  </span>
                ) : (
                  <Translate content="common.nothingF" />
                )}
              </Badge>
            </div>
          </div>
          {response && (
            <JSONPretty
              id="response"
              body={response}
              style={{ marginTop: '10px' }}
            />
          )}
        </React.Fragment>
      );
    }
  }
);
