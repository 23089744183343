const getOrderField = (map, key) => {
  //Return a higher value for not found order map
  return map[key] && map[key].order ? map[key].order : 10000;
};

export const sortByKey = (arr, key, dir) => {
  return dir === 'desc'
    ? arr.sort((a, b) => (b[key] < a[key] ? -1 : 1))
    : arr.sort((a, b) => (a[key] < b[key] ? -1 : 1));
};

export const sortDate = (arr, key, dir) => {
  return dir === 'desc'
    ? arr.sort((a, b) => (b[key].isBefore(a[key]) ? -1 : 1))
    : arr.sort((a, b) => (a[key].isBefore(b[key]) ? -1 : 1));
};

export const sortStatus = (arr, key, dir, map) => {
  return dir === 'desc'
    ? arr.sort((a, b) =>
        getOrderField(map, b[key]) < getOrderField(map, a[key]) ? -1 : 1
      )
    : arr.sort((a, b) =>
        getOrderField(map, a[key]) < getOrderField(map, b[key]) ? -1 : 1
      );
};
