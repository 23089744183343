import React from 'react';
import './styles/grid.css';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import store from '../../../store';

const styles = theme => ({});
class Grid extends React.Component {
  static propTypes = {
    children: PropTypes.arrayOf(
      PropTypes.shape({
        preview: PropTypes.element,
        detail: PropTypes.element,
      })
    ),
  };

  state = {
    selected: null,
  };

  onClickPreview = id => {
    if (this.state.selected === id) {
      this.setState({ selected: null });
    } else {
      this.setState({ selected: id });
    }
  };
  render() {
    const { children } = this.props;
    const isSandboxMode = store.app.sandboxMode;
    const gridList =
      children &&
      children.map((item, k) => {
        return (
          <div
            key={k}
            className={classnames({
              image__cell: true,
              'is-collapsed': this.state.selected !== item.id,
              'is-expanded': this.state.selected === item.id,
              isSandboxMode: isSandboxMode,
            })}
          >
            <div
              id={item.id}
              className="image--basic"
              onClick={() => {
                this.onClickPreview(item.id);
              }}
              aria-selected={this.state.selected === item.id}
            >
              {React.cloneElement(item.preview, {
                selected: this.state.selected === item.id,
              })}
            </div>
            <div className="arrow--up" />
            <div className="image--expand" id={item.id + '_detail'}>
              {item.detail}
            </div>
          </div>
        );
      });
    return <div className="image-grid">{gridList}</div>;
  }
}

export default withStyles(styles)(Grid);
