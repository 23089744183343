import { withStyles } from '@material-ui/core';
import React from 'react';
import Loader from '../../../../common/Loader';
import Typography from '@material-ui/core/Typography/Typography';
import classnames from 'classnames';
import Translate from 'react-translate-component';
import { modalStyles } from './style';

export const ChargingModal = withStyles(modalStyles)(props => {
  const { classes, callback_name } = props;
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Loader />
      <Typography
        variant="body2"
        className={classnames([classes.callbackLoading, 'f-22 montserrat'])}
      >
        <Translate
          content="payment.testing.modalLoading"
          with={{ callback_name: callback_name }}
        />
      </Typography>
    </div>
  );
});
