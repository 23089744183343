import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core';
import { styles } from './styles';
import { buildPath } from '../../../utils/routes';
import { ROUTE_PARAMETERS, ROUTES } from '../../../constants/routes';
import { Message } from './Messages';
import translator from 'counterpart';
import Translate from 'react-translate-component';
import Button from '../../common/Button';
import * as React from 'react';

export const EmptyListAccount = withRouter(
  withStyles(styles)(props => {
    const { classes, removeFilter } = props;
    const goBack = () => {
      props.history.push(
        buildPath(ROUTES.ACCOUNT_LIST, {
          [ROUTE_PARAMETERS.BUSINESS_ID]: props.businessId,
          [ROUTE_PARAMETERS.ACCOUNT_TYPE]: props.idFilter,
        })
      );
    };
    return (
      <div className={classes.emptyListFiltered}>
        <Message
          title={translator('payment.list.messages.other_filter.account.title')}
        />
        <Button
          customClass={classes.emptyListFilteredButton}
          variant="raised"
          color="primary"
          onClick={removeFilter}
        >
          <Translate content="payment.list.messages.other_filter.viewAll" />
        </Button>
        <Button
          customClass={classes.emptyListFilteredButton}
          variant="raised"
          color="primary"
          onClick={goBack}
        >
          <Translate content="payment.list.messages.other_filter.account.goBack" />
        </Button>
      </div>
    );
  })
);
