import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ActivateModal } from '../../common/Confirm';
import Translate from 'react-translate-component';

const styles = theme => ({
  innerContainer: {
    textAlign: 'center',
  },
});

class Activate extends React.Component {
  render() {
    const {
      hasAccounts,
      pos,
      open,
      handleClose,
      classes,
      onConfirm,
      submitting,
      ...otherProps
    } = this.props;

    const mustAssignAccount = !pos.deposit_account && hasAccounts;
    const mustCreateAccount = !pos.deposit_account && !hasAccounts;
    let title = 'POS.activate.confirm.web.withAccount.titles.title';
    let subtitle = 'POS.activate.confirm.web.withAccount.titles.subtitle';
    let buttonText = 'POS.activate.confirm.web.withAccount.buttons.confirm';

    if (mustAssignAccount) {
      title = 'POS.activate.confirm.web.noAssignedAccount.titles.title';
      subtitle = 'POS.activate.confirm.web.noAssignedAccount.titles.subtitle';
      buttonText = 'POS.activate.confirm.web.noAssignedAccount.buttons.confirm';
    } else if (mustCreateAccount) {
      title = 'POS.activate.confirm.web.noAccountsCreated.titles.title';
      subtitle = 'POS.activate.confirm.web.noAccountsCreated.titles.subtitle';
      buttonText = 'POS.activate.confirm.web.noAccountsCreated.buttons.confirm';
    }

    return (
      <ActivateModal
        {...otherProps}
        open={open}
        handleClose={handleClose}
        title={<Translate content={title} />}
        subtitle={
          <Translate
            content={subtitle}
            with={{ posName: pos.name, website: pos.website }}
          />
        }
        confirmButtonText={<Translate content={buttonText} />}
        onConfirm={onConfirm}
        submitting={submitting}
      />
    );
  }
}

export default withStyles(styles)(Activate);
