import React from 'react';
import PropTypes from 'prop-types';
import store from '../../../store/index';
import { ROUTES } from '../../../constants/routes';
import { ConditionalRoute } from './ConditionalRoute';

const AuthenticatedRoute = props => {
  const isAuthenticated = store.app.isLoggedIn;
  return (
    <ConditionalRoute
      fallbackRoute={ROUTES.LOGIN}
      condition={isAuthenticated}
      {...props}
    />
  );
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export { AuthenticatedRoute };
