import store from '../store';

export default {
  getList: (page, orderBy, order, onSuccess, onError) => {
    const getAll = store.app.factory.make('GetPaymentOrdersAction');

    getAll.queryParams = {
      page: page,
      sort_by: orderBy,
      sort_order: order,
      page_size: 20,
    };

    getAll
      .run()
      .then(onSuccess)
      .catch(onError);
  },

  get: (payment_uuid, onSuccess, onError) => {
    const getPayment = store.app.factory.make('GetPaymentOrderAction');
    getPayment.routeParams = { payment_order_uuid: payment_uuid };

    getPayment
      .run()
      .then(onSuccess)
      .catch(onError);
  },
  getCallback: (payment_uuid, onSuccess, onError) => {
    const getCallbacks = store.app.factory.make('GetPaymentCallbacksAction');

    getCallbacks.routeParams = { payment_order_uuid: payment_uuid };

    getCallbacks
      .run()
      .then(onSuccess)
      .catch(onError);
  },
  paymentOrderStatusUpdate: (
    payment_uuid,
    callbackSelected,
    onSuccess,
    onError
  ) => {
    const paymentOrderStatusUpdate = store.app.factory.make(
      'PaymentOrderStatusUpdateAction'
    );

    paymentOrderStatusUpdate.requestBody = paymentOrderStatusUpdate.constructor.requestClass.fromJson(
      {
        callback: callbackSelected,
      }
    );

    paymentOrderStatusUpdate.routeParams = {
      payment_order_uuid: payment_uuid,
    };

    paymentOrderStatusUpdate
      .run()
      .then(onSuccess)
      .catch(onError);
  },
  reset: (payment_uuid, onSuccess, onError) => {
    const getCallbacks = store.app.factory.make('ResetTestPaymentAction');

    getCallbacks.routeParams = { payment_order_uuid: payment_uuid };

    getCallbacks
      .run()
      .then(onSuccess)
      .catch(onError);
  },
};
