import React from 'react';
import PropTypes from 'prop-types';
import Translate from 'react-translate-component';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-flexbox-grid';
import Button from '../../common/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {
  ROUTE_FILTERS,
  ROUTE_PARAMETERS,
  ROUTES,
} from '../../../constants/routes';
import store from '../../../store/index';
import GoToDetail from '../../common/GoToDetail';
import classnames from 'classnames';
import { buildPath } from '../../../utils/routes';
import AccountsApi from '../../../api/Accounts';
import RemoveAccountModal from '../modals/RemoveAccountModal';
import CannotRemoveAccountModal from '../modals/CannotRemoveAccountModal';
import { LoadingModal } from '../../common/LoadingModal';

class ListDetail extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    avatar: PropTypes.element.isRequired,
    name: PropTypes.string.isRequired,
    account_uuid: PropTypes.string.isRequired,
    business_uuid: PropTypes.string.isRequired,
    account_type: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    classes: PropTypes.object.isRequired,
    poses: PropTypes.array,
    onAccountDeleted: PropTypes.func,
  };

  state = {
    loadingPos: true,
    poses: null,
    removeAccountModalOpened: false,
    loadingModalOpened: false,
    cannotRemoveAccountModalOpened: false,
  };

  componentDidMount() {
    AccountsApi.getAccountPoses(
      this.props.account_uuid,
      result => {
        this.setState({
          loadingPos: false,
          poses: result.poses,
        });
      },
      e => {
        this.setState({
          loadingPos: true,
        });
      }
    );
  }

  handleRemoveButtonClick = () => {
    let activePoses = this.state.poses.filter(pos => pos.active);

    if (!activePoses.length) {
      this.setState({
        removeAccountModalOpened: true,
      });
    } else {
      this.setState({
        cannotRemoveAccountModalOpened: true,
      });
    }
  };

  handleAccountModalClose = () => {
    this.setState({
      removeAccountModalOpened: false,
    });
  };

  handleAccountModalConfirm = () => {
    this.setState({
      removeAccountModalOpened: false,
      loadingModalOpened: true,
    });

    this.deleteDepositAccount(
      this.props.business_uuid,
      this.props.account_uuid,
      this.props.account_type,
      this.onAccountDeleted,
      this.onAccountDeleteError
    );
  };

  deleteDepositAccount = () => {
    AccountsApi.deleteBankAccount(
      this.props.business_uuid,
      this.props.account_uuid,
      this.onAccountDeleted,
      this.onAccountDeleteError
    );
  };

  onAccountDeleted = () => {
    this.setState({
      loadingModalOpened: false,
    });

    if (this.props.onAccountDeleted)
      this.props.onAccountDeleted(this.props.index, this.props.account_type);
  };

  onAccountDeleteError = error => {
    this.setState({
      loadingModalOpened: false,
    });
  };

  handleCannotRemoveAccountModalClose = () => {
    this.setState({
      cannotRemoveAccountModalOpened: false,
    });
  };

  render() {
    const { classes } = this.props;

    const { avatar, children } = this.props;
    const { name, account_uuid, account_type, business_uuid } = this.props;

    const {
      poses,
      removeAccountModalOpened,
      loadingModalOpened,
      cannotRemoveAccountModalOpened,
    } = this.state;

    const activePoses = poses ? poses.filter(pos => pos.active) : [];

    const isSandboxMode = store.app.sandboxMode;
    return (
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.row}>
            <div className={classes.avatarAndName}>
              {avatar}
              <div>
                <Typography variant="title" className="w600">
                  {name}
                </Typography>
              </div>
            </div>
            <div className={classes.actions}>
              <Button
                id="visualizzaPagamenti"
                small
                customClass={classes.button}
                variant="outlined"
                color="primary"
                component={props => (
                  <Link
                    to={buildPath(ROUTES.PAYMENT_ORDER_LIST_WITH_FILTER, {
                      [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
                      [ROUTE_PARAMETERS.PAYMENT_ORDER_FILTER_TYPE]:
                        ROUTE_FILTERS.PAYMENT_ORDER[account_type],
                      [ROUTE_PARAMETERS.PAYMENT_ORDER_FILTER_ID]: account_uuid,
                    })}
                    {...props}
                  />
                )}
              >
                <Translate content="account.detail.actions.viewPayment" />
              </Button>
              {!isSandboxMode && (
                <Button
                  id="modificabank"
                  customClass={classes.button}
                  small
                  variant="outlined"
                  color="primary"
                  component={props => (
                    <Link
                      to={buildPath(ROUTES.ACCOUNT_EDIT, {
                        [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
                        [ROUTE_PARAMETERS.ACCOUNT_ID]: account_uuid,
                        [ROUTE_PARAMETERS.ACCOUNT_TYPE]: account_type,
                      })}
                      {...props}
                    />
                  )}
                >
                  <Translate content="account.detail.actions.edit" />
                </Button>
              )}
              {!isSandboxMode && (
                <Button
                  id="rimuoviConto"
                  small
                  customClass={classes.button}
                  variant="outlined"
                  color="primary"
                  onClick={this.handleRemoveButtonClick}
                >
                  <Translate content="account.detail.actions.remove" />
                </Button>
              )}
            </div>
          </div>
          <Divider className={classes.divider} />
          <Row>
            <Col xs={12} style={{ padding: '5px 10px' }}>
              <Typography
                variant="subheading"
                gutterBottom
                className={classnames([
                  'w600',
                  classes.detailRow,
                  classes.detailTitle,
                ])}
              >
                <Translate content="account.detail.title" />
              </Typography>
              {children}
              <div className={classes.spacingDiv} />
              <React.Fragment>
                <Typography
                  variant="subheading"
                  gutterBottom
                  className={classnames([
                    'w600',
                    classes.detailRow,
                    classes.detailTitle,
                  ])}
                >
                  <Translate content="account.detail.poses" />
                </Typography>

                <div className={classes.posContainer}>
                  {(!poses || poses.length === 0) && (
                    <Typography variant="body2" gutterBottom>
                      <Translate content="account.detail.emptyPoses" />
                    </Typography>
                  )}
                  {poses &&
                    poses.map((pos, k) => (
                      <GoToDetail
                        customClasses={classes.posItemClass}
                        name={pos.name}
                        id={pos.uuid}
                        type={pos.type}
                        key={k}
                        active={pos.active}
                      />
                    ))}
                </div>
              </React.Fragment>
            </Col>
          </Row>
        </div>
        <RemoveAccountModal
          id={'removeAccountModal'}
          accountName={name}
          accountType={account_type}
          open={removeAccountModalOpened}
          handleClose={this.handleAccountModalClose}
          onConfirm={this.handleAccountModalConfirm}
        />
        <LoadingModal
          id={'loadingRemoveAccountModal'}
          open={loadingModalOpened}
          title={<Translate content={'account.delete.loading.title'} />}
        />
        <CannotRemoveAccountModal
          id={'cannotRemoveAccountModal'}
          poses={activePoses}
          open={cannotRemoveAccountModalOpened}
          handleClose={this.handleCannotRemoveAccountModalClose}
        />
      </div>
    );
  }
}

export { ListDetail };
