import React from 'react';
import Translate from 'react-translate-component';
import { withStyles } from '@material-ui/core/styles';
import PageContainer from '../common/PageContainer';
import PageTitle from '../common/PageTitle';
import Typography from '@material-ui/core/Typography';
import { ROUTES } from '../../constants/routes';
import Button from '../common/Button';
import CheckboxField, { GDPRCheckbox } from '../common/Forms/CheckboxField';
import { withFormsValidation } from '../common/Forms/FormsCommon';
import Loader from '../common/Loader';
import { withRouter } from 'react-router-dom';
import translate from 'counterpart';
import store from '../../store';
import GDPRApi from '../../api/GDPR';
import { withGenericErrorHandling } from '../common/ErrorHandling';
import { WebsiteLink } from '../common/WebsiteLink';

class GestioneConsensi extends React.Component {
  state = {
    sending: false,
    loading: false,
  };

  componentDidMount() {
    GDPRApi.getPersonGdpr(result => {
      this.setState({
        privacy_policy: result.privacy_policy.uuid,
        data_sharing: result.data_sharing.uuid,
      });
    }, this.props.onError);
    this.props.initObject({
      [this.props.form.ids.uri$person_uuid]: store.app.profile.uuid,
      [this.props.form.ids.form$gdpr$data_sharing]:
        store.app.profile.consensi.dataSharing,
      [this.props.form.ids.form$gdpr$updates_notifications]:
        store.app.profile.consensi.updatesNotifications,
    });
  }

  save = event => {
    event.preventDefault();
    this.setState({
      sending: true,
    });
    this.props.send(
      () => {
        store.app.snackBar.open(translate('messages.generic.ok'), 'success');
        this.props.history.push(ROUTES.PROFILE_VIEW);
      },
      null,
      () => {
        this.setState({
          sending: false,
        });
      }
    );
  };

  render() {
    const { classes } = this.props;
    const { toggleCheckbox, object, errors } = this.props;
    const { loading, sending } = this.state;
    if (loading) {
      return (
        <PageContainer main white style={{ paddingTop: '40px' }}>
          <Loader />
        </PageContainer>
      );
    }

    const ids = {
      firstConsenso: this.props.form.ids.form$gdpr$data_sharing,
      secondConsenso: this.props.form.ids.form$gdpr$updates_notifications,
    };
    return (
      <PageContainer main white style={{ paddingTop: '40px' }}>
        <PageTitle
          title={<Translate content="gdpr.gestioneConsensi.title" />}
          parent={{
            text: <Translate content="profile.titles.goBack" />,
            url: ROUTES.PROFILE_VIEW,
          }}
        />
        <Typography variant="body1">
          <Translate content="gdpr.gestioneConsensi.text1" />
          <WebsiteLink withoutIcon to="privacy_policy">
            <Translate content="common.normativaDati" />
          </WebsiteLink>
          <Translate content="gdpr.gestioneConsensi.text2" />
        </Typography>
        <form onSubmit={this.save}>
          <div className={classes.consensi}>
            <GDPRCheckbox
              uuid={this.state.data_sharing}
              id="consenso_1"
              checked={object[ids.firstConsenso]}
              onChange={this.props.handleChangeGeneric(ids.firstConsenso)}
              error={Boolean(errors[ids.firstConsenso])}
              helperText={errors[ids.firstConsenso]}
              className={classes.marginBottom}
              label={
                <Translate
                  className="f-13"
                  content="gdpr.gestioneConsensi.consensi.first"
                />
              }
            />
            <CheckboxField
              id="consenso_2"
              checked={object[ids.secondConsenso]}
              onChange={() => toggleCheckbox(ids.secondConsenso)}
              error={Boolean(errors[ids.secondConsenso])}
              helperText={errors[ids.secondConsenso]}
              className={classes.marginBottom}
              label={
                <Translate
                  className="f-13"
                  content="gdpr.gestioneConsensi.consensi.second"
                />
              }
            />
          </div>
          <div className={classes.actions}>
            <Button
              variant="raised"
              color="primary"
              customClass={classes.saveAction}
              fullWidth
              type="submit"
              onClick={this.save}
              loading={sending}
            >
              <Translate content="common.save" />
            </Button>
            <Button
              variant="outlined"
              color="primary"
              customClass={classes.goBackAction}
              fullWidth
              onClick={() => {
                this.props.history.goBack();
              }}
              loading={sending}
            >
              <Translate content="common.abort" />
            </Button>
          </div>
        </form>
      </PageContainer>
    );
  }
}

const styles = theme => ({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '50%',
    marginTop: '70px',
  },
  saveAction: {
    flex: 2,
    marginRight: '20px',
  },
  goBackAction: {
    flex: 1,
  },
  consensi: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginTop: '60px',
  },
  marginBottom: {
    marginBottom: '20px',
  },
});

//TODO implements
export default withGenericErrorHandling(
  withFormsValidation('UpdatePersonForm')(
    withRouter(withStyles(styles)(GestioneConsensi))
  )
);
