export default {
  privacy: {
    title: 'Privacy',
    subtitle:
      'View and download your personal information or review how and with whom you want to share it.',
    action: 'Consent management',
  },
  account: {
    title: 'Account',
    subtitle:
      'If you are no longer interested in ChainsidePay services, find out how to permanently delete your account and your data.',
    action: 'Delete Account',
  },
  gestioneConsensi: {
    title: 'Consent Management',
    text1: 'Ours ',
    text2:
      ' contains more information on how we collect and use your information, how it is shared and how much we keep it. It also explains your rights and how you can exercise them and how we operate and transfer your information to the internal of our global services. ',
    consensi: {
      first: 'I agree that my data will be shared with third parties',
      second: 'I want to stay updated on news, offers, and events',
    },
  },
  deleteAccount: {
    title: 'Delete Account',
    subtitle:
      'This procedure will permanently delete your ChainsidePay account. You will no longer be able to reactivate the account or retrieve the information it contains.',
    pwdSection: 'Confirm the request by entering your password',
    action: 'Delete Account',
    contactUs: 'If you still want to permanently delete your account, ',
  },
  privacyPolicy: {
    title: 'Terms and conditions of the service',
    subtitle:
      'It is necessary to read and agree to the terms of the service to proceed. If you have questions about it, ',
  },
};
