/**
 * System wide constants
 */

const ENABLE_JAVASCRIPT_URL = {
  it: 'https://www.wikihow.it/Abilitare-JavaScript-sul-tuo-Computer',
  en: 'https://www.wikihow.com/Enable-JavaScript',
};

const MOBILE_POS_LINKS = {
  playStore:
    'https://play.google.com/store/apps/details?id=net.chainside.mobilepos',
};

const DOMAIN_MAIL_TIER_MOBILE_POS = ['moveax.it', 'chainside.net'];

const WEBSITE_BASE_URL = 'https://www.chainside.net';

export {
  ENABLE_JAVASCRIPT_URL,
  WEBSITE_BASE_URL,
  MOBILE_POS_LINKS,
  DOMAIN_MAIL_TIER_MOBILE_POS,
};
