export default {
  titles: {
    edit: 'Modifica dettagli',
    editBusiness: 'Modifica dettagli azienda',
    goBackEditBusiness: 'Torna ai Dettagli Azienda',
  },
  people: 'Persone',
  editSubtitle:
    'Per motivi di sicurezza, non è possibile modificare i dati fondamentali' +
    ' dell’azienda come Nome e Partita IVA. Nel caso tu voglia effettuare' +
    ' una modifica a tali dati',
  details: 'Dettagli',
  businessData: 'Dati del tuo business',
  piva: 'Partita IVA',
  pivaCf: 'Partita IVA / Codice Fiscale',
  cf: 'Codice Fiscale',
  equalsToPiva: 'uguale a Partita IVA',
  address: 'Residenza',
  phone: 'Telefono',
  email: 'Email',
  businessName: 'Nome Azienda',
  differentNameMessage:
    'Voglio usare un nome azienda diverso per le comunicazioni con i miei clienti',
  hintDifferentName:
    'Questo nome sarà quello mostrato ai tuoi clienti e utilizzato negli' +
    ' ordini di pagamento e nelle ricevute. Il nome ufficiale che hai inserito sopra sarà visibile solo a Chainside',
  differentName: 'Nome azienda per comunicazioni con i clienti',
  legalAddress: 'Sede Legale',
  actualAddressMessage: 'Sede operativa diversa da sede legale',
  actualAddress: 'Sede operativa',
  personRole: "Il tuo ruolo all'interno dell'azienda",
  role:
    'Confermo di essere un rappresentante legale dell’azienda e di poter agire per conto di essa.',
  businessType: 'Tipo Azienda',
  nameComunication: 'Nome Comunicazioni',
  sector: 'Settore',
  sector_description: 'Settore della tua azienda',
  missingName: 'Nome mancante',
  goToDetail: 'Dettagli azienda',
  excludeVat: 'Non ho Partita IVA',
  logo: {
    title: 'Logo della tua azienda (opzionale)',
    subtitle:
      'Carica il logo della tua azienda in formato jpeg, png o bmp. Max size: %(maxSize)s',
    editText:
      'Il logo della tua azienda è visibile agli utenti durante il ' +
      ' processo di pagamento.',
    restore: 'mantieni il vecchio logo',
    remove: 'Sei sicuro di voler rimuovere il logo?',
  },
  positions: {
    business_admin: 'Business Admin',
  },
  register: {
    welcome: 'Ciao %(nome)s, benvenuto in ChainsidePay!',
    new: 'Registra una nuova Azienda',
    sendedMail:
      'Ti abbiamo inviato una mail per verificare l’indirizzo da te fornito. Controlla la tua posta!',
    subtitle: 'Per iniziare ad accettare bitcoin, registra la tua azienda',
  },
};
