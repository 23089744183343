import React from 'react';
import PageContainer from '../../common/PageContainer';
import PageTitle from '../../common/PageTitle';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import WebForm from '../common/WebForm';
import Translate from 'react-translate-component';
import { ROUTE_PARAMETERS, ROUTES } from '../../../constants/routes';
import { ConfirmTitle, ConfirmSection, DetailRow } from '../../common/Confirm';
import CustomField from '../../common/CustomField';
import FaqLink from '../../common/FaqLink';
import store from '../../../store/index';
import { buildPath } from '../../../utils/routes';
import PropTypes from 'prop-types';

import { withFormsValidation } from '../../common/Forms/FormsCommon';
import { buildWebsiteLink } from '../../../utils/links';

const styles = theme => ({
  rowMargin: {
    marginBottom: '10px',
  },
  confirmButton: {
    alignSelf: 'center',
    marginTop: '50px',
  },
});

class WebPOS extends React.Component {
  state = {
    errors: {},
    currency: '',
    confirmation: 3,
    confirmPage: false,
    confirmationData: null,
  };

  componentDidMount() {
    this.props.initObject({
      [this.props.form.ids.form$instant_only]: false,
    });
  }

  onConfirm = () => {
    this.setState(
      {
        submitting: true,
      },
      () => {
        this.props.send(
          result => {
            this.setState({
              confirmationData: result,
              confirmPage: true,
            });
          },
          null,
          () => {
            this.setState({
              submitting: false,
            });
          }
        );
      }
    );
  };

  onExitRecapPage = () => {
    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    const { confirmationData = {} } = this.state;
    store.app.reloadInfoAndKeepToken(() => {
      this.props.history.push(
        buildPath(ROUTES.POS_VIEW, {
          [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
          [ROUTE_PARAMETERS.POS_ID]: confirmationData.pos_uuid,
        })
      );
    });
  };

  render() {
    const { object, errors } = this.props;
    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    if (this.state.confirmPage) {
      const { confirmationData } = this.state;
      return (
        <RecapPage
          business_uuid={business_uuid}
          webPOS={object}
          form={this.props.form}
          confirmationData={confirmationData}
          ids={this.props.form.ids}
          onExit={this.onExitRecapPage}
        />
      );
    }
    return (
      <div>
        {this.state.confirmPage === false && (
          <PageContainer main white top>
            <PageTitle
              title={<Translate content="POS.new.web.titles.title" />}
              style={{ marginBottom: '45px' }}
              parent={{
                text: <Translate content="POS.new.web.titles.parent" />,
                url: buildPath(ROUTES.POS_LIST, {
                  [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
                }),
              }}
            />
            <WebForm
              newPos={true}
              business_uuid={business_uuid}
              handleChange={this.props.handleChange}
              handleChangeGeneric={this.props.handleChangeGeneric}
              signalError={this.props.signalError}
              validateField={this.props.validateField}
              webPOS={object}
              errors={errors}
              form={this.props.form}
              validate={this.props.validate}
              onConfirm={this.onConfirm}
              submitting={this.state.submitting}
            />
          </PageContainer>
        )}
      </div>
    );
  }
}

const RecapPage = withStyles(styles)(props => {
  const { webPOS, confirmationData, classes, ids, onExit } = props;
  return (
    <PageContainer white top main>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ConfirmTitle
          title={
            <Translate
              content="POS.confirmPage.web.title"
              with={{ posName: webPOS[ids.form$name] }}
            />
          }
          subtitle={<Translate content="POS.confirmPage.web.subtitle" />}
        />
        <ConfirmSection
          title={<Translate content="POS.confirmPage.integrationTitle" />}
        >
          <React.Fragment>
            <DetailRow
              nearValue
              text={<Translate content="POS.resume.urlSuccess" />}
              value={webPOS[ids.form$default_continue_url]}
            />
            <DetailRow
              nearValue
              text={<Translate content="POS.resume.urlError" />}
              value={webPOS[ids.form$default_cancel_url]}
            />
            <DetailRow
              nearValue
              text={<Translate content="POS.resume.urlCallback" />}
              value={webPOS[ids.form$callback_url]}
            />
            <DetailRow
              nearValue
              text={<Translate content="POS.detail.clientID" />}
              value={
                <CustomField backgroundWhite copiable>
                  {confirmationData.pos_id}
                </CustomField>
              }
            />
            <DetailRow
              nearValue
              text={<Translate content="POS.detail.secret" />}
              value={
                <CustomField backgroundWhite hidden copiable>
                  {confirmationData.secret}
                </CustomField>
              }
            />
          </React.Fragment>
        </ConfirmSection>
        <ConfirmSection title={<Translate content="common.utilsDoc" />}>
          <React.Fragment>
            <FaqLink
              text={<Translate content="POS.confirmPage.web.faq.0" />}
              url={buildWebsiteLink('web_pos_integration')}
            />
            <FaqLink
              text={<Translate content="POS.confirmPage.web.faq.1" />}
              url={buildWebsiteLink('web_pos_sdk')}
            />
          </React.Fragment>
        </ConfirmSection>
        <Button
          variant="raised"
          color="primary"
          onClick={onExit}
          className={classes.confirmButton}
        >
          <Translate content="POS.new.titles.parent" />
        </Button>
      </div>
    </PageContainer>
  );
});

RecapPage.propTypes = {
  webPOS: PropTypes.object.isRequired,
  confirmationData: PropTypes.object.isRequired,
  onExit: PropTypes.func.isRequired,
};

export default withFormsValidation('CreateWebPosForm')(
  withStyles(styles)(WebPOS)
);
