import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import EditBankAccount from './EditBankAccount';
import { ROUTE_PARAMETERS } from '../../../constants/routes';

const styles = theme => ({});

class Modifica extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account_uuid: props.match.params[ROUTE_PARAMETERS.ACCOUNT_ID],
    };
  }

  render() {
    const { account_uuid } = this.state;
    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    const { history } = this.props;
    return (
      <React.Fragment>
        <EditBankAccount
          account_uuid={account_uuid}
          business_uuid={business_uuid}
          history={history}
        />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Modifica);
