export const styles = theme => ({
  container: {
    border: '1px solid',
    height: '100%',
    borderColor: theme.palette.primary.light,
    borderRadius: '6px',
    backgroundColor: theme.palette.common.white,
    marginRight: '5px',
  },
  innerContainer: {
    padding: '30px',
  },
  avatar: {
    marginRight: '15px',
  },
  button: {
    margin: theme.spacing.unit,
  },
  row: {
    marginBottom: '30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatarAndName: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    wordBreak: 'break-all',
  },
  divider: {
    marginBottom: '20px',
  },
  spacingDiv: {
    margin: '10px 0px',
  },
  extended_public_key: {
    overflowWrap: 'break-word',
  },
  detailRow: {
    marginBottom: '12px',
  },
  detailTitle: {
    color: theme.palette.primary.main,
  },
  posContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  posItemClass: {
    marginRight: '30px',
    flex: '0 1 auto',
    width: '260px',
    marginBottom: '15px',
  },
  accountIcon: theme.icon.medium,
  actions: {
    textAlign: 'center',
  },
});
