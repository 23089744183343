import { withRouter } from 'react-router-dom';
import ErrorPage from '../common/ErrorPage';
import Translate from 'react-translate-component';
import store from '../../store';
import Typography from '@material-ui/core/Typography';
import { ActionLink } from '../common/Links';
import React from 'react';
import { WebsiteLink } from '../common/WebsiteLink';

export const GeneralError = withRouter(props => {
  return (
    <ErrorPage
      title={<Translate content="errors.500.title" />}
      errorCode={500}
      showLogo={!store.app.isLoggedIn}
    >
      <Typography
        className="f-18"
        variant="caption"
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
          width: '70%',
          alignSelf: 'center',
        }}
      >
        <Translate content="errors.500.text" />
      </Typography>
      <Typography
        className="f-18"
        variant="caption"
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '10px',
        }}
      >
        <ActionLink
          customClasses="f-18"
          forward
          onClick={() => {
            props.history.go(-1);
          }}
        >
          <Translate content="common.refreshPage" />
        </ActionLink>
      </Typography>
      <Typography
        className="f-18"
        variant="caption"
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ActionLink
          customClasses="f-18"
          forward
          style={{ marginBottom: '10px' }}
          onClick={() => {
            props.history.go(-2);
          }}
        >
          <Translate content="common.lastPage" />
        </ActionLink>
      </Typography>
      <Typography className="f-18" variant="caption">
        <WebsiteLink
          className="f-18"
          withoutIcon
          style={{ marginBottom: '10px' }}
          to="contact_us"
        >
          <Translate content="common.contactUs" />
        </WebsiteLink>
      </Typography>
    </ErrorPage>
  );
});
