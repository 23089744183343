import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  container: {
    width: '100%',
  },
  textArea: {
    border: '1px solid',
    borderColor: theme.palette.primary.bluegrey,
    backgroundColor: theme.palette.primary.bg,
    borderRadius: '6px',
    boxSizing: 'border-box',
    display: 'block',
    fontFamily: theme.typography.fontFamilies.code,
    fontSize: '14px',
    minHeight: '140px',
    maxHeight: '310px',
    overflowY: 'scroll',
    padding: '16px',
    resize: 'none',
    width: '100%',
  },
});

export const JSONPretty = withStyles(styles)(props => {
  const { classes, body, ...otherProps } = props;
  const stringPayment = JSON.parse(body);
  return (
    <div className={classes.container}>
      <textarea className={classes.textArea} {...otherProps} readOnly>
        {JSON.stringify(stringPayment, null, '  ')}
      </textarea>
    </div>
  );
});
