import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
// import Translate from 'react-translate-component';
import store from '../../store';

const LoginImage = require('../../img/setup/Login.png');
const LoginImageSandbox = require('../../img/setup/LoginSandbox.png');
const SignupImage = require('../../img/setup/Signup.png');

const styles = theme => ({
  mainDiv: {
    flex: 1,
  },
  image: {
    flex: 1,
    '@media (max-width: 800px)': {
      display: 'none',
    },
  },
  login: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  loginImage: {
    background: `url(${LoginImage})`,
  },
  loginSandboxImage: {
    background: `url(${LoginImageSandbox})`,
  },
  signup: {
    background: `url(${SignupImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  container: {
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
  },
  quote: {
    marginLeft: '95px',
    marginTop: '140px',
    width: '370px',
  },
  quoteIcon: {
    height: '45px',
    width: '60px',
    marginBottom: '20px',
  },
  quoteString: {
    color: theme.palette.common.white,
    fontSize: '16px',
    fontFamily: theme.typography.fontFamilies.accent,
  },
  quoteAuthor: {
    fontFamily: theme.typography.fontFamilies.accent,
    color: theme.palette.common.white,
    opacity: 0.5,
    marginTop: '20px',
  },
});

export default withStyles(styles)(props => {
  const { children, classes, variant } = props;
  return (
    <div className={classes.container}>
      <div className={classes.mainDiv}>{children}</div>

      <div
        className={classnames({
          [classes.image]: true,
          [classes.signup]: variant === 'signup',
          [classes.login]: variant === 'login',
          [classes.loginImage]: variant === 'login' && !store.app.sandboxMode,
          [classes.loginSandboxImage]:
            variant === 'login' && store.app.sandboxMode,
        })}
      >
        {/*  <div className={classes.quote}>*/}
        {/*    <img*/}
        {/*      src={require('../../img/setup/quote.png')}*/}
        {/*      alt=""*/}
        {/*      className={classes.quoteIcon}*/}
        {/*    />*/}
        {/*    <Typography variant="body1" className={classes.quoteString}>*/}
        {/*      <Translate content={`common.quote.${variant}.quote`} />*/}
        {/*    </Typography>*/}
        {/*    <Typography variant="body1" className={classes.quoteAuthor}>*/}
        {/*      <Translate content={`common.quote.${variant}.author`} />*/}
        {/*    </Typography>*/}
        {/*  </div>*/}
      </div>
    </div>
  );
});
