export default {
  intestatarioContoCorrente: 'Inserisci Nome intestatario',
  IBAN: 'Inserisci IBAN',
  istituto: 'Nome istituto o banca',
  BIC: 'Inserisci BIC filiale',
  partitaIVA: 'Inserisci Partita IVA',
  codiceFiscale: 'Inserisci Codice Fiscale',
  indirizzo: 'Inserisci indirizzo con numero civico',
  telefono: 'Inserisci il tuo numero di telefono',
  email: 'Inserisci la tua email',
  settore: 'Settore',
  settore_description: 'In quale settore opera la tua azienda?',
  nomeAzienda: "Nome ufficiale e registrato dell'azienda",
  aziendaSrl: 'Azienda Srl',
  nomeComunicazioni: 'Nome azienda per le comunicazioni con i clienti',
  ruolo: 'Seleziona il ruolo più vicino alla tua posizione in azienda',
  tipo: 'Seleziona il tipo',
  logo: 'Nessun logo selezionato',
  nome: 'Inserisci un nome',
  location: 'Inserisci la posizione',
  nameSite: 'Nome del negozio',
  internetSite: 'https://www.website.com',
  newPassword: 'La tua nuova passoword',
  passwordConfirm: 'Conferma password',
  personalName: 'Inserisci il tuo nome',
  lastName: 'Inserisci il tuo cognome',
  password: 'Inserisci la tua password',
  currency: 'Valuta',
  pastePublicKey: 'Copia qui la tua chiave pubblica estesa',
};
