const styles = () => ({
  row: {
    marginBottom: '10px',
    marginTop: '30px',
  },
  circularProgress: {
    width: '20px',
    height: '20px',
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});

export default styles;
