import store from '../store';
import Decimal from 'decimal.js';

const local = {
  en: 'en-EN',
  it: 'it-IT',
};

//TODO: define these objects on BE
export const cryptos = {
  BTC: {
    label: 'BTC',
    minimumFractionDigits: 8,
    exponent: 1e8,
  },
  'USDT-ERC20': {
    label: 'USDT',
    minimumFractionDigits: 6,
    exponent: 1e6,
  },
};

export const formatCrypto = (number, currency) => {
  if (number === null || number === undefined) {
    return number;
  }
  number = Number(number);
  const language = store.app.getLanguage;
  return number.toLocaleString(local[language], {
    minimumFractionDigits: cryptos[currency].minimumFractionDigits,
  });
};

export const getLocalString = number => {
  if (number === null || number === undefined) {
    return number;
  }
  number = Number(number);
  const language = store.app.getLanguage;
  return number.toLocaleString(local[language], {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const minimalUnitToFiat = (amount, rate, currency) => {
  return getLocalString(
    new Decimal(amount).dividedBy(cryptos[currency].exponent).times(rate)
  );
};

export const minimalUnitToCrypto = (amount, currency) => {
  return formatCrypto(
    new Decimal(amount).dividedBy(cryptos[currency].exponent),
    currency
  );
};
