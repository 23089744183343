export default {
  supporto: {
    title: 'SUPPORT',
    faqs: 'Faqs',
    documentazione: 'Documentation',
  },
  condizioni: {
    title: 'CONDITIONS',
    termini: 'Terms',
    privacy: 'Privacy',
  },
  lingua: {
    title: 'LANGUAGE',
    italiano: 'Italiano',
    inglese: 'English',
  },
};
