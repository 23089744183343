import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import Translate from 'react-translate-component';
import Button from '../../common/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PageContainer from '../../common/PageContainer';
import { ROUTE_PARAMETERS, ROUTES } from '../../../constants/routes';
import store from '../../../store/index';
import ListGrid from '../../common/ListGrid/Grid';
import { ListComponent } from './ListComponent';
import { BankHelper } from './Helper';
import Loader from '../../common/Loader';
import SubHeader from '../../common/SubHeader';
import { withGenericErrorHandling } from '../../common/ErrorHandling';

import '../../../styles/style.css';
import classnames from 'classnames';
import BankAccountListDetail from './BankAccountListDetail';
import { buildPath } from '../../../utils/routes';
import { WebsiteLink } from '../../common/WebsiteLink';

const styles = theme => ({
  listSection: {
    margin: '30px 0px',
  },
  helperDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '100px',
    marginTop: '60px',
  },
  titlesSolo: {
    marginBottom: '14px',
  },
  titlesDescription: {
    marginBottom: '9px',
  },
  textHelper: {
    marginBottom: '22px',
  },
});

class ListaConti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bankElements: null,
      loading: true,
      bankAccounts: [],
      poses: [],
    };
  }

  componentDidMount() {
    let idAccount = this.props.match.params[ROUTE_PARAMETERS.ACCOUNT_ID];
    this.reloadAccount(idAccount);
  }

  onAccountDeleted = idx => {
    const accounts = this.state.bankAccounts;

    if (!accounts) return;

    accounts.splice(idx, 1);

    this.setState({
      bankAccounts: accounts,
    });
  };

  reloadAccount = idAccount => {
    this.setState(
      {
        bankAccounts: store.app.hasAccount ? store.app.accounts.bank : [],
        loading: false,
      },
      () => {
        //FIXME valueList (in questo caso devi commentare tutta la funzione set state e scommentare quella qua sotto)
        // per invece vedere solo bitcoin o bank commenta la riga bitcoinAccounts o bankAccounts nel json del set state

        // this.setState({loading:false});

        //START FUNZIONE
        //FIXME bad use, but I can't access to ref
        const domElement = document.getElementById(idAccount);
        if (domElement) {
          domElement.click();
        }
        //ENDFUNZIONE
      }
    );
  };

  concat = (list, type) => {
    let res = list;
    if (this.state[type]) {
      res = list.concat(this.state[type]);
    }
    return res;
  };

  render() {
    const { classes } = this.props;
    const { bankAccounts, poses, loading } = this.state;

    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];

    const bankElements =
      bankAccounts &&
      bankAccounts.map((account, idx) => {
        return {
          preview: <ListComponent account={account} key={idx} />,
          detail: (
            <BankAccountListDetail
              account={account}
              business_uuid={business_uuid}
              key={idx}
              poses={poses.filter(pos => pos.deposit_account === account.uuid)}
              reloadAccount={this.reloadAccount}
              onAccountDeleted={this.onAccountDeleted}
            />
          ),
          id: account.uuid,
        };
      });

    const hasAccount = bankAccounts && bankAccounts.length > 0;
    const isSandboxMode = store.app.sandboxMode;
    return (
      <React.Fragment>
        <SubHeader
          description={<Translate content="account.list.subtitle" />}
          action={
            isSandboxMode ? null : (
              <Button
                variant="raised"
                id="addAccount"
                color="primary"
                disabled={loading || !store.app.hasBankAccounts}
                fullWidth
                component={props => (
                  <Link
                    to={buildPath(ROUTES.ACCOUNT_NEW, {
                      [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
                    })}
                    {...props}
                  />
                )}
              >
                <Translate content="account.list.add" />
              </Button>
            )
          }
          withoutFilter={!hasAccount}
        />
        <PageContainer main grey>
          <div className={classes.listSection}>
            {loading && (
              <Loader>
                <Translate content="account.loading" />
              </Loader>
            )}
            {bankElements && bankElements.length > 0 && (
              <ListGrid>{bankElements}</ListGrid>
            )}
          </div>
          {!bankElements.length && !loading && <BankHelp />}
        </PageContainer>
      </React.Fragment>
    );
  }
}

const BankHelp = observer(
  withStyles(styles)(props => {
    const { classes } = props;
    const sandboxMode = store.app.sandboxMode;
    return (
      <div className={classes.helperDiv}>
        {sandboxMode || (
          <React.Fragment>
            <Typography
              variant="title"
              className={classnames([
                classes.titlesDescription,
                'section-create-title',
              ])}
            >
              <Translate content="account.list.suggestion.bank.alert" />
            </Typography>
            <Typography
              variant="body1"
              className={classnames([
                classes.textHelper,
                'section-create-description',
              ])}
            >
              <Translate content="account.list.suggestion.bank.hint" />
            </Typography>
          </React.Fragment>
        )}
        <BankHelper />
        <Typography variant="caption" className={'section-create-link'}>
          <Translate content="account.list.suggestion.bank.faq" />
          <WebsiteLink to="faq_root">
            <Translate content="POS.list.suggestion.faqLink" />
          </WebsiteLink>
        </Typography>
      </div>
    );
  })
);

export default withGenericErrorHandling(withStyles(styles)(ListaConti));
