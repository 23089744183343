import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import Tooltip from '@material-ui/core/Tooltip';
import Translate from 'react-translate-component';
import classnames from 'classnames';

const style = theme => ({
  container: {
    borderRadius: '6px',
    border: '1px solid',
    borderColor: theme.palette.primary.bluegrey,
  },
  backgroundWhite: {
    backgroundColor: theme.palette.common.white,
  },
  innerContainer: {
    padding: '8px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    wordBreak: 'break-all',
  },
  visible: {
    height: '14px',
    width: '14px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  buttonContainer: {
    display: 'flex',
    marginLeft: '10px',
  },
  arrowArrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  arrowPopper: {
    '&[x-placement*="bottom"] $arrowArrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${
          theme.palette.grey[700]
        } transparent`,
      },
    },
    '&[x-placement*="top"] $arrowArrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${
          theme.palette.grey[700]
        } transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrowArrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${
          theme.palette.grey[700]
        } transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrowArrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${
          theme.palette.grey[700]
        }`,
      },
    },
  },
});

class CustomField extends React.Component {
  static propTypes = {
    children: PropTypes.string.isRequired,
    copiable: PropTypes.bool,
    hidden: PropTypes.bool,
    backgroundWhite: PropTypes.bool,
  };
  constructor(props) {
    super(props);

    this.state = {
      visible: !props.hidden,
      hiddenCamp: '**********',
      openTooltip: false,
      arrowRef: null,
    };
  }

  handleArrowRef = node => {
    this.setState({
      arrowRef: node,
    });
  };

  onClick = () => {
    this.setState({ visible: !this.state.visible });
  };

  openTooltip = () => {
    this.setState({ openTooltip: true }, () => {
      setTimeout(() => {
        this.setState({
          openTooltip: false,
        });
      }, 1500);
    });
  };

  copy = event => {
    event.clipboardData.setData('text/plain', this.props.children);
  };

  render() {
    const { visible, hiddenCamp, openTooltip } = this.state;
    const { children, classes, hidden, copiable, backgroundWhite } = this.props;

    return (
      <div
        className={classnames({
          [classes.container]: true,
          [classes.backgroundWhite]: backgroundWhite,
        })}
      >
        <div className={classes.innerContainer}>
          {visible ? children : hiddenCamp}
          <div className={classes.buttonContainer}>
            {hidden && (
              <img
                onClick={this.onClick}
                className={classes.visible}
                src={
                  visible
                    ? require('../../img/others/hide.png')
                    : require('../../img/others/show.png')
                }
                alt=""
              />
            )}
            {copiable && (
              <Tooltip
                title={
                  <React.Fragment>
                    <Translate content="common.copied" />
                    <span
                      className={classes.arrowArrow}
                      ref={this.handleArrowRef}
                    />
                  </React.Fragment>
                }
                open={openTooltip}
                classes={{ popper: classes.arrowPopper }}
                PopperProps={{
                  popperOptions: {
                    modifiers: {
                      arrow: {
                        enabled: Boolean(this.state.arrowRef),
                        element: this.state.arrowRef,
                      },
                    },
                  },
                }}
              >
                <CopyToClipboard
                  text={this.props.children}
                  onCopy={this.openTooltip}
                >
                  <img
                    className={classes.visible}
                    src={require('../../img/others/copy.png')}
                    alt="copy"
                  />
                </CopyToClipboard>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(style)(CustomField);
