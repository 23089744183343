import React from 'react';
import PageContainer from '../../../common/PageContainer';
import PageTitle from '../../../common/PageTitle';
import { withStyles } from '@material-ui/core/styles';
import PosAPI from '../../../../api/POS';
import store from '../../../../store/index';
import WebForm from '../../common/WebForm';
import Translate from 'react-translate-component';
import translator from 'counterpart';
import { withGenericErrorHandling } from '../../../common/ErrorHandling';
import Loader from '../../../common/Loader';
import { ROUTE_PARAMETERS, ROUTES } from '../../../../constants/routes';
import { withRouter } from 'react-router-dom';
import { buildPath } from '../../../../utils/routes';
import { withFormsValidation } from '../../../common/Forms/FormsCommon';
import Chip from '@material-ui/core/Chip';
import { WebDetailProps, WebDetailState } from './propTypes';
import styles from './theme';

class DetailPOS extends React.Component<WebDetailProps, WebDetailState> {
  state: WebDetailState = {
    idPOS: undefined,
    confirmPage: false,
    loading: true,
    webPos: undefined,
    submitting: false,
  };

  componentDidMount() {
    let idPOS = this.props.match.params[ROUTE_PARAMETERS.POS_ID];
    PosAPI.getDetailWeb(
      idPOS,
      (data: { [key: string]: any }) => {
        this.initWebPOS(data);
      },
      this.props.onError
    );
    this.setState({ idPOS: idPOS });
  }

  initWebPOS = (pos: { [key: string]: any }) => {
    const ids = this.props.form.ids;
    const webPOS = {
      [ids.form$name]: pos.name,
      [ids.form$website]: pos.website,
      [ids.form$currency]: pos.currency,
      [ids.form$deposit_account]: pos.deposit_account
        ? pos.deposit_account.uuid
        : null,
      [ids.form$callback_url]: pos.callback_url,
      [ids.form$default_continue_url]: pos.default_continue_url,
      [ids.form$default_cancel_url]: pos.default_cancel_url,
      [ids.uri$pos_uuid]: pos.uuid,
    };
    this.props.initObject(webPOS, () => {
      this.setState({
        loading: false,
        webPos: {
          pos_id: pos.pos_id,
          secret: pos.secret,
          active: pos.active,
        },
      });
    });
  };

  onConfirm = () => {
    this.setState({ submitting: true });
    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];

    this.props.send(
      () => {
        this.setState({ submitting: false });
        store.app.reloadInfoAndKeepToken(() => {
          this.props.history.push(
            buildPath(ROUTES.POS_VIEW, {
              [ROUTE_PARAMETERS.POS_ID]: this.state.idPOS,
              [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
            })
          );
        });
      },
      () => {
        this.setState({ submitting: false });
      }
    );
  };

  onResetCredentials = (clientID: string, secret: string) => {
    this.setState(state => ({
      webPos: {
        ...state.webPos,
        pos_id: clientID,
        secret: secret,
      },
    }));
  };

  render() {
    if (this.state.loading) {
      return (
        <PageContainer white main style={{ paddingTop: '50px' }}>
          <Loader />
        </PageContainer>
      );
    }

    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    const { classes = {} } = this.props;

    const {
      object,
      errors,
      handleChange,
      signalError,
      handleChangeGeneric,
      validateField,
    } = this.props;
    const webPos: { [key: string]: string | boolean } = {
      ...this.state.webPos,
      ...object,
    };

    return (
      <div id={'posFormContainer'}>
        {this.state.confirmPage === false && (
          <PageContainer main white style={{ paddingTop: '45px' }}>
            <PageTitle
              noMarginBottom={!webPos.active}
              title={<Translate content="POS.edit.web.title" />}
              parent={{
                text: <Translate content="POS.new.web.titles.parent" />,
                url: buildPath(ROUTES.POS_LIST, {
                  [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
                  [ROUTE_PARAMETERS.POS_ID]: this.state.idPOS,
                }),
              }}
            />
            {!webPos.active && (
              <div
                className={classes.titleLabelContainer}
                style={{ width: '100%' }}
              >
                <Chip
                  style={{ width: '100%', justifyContent: 'flex-start' }}
                  label={translator('POS.edit.state.notActive')}
                  className={classes.errorLabel}
                />
              </div>
            )}
            <WebForm
              business_uuid={business_uuid}
              handleChange={handleChange}
              handleChangeGeneric={handleChangeGeneric}
              signalError={signalError}
              errors={errors}
              validateField={validateField}
              webPOS={webPos}
              onConfirm={this.onConfirm}
              submitting={this.state.submitting}
              form={this.props.form}
              validate={this.props.validate}
              onResetCredentials={this.onResetCredentials}
              idPOS={this.state.idPOS}
              edit
            />
          </PageContainer>
        )}
      </div>
    );
  }
}

export default withFormsValidation('UpdateWebPosForm')(
  withGenericErrorHandling(withRouter(withStyles(styles)(DetailPOS)))
);
