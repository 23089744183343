import React from 'react';

import classnames from 'classnames';

import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  selectField: {
    ...theme.components.inputField,
    fontWeight: theme.typography.weights.normal,
  },
  selectRoot: {
    backgroundColor: theme.palette.primary.bg,
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
  },
  innerSelect: {
    padding: 0,
    '&:focus': {
      background: 'inherit',
      border: 'inherit',
    },
  },
  placeholder: {
    color: '#999999 !important',
  },
  placeholderItem: {
    display: 'none',
  },
  inputLabel: {
    fontSize: '14px',
    transform: 'translate(-10px, -5px) scale(1)',
    fontWeight: theme.typography.weights.normal,
    color: '#777777',
  },
  icon: {
    marginRight: '10px',
  },
  disabled: {
    color: theme.palette.primary.bluegreydark,
  },
  selectMenu: {
    padding: '10px 0px',
    paddingLeft: '10px',
  },
  error: {
    backgroundColor: theme.palette.red.bg + ' !important',
    borderColor: theme.palette.red.main,
  },
});

class SelectField extends React.Component {
  handleChange = event => {
    this.props.onChange({
      target: {
        value: event.target.value,
        id: this.props.id,
      },
    });
  };

  render() {
    const {
      classes,
      id,
      label,
      value,
      placeholder,
      children,
      helperText,
      ...otherProps
    } = this.props;

    //BADUSE
    const class_names = [classes.placeholder];
    let errorTextHtml = null;
    if (helperText) {
      errorTextHtml = (
        <div>
          {helperText.map((text, k) => {
            return (
              <span key={k}>
                {text}
                <br />
              </span>
            );
          })}
        </div>
      );
    }

    return (
      <FormControl id={id + '_select'} {...otherProps}>
        {label && (
          <InputLabel htmlFor={id} shrink={true} className={classes.inputLabel}>
            {label}
          </InputLabel>
        )}
        <Select
          classes={{
            root: classnames({
              [classes.selectRoot]: true,
              color4: true,
              [classes.error]: this.props.error,
              [classes.placeholder]: !value,
            }),
            select: classes.innerSelect,
            icon: classes.icon,
            disabled: classes.disabled,
            selectMenu: classes.selectMenu,
          }}
          disableUnderline={true}
          displayEmpty={Boolean(placeholder)}
          value={value}
          onChange={this.handleChange}
          id={id}
          inputProps={{
            name: label,
            placeholder: placeholder,
          }}
          // className={classes.selectField}
          className={classnames(class_names)}
        >
          {placeholder && (
            <MenuItem
              value=""
              className={classnames([
                classes.placeholderItem,
                classes.placeholder,
              ])}
            >
              {placeholder}
            </MenuItem>
          )}
          {children}
        </Select>
        <FormHelperText id={id + '-helper-text'}>
          {errorTextHtml}
        </FormHelperText>
      </FormControl>
    );
  }
}

SelectField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.string,
  helperText: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
};

SelectField.defaultProps = {
  value: '',
};

export default withStyles(styles)(SelectField);
