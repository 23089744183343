export default {
  set: (key, object) => {
    try {
      localStorage.setItem(key, JSON.stringify(object));
    } catch (err) {
      console.error(err);
    }
  },
  get: key => {
    try {
      const item = localStorage.getItem(key);
      return JSON.parse(item);
    } catch (err) {
      console.error(err);
      return null;
    }
  },
};
