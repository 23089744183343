import React from 'react';

import PropTypes from 'prop-types';

import Translate from 'react-translate-component';
import translator from 'counterpart';

import { Col, Row } from 'react-flexbox-grid';

import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { AddressField } from '../../common/Forms/AddressField';
import SelectField from '../../common/Forms/SelectField';
import CheckboxField from '../../common/Forms/CheckboxField';
import TextField from '../../common/Forms/TextField';

import LogoWidget from '../../common/Forms/LogoWidget';

import validators from '../../../utils/validators';

import CopyButton from './CopyButton';

const styles = theme => ({
  sectionTitle: {
    fontSize: '16px',
    fontWeight: theme.typography.weights.normal,
    color: '#444444',
    marginBottom: '10px',
  },
  copyVatParent: {
    position: 'relative',
  },
  formRow: {
    marginBottom: '30px',
  },
  checkBoxRow: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  checkBoxRowUnchecked: {
    marginTop: '10px',
    marginBottom: '30px',
  },
  logoWidget: {
    marginTop: '100px',
    marginBottom: '100px',
  },
  ruoloRow: {
    marginBottom: '100px',
  },
});

class ModificaBusinessFormSrl extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    errors: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    communications_name: PropTypes.string.isRequired,
    nome_azienda_comunicazioni_presente: PropTypes.bool.isRequired,
    vat: PropTypes.string.isRequired,
    fiscal_code: PropTypes.string.isRequired,
    legal_address: PropTypes.string.isRequired,
    indirizzo_secondario: PropTypes.string.isRequired,
    indirizzo_secondario_presente: PropTypes.bool.isRequired,
    telefono: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    operational_field: PropTypes.string.isRequired,
    ruolo: PropTypes.string.isRequired,
    logo: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    validateImage: PropTypes.func.isRequired,
    handleAddressSelect: PropTypes.func.isRequired,
    handleAddressBlur: PropTypes.func.isRequired,
    toggleCheckbox: PropTypes.func.isRequired,
    positions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    operationalFields: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
  };

  static requiredFields(business) {
    let fields = [
      'name',
      'vat',
      'legal_address',
      'telefono',
      'email',
      'operational_field',
      'ruolo',
    ];

    if (business.nome_azienda_comunicazioni_presente) {
      fields = fields.concat(['communications_name']);
    }

    if (business.indirizzo_secondario_presente) {
      fields = fields.concat(['indirizzo_secondario']);
    }

    return fields;
  }

  static validate(business) {
    let errors = {};

    // TODO: should I validate the VAT?

    // TODO: il codice fiscale e' richiesto?

    if (validators.isEmptyString(business.name)) {
      errors['name'] = translator('errors.requiredField');
    }

    if (
      business.nome_azienda_comunicazioni_presente &&
      validators.isEmptyString(business.communications_name)
    ) {
      errors['communications_name'] = translator('errors.requiredField');
    }

    if (validators.isEmptyString(business.vat)) {
      errors['vat'] = translator('errors.requiredField');
    }

    if (validators.isEmptyString(business.legal_address)) {
      errors['legal_address'] = translator('errors.requiredField');
    }

    if (validators.isEmptyString(business.telefono)) {
      errors['telefono'] = translator('errors.requiredField');
    } else if (!validators.isValidPhoneNumber(business.telefono)) {
      errors['telefono'] = translator('errors.validPhone');
    }

    if (validators.isEmptyString(business.email)) {
      errors['email'] = translator('errors.requiredField');
    } else if (!validators.isValidEmail(business.email)) {
      errors['email'] = translator('errors.emailValid');
    }

    return errors;
  }

  render() {
    const { errors } = this.props;
    const {
      classes,
      disabled,
      name,
      communications_name,
      nome_azienda_comunicazioni_presente,
      vat,
      fiscal_code,
      legal_address,
      operational_address,
      indirizzo_secondario_presente,
      phone,
      email,
      operational_field,
      ruolo,
      logo,
      editMode,
      positions,
      operationalFields,
    } = this.props;
    const {
      handleChange,
      validateImage,
      handleAddressSelect,
      handleAddressBlur,
      toggleCheckbox,
      staticAction,
      validateField,
      handleChangeGeneric,
    } = this.props;
    return (
      <React.Fragment>
        <Row className={classes.formRow}>
          <Col xs={12}>
            <TextField
              disabled={disabled || editMode}
              label={<Translate content="business.businessName" />}
              placeholder={translator('placeholder.nomeAzienda')}
              value={name}
              id="name"
              onChange={handleChange('name')}
              error={Boolean(errors.name)}
              helperText={errors.name}
              fullWidth
              staticAction={staticAction}
              validateField={validateField}
            />
          </Col>
        </Row>
        <Row
          className={
            nome_azienda_comunicazioni_presente
              ? classes.checkBoxRow
              : classes.checkBoxRowUnchecked
          }
        >
          <Col xs={12}>
            <CheckboxField
              disabled={disabled}
              id="nome_azienda_comunicazioni_presente"
              checked={nome_azienda_comunicazioni_presente}
              onChange={() =>
                toggleCheckbox('nome_azienda_comunicazioni_presente')
              }
              label={<Translate content="business.differentNameMessage" />}
            />
          </Col>
        </Row>
        {nome_azienda_comunicazioni_presente && (
          <Row className={classes.formRow}>
            <Col xs={12}>
              <Typography variant="caption" style={{ marginBottom: '12px' }}>
                <Translate content="business.hintDifferentName" />
              </Typography>
              <TextField
                disabled={disabled}
                placeholder={translator('placeholder.nomeComunicazioni')}
                value={communications_name}
                id="communications_name"
                onChange={handleChange('communications_name')}
                error={Boolean(errors.communications_name)}
                helperText={errors.communications_name}
                fullWidth
                staticAction={staticAction}
                validateField={validateField}
              />
            </Col>
          </Row>
        )}
        <Row className={classes.formRow}>
          <Col xs={6}>
            <TextField
              disabled={disabled || editMode}
              label={<Translate content="business.piva" />}
              placeholder={translator('placeholder.partitaIVA')}
              value={vat}
              id="vat"
              onChange={handleChange('vat')}
              error={Boolean(errors.vat)}
              helperText={errors.vat}
              fullWidth
              staticAction={staticAction}
              validateField={validateField}
            />
          </Col>
          <Col xs={6} className={classes.copyVatParent}>
            {!editMode && (
              <CopyButton
                onClick={() => handleChangeGeneric('fiscal_code')(vat)}
                text={translator('business.equalsToPiva') + ' >'}
              />
            )}
            <TextField
              disabled={disabled || editMode}
              label={<Translate content="business.cf" />}
              placeholder={translator('placeholder.codiceFiscale')}
              value={fiscal_code}
              id="fiscal_code"
              onChange={handleChange('fiscal_code')}
              error={Boolean(errors.fiscal_code)}
              helperText={errors.fiscal_code}
              fullWidth
              staticAction={staticAction}
              validateField={validateField}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <AddressField
              disabled={disabled}
              value={legal_address}
              id="legal_address"
              onChange={handleChangeGeneric('legal_address')}
              onSelect={value => handleAddressSelect('legal_address', value)}
              onBlur={event =>
                handleAddressBlur('legal_address', event.target.value)
              }
              fullWidth
              label={<Translate content="business.legalAddress" />}
              placeholder={translator('placeholder.indirizzo')}
              error={Boolean(errors.legal_address)}
              helperText={errors.legal_address}
            />
          </Col>
        </Row>
        <Row
          className={
            nome_azienda_comunicazioni_presente
              ? classes.checkBoxRow
              : classes.checkBoxRowUnchecked
          }
        >
          <Col xs={12}>
            <CheckboxField
              disabled={disabled}
              id="indirizzo_secondario_presente"
              checked={indirizzo_secondario_presente}
              onChange={() => toggleCheckbox('indirizzo_secondario_presente')}
              label={<Translate content="business.actualAddressMessage" />}
            />
          </Col>
        </Row>
        {indirizzo_secondario_presente && (
          <Row className={classes.formRow}>
            <Col xs={12}>
              <AddressField
                disabled={disabled}
                value={operational_address}
                id="operational_address"
                onChange={handleChangeGeneric('operational_address')}
                onSelect={value =>
                  handleAddressSelect('operational_address', value)
                }
                onBlur={event =>
                  handleAddressBlur('operational_address', event.target.value)
                }
                fullWidth
                label={
                  <Translate
                    style={{ marginBottom: '12px' }}
                    content="business.actualAddress"
                  />
                }
                placeholder={translator('placeholder.indirizzo')}
                error={Boolean(errors.operational_address)}
                helperText={errors.operational_address}
              />
            </Col>
          </Row>
        )}
        <Row className={classes.formRow}>
          <Col xs={6}>
            <TextField
              disabled={disabled}
              label={<Translate content="business.phone" />}
              value={phone}
              id="phone"
              onChange={handleChange('phone')}
              error={Boolean(errors.phone)}
              helperText={errors.phone}
              placeholder={translator('placeholder.telefono')}
              fullWidth
              staticAction={staticAction}
              validateField={validateField}
            />
          </Col>
          <Col xs={6}>
            <TextField
              disabled={disabled}
              label={<Translate content="business.email" />}
              placeholder={translator('placeholder.email')}
              value={email}
              id="email"
              onChange={handleChange('email')}
              error={Boolean(errors.email)}
              helperText={errors.email}
              fullWidth
              staticAction={staticAction}
              validateField={validateField}
            />
          </Col>
        </Row>
        <Row className={classes.formRow}>
          <Col xs={6}>
            <SelectField
              disabled={disabled}
              id="operational_field"
              value={operational_field}
              onChange={handleChange('operational_field')}
              fullWidth
              label={translator('business.sector')}
              error={Boolean(errors.operational_field)}
              helperText={errors.operational_field}
              placeholder={translator('placeholder.operational_field')}
            >
              {operationalFields.map((item, idx) => (
                <MenuItem key={idx} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </SelectField>
          </Col>
        </Row>
        <LogoWidget
          className={classes.logoWidget}
          disabled={disabled}
          onChange={handleChangeGeneric('logo', logo)}
          validateImage={validateImage}
          logo={logo}
          error={Boolean(errors.logo)}
          helperText={errors.logo}
        />
        {positions && positions.length > 0 && (
          <Row className={classes.ruoloRow}>
            <Col xs={6}>
              <Typography
                variant="body2"
                className="f-16"
                style={{ marginBottom: '15px' }}
              >
                <Translate content="business.personRole" />
              </Typography>
              <SelectField
                disabled={disabled}
                id="ruolo"
                value={ruolo}
                onChange={handleChange('ruolo')}
                fullWidth
                label={<Translate content="business.role" />}
                placeholder={translator('placeholder.ruolo')}
                error={Boolean(errors.ruolo)}
                helperText={errors.ruolo}
              >
                {positions.map((position, idx) => (
                  <MenuItem key={idx} value={position.uuid}>
                    <Translate
                      content={`business.positions.${position.name}`}
                    />
                  </MenuItem>
                ))}
              </SelectField>
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ModificaBusinessFormSrl);
