const styles = ({ typography, palette }: { [key: string]: any }) => ({
  link: {
    textDecoration: 'none',
    color: palette.primary.link,
    fontSize: '14px',
    fontWeight: typography.fontWeightBold,
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  disabled: {
    cursor: 'default',
    opacity: 0.5,
    '&:hover': {
      textDecoration: 'none',
    },
  },
});

export default styles;
