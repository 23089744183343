import * as React from 'react';
import Translate from 'react-translate-component';
import { WithStyles, withStyles } from '@material-ui/core';
import { style } from './styles';
import { Col, Row } from 'react-flexbox-grid';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

interface SortableTableHeadProps extends WithStyles<typeof style> {
  order: 'asc' | 'desc';
  orderBy: string;
  columns: Array<{
    name: string;
    label: string;
    width: number;
    sortable: boolean;
    rightAlign?: boolean;
  }>;
  sortData: (name: string) => void;
  extraCol?: boolean;
}

const SortableTableHead = withStyles(style)(
  ({
    classes,
    order,
    orderBy,
    columns,
    sortData,
    extraCol,
  }: SortableTableHeadProps) => {
    return (
      <div className={classes.sortableTableHead}>
        <Row className={classes.sortableTableHeadRow}>
          <Col xs={extraCol ? 11 : 12}>
            <Row>
              {columns.map(({ name, label, width, sortable, rightAlign }) => (
                <Col
                  xs={width}
                  className={classNames({
                    [classes.sortableTableHeadCell]: true,
                    [classes.sortableTableHeadCellInteractive]: sortable,
                  })}
                  key={name}
                  onClick={() => {
                    if (sortable) {
                      sortData(name);
                    }
                  }}
                  style={{
                    justifyContent: rightAlign ? 'flex-end' : 'flex-start',
                  }}
                >
                  <Typography variant="caption" className={classes.tableHeader}>
                    <Translate content={label} />
                  </Typography>
                  {sortable &&
                    (orderBy === name ? (
                      order === 'asc' ? (
                        <img
                          src={require('../../../img/others/caret_up.png')}
                          className={classes.sortIcon}
                          alt=""
                        />
                      ) : (
                        <img
                          src={require('../../../img/others/caret_down.png')}
                          className={classes.sortIcon}
                          alt=""
                        />
                      )
                    ) : (
                      <img
                        src={require('../../../img/others/caret_empty.png')}
                        className={classes.sortIcon}
                        alt=""
                      />
                    ))}
                </Col>
              ))}
            </Row>
          </Col>
          {extraCol && (
            <Col xs={1}>
              <Row>
                <Col className={classes.sortableTableHeadCell} />
              </Row>
            </Col>
          )}
        </Row>
      </div>
    );
  }
);

export { SortableTableHead };
