import React from 'react';
import PropTypes from 'prop-types';
import store from '../../../store/index';
import { ROUTES } from '../../../constants/routes';
import { ConditionalRoute } from './ConditionalRoute';

const UnAuthenticatedRoute = props => {
  const isNotAuthenticated = !store.app.isLoggedIn;
  const { from } = props.location.state || {
    from: { pathname: ROUTES.PROFILE_VIEW },
  };
  return (
    <ConditionalRoute
      fallbackRoute={from.pathname}
      condition={isNotAuthenticated}
      {...props}
    />
  );
};

UnAuthenticatedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export { UnAuthenticatedRoute };
