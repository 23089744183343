export default {
  intestatarioContoCorrente: 'Enter account holder name',
  IBAN: 'Enter IBAN',
  istituto: 'Institute or bank name',
  BIC: 'Enter branch BIC',
  partitaIVA: 'Enter VAT number',
  codiceFiscale: 'Enter Tax Code',
  indirizzo: 'Enter address with house number',
  telefono: 'Enter your phone number',
  email: 'Enter your email',
  settore: 'Sector',
  settore_description: 'In which sector does your company operate?',
  nomeAzienda: 'Registered name of the company',
  aziendaSrl: 'Company Ltd',
  nomeComunicazioni: 'Company name for communications with customers',
  ruolo: 'Select the role closest to your position in the company',
  tipo: 'Select type',
  logo: 'No logo selected',
  nome: 'Enter a name',
  location: 'Enter location',
  nameSite: 'Store name',
  internetSite: 'https://www.website.com',
  newPassword: 'Your new passoword',
  passwordConfirm: 'Confirm password',
  personalName: 'Enter your name',
  lastName: 'Enter your surname',
  password: 'Enter your password',
  currency: 'Currency',
  pastePublicKey: 'Copy your extended public key here',
};
