import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { LinkPrimary } from './Links';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

const styles = theme => ({
  title: {
    fontSize: '24px',
    lineHeight: '33px',
    fontWeight: theme.typography.weights.normal,
    color: theme.palette.grey[4],
    fontFamily: theme.typography.fontFamilies.accent,
  },
  goBack: {
    fontSize: '13px',
    fontWeight: '600',
    marginBottom: '15px',
    lineHeight: '16px',
  },
  marginBottom: {
    marginBottom: '40px',
  },
  // title: {
  //   textAlign: "left",
  //   color: theme.palette.grey.dark,
  //   marginTop: '5px',
  //   marginBottom: '0'
  // }
});

const PageTitle = props => {
  const { classes, parent, noMarginBottom } = props;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {parent && (
        <Typography variant="caption" className={classes.goBack}>
          <LinkPrimary to={parent.url} back>
            {parent.text}
          </LinkPrimary>
        </Typography>
      )}
      <Typography
        className={classnames({
          [classes.title]: true,
          [classes.marginBottom]: !noMarginBottom,
        })}
      >
        {props.title}
      </Typography>
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  parent: PropTypes.shape({
    text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    url: PropTypes.string,
  }),
  goBack: PropTypes.bool,
  parents: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        .isRequired,
      url: PropTypes.url,
    })
  ),
};

export default withRouter(withStyles(styles)(PageTitle));
