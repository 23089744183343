import { STATES } from './states';

export const SELECT_CALLBACK = 'SELECT_CALLBACK';
export const UNSELECT_CALLBACK = 'UNSELECT_CALLBACK';
export const SENT_REQUEST = 'SENT_REQUEST';
export const LOADED_CALLBACKS = 'LOADED_CALLBACKS';
export const ERROR_LOADING_CALLBACKS = 'ERROR_LOADING_CALLBACKS';
export const OPEN_MODAL = 'OPEN_MODAL';
export const SOCKET_OK = 'SOCKET_OK';
export const SOCKET_KO = 'SOCKET_KO';
export const SOCKET_FATAL = 'SOCKET_FATAL';
export const RESETTING = 'RESETTING';
export const RESET = 'RESET';
export const REINITIALIZE = 'REINITIALIZE';
export const INVALID_CALLBACK = 'INVALID_CALLBACK';

export const ACTIONS_HANDLER = {
  [LOADED_CALLBACKS]: (state, payload) => {
    return {
      ...state,
      availableCallbacks: payload.callbacks,
      selected_callback: null,
      canReset: payload.canReset,
      state: STATES.NO_CALLBACK_SELECTED,
    };
  },
  [ERROR_LOADING_CALLBACKS]: (state, payload) => {
    return { ...state, state: STATES.ERROR_LOADING };
  },
  [SELECT_CALLBACK]: (state, payload) => {
    return {
      ...state,
      selected_callback: payload.what,
      state: STATES.CALLBACK_SELECTED,
    };
  },
  [UNSELECT_CALLBACK]: (state, payload) => {
    return {
      ...state,
      selected_callback: null,
      state: STATES.NO_CALLBACK_SELECTED,
    };
  },
  [INVALID_CALLBACK]: (state, payload) => {
    return {
      ...state,
      state: STATES.INVALID_CALLBACK,
      openModal: true,
    };
  },
  [OPEN_MODAL]: (state, payload) => {
    return {
      ...state,
      state: STATES.WAITING_SOCKET,
      openModal: true,
    };
  },
  [SENT_REQUEST]: (state, payload) => {
    return {
      ...state,
      state: STATES.WAITING_SOCKET,
      openModal: true,
      timeout: payload.timeoutID,
    };
  },
  [SOCKET_OK]: (state, payload) => {
    return {
      ...state,
      ...payload,
      state: STATES.SOCKET_OK,
    };
  },
  [SOCKET_KO]: (state, payload) => {
    return {
      ...state,
      ...payload,
      state: STATES.SOCKET_KO,
    };
  },
  [SOCKET_FATAL]: (state, payload) => {
    return {
      ...state,
      state: STATES.SOCKET_FATAL,
      paymentResponse: payload.payment,
    };
  },
  [RESETTING]: (state, payload) => {
    return { ...state, state: STATES.RESETTING, openModal: true };
  },
  [RESET]: (state, payload) => {
    return { ...state, state: STATES.RESET, paymentResponse: payload.payment };
  },
  [REINITIALIZE]: (state, payload) => {
    return {
      ...state,
      openModal: false,
      availableCallbacks: [],
      state: STATES.LOADING_CALLBACKS,
    };
  },
};
