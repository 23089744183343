import { withRouter } from 'react-router-dom';
import ErrorPage from '../common/ErrorPage';
import Translate from 'react-translate-component';
import store from '../../store';
import Typography from '@material-ui/core/Typography';
import { LinkPrimary, ActionLink } from '../common/Links';
import React from 'react';

export const NotFound = withRouter(props => {
  return (
    <ErrorPage
      title={<Translate content="errors.404.title" />}
      errorCode={404}
      showLogo={!store.app.isLoggedIn}
    >
      <Typography
        className="f-18"
        variant="caption"
        style={{ marginBottom: '10px' }}
      >
        <Translate content="errors.404.utils" />
      </Typography>
      <ActionLink
        customClasses="f-18"
        forward
        style={{ marginBottom: '10px' }}
        onClick={() => {
          props.history.goBack();
        }}
      >
        <Translate content="common.lastPage" />
      </ActionLink>
      <LinkPrimary customClasses="f-18" forward to="/">
        <Translate content="common.homePage" />
      </LinkPrimary>
    </ErrorPage>
  );
});
