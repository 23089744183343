import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { LinkPrimary } from './Links';
import { buildWebsiteLink } from '../../utils/links';

const styles = () => ({
  icon: {
    marginRight: '6px',
    width: '16px',
    height: '16px',
    verticalAlign: 'text-bottom',
  },
});

const WebsiteLink = withStyles(styles)(props => {
  const { classes, children, to, withoutIcon, ...otherProps } = props;
  return (
    <LinkPrimary external to={buildWebsiteLink(to)} {...otherProps}>
      {withoutIcon || (
        <img
          src={require('../../img/help/info.png')}
          className={classes.icon}
          alt=""
        />
      )}
      {children}
    </LinkPrimary>
  );
});

WebsiteLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  to: PropTypes.string.isRequired,
  withoutIcon: PropTypes.bool,
};

WebsiteLink.defaultProps = {
  withoutIcon: false,
};

export { WebsiteLink };
