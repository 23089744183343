import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import classnames from 'classnames';
import Translate from 'react-translate-component';
import { PaymentTransaction } from '../PaymentTransaction';
import Button from '../../../../common/Button';
import React from 'react';
import { modalStyles } from './style';
import { WebsiteLink } from '../../../../common/WebsiteLink';

export const FatalErrorModal = withStyles(modalStyles)(props => {
  const { classes, oldPayment, payment } = props;
  return (
    <div className={classes.container}>
      <img
        src={require('../../../../../img/payment-testing/error.png')}
        alt=""
        className={classes.topIcon}
      />
      <Typography
        variant="title"
        className={classnames([classes.errorTitle, 'f-22 monteserrat'])}
      >
        <Translate content="payment.testing.fatal.title" />
      </Typography>
      <div className={classes.helperTextContainer}>
        <Typography variant="body1">
          <Translate content="payment.testing.fatal.text" />
          <WebsiteLink
            withoutIcon
            style={{
              marginLeft: '2px',
              display: 'inline-block',
            }}
            to="contact_us"
          >
            <Translate content="common.contactUsLowercase" />
          </WebsiteLink>
        </Typography>
      </div>
      <PaymentTransaction
        oldStatus={oldPayment.status}
        status={payment.status}
        oldValue={oldPayment.cryptoAmountString}
        value={payment.cryptoAmountString}
      />
      <Button
        variant="raised"
        color="primary"
        small
        style={{ marginTop: '37px', width: '220px' }}
        onClick={props.closeModal}
      >
        <Translate content="common.goBack" />
      </Button>
    </div>
  );
});
