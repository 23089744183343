const date = {
  creation: 'payment.detail.createdAt',
  payment: 'payment.detail.paymentDate',
  startDispute: 'payment.detail.startDispute',
  chargeback: 'payment.detail.chargeback',
  expiring: 'payment.detail.expiredDate',
  cancelled: 'payment.detail.cancelledDate',
};

class PaymentDate {
  constructor(label, dateMoment) {
    this.label = label;
    this.dateString = dateMoment ? dateMoment.format('D MMM, H:mm') : '';
  }
}

export const getPaymentDates = payment => {
  const dates = {
    pending: [new PaymentDate(date.creation, payment.created_at)],
    partial: [new PaymentDate(date.creation, payment.created_at)],
    paid: [
      new PaymentDate(date.payment, payment.payed_at),
      new PaymentDate(date.creation, payment.created_at),
    ],
    network_dispute: [
      new PaymentDate(date.startDispute, payment.start_dispute),
      new PaymentDate(date.payment, payment.payed_at),
      new PaymentDate(date.creation, payment.created_at),
    ],
    chargeback: [
      new PaymentDate(date.chargeback, payment.chargeback_date),
      new PaymentDate(date.startDispute, payment.start_dispute),
      new PaymentDate(date.payment, payment.payed_at),
      new PaymentDate(date.creation, payment.created_at),
    ],
    cancelled: [
      new PaymentDate(date.cancelled, payment.cancellation_date),
      new PaymentDate(date.creation, payment.created_at),
    ],
    expired: [
      new PaymentDate(date.expiring, payment.expiration_time),
      new PaymentDate(date.creation, payment.created_at),
    ],
  };
  return dates[payment.status];
};
