import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Translate from 'react-translate-component';
import classnames from 'classnames';
import { PaymentButton } from '../common/PaymentButton';
import Button from '../../../common/Button';
import { ActionLink } from '../../../common/Links';
import { STATES } from '../constants/states';

const styles = theme => ({
  container: {
    border: '1px solid',
    borderColor: theme.palette.primary.bluegrey,
    backgroundColor: theme.palette.primary.bg,
    borderRadius: '6px',
    marginTop: '55px',
    marginBottom: '80px',
  },
  innerContainer: {
    padding: '15px 40px 35px 25px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  iconColumn: {
    width: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  icon: {
    width: '26px',
    height: '26px',
  },
  textColumn: {
    flex: 12,
  },
  title: {
    color: theme.palette.primary.main,
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
});

export const SelectCallback = withStyles(styles)(props => {
  const { classes } = props;
  const {
    callbacks,
    selectCallback,
    sendRequest,
    selected_callback,
    state,
    canReset,
  } = props;
  return (
    <React.Fragment>
      <div className={classes.iconColumn}>
        <img
          src={require('../../../../img/payment-testing/sandbox-payment-testing.png')}
          alt="info"
          className={classes.icon}
        />
      </div>
      <div className={classes.textColumn}>
        <Typography
          variant="title"
          className={classnames([classes.title, 'f-16'])}
          gutterBottom
        >
          <Translate content="payment.testing.boxTitle" />
        </Typography>
        <Typography variant="caption">
          <Translate content="payment.testing.boxSubtitle" />
        </Typography>
        <Typography variant="caption" style={{ marginTop: '22px' }}>
          <Translate content="payment.testing.selectTitle" />
        </Typography>
        {callbacks &&
          callbacks.map((action, key) => {
            return (
              <PaymentButton
                action={action}
                key={key}
                handleChange={selectCallback}
                selected={selected_callback}
              />
            );
          })}
        <div className={classes.actionRow}>
          <Button
            variant="raised"
            color="primary"
            style={{ marginRight: '10px', marginTop: '15px' }}
            small
            onClick={sendRequest}
            disabled={state !== STATES.CALLBACK_SELECTED}
          >
            <Translate content="payment.testing.sendCallback" />
          </Button>
          {canReset && (
            <React.Fragment>
              {' '}
              <Typography variant="caption" style={{ marginRight: '2px' }}>
                <Translate content="common.or" />
              </Typography>
              &nbsp;
              <ActionLink onClick={props.resetPayment}>
                <Translate content="payment.testing.sendToInitalState" />
              </ActionLink>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
});

SelectCallback.propTypes = {
  callbacks: PropTypes.array.isRequired,
  sendRequest: PropTypes.func.isRequired,
  selectCallback: PropTypes.func.isRequired,
};
