import { WithStyles, withStyles } from '@material-ui/core';
import { style } from './styles';
import { Col, Row } from 'react-flexbox-grid';
import classNames from 'classnames';
import React from 'react';
import { SortableTableCell } from './SortableTableCell';

interface SortableTableRowProps extends WithStyles<typeof style> {
  children: React.ReactNode;
  className?: string;
  extraCol?: boolean;
  onClick?: () => void;
}

const SortableTableRow = withStyles(style)((props: SortableTableRowProps) => {
  const { children, className, classes, extraCol, ...otherProps } = props;
  return (
    <Row
      className={classNames(classes.sortableTableBodyRow, className)}
      {...otherProps}
    >
      <Col xs>
        <Row>
          <Col xs={extraCol ? 11 : 12}>
            <Row middle="xs" style={{ height: '100%' }}>
              {children}
            </Row>
          </Col>
          {extraCol && (
            <Col xs={1}>
              <Row>
                <SortableTableCell>
                  <img
                    src={require('../../../img/arrow/small.png')}
                    alt="go"
                    className={classes.arrowSmall}
                  />
                </SortableTableCell>
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
});

export { SortableTableRow };
