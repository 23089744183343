import store from '../store';
import { onErrorNotification, onSuccessNotification } from './common';
import translator from 'counterpart';

export default {
  getListMobile: (onSuccess, onError) => {
    const getAll = store.app.factory.make('GetMobilePosesAction');

    getAll
      .run()
      .then(onSuccess)
      .catch(onError);
  },
  getDetailMobile: (uuid, onSuccess, onError) => {
    const getMobilePos = store.app.factory.make('GetMobilePosAction');
    getMobilePos.routeParams = { pos_uuid: uuid };

    getMobilePos
      .run()
      .then(onSuccess)
      .catch(onError);
  },
  deactivate: (idPos, onSuccess, onError) => {
    const deactivateMobilePos = store.app.factory.make(
      'MobilePosDeactivationAction'
    );
    deactivateMobilePos.routeParams = {
      pos_uuid: idPos,
    };

    deactivateMobilePos
      .run()
      .then(
        onSuccessNotification(
          onSuccess,
          translator('POS.deactivate.success.message')
        )
      )
      .catch(onErrorNotification(onError));
  },
  generateNewClientIdAndSecret: (uuid, onSuccess, onError) => {
    const generateNewClientIdAndSecret = store.app.factory.make(
      'PosCredentialsResetAction'
    );
    generateNewClientIdAndSecret.routeParams = {
      pos_uuid: uuid,
    };

    generateNewClientIdAndSecret
      .run()
      .then(onSuccessNotification(onSuccess))
      .catch(onErrorNotification(onError));
  },
};
