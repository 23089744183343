import React from 'react';
import Translate from 'react-translate-component';
import { ROUTE_PARAMETERS_VALUE } from '../../../../constants/routes';

export const ACCOUNTS_TYPE = [
  {
    type: ROUTE_PARAMETERS_VALUE.ACCOUNT.bank,
    text: <Translate content="account.new.types.bank.text" />,
    // commissione: (
    //   <Translate content="account.new.types.bank.commissione" component="b" />
    // ),
    // paymentTime: (
    //   <Translate content="account.new.types.bank.paymentTime" component="b" />
    // ),
    features: [
      <Translate content="account.new.types.bank.features.1" />,
      <Translate content="account.new.types.bank.features.2" />,
      <Translate content="account.new.types.bank.features.3" />,
    ],
    icon: (
      <img src={require('../../../../img/bancario/large.png')} alt="bank" />
    ),
  },
];

export const BitcoinWalletsDetails = {
  ledger: {
    id: 'ledger',
    icon: undefined,
    name: 'Ledger',
    nextStep: 2,
    step2: {
      description: (
        <Translate content="account.new.bitcoin.other.ledger.step2.description" />
      ),
      steps: [
        {
          description: (
            <Translate content="account.new.bitcoin.other.ledger.step2.steps.1" />
          ),
          image: require('../../../../img/guides-screenshots/ledger/1.png'),
          stepNumber: '1.',
        },
        {
          description: (
            <Translate content="account.new.bitcoin.other.ledger.step2.steps.2" />
          ),
          image: require('../../../../img/guides-screenshots/ledger/2.png'),
          stepNumber: '2.',
        },
      ],
    },
    step3: {
      steps: [
        {
          description: (
            <Translate content="account.new.bitcoin.other.ledger.step3.steps.1" />
          ),
          image: require('../../../../img/guides-screenshots/ledger/3.png'),
          stepNumber: '1.',
        },
      ],
    },
  },
  myceliumAndroid: {
    id: 'mycelium',
    width33: true,
    icon: undefined,
    name: 'MyCelium (Android)',
    nextStep: 2,
    step2: {
      description: (
        <Translate content="account.new.bitcoin.other.myceliumAndroid.step2.description" />
      ),
      steps: [
        {
          description: (
            <Translate content="account.new.bitcoin.other.myceliumAndroid.step2.steps.1" />
          ),
          image: require('../../../../img/guides-screenshots/my-celium-android/1.png'),
          stepNumber: '1.',
        },
        {
          description: (
            <Translate content="account.new.bitcoin.other.myceliumAndroid.step2.steps.2" />
          ),
          image: require('../../../../img/guides-screenshots/my-celium-android/2.png'),
          stepNumber: '2.',
        },
        {
          description: (
            <Translate content="account.new.bitcoin.other.myceliumAndroid.step2.steps.3" />
          ),
          image: require('../../../../img/guides-screenshots/my-celium-android/3.png'),
          stepNumber: '2.',
        },
      ],
    },
    step3: {
      steps: [
        {
          description: (
            <Translate content="account.new.bitcoin.other.myceliumAndroid.step3.steps.1" />
          ),
          image: require('../../../../img/guides-screenshots/my-celium-android/4.png'),
          stepNumber: '1.',
        },
        {
          description: (
            <Translate content="account.new.bitcoin.other.myceliumAndroid.step3.steps.2" />
          ),
          image: require('../../../../img/guides-screenshots/my-celium-android/5.png'),
          stepNumber: '2.',
        },
      ],
    },
  },
  myCeliumIOS: {
    id: 'mycelium',
    icon: undefined,
    name: 'MyCelium (IOS)',
    width33: true,
    nextStep: 2,
    step2: {
      steps: [
        {
          description: (
            <Translate content="account.new.bitcoin.other.myCeliumIOS.step2.steps.1" />
          ),
          image: require('../../../../img/guides-screenshots/my-celium-ios/1.png'),
          stepNumber: '1.',
        },
        {
          description: (
            <Translate content="account.new.bitcoin.other.myCeliumIOS.step2.steps.2" />
          ),
          image: require('../../../../img/guides-screenshots/my-celium-ios/2.png'),
          stepNumber: '2.',
        },
      ],
    },

    step3: {
      steps: [
        {
          description: (
            <Translate content="account.new.bitcoin.other.myCeliumIOS.step3.steps.1" />
          ),
          image: require('../../../../img/guides-screenshots/my-celium-ios/3.png'),
          stepNumber: '3.',
        },
      ],
    },
  },
  electrum: {
    id: 'electrum',
    icon: undefined,
    name: 'Electrum',
    nextStep: 2,
    explanation: true,
    step2: {
      steps: [],
    },
    step3: {
      description: (
        <Translate content="account.new.bitcoin.other.electrum.step3.description" />
      ),
      steps: [
        {
          description: (
            <Translate content="account.new.bitcoin.other.electrum.step3.steps.1" />
          ),
          image: require('../../../../img/guides-screenshots/electrum/1.png'),
          stepNumber: '1.',
        },
        {
          description: (
            <Translate content="account.new.bitcoin.other.electrum.step3.steps.2" />
          ),
          image: require('../../../../img/guides-screenshots/electrum/2.png'),
          stepNumber: '2.',
        },
      ],
    },
  },
  samourai_wallet: {
    id: 'samourai_wallet',
    icon: undefined,
    name: 'Samourai',
    explanation: true,
    nextStep: 2,
    width33: true,
    step2: {
      steps: [],
    },
    step3: {
      description: (
        <Translate content="account.new.bitcoin.other.samourai.step3.description" />
      ),
      steps: [
        {
          description: (
            <Translate content="account.new.bitcoin.other.samourai.step3.steps.1" />
          ),
          image: require('../../../../img/guides-screenshots/samourai/1.png'),
          stepNumber: '1.',
        },
        {
          description: (
            <Translate content="account.new.bitcoin.other.samourai.step3.steps.2" />
          ),
          image: require('../../../../img/guides-screenshots/samourai/2.png'),
          stepNumber: '2.',
        },
        {
          description: (
            <Translate content="account.new.bitcoin.other.samourai.step3.steps.3" />
          ),
          image: require('../../../../img/guides-screenshots/samourai/3.png'),
          stepNumber: '3.',
        },
      ],
    },
  },
};
