import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Translate from 'react-translate-component';

const styles = theme => ({
  label: {
    marginBottom: '10px',
    color: theme.palette.red.main,
  },
});

const InvalidFormMessage = withStyles(styles)(props => {
  const { errors, classes } = props;
  const hasErrors =
    errors &&
    Object.keys(errors).filter(
      key => errors[key] !== null && errors[key] !== undefined
    ).length > 0;
  return (
    hasErrors && (
      <Typography className={classes.label} variant="caption">
        <Translate content="errors.invalidForm" />
      </Typography>
    )
  );
});

InvalidFormMessage.defaultProps = {
  errors: {},
};

export default InvalidFormMessage;
