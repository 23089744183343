import React from 'react';

import classnames from 'classnames';

import PropTypes from 'prop-types';

import Translate from 'react-translate-component';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import PageContainer from './PageContainer';
import { LinkPrimary } from './Links';

const styles = theme => ({
  row: {
    minHeight: '129px',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.primary.bluegreylight,
    backgroundColor: theme.palette.common.white,
    margin: '0px',
  },
  mainRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    minWidth: '200px',
    marginLeft: '140px',
  },
  filtersRow: {
    backgroundColor: theme.palette.common.white,
    margin: '0px',
    border: 'none !important',
  },
  shadow: {
    marginBottom: '2px',
    ...theme.shadow,
  },
  tabFilterBar: {
    height: '45px',
  },
  tabFilter: {
    textTransform: 'none',
    minWidth: 0,
    fontSize: '14px',
    fontWeight: 600,
    opacity: '0.5',
    color: theme.palette.primary.main,
  },
  tabFilterSelected: {
    opacity: '1',
  },
  messageFilterBar: {
    borderTop: 'solid 1px',
    borderBottom: 'solid 1px',
    borderTopColor: theme.palette.primary.bluegreylight,
    borderBottomColor: theme.palette.primary.bluegreylight,
    height: '45px',
    fontSize: '14px',
    fontWeight: 600,
    color: '#999999',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  removeFilter: {
    textAlign: 'right',
    float: 'right',
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  indicator: {
    height: '3px',
  },
  flexContainer: {
    height: '100%',
  },
  filtersRoot: {
    height: '45px !important',
    minHeight: '45px',
  },
  hiddenOverflow: {
    overflowY: 'hidden',
  },
  withoutFilter: {
    height: '174px',
  },
});

const SubHeader = props => {
  const {
    description,
    action,
    classes,
    title,
    goBack,
    children,
    withoutFilter,
  } = props;
  return (
    <React.Fragment>
      <div
        className={classnames({
          [classes.row]: true,
          [classes.shadow]: withoutFilter,
          [classes.withoutFilter]: withoutFilter,
        })}
        middle="xs"
      >
        <PageContainer top>
          <div className={classes.mainRow}>
            <div>
              {goBack && (
                <Typography
                  style={{ marginBottom: '15px' }}
                  variant="caption"
                  gutterBottom
                >
                  <LinkPrimary to={goBack.url} back>
                    {goBack.text}
                  </LinkPrimary>
                </Typography>
              )}
              {title && (
                <Typography variant="title" className="montserrat" gutterBottom>
                  {title}
                </Typography>
              )}
              <Typography variant="body1" gutterBottom>
                {description}
              </Typography>
            </div>
            {action && <div className={classes.button}>{action}</div>}
          </div>
        </PageContainer>
      </div>
      {children}
    </React.Fragment>
  );
};

SubHeader.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  action: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  goBack: PropTypes.shape({
    url: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  }),
};

const Filter = withStyles(styles)(props => {
  const { className, children, classes } = props;
  return (
    <div
      className={classnames([classes.shadow, classes.filtersRow, className])}
    >
      <PageContainer>
        <div className={className}>{children}</div>
      </PageContainer>
    </div>
  );
});

const TabFilter = withStyles(styles)(props => {
  const { classes, value, values_dict, onClick, ...otherProps } = props;
  if (!values_dict) {
    return null;
  }
  return (
    <Filter className={classes.tabFilterBar}>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        classes={{
          indicator: classes.indicator,
          flexContainer: classes.flexContainer,
          root: classes.filtersRoot,
          fixed: classes.hiddenOverflow,
        }}
        {...otherProps}
      >
        {Object.keys(values_dict).map((value, k) => (
          <Tab
            classes={{
              root: classes.tabFilter,
              selected: classes.tabFilterSelected,
            }}
            key={k}
            value={value}
            label={values_dict[value]}
            onClick={() => onClick(value)}
          />
        ))}
      </Tabs>
    </Filter>
  );
});

TabFilter.propTypes = {
  value: PropTypes.string.isRequired,
  values_dict: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

const MessageFilter = withStyles(styles)(props => {
  const { classes, message, removeFilter } = props;
  return (
    <Filter className={classes.messageFilterBar}>
      <Typography variant="body1">{message}</Typography>
      <div onClick={removeFilter} className={classes.removeFilter}>
        <Translate content="payment.filters.remove_filter" />
      </div>
    </Filter>
  );
});

MessageFilter.propTypes = {
  message: PropTypes.element.isRequired,
  removeFilter: PropTypes.func.isRequired,
};

export default withStyles(styles)(SubHeader);

export { TabFilter, MessageFilter };
