import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { ROUTE_PARAMETERS, ROUTES } from '../../../constants/routes';
import PageContainer from '../../common/PageContainer';
import SubHeader from '../../common/SubHeader';
import { Row } from 'react-flexbox-grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { TIPO_POS } from './constants/index';
import GenericSelectable from '../../common/GenericSelectable';
import Avatar from '@material-ui/core/Avatar';
import Translate from 'react-translate-component';
import { WebPOS, MobilePOS } from '../list/common';
import Button from '../../common/Button';
import { LinkPrimary } from '../../common/Links';
import { blockIfEmailNotVerified } from '../../common/BlockUnverifiedHOC';
import classnames from 'classnames';
import store from '../../../store/index';
import { withGenericErrorHandling } from '../../common/ErrorHandling';
import { buildPath } from '../../../utils/routes';
import NoAccountModal from '../common/NoAccountModal';
import AccountsAPI from '../../../api/Accounts';

const styles = theme => ({
  containerPOS: {
    border: '1px solid',
    borderColor: theme.palette.grey.light,
    backgroundColor: theme.palette.common.white,
    margin: '0px 10px',
    borderRadius: '6px',
    boxShadow: '0 1px 2px 0 #d7dfe5',
  },
  containerNewPOS: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
  },
  avatar: {
    width: '60px',
    height: '60px',
    backgroundColor: 'transparent',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-beetween',
    alignItems: 'center',
    marginTop: '40px',
  },
  headerPOS: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '15px',
  },
  headerPOSBottom: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey.light,
    paddingBottom: '30px',
  },
  containerStepPOS: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '20px 10px 0px',
  },
  stepPOS: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    margin: '0px 11px',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      display: 'block',
    },
  },
  textStepPOS: {
    fontWeight: '500',
    fontSize: '14px',
  },
  captionLink: {
    color: 'blue',
    cursor: 'pointer',
    fontSize: '13px',
    fontWeight: '600',
  },
  choicheTitle: {
    fontWeight: 600,
    fontSize: '18px',
    marginBottom: '10px',
    color: theme.palette.common.black,
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '25px 35px 50px 30px',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  containerChoiche: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0px 10px 0px 20px',
    minHeight: '80px',
  },
  imageStep: {
    height: '100px',
  },
  marginBottom10: {
    marginBottom: '10px',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '80%',
    marginTop: 'auto',
  },
  button: {
    margin: '0px 10px',
  },
  color7: {
    color: theme.palette.grey[7],
  },
  comingSoonContainer: {
    backgroundColor: theme.palette.primary.bg,
    filter: 'grayscale(100%)',
    opacity: 0.5,
  },
});

class NewPos extends React.Component {
  state = {
    selected: '',
    loading: false,
    modalOpen: true,
    hasAccounts: true,
  };

  componentDidMount() {
    AccountsAPI.getList(
      this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID],
      this.onAccountsLoaded
    );
  }

  handleChange = value => {
    if (this.state.selected === value) {
      this.setState({ selected: '' });
    } else {
      this.setState({ selected: value });
    }
  };

  handleNoAccountModalConfirm = () => {
    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    this.props.history.push(
      buildPath(ROUTES.ACCOUNT_NEW, {
        [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
      })
    );
  };

  createWebPos = () => {
    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    this.props.history.push(
      buildPath(ROUTES.POS_NEW_WEB, {
        [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
      })
    );
  };

  onAccountsLoaded = result => {
    this.setState({
      hasAccounts: result.bank.length,
    });
  };

  createMobilePOS = () => {
    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    this.props.history.push(
      buildPath(ROUTES.POS_NEW_MOBILE, {
        [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
      })
    );
  };

  render() {
    const { hasAccounts } = this.state;
    const { classes } = this.props;

    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];

    const emailNotVerified = !store.app.isVerified;
    return (
      <React.Fragment>
        <SubHeader
          description={<Translate content="POS.new.titles.suggestion" />}
          goBack={{
            text: <Translate content="POS.new.titles.parent" />,
            url: buildPath(ROUTES.POS_LIST, {
              [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
            }),
          }}
          title={<Translate content="POS.new.titles.title" />}
          withoutFilter
        />
        <PageContainer main grey>
          <div className={classes.container}>
            <Typography variant="subheading" className={classes.choicheTitle}>
              <Translate content="POS.new.types.title" />
            </Typography>
            <Row style={{ marginBottom: '20px', width: '80%' }}>
              {TIPO_POS.map((item, k) => {
                return (
                  <GenericSelectable
                    value={item.type}
                    id={item.type}
                    key={k}
                    handleChange={this.handleChange}
                    isActive={this.state.selected === item.type}
                    disabled={
                      !store.app.sandboxMode &&
                      (!store.app.hasAccount || emailNotVerified)
                    }
                  >
                    <div className={classes.containerChoiche}>
                      <img
                        src={item.icon}
                        alt={item.type}
                        style={{ marginRight: '15px', height: '44px' }}
                      />

                      <Typography variant="body2" className="f-18">
                        {item.text}
                      </Typography>
                    </div>
                  </GenericSelectable>
                );
              })}
            </Row>
            {this.state.selected === '' && (
              <Typography variant="caption">
                <Translate content="POS.new.types.hint" />
              </Typography>
            )}

            {this.state.selected === 'mobilePOS' && (
              <React.Fragment>
                <Typography
                  variant="subheading"
                  className={classes.choicheTitle}
                >
                  <Translate content="POS.new.types.store.title" />
                </Typography>
                <CreateMobilePOS onClick={this.createMobilePOS} />
              </React.Fragment>
            )}
            {this.state.selected === 'webPOS' && (
              <React.Fragment>
                <Typography
                  variant="subheading"
                  className={classes.choicheTitle}
                >
                  <Translate content="POS.new.types.webstore.title" />
                </Typography>
                <CreateWebPOS
                  onClick={this.createWebPos}
                  business_uuid={business_uuid}
                />
              </React.Fragment>
            )}
          </div>
          {!store.app.hasAccount &&
            !store.app.sandboxMode &&
            !emailNotVerified && (
              <NoAccountModal
                id="no-account-modal"
                open={!hasAccounts}
                onConfirm={this.handleNoAccountModalConfirm}
              />
            )}
        </PageContainer>
      </React.Fragment>
    );
  }
}

const CreateMobilePOS = withStyles(styles)(props => {
  const { classes, onClick } = props;
  return (
    <div style={{ width: '80%' }}>
      <div
        id="infoMobilePOS"
        className={classnames({
          [classes.containerPOS]: true,
        })}
      >
        <div
          className={classes.totalContainer}
          style={{ paddingBottom: '50px' }}
        >
          <div className={classes.logoContainer}>
            <Avatar
              src={require('../../../img/mobilePos/active/large.png')}
              className={classes.avatar}
            />
          </div>
          <div className={classes.containerNewPOS}>
            <div
              className={classnames({
                [classes.headerPOS]: true,
                [classes.headerPOSBottom]: true,
              })}
            >
              <div>
                <Typography variant="body2" gutterBottom>
                  <MobilePOS fontSize={22} />
                </Typography>
                <Typography variant="caption">
                  <Translate content="POS.new.types.store.suggestion" />
                </Typography>
              </div>
              <Button
                onClick={onClick}
                style={{ minWidth: '140px' }}
                id="createNewMobilePOS"
                color="green"
                variant="raised"
              >
                <Translate content="common.createNow" />
              </Button>
            </div>
            <div className={classes.containerStepPOS}>
              <div className={classes.stepPOS}>
                <img
                  src={require('../../../img/business-type/pos-phone.png')}
                  alt="web"
                  className={classnames([
                    classes.marginBottom10,
                    classes.imageStep,
                  ])}
                />
                <Typography variant="subheading">1.</Typography>
                <Typography
                  variant="body2"
                  className={classnames([
                    classes.marginBottom10,
                    classes.color7,
                  ])}
                >
                  <Translate content="POS.new.mobile.1.text" />
                </Typography>
                <LinkPrimary to="/hint" forward>
                  <Translate content="POS.new.mobile.1.hint" />
                </LinkPrimary>
              </div>
              <div className={classes.stepPOS}>
                <img
                  src={require('../../../img/business-type/pos-phone.png')}
                  alt="web"
                  className={classnames([
                    classes.marginBottom10,
                    classes.imageStep,
                  ])}
                />
                <Typography variant="subheading">2.</Typography>
                <Typography
                  variant="body2"
                  className={classnames([
                    classes.marginBottom10,
                    classes.color7,
                  ])}
                >
                  <Translate content="POS.new.web.2.text" />
                </Typography>
                <LinkPrimary to="/hint" forward>
                  <Translate content="POS.new.mobile.2.hint" />{' '}
                </LinkPrimary>
              </div>
              <div className={classes.stepPOS}>
                <img
                  src={require('../../../img/business-type/pos-phone.png')}
                  alt="web"
                  className={classnames([
                    classes.marginBottom10,
                    classes.imageStep,
                  ])}
                />
                <Typography variant="subheading">3.</Typography>
                <Typography
                  variant="body2"
                  className={classnames([
                    classes.marginBottom10,
                    classes.color7,
                  ])}
                >
                  <Translate content="POS.new.mobile.3.text" />
                </Typography>
                <LinkPrimary to="/hint" forward>
                  <Translate content="POS.new.mobile.3.hint" />
                </LinkPrimary>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const CreateWebPOS = withStyles(styles)(props => {
  const { classes, onClick } = props;
  return (
    <div style={{ width: '80%' }}>
      <div id="infoWebPOS" className={classes.containerPOS}>
        <div className={classes.totalContainer}>
          <div className={classes.logoContainer}>
            <Avatar
              src={require('../../../img/webPos/active/large.png')}
              className={classes.avatar}
            />
          </div>
          <div className={classes.containerNewPOS}>
            <div
              className={classnames({
                [classes.headerPOS]: true,
                [classes.headerPOSBottom]: true,
              })}
            >
              {' '}
              <div>
                <Typography variant="body2" gutterBottom>
                  <WebPOS fontSize={22} />
                </Typography>
                <Typography variant="caption">
                  <Translate content="POS.new.types.webstore.suggestion" />
                </Typography>
              </div>
              <Button
                onClick={onClick}
                style={{ minWidth: '140px' }}
                id="createNewWebPOS"
                color="orange"
                variant="raised"
              >
                <Translate content="common.createNow" />
              </Button>
            </div>
            <div className={classes.containerStepPOS}>
              <div className={classes.stepPOS}>
                <img
                  src={require('../../../img/business-type/webpos.png')}
                  alt="web"
                  className={classnames([
                    classes.marginBottom10,
                    classes.imageStep,
                  ])}
                />
                <Typography variant="subheading">1.</Typography>
                <Typography
                  variant="body2"
                  className={classnames([
                    classes.marginBottom10,
                    classes.color7,
                  ])}
                >
                  <Translate content="POS.new.web.1.text" />
                </Typography>
              </div>
              <div className={classes.stepPOS}>
                <img
                  src={require('../../../img/business-type/integrazione.png')}
                  alt="web"
                  className={classnames([
                    classes.marginBottom10,
                    classes.imageStep,
                  ])}
                />
                <Typography variant="subheading">2.</Typography>
                <Typography
                  variant="body2"
                  className={classnames([
                    classes.marginBottom10,
                    classes.color7,
                  ])}
                >
                  <Translate content="POS.new.web.2.text" />
                </Typography>
              </div>
              <div className={classes.stepPOS}>
                <img
                  src={require('../../../img/business-type/payments.png')}
                  alt="web"
                  className={classnames([
                    classes.marginBottom10,
                    classes.imageStep,
                  ])}
                />
                <Typography variant="subheading">3.</Typography>
                <Typography
                  variant="body2"
                  className={classnames([
                    classes.marginBottom10,
                    classes.color7,
                  ])}
                >
                  <Translate content="POS.new.web.3.text" />
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default withGenericErrorHandling(
  blockIfEmailNotVerified(withRouter(withStyles(styles)(observer(NewPos))))
);
