import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import GenericSelectable from '../../../common/GenericSelectable';

const styles = theme => ({
  container: {
    margin: '0px',
    padding: '10px 0px 0px',
  },
  innerContainer: {
    padding: '5px 10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: '40px',
  },
  nameSection: {
    marginRight: '10px',
  },
});
export const PaymentButton = withStyles(styles)(props => {
  const { classes, action, selected, ...otherProps } = props;
  return (
    <GenericSelectable
      width={100}
      noMarginLeft
      {...otherProps}
      id={action.name}
      value={action.name}
      isActive={selected === action.name}
      className={classes.container}
    >
      <div className={classes.innerContainer}>
        <div className={classes.nameSection}>
          <Typography variant="body2">{action.name}</Typography>
        </div>
        <div>
          <Typography variant="caption">{action.description}</Typography>
        </div>
      </div>
    </GenericSelectable>
  );
});

PaymentButton.propTypes = {
  action: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
  ...GenericSelectable.propTypes,
};
