import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../common/Loader';
import UnauthenticatedPage from '../common/UnauthenticatedPage';
import store from '../../store';
import Translate from 'react-translate-component';
import { ROUTES, ROUTE_PARAMETERS } from '../../constants/routes';
import { withGenericErrorHandling } from '../common/ErrorHandling';
import { buildPath, matchesRoute } from '../../utils/routes';

const styles = theme => ({
  button: {
    width: '290px',
    marginTop: '35px',
  },
});

class VerificaEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: 'loading',
    };
  }

  componentDidMount() {
    const token = this.props.match.params[ROUTE_PARAMETERS.TOKEN];
    const location = this.props.location.pathname;
    const action = matchesRoute(ROUTES.CONFIRM_EMAIL, location)
      ? store.app.confirmEmailChange
      : store.app.verify;

    action(token)
      .then(() => {
        this.setState({
          state: 'verified',
        });
      })
      .catch(e => {
        if (e.error_code === '1040') {
          this.setState({
            state: 'alreadyVerified',
          });
        } else {
          this.setState({
            state: 'notVerified',
          });
        }
      });
  }

  completeAction = () => {
    store.app.reloadInfoAndKeepToken(() => {
      this.props.history.push(buildPath('/'));
    });
  };

  render() {
    const { state } = this.state;
    const { classes } = this.props;

    if (state === 'loading') {
      return (
        <UnauthenticatedPage>
          <Loader />
        </UnauthenticatedPage>
      );
    } else {
      if (state === 'verified') {
        return (
          <UnauthenticatedPage
            title={<Translate content="common.verifiedEmail" />}
            width={450}
            icon={require('../../img/snackbar/success.png')}
          >
            <Button
              variant="raised"
              color="primary"
              className={classes.button}
              onClick={this.completeAction}
              id="goOn"
            >
              <Translate content="common.goOn" />
            </Button>
          </UnauthenticatedPage>
        );
      } else if (state === 'notVerified') {
        return (
          <UnauthenticatedPage
            title={<Translate content="common.errorEmail" />}
            width={450}
            icon={require('../../img/snackbar/error.png')}
          />
        );
      } else if (state === 'alreadyVerified') {
        return (
          <UnauthenticatedPage
            title={<Translate content="common.alreadyVerified" />}
            width={450}
            icon={require('../../img/snackbar/alert_general.png')}
          >
            <Button
              variant="raised"
              color="primary"
              className={classes.button}
              onClick={this.completeAction}
              id="goOn"
            >
              <Translate content="common.goOn" />
            </Button>
          </UnauthenticatedPage>
        );
      }
    }
  }
}

export default withGenericErrorHandling(withStyles(styles)(VerificaEmail));
