import React from 'react';

import { Col, Row } from 'react-flexbox-grid';

import { withStyles } from '@material-ui/core/styles';

import { style } from './styles';

const SortableTable = withStyles(style)(props => {
  const { classes, children, ...otherProps } = props;
  return (
    <Row {...otherProps} className={classes.sortableTable}>
      <Col xs>{children}</Col>
    </Row>
  );
});

export { SortableTable };
