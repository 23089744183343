import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { withoutSandboxModeUI } from './SandboxHOC';
import store from '../../store';

const stylesSnackBar = theme => ({
  success: {
    backgroundColor: theme.palette.green.bg,
    color: theme.palette.green.main,
    borderColor: theme.palette.green.light,
  },
  error: {
    backgroundColor: theme.palette.red.bg,
    color: theme.palette.red.main,
    borderColor: theme.palette.red.main,
  },
  warning: {
    backgroundColor: theme.palette.orange.bg,
    color: theme.palette.orange.main,
    borderColor: theme.palette.orange.light,
  },
  mail: {
    backgroundColor: theme.palette.green.bg,
    color: theme.palette.green.main,
    borderColor: theme.palette.green.light,
  },
  snackBarRoot: {
    position: 'absolute',
    top: '130px',
    borderRadius: '6px',
    border: 'solid 1px',
    height: '60px',
    width: '90%',
  },
  maxWidth: {
    '@media (min-width: 960px)': {
      width: '100%',
    },
  },
  message: {
    color: 'inherit',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '16px',
  },
  contentMessage: {
    maxWidth: '90%',
  },
  close: {
    height: '30px',
    width: '31px',
    cursor: 'pointer',
  },
});
const iconSignal = {
  success: require('../../img/snackbar/success.png'),
  error: require('../../img/snackbar/error.png'),
  warning: require('../../img/snackbar/alert_general.png'),
  mail: require('../../img/snackbar/success_mail.png'),
};
const iconClose = {
  success: require('../../img/snackbar/success_close.png'),
  error: require('../../img/snackbar/error_close.png'),
  warning: require('../../img/snackbar/alert_close.png'),
  mail: require('../../img/snackbar/success_close.png'),
};
export const SnackBarStyled = withoutSandboxModeUI(
  withStyles(stylesSnackBar)(props => {
    const { classes, className, snackBar, open, ...otherProps } = props;
    const icon = iconSignal[snackBar.type];
    const closeIcon = iconClose[snackBar.type];
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
          // vertical: "bottom",
          // horizontal: "right",
        }}
        id="snackbar"
        autoHideDuration={3000}
        open={open}
        onClose={store.app.snackBar.close}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        classes={{
          root: classes.maxWidth,
        }}
      >
        <SnackbarContent
          className={classnames(
            classes[snackBar.type],
            className,
            classes.snackBarRoot,
            classes.maxWidth
          )}
          aria-describedby="client-snackbar"
          classes={{
            message: classes.contentMessage,
          }}
          message={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <img
                src={icon}
                alt="type"
                style={{ marginRight: '10px', height: '28px', width: '28px' }}
              />
              <Typography id="client-snackbar" className={classes.message}>
                {snackBar.message}
              </Typography>
            </div>
          }
          action={[
            <img
              src={closeIcon}
              key="close"
              alt="close"
              onClick={snackBar.close}
              className={classes.close}
            />,
          ]}
          {...otherProps}
        />
      </Snackbar>
    );
  })
);
