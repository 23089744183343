import { geocodeByAddress } from 'react-places-autocomplete';

/*
 * A Google API address is structured as follows:
 *
 * Object address = {
 *   'address_component': [
 *     {
 *       long_name: String,
 *       short_name: String,
 *       types: [String, ...],
 *       },
 *   ],
 *   ...
 * }
 *
 * For an italian address, the types are the following:
 * - country
 * - postal_code
 * - administrative_area_level_1 (regione)
 * - administrative_area_level_2 (provincia)
 * - administrative_area_level_3 (comune)
 * - locality (opzionale?)
 * - route (street)
 * - street_number
 */
class Address {
  static fields = [
    'country',
    'postal_code',
    'administrative_area_level_1',
    'administrative_area_level_2',
    'administrative_area_level_3',
    'locality',
    'route',
    'street_number',
  ];

  constructor(address) {
    this.country = Address.getField(address, 'country')
      ? Address.getField(address, 'country').long_name
      : null;
    this.postal_code = Address.getField(address, 'postal_code')
      ? Address.getField(address, 'postal_code').long_name
      : null;
    this.regione = Address.getField(address, 'administrative_area_level_1')
      ? Address.getField(address, 'administrative_area_level_1').long_name
      : null;
    this.provincia = Address.getField(address, 'administrative_area_level_2')
      ? Address.getField(address, 'administrative_area_level_2').long_name
      : null;
    this.comune = Address.getField(address, 'administrative_area_level_3')
      ? Address.getField(address, 'administrative_area_level_3').long_name
      : null;
    this.locality = Address.getField(address, 'locality')
      ? Address.getField(address, 'locality').long_name
      : null;
    this.street = Address.getField(address, 'route')
      ? Address.getField(address, 'route').long_name
      : null;
    this.street_number = Address.getField(address, 'street_number')
      ? Address.getField(address, 'street_number').long_name
      : null;
    this.full_address = address.formatted_address;
    this.latitude = address.geometry.location.lat().toString();
    this.longitude = address.geometry.location.lng().toString();
  }

  static getField(addr, field) {
    return addr.address_components.find(el => el.types.includes(field));
  }

  toJson() {
    return {
      country: this.country,
      city: this.locality,
      street: `${this.street} ${this.street_number}`,
      zip: this.postal_code,
      lat: this.latitude,
      lon: this.longitude,
    };
  }

  static getAddress(addr) {
    return new Promise((resolve, reject) => {
      geocodeByAddress(addr)
        .then(result => {
          resolve(result[0]);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  // static isValidAsync(addr) {
  //   return new Promise((resolve, reject) => {
  //     Address.getAddress(addr)
  //       .then((address) => {
  //         // if (addr !== address.formatted_address) {
  //         //   reject();
  //         // }
  //         if (Address.isValid(address)) {
  //           resolve();
  //         } else {
  //           reject();
  //         }
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //         reject();
  //       });
  //   });
  // }

  static isValid(addr) {
    return Address.fields.reduce(
      (acc, field) => acc && Address.getField(addr, field),
      true
    );
  }
}

export default Address;
