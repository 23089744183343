import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CustomModal from '../../../common/CustomModal';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { modalComponents } from './ModalLibrary';
const styles = theme => ({
  root: {
    padding: '45px 42px 30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const CallbackModal = withStyles(styles)(
  class extends React.Component {
    render() {
      const {
        classes,
        callback_name,
        state,
        closeModal,
        payment,
        oldPayment,
        resetPayment,
        request,
        response,
        httpStatus,
        ...otherProps
      } = this.props;
      const ModalBody = modalComponents[state];
      return (
        <Dialog
          role="alert"
          fullWidth
          scroll="body"
          disableBackdropClick
          disableEscapeKeyDown
          {...otherProps}
        >
          <DialogContent
            classes={{
              root: [classes.root],
            }}
          >
            <ModalBody
              callback_name={callback_name}
              closeModal={closeModal}
              payment={payment}
              oldPayment={oldPayment}
              resetPayment={resetPayment}
              request={request}
              response={response}
              httpStatus={httpStatus}
            />
          </DialogContent>
        </Dialog>
      );
    }
  }
);

CallbackModal.propTypes = {
  callback_name: PropTypes.string,
  state: PropTypes.string,
  ...CustomModal.propTypes,
};
