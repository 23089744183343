export default {
  privacy: {
    title: 'Privacy',
    subtitle:
      'Visualizza e scarica le tue informazioni personali oppure revisiona come e con chi vuoi condividerle.',
    action: 'Gestione consensi',
  },
  account: {
    title: 'Account',
    subtitle:
      'Se non sei più interessato ai servizi ChainsidePay, scopri come fare per  eliminare in modo permanente il tuo account e le tue informazioni.',
    action: 'Elimina Account',
  },
  gestioneConsensi: {
    title: 'Gestione dei Consensi',
    text1: 'La nostra ',
    text2:
      ' contiene più informazioni sul modo in cui raccogliamo e usiamo le tue informazioni, sul modo in cui vengono condivise e su quanto le conserviamo. Illustra anche i tuoi diritti e come puoi esercitarli e il modo in cui operiamo e trasferiamo le tue informazioni all&apos;interno dei nostri servizi globali.',
    consensi: {
      first: 'Accetto che i miei dati vengano condivisi con terze parti',
      second: 'Voglio rimanere aggiornato su novità, offerte, ed eventi',
    },
  },
  deleteAccount: {
    title: 'Elimina Account',
    subtitle:
      "Questa procedura eliminerà in modo permanente il tuo account ChainsidePay. Non potrai più riattivare l'account né recuperare le informazioni in esso contenute.",
    pwdSection: 'Conferma la richiesta inserendo la tua password',
    action: 'Elimina Account',
    contactUs:
      'Se desideri comunque eliminare definitivamente il tuo account, ',
  },
  privacyPolicy: {
    title: 'Termini e condizioni del servizio',
    subtitle:
      'E’ necessario leggere e acconsentire ai termini del servizio per procedere. Se hai domande in merito, ',
  },
};
