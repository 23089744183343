import React from 'react';
import { Redirect } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';

export const withGenericErrorHandling = WrappedComponent => {
  return class extends React.Component {
    state = {
      genericError: false,
    };

    onError = (error, callback) => {
      Sentry.withScope(scope => {
        scope.setExtras({
          ...error,
        });
        scope.setLevel(Severity.Error);
        Sentry.captureMessage(
          `Error page shown on ${WrappedComponent.displayName ||
            WrappedComponent.name ||
            'Component'}`
        );
      });
      if (error.error_code === '3001') {
        this.setState({
          notFound: true,
        });
      } else {
        this.setState({ genericError: true });
      }
      if (callback) {
        callback();
      }
    };

    render() {
      if (this.state.notFound) {
        return <Redirect push to={ROUTES.NOT_FOUND} />;
      }
      if (this.state.genericError) {
        return <Redirect push to={ROUTES.GENERIC_ERROR} />;
      }
      return <WrappedComponent onError={this.onError} {...this.props} />;
    }
  };
};
