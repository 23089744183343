export default {
  titles: {
    edit: 'Modifica Dettagli',
    editEmail: 'Modifica Email',
    editPassword: 'Modifica Password',
    parent: 'Il mio Profilo',
    goBack: 'Torna al profilo',
  },
  edit: {
    subtitle: 'Mantieni aggiornati i dettagli del tuo profilo.',
    fields: {
      firstName: {
        label: 'Nome',
        errors: {
          empty: 'Inserire un nome',
        },
      },
      lastName: {
        label: 'Cognome',
        errors: {
          empty: 'Inserire un cognome',
        },
      },
      phone: {
        label: 'Telefono',
        placeholder: 'Inserisci il tuo numero di telefono',
        errors: {
          empty: 'Inserire un telefono',
          invalid: 'Inserire un telefono valido',
        },
      },
      password: {
        label: 'Password',
        placeholder: 'Inserisci la tua password',
        errors: {
          empty: 'Inserire la password',
        },
      },
    },
    submit: {
      error: 'Errore nella modifica dettagli',
      success: 'Dettagli modificati con successo',
    },
  },
  editEmail: {
    subtitle:
      'Aggiorna la tua email e inserisci la tua password per' +
      ' confermare. Ti invieremo una email di verifica al tuo nuovo indirizzo.',
    fields: {
      newEmail: {
        label: 'Nuova Email',
        placeholder: 'Inserisci la tua nuova email',
        errors: {
          empty: "Inserire un'email",
          invalid: "Inserire un'email valida",
        },
      },
      confirmEmail: {
        label: 'Conferma Email',
        placeholder: 'Conferma la tua nuova email',
        errors: {
          empty: "Inserire un'email",
          differ: 'Le email non coincidono',
        },
      },
      password: {
        label: 'Password',
        placeholder: 'Inserisci la tua password',
        errors: {
          empty: 'Inserire la password',
        },
      },
    },
    submit: {
      error: "Errore nella modifica dell'email",
      success: 'Richiesta modifica email inviata con successo',
    },
  },
  editPassword: {
    subtitle:
      'Cambia la tua password, inserendo quella attuale per confermare.',
    fields: {
      oldPassword: {
        label: 'Vecchia password',
        placeholder: 'Inserisci la tua vecchia password',
        errors: {
          empty: 'Inserire la vecchia password',
        },
      },
      newPassword: {
        label: 'Nuova password',
        placeholder: 'Inserisci la tua nuova password',
        errors: {
          empty: 'Inserire la nuova password',
          short: 'La nuova password è troppo corta',
        },
      },
      confirmPassword: {
        label: 'Conferma password',
        placeholder: 'Conferma la tua nuova password',
        errors: {
          empty: 'Inserire conferma nuova password',
          differ: 'Le password non coincidono',
        },
      },
    },
    submit: {
      success: 'Password modificata con successo',
      error: 'Errore nella modifica della password',
    },
  },
  confirmMessage: 'Conferma le modifiche inserendo la tua password',
  firstName: 'Nome',
  lastName: 'Cognome',
  phone: 'Telefono',
  email: 'Email',
  password: 'Password',
  newPassword: 'Nuova Password',
  passwordConfirm: 'Conferma Password',
  myCompanies: 'Le mie aziende',
  myDetails: 'I miei dettagli',
  myCredentials: 'Le mie credenziali',
  noCompanyAvailable: 'Nessuna azienda disponibile',
  piva: 'P.IVA',
  address: 'Sede',
  role: 'Ruolo',
  mailLanguage: 'Lingua per le comunicazioni email',
  stepperProfile: {
    configurationState: 'Avanzamento configurazione',
    business: 'Registra la tua azienda',
    firstBusiness:
      'Aggiungi la tua prima azienda ed inizia subito ad accettare pagamenti in bitcoin',
    firstBusinessAction: 'AGGIUNGI LA TUA AZIENDA',
    mail: 'Verifica la tua email',
    account: 'Crea un conto dove ricevere il denaro',
    pos: 'Crea un POS per accettare pagamenti',
    nextStep: 'Step successivo',
    completeSignUp: 'Completa la registrazione',
  },
  verifyMail: 'Verifica email',
  actions: {
    login: 'Accedi',
    seeDetails: 'Visualizza Dettagli',
    editDetails: 'Modifica Dettagli',
    editPassword: 'Modifica Password',
    editEmail: 'Modifica Email',
    business: {
      edit: 'Modifica',
    },
  },
};
