import React from 'react';
import PageContainer from '../../common/PageContainer';
import Typography from '@material-ui/core/Typography';
import Button from '../../common/Button';
import SubHeader, { TabFilter } from '../../common/SubHeader';
import { ListComponent } from './ListComponent';
import { withStyles } from '@material-ui/core/styles';
import ListGrid from '../../common/ListGrid/Grid';
import ListDetail from './ListDetail';
import { MobilePOSHelper, WebposHelper } from './Helper';
import { Link } from 'react-router-dom';
import { ROUTE_PARAMETERS, ROUTES } from '../../../constants/routes';
import Translate from 'react-translate-component';
import translator from 'counterpart';
import Loader from '../../common/Loader';
import { withGenericErrorHandling } from '../../common/ErrorHandling';
import '../../../styles/style.css';
import classnames from 'classnames';
import { buildPath } from '../../../utils/routes';
import store from '../../../store';
import { WebsiteLink } from '../../common/WebsiteLink';

const styles = theme => ({
  listSection: {
    margin: '30px 0px',
    width: '100%',
  },
  colList: {
    margin: '5px 0px',
  },
  rowList: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  containerDetail: {
    border: '1px solid',
    width: '300%',
    height: '100%',
    borderColor: theme.palette.grey.light,
  },
  helperDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '100px',
  },
  titlesSolo: {
    marginBottom: '14px',
  },
  titlesDescription: {
    marginBottom: '9px',
  },
  textHelper: {
    marginBottom: '22px',
  },
});

class ListaPos extends React.Component {
  state = {
    posList: {
      web: [],
      mobile: [],
    },
    filterSelected: 'all',
    loading: true,
  };

  componentDidMount() {
    store.app.onPosActivate(this.activateMobilePos);

    let idPos = this.props.match.params[ROUTE_PARAMETERS.POS_ID];
    this.getPos(idPos);
  }

  getPos = idPos => {
    const listaPos = {
      web: store.app.hasWebPos ? store.app.webPoses : [],
      mobile: store.app.hasMobilePos ? store.app.mobilePoses : [],
      all: [],
    };
    if (store.app.hasMobilePos && store.app.hasWebPos) {
      listaPos.all = listaPos.web.concat(listaPos.mobile);
    } else if (store.app.hasMobilePos) {
      listaPos.all = listaPos.mobile;
    } else if (store.app.hasWebPos) {
      listaPos.all = listaPos.web;
    }
    //FIXME valueList (in questo caso devi commentare tutta la funzione set state e scommentare quella qua sotto)
    // this.setState({loading:false});

    //START FUNZIONE
    this.setState({ posList: listaPos, loading: false }, () => {
      if (idPos) {
        //FIXME bad use, but I can't access to ref
        const domElement = document.getElementById(idPos);
        if (domElement) {
          domElement.click();
        }
      }
    });
    //ENDFUNZIONE
  };

  selectFilter = value => {
    if (value === this.state.filterSelected) {
      return;
    }
    this.setState({ filterSelected: value });
  };

  getPosList = () => {
    if (this.state.filterSelected === 'all') {
      return this.state.posList.all;
    }
    if (this.state.filterSelected === 'web') {
      return this.state.posList.web;
    }
    if (this.state.filterSelected === 'mobile') {
      return this.state.posList.mobile;
    }
  };

  activateMobilePos = ({ pos_uuid, activation_date, device }) => {
    const newList = this.state.posList.mobile.map(pos => {
      if (pos.uuid === pos_uuid) {
        let activatedPos = pos;
        activatedPos.active = true;
        activatedPos.activation_date = activation_date;
        activatedPos.device = device;
        return activatedPos;
      }
      return pos;
    });
    this.setState({
      posList: {
        mobile: newList,
        web: this.state.posList.web,
        all: [...this.state.posList.web, ...newList],
      },
    });
  };

  handlePosChange = (key, pos) => {
    let posList = this.state.posList.slice(0);
    posList[key] = pos;

    this.setState({
      posList: posList,
    });
  };

  render() {
    const { classes } = this.props;
    const { loading, filterSelected } = this.state;

    const posList = this.getPosList();

    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];

    const postElement =
      posList &&
      posList.map((item, k) => {
        return {
          preview: <ListComponent rkey={k} epos={item} />,
          detail: (
            <ListDetail
              id={'POSContainer-' + item.uuid}
              epos={item}
              business_uuid={business_uuid}
              key={k}
            />
          ),
          id: item.uuid,
        };
      });

    return (
      <React.Fragment>
        <SubHeader
          description={<Translate content="POS.list.subtitle" />}
          action={
            <Button
              variant="raised"
              color="primary"
              id="addPOS"
              fullWidth
              disabled={loading}
              component={props => (
                <Link
                  to={buildPath(ROUTES.POS_NEW, {
                    [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
                  })}
                  {...props}
                />
              )}
            >
              {<Translate content="POS.list.add" />}
            </Button>
          }
          withoutFilter={!this.state.posList || this.state.posList.length === 0}
        >
          <TabFilter
            onClick={this.selectFilter}
            value={filterSelected}
            values_dict={
              filterSelected === 'all' && (!posList || posList.length === 0)
                ? null
                : translator('POS.filter')
            }
          />
        </SubHeader>
        <PageContainer main grey>
          <div className={classes.listSection}>
            {loading && <Loader>Caricamento POS in corso...</Loader>}
            {posList && posList.length > 0 && (
              <ListGrid>{postElement}</ListGrid>
            )}
          </div>
          <div style={{ marginTop: '60px' }}>
            {!loading &&
              filterSelected === 'all' &&
              posList &&
              posList.length === 0 && <GenericEmptyList />}
            {!loading &&
              filterSelected === 'mobile' &&
              posList &&
              posList.length === 0 && <MobilePosEmptyList />}
            {!loading &&
              filterSelected === 'web' &&
              posList &&
              posList.length === 0 && <WebPosEmptyList />}
          </div>
        </PageContainer>
      </React.Fragment>
    );
  }
}

const GenericEmptyList = withStyles(styles)(props => {
  const { classes } = props;
  return (
    <div className={classes.helperDiv}>
      <Typography
        variant="title"
        className={classnames([classes.titlesSolo, 'section-create-title'])}
      >
        <Translate content="POS.list.suggestion.generic.title" />
      </Typography>
      <WebposHelper />
      <MobilePOSHelper />
      <Typography variant="caption" className={'section-create-link'}>
        <Translate content="POS.list.suggestion.generic.faq" />
        <WebsiteLink to="faq_root">
          <Translate content="POS.list.suggestion.faqLink" />
        </WebsiteLink>
      </Typography>
    </div>
  );
});

const WebPosEmptyList = withStyles(styles)(props => {
  const { classes } = props;
  return (
    <div className={classes.helperDiv}>
      <Typography
        variant="title"
        className={classnames([classes.titlesSolo, 'section-create-title'])}
      >
        <Translate content="POS.list.suggestion.web.title" />
      </Typography>
      <Typography
        variant="body1"
        className={classnames([
          classes.textHelper,
          'section-create-description',
        ])}
      >
        <Translate content="POS.list.suggestion.web.hint" />
      </Typography>
      <WebposHelper />
      <Typography variant="caption" className={'section-create-link'}>
        <Translate content="POS.list.suggestion.web.faq" />
        <WebsiteLink to="faq_root">
          <Translate content="POS.list.suggestion.faqLink" />
        </WebsiteLink>
      </Typography>
    </div>
  );
});

const MobilePosEmptyList = withStyles(styles)(props => {
  const { classes } = props;
  const sandboxMode = store.app.sandboxMode;
  return (
    <div className={classes.helperDiv}>
      {sandboxMode || (
        <React.Fragment>
          <Typography
            variant="title"
            className={classnames([classes.titlesSolo, 'section-create-title'])}
          >
            <Translate content="POS.list.suggestion.mobile.title" />
          </Typography>
          <Typography
            variant="body1"
            className={classnames([
              classes.textHelper,
              'section-create-description',
            ])}
          >
            <Translate content="POS.list.suggestion.mobile.hint" />
          </Typography>
        </React.Fragment>
      )}
      <MobilePOSHelper />
      <Typography variant="caption" className={'section-create-link'}>
        <Translate content="POS.list.suggestion.mobile.faq" />
        <WebsiteLink to="faq_root">
          <Translate content="POS.list.suggestion.faqLink" />
        </WebsiteLink>
      </Typography>
    </div>
  );
});

export default withGenericErrorHandling(withStyles(styles)(ListaPos));
