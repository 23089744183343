import React from 'react';
import { Prompt } from 'react-router-dom';

import PropTypes from 'prop-types';

import { Row, Col } from 'react-flexbox-grid';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '../../common/Button';
import InvalidFormMessage from '../../common/InvalidFormMessage';
import TextField from '../../common/Forms/TextField';

import store from '../../../store/index';
import Translate from 'react-translate-component';
import translator from 'counterpart';
import { withFormsValidation } from '../../common/Forms/FormsCommon';

const styles = theme => ({
  container: {
    margin: '0px 2px',
    borderRadius: '6px',
    boxShadow: '0 2px 4px 0 #d7dfe5',
    backgroundColor: theme.palette.common.white,
    border: 'solid 1px #d7dfe5',
  },
  innerContainer: {
    margin: '30px 40px 40px 30px',
  },
});

class BankForm extends React.Component {
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.props.initObject({
      [this.props.form.ids.uri$business_uuid]: store.app.business_uuid,
    });
  }

  save = event => {
    event.preventDefault();
    this.setState({
      loading: true,
    });

    const { onSuccess, object } = this.props;
    const ids = {
      holder: this.props.form.ids.form$holder,
      iban: this.props.form.ids.form$iban,
    };

    const name = object[ids.iban]
      ? object[ids.holder] + ' (**** ' + object[ids.iban].slice(-4) + ')'
      : undefined;

    this.props.handleChange({
      target: {
        id: this.props.form.ids.form$name,
        value: name,
      },
    });

    this.props.send(
      onSuccess,
      e => console.log(e),
      () => {
        this.setState({
          loading: false,
        });
      }
    );
  };

  render() {
    const { classes, object, errors, handleChange, validateField } = this.props;

    const { submitted } = this.state;

    const { loading } = this.state;

    return (
      <div className={classes.container}>
        <Prompt
          message={translator('common.exitAlertMessage')}
          when={!submitted}
        />
        <div className={classes.innerContainer}>
          <Typography
            variant="subheading"
            className="w600"
            style={{ margin: '30px 0px' }}
            gutterBottom
          >
            <Translate content="account.new.bank.title" />
          </Typography>
          <form onSubmit={this.save}>
            <BankFormFields
              object={object}
              form={this.props.form}
              errors={errors}
              disabled={loading}
              onChange={handleChange}
              validateField={validateField}
            />
            <InvalidFormMessage errors={errors} />
            <Row style={{ marginTop: '20px' }}>
              <Col xs={3}>
                <Button
                  id="creaConto"
                  variant="raised"
                  color="primary"
                  fullWidth
                  loading={loading}
                  onClick={this.save}
                >
                  <Translate content="account.new.action" />
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    );
  }
}

export default withFormsValidation('CreateBankAccountForm')(
  withStyles(styles)(BankForm)
);

class BankFormFields extends React.Component {
  static propTypes = {
    object: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  render() {
    const { object, errors } = this.props;
    const { onChange } = this.props;
    const { disabled } = this.props;

    const ids = {
      bankname: this.props.form.ids.form$bankname,
      bic: this.props.form.ids.form$bic,
      iban: this.props.form.ids.form$iban,
      holder: this.props.form.ids.form$holder,
    };

    return (
      <React.Fragment>
        <Row style={{ marginBottom: '20px' }}>
          <Col xs={6} style={{ paddingRight: '30px' }}>
            <TextField
              fullWidth
              id={ids.holder}
              name={ids.holder}
              type="text"
              label={<Translate content="account.edit.bank.intestatario" />}
              placeholder={translator('placeholder.intestatarioContoCorrente')}
              disabled={disabled}
              onChange={onChange}
              value={object[ids.holder]}
              error={Boolean(errors[ids.holder])}
              helperText={errors[ids.holder]}
              validateField={this.props.validateField}
            />
          </Col>
          <Col xs={6}>
            <TextField
              fullWidth
              id={ids.iban}
              name={ids.iban}
              type="text"
              label={<Translate content="account.edit.bank.IBAN" />}
              placeholder={translator('placeholder.IBAN')}
              disabled={disabled}
              onChange={onChange}
              value={object[ids.iban]}
              error={Boolean(errors[ids.iban])}
              helperText={errors[ids.iban]}
              validateField={this.props.validateField}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export { BankFormFields };
