import { withStyles } from '@material-ui/core';
import { style } from './styles';
import { Col, Row } from 'react-flexbox-grid';
import React from 'react';

const SortableTableBody = withStyles(style)(props => {
  const { children, classes, ...otherProps } = props;
  return (
    <Row className={classes.sortableTableBody} {...otherProps}>
      <Col xs>{children}</Col>
    </Row>
  );
});

export { SortableTableBody };
