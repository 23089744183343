import React from 'react';

import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  checkbox: {
    width: '16px',
    height: '16px',
    marginRight: '10px',
  },
  icon: {
    width: '16px',
    height: '16px',
  },
  labelRoot: {
    marginLeft: 0,
  },
  label: {
    fontWeight: theme.typography.weights.normal,
    color: theme.palette.grey[4],
  },
});

const CheckboxField = withStyles(styles)(
  class extends React.Component {
    render() {
      const {
        classes,
        id,
        checked,
        label,
        error,
        onChange,
        className,
        FormHelperTextProps,
        helperText,
        ...otherProps
      } = this.props;

      const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

      return (
        <FormControl
          aria-describedby={helperTextId}
          className={className}
          error={error}
        >
          <FormControlLabel
            classes={{
              root: classes.labelRoot,
              label: classes.label,
            }}
            control={
              <Checkbox
                id={id}
                classes={{
                  root: classes.checkbox,
                }}
                disableRipple={true}
                color="primary"
                checked={checked}
                icon={
                  <img
                    src={require('../../../img/form/checkbox-unselected.png')}
                    alt=""
                    className={classes.icon}
                  />
                }
                onChange={onChange}
                {...otherProps}
              />
            }
            label={label}
          />
          {helperText && (
            <FormHelperText id={helperTextId} {...FormHelperTextProps}>
              {helperText}
            </FormHelperText>
          )}
        </FormControl>
      );
    }
  }
);

CheckboxField.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  className: PropTypes.string,
  FormHelperTextProps: PropTypes.object,
  helperText: PropTypes.node,
};

export default CheckboxField;

export const GDPRCheckbox = props => {
  const { uuid, onChange, checked, ...otherProps } = props;

  const handleChange = () => {
    if (checked) {
      onChange(null);
    } else {
      onChange(uuid);
    }
  };
  return (
    <CheckboxField
      onChange={handleChange}
      checked={Boolean(checked)}
      {...otherProps}
    />
  );
};
