import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '../../common/Button';
import AnagAPI from '../../../api/Exchanges';
import InvalidFormMessage from '../../common/InvalidFormMessage';
import { blockIfEmailNotVerified } from '../../common/BlockUnverifiedHOC';
import { withGenericErrorHandling } from '../../common/ErrorHandling';
import TextField from '../../common/Forms/TextField';
import CheckboxField from '../../common/Forms/CheckboxField';
import { AddressField } from '../../common/Forms/AddressField';
import AccountsAPI from '../../../api/Accounts';
import MobilePOSApi from '../../../api/MobilePoS';
import Translate from 'react-translate-component';
import translator from 'counterpart';
import { ROUTE_PARAMETERS, ROUTES } from '../../../constants/routes';
import '../../../styles/style.css';
import { withRouter, Prompt } from 'react-router-dom';
import ConfirmModal from './ConfirmModal';
import { buildPath } from '../../../utils/routes';
import store from '../../../store';
import NoAccountModal from '../common/NoAccountModal';
import { FormSectionTitle } from '../../common/Forms/FormSectionTitle';
import Map from './Map';
import Address from '../../common/Address';
import CredentialSection from './partial/CredentialSection';
import { AccountsSection, CurrencySection } from './partial';
import PosIsActiveOnGenerateModal from './partial/PosIsActiveOnGenerateModal';

const styles = theme => ({
  rowMargin: {
    marginBottom: '10px',
  },
  rowMarginExtra: {
    marginBottom: '20px',
  },
  containerConfirmation: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  accountContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px',
  },
  errorLabel: theme.typography.errorLabel,
  notActiveLabel: {
    backgroundColor: theme.palette.red.main,
    color: '#fff',
    fontWeight: 600,
    marginLeft: '11px',
    borderRadius: '4px',
    height: '24px',
  },
  disableItalic: {
    fontStyle: 'initial',
  },
  textMapContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  fieldContainer: {
    flex: 8,
    display: 'flex',
    flexDirection: 'column',
  },
  mapContainer: {
    flex: 4,
    alignSelf: 'stretch',
    marginLeft: '30px',
  },
});

class MobilePOS extends React.Component {
  static propTypes = {
    newPos: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    handleChangeGeneric: PropTypes.func.isRequired,
    toggleCheckbox: PropTypes.func.isRequired,
    signalError: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    errors: PropTypes.object,
    mobilePOS: PropTypes.shape({
      name: PropTypes.string,
      site: PropTypes.string,
      currency: PropTypes.string,
      confirimation: PropTypes.string,
      account: PropTypes.string,
      urlSuccess: PropTypes.string,
      urlError: PropTypes.string,
      urlCallback: PropTypes.string,
    }),
    business_uuid: PropTypes.string.isRequired,
  };

  state = {
    currencies: [],
    openModal: false,
    submitted: false,
    mobilePosWithoutDepositAccount: false,
    prevAccountSelected: null,
    bankAccountSelected: false,
    posActiveModal: false,
  };

  componentDidMount() {
    this.loadCurrency();
    this.loadAccounts();
    this.props.form.mergeKeys = ['form$store_address'];

    if (
      !this.props.newPos &&
      !this.props.mobilePOS[this.props.formIds.form$deposit_account]
    ) {
      this.checkMissingDepositAccount();
    }
  }

  loadCurrency = () => {
    AnagAPI.getCurrency(
      this.currencyLoaded,
      this.props.onError,
      'true' // only FIAT
    );
  };

  currencyLoaded = data => {
    this.setState({ currencies: data.currencies }, () => {
      if (data.currencies !== null && data.currencies.length === 1) {
        //FIXME accrocchio per mascherare l'event
        this.handleChangeCurrency({
          target: {
            value: data.currencies[0].uuid,
            id: this.props.formIds.form$currency,
          },
        });
      }
    });
  };

  loadAccounts = () => {
    const { business_uuid } = this.props;
    AccountsAPI.getList(business_uuid, this.accountsLoaded, this.props.onError);
  };

  accountsLoaded = accounts => {
    this.setState({
      bankAccounts: accounts.bank,
      bitcoinAccounts: accounts.bitcoin,
    });
  };

  handleChangeCurrency = event => {
    this.props.handleChange(event);
    this.setState({
      currency: event.target.value,
    });
  };

  handleChangeDeposit = accountId => {
    this.props.handleChangeGeneric(this.props.formIds.form$deposit_account)(
      accountId
    );
    this.setState({
      prevAccountSelected: accountId,
    });
  };

  getAccountList = () => {
    let accountsList = [];
    if (this.state.bankAccounts) {
      accountsList = accountsList.concat(this.state.bankAccounts);
    }
    if (this.state.bitcoinAccounts) {
      accountsList = accountsList.concat(this.state.bitcoinAccounts);
    }
    return accountsList;
  };

  onConfirm = event => {
    event.preventDefault();
    this.props.validate(isValid => {
      let depositAccount = this.props.mobilePOS[
        this.props.formIds.form$deposit_account
      ];
      if (
        isValid &&
        (depositAccount || this.state.mobilePosWithoutDepositAccount)
      ) {
        if (this.props.mobilePOS.form$store_address === {}) {
          this.props.handleChangeGeneric('form$store_address')(null);
        }
        this.setState({ openModal: true, submitted: true });
      }
    });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false, submitted: false });
  };

  generateSecret = () => {
    this.setState(
      {
        loadingSecret: true,
      },
      () => {
        MobilePOSApi.generateNewClientIdAndSecret(
          this.props.idPOS,
          result => {
            this.props.onResetCredentials(result.pos_id, result.secret);
            this.setState({
              loadingSecret: false,
            });
          },
          e => {
            this.setState({
              loadingSecret: false,
            });
          }
        );
      }
    );
  };

  handlePosAddress = value => {
    // retrieve the address
    if (!value || value === '') {
      this.props.handleChangeGeneric('form$store_address')(null);
      this.props.validateField('form$store_address');
    } else {
      const address_obj = new Address(value);
      const addressSDK = {
        city: address_obj.comune,
        country: address_obj.country,
        lat: address_obj.latitude,
        lon: address_obj.longitude,
        street: address_obj.street,
        zip: address_obj.postal_code,
        street_number: address_obj.street_number,
      };
      this.props.handleChangeGeneric('form$store_address')(addressSDK);
      this.props.validateField('form$store_address');
    }
  };

  toogleDisableAddress = () => {
    this.props.toggleCheckbox('form$no_store_address_available');
    this.props.handleChangeGeneric('form$store_address')(null);
  };

  toggleMobilePosWithoutDepositAccount = () => {
    this.setState(
      {
        mobilePosWithoutDepositAccount: !this.state
          .mobilePosWithoutDepositAccount,
      },
      () => {
        this.checkMissingDepositAccount();
      }
    );
  };

  checkMissingDepositAccount = () => {
    let mobilePosAccount = this.props.mobilePOS[
      this.props.formIds.form$deposit_account
    ];
    if (
      store.app.hasAccount &&
      !mobilePosAccount &&
      !this.state.mobilePosWithoutDepositAccount
    ) {
      this.state.prevAccountSelected
        ? this.handleDepositAccountChange(this.state.prevAccountSelected)
        : this.signalMissingDepositAccount();
    } else {
      this.handleDepositAccountChange(null);
    }
  };

  handleDepositAccountChange = value => {
    this.props.handleChangeGeneric(this.props.formIds.form$deposit_account)(
      value
    );
  };

  handleNoAccountModalConfirm = () => {
    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    this.props.history.push(
      buildPath(ROUTES.ACCOUNT_NEW, {
        [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
      })
    );
  };

  signalMissingDepositAccount = () => {
    this.props.signalError(
      this.props.formIds.form$deposit_account,
      translator('POS.edit.noAccountSelectedError')
    );
  };

  openAlertPosActive = () => {
    this.setState({ posActiveModal: true });
  };

  closeAlertPosActive = () => {
    this.setState({ posActiveModal: false });
  };

  render() {
    const {
      classes,
      mobilePOS,
      edit,
      business_uuid,
      handleChange,
      errors,
      newPos,
      formIds,
    } = this.props;
    const {
      openModal,
      submitted,
      mobilePosWithoutDepositAccount,
      posActiveModal,
    } = this.state;

    const accountsList = this.getAccountList();

    const ids = {
      currency: formIds.form$currency,
      depositAccount: formIds.form$deposit_account,
      instantOnly: formIds.form$instant_only,
      name: formIds.form$name,
      store_address: 'form$store_address',
      noStoreAddress: formIds.form$no_store_address_available,
    };
    const mobilePosAccount = mobilePOS[ids.depositAccount];
    const accountSelected =
      accountsList &&
      accountsList.filter(
        item => item.uuid === mobilePOS[ids.depositAccount]
      )[0];
    const currencySelected =
      this.state.currencies &&
      this.state.currencies.filter(currency => {
        return currency.uuid === mobilePOS[ids.currency];
      })[0];
    const hasAccounts = store.app.hasAccount;

    return (
      mobilePOS && (
        <React.Fragment>
          <Prompt
            message={translator('common.exitAlertMessage')}
            when={!submitted && store.app.isVerified && hasAccounts}
          />
          {!store.app.sandboxMode && !hasAccounts && store.app.isVerified && (
            <NoAccountModal
              id="no-account-modal"
              open={!hasAccounts}
              onConfirm={this.handleNoAccountModalConfirm}
            />
          )}
          <PosIsActiveOnGenerateModal
            handleClose={this.closeAlertPosActive}
            open={posActiveModal && edit}
          />

          <form onSubmit={this.onConfirm}>
            {edit && (
              <CredentialSection
                onClickGenerate={
                  mobilePOS.active
                    ? this.openAlertPosActive
                    : this.generateSecret
                }
                secret={mobilePOS.secret}
                clientId={mobilePOS.pos_id}
                loading={this.state.loadingSecret}
              />
            )}
            <FormSectionTitle>
              <Translate content="POS.mobile.where.title" />
            </FormSectionTitle>
            <Typography variant="caption" style={{ marginBottom: '25px' }}>
              <Translate content="POS.mobile.where.subtitle" />
            </Typography>
            <div className={classes.textMapContainer}>
              <div className={classes.fieldContainer}>
                <TextField
                  ref="testRef"
                  label={<Translate content="POS.mobile.name" />}
                  placeholder={translator('placeholder.nameSite')}
                  error={Boolean(errors[ids.name])}
                  helperText={errors[ids.name]}
                  value={mobilePOS[ids.name]}
                  id={ids.name}
                  name={ids.name}
                  onChange={handleChange}
                  fullWidth
                  validateField={this.props.validateField}
                  style={{ marginBottom: '25px' }}
                />
                <AddressField
                  ref="addressRef"
                  label={<Translate content="POS.mobile.address" />}
                  placeholder={translator('placeholder.indirizzo')}
                  error={Boolean(errors[ids.store_address])}
                  helperText={errors[ids.store_address]}
                  value={mobilePOS[ids.store_address]}
                  id={ids.store_address}
                  name={ids.store_address}
                  handleChange={this.handlePosAddress}
                  fullWidth
                  visible={!mobilePOS[ids.noStoreAddress]}
                  validateField={this.props.validateField}
                  style={{ marginBottom: '25px' }}
                  disabled={mobilePOS[ids.noStoreAddress]}
                />
                <span style={{ marginBottom: '25px' }}>
                  <CheckboxField
                    id={ids.noStoreAddress}
                    name={ids.noStoreAddress}
                    checked={mobilePOS[ids.noStoreAddress]}
                    onChange={this.toogleDisableAddress}
                    label={<Translate content="POS.mobile.disableAddress" />}
                    error={Boolean(errors[ids.noStoreAddress])}
                    helperText={errors[ids.noStoreAddress]}
                  />
                </span>
              </div>
              <div className={classes.mapContainer}>
                <Map position={mobilePOS[ids.store_address]} />
              </div>
            </div>
            <AccountsSection
              hasAccounts={hasAccounts}
              business_uuid={business_uuid}
              error={errors[ids.depositAccount]}
              handleChange={this.handleChangeDeposit}
              accountConnected={mobilePosAccount}
              accountsList={accountsList}
              isNewPos={newPos}
              posWithoutAccount={mobilePosWithoutDepositAccount}
              tooglePosWithoutAccount={
                this.toggleMobilePosWithoutDepositAccount
              }
            />
            <CurrencySection
              handleChange={this.handleChangeCurrency}
              currencyId={ids.currency}
              value={mobilePOS[ids.currency]}
              currencies={this.state.currencies}
              error={errors[ids.currency]}
            />
            <InvalidFormMessage errors={errors} />
            <Row className={classes.rowMargin}>
              <Col xs={4}>
                <Button
                  variant="raised"
                  id="save"
                  onClick={this.onConfirm}
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={this.state.loadingSecret}
                >
                  <Translate content="common.save" />
                </Button>
              </Col>
              <Col xs={2}>
                <Button
                  variant="outlined"
                  id="back"
                  onClick={this.props.history.goBack}
                  color="primary"
                  fullWidth
                  disabled={this.state.loadingSecret}
                >
                  <Translate content="common.back" />
                </Button>
              </Col>
            </Row>

            <ConfirmModal
              id={'confirmEditPosModal'}
              type="mobile"
              open={openModal}
              onConfirm={this.props.onConfirm}
              handleClose={this.handleCloseModal}
              pos={mobilePOS}
              account={accountSelected}
              submitting={this.props.submitting}
              currencyName={currencySelected && currencySelected.name}
              ids={ids}
            />
          </form>
        </React.Fragment>
      )
    );
  }
}

export default withGenericErrorHandling(
  blockIfEmailNotVerified(withRouter(withStyles(styles)(MobilePOS)))
);
