import React from 'react';
import PropTypes from 'prop-types';
import LogoWidget from './LogoWidget';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import Translate from 'react-translate-component';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '../Button';
import CustomModal from '../CustomModal';

const styles = theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '15px',
  },
  logoAndText: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
  },
  avatar: {
    height: '83px',
    width: '83px',
  },
  title: {
    fontSize: '16px',
    fontWeight: theme.typography.weights.normal,
    color: '#444444',
  },
  subtitle: {
    color: theme.palette.grey[4],
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: '40px',
  },
  actionContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  buttons: {
    width: '120px',
    marginLeft: '20px',
  },
  modalButtons: {
    maxWidth: '220px',
  },
  modalActionContainer: {
    marginTop: '40px',
  },
  modalTitle: {
    color: theme.palette.grey[4],
    fontWeight: theme.typography.fontWeightBold,
  },
});

class EditLogo extends React.Component {
  static propTypes = {
    edit: PropTypes.bool,
    originalImage: PropTypes.string,
  };

  state = {
    editing: false,
    openModal: false,
  };

  editLogo = () => {
    this.props.onChange(null);
    this.setState({
      editing: true,
    });
  };
  closeModal = () => {
    this.setState({
      openModal: false,
    });
  };
  openModal = () => {
    this.setState({
      openModal: true,
    });
  };

  restoreImage = () => {
    this.props.onChange(this.props.originalImage);
    this.setState({
      openModal: false,
      editing: false,
    });
  };

  render() {
    const { classes, edit, disabled, ...otherProps } = this.props;
    const { editing, openModal } = this.state;
    if (editing || !this.props.originalImage) {
      return (
        <LogoWidget
          restoreImage={this.restoreImage}
          edit={edit && this.props.originalImage}
          disabled={disabled}
          {...otherProps}
        />
      );
    }
    return (
      <div className={this.props.className}>
        <div className={classes.mainContainer}>
          <div className={classes.logoAndText}>
            <Avatar src={this.props.originalImage} className={classes.avatar} />
            <Typography variant="body1" className={classes.subtitle}>
              <Translate content="business.logo.editText" />
            </Typography>
          </div>
          <div className={classes.actionContainer}>
            <Button
              id="change"
              onClick={this.editLogo}
              disabled={disabled}
              variant="outlined"
              color="primary"
              customClass={classes.buttons}
              small
            >
              <Translate content="common.change" />
            </Button>
            <Button
              id="removeAction"
              onClick={this.openModal}
              variant="outlined"
              disabled={disabled}
              color="primary"
              customClass={classes.buttons}
              small
            >
              <Translate content="common.remove" />
            </Button>
          </div>
        </div>
        <RemoveModal
          open={openModal}
          onConfirm={this.editLogo}
          onAbort={this.closeModal}
        />
      </div>
    );
  }
}

const RemoveModal = withStyles(styles)(props => {
  const { open, onConfirm, onAbort, classes } = props;
  return (
    <CustomModal open={open} fitContent id="removeModal">
      <Typography
        variant="body1"
        className={classnames([classes.modalTitle, 'f-22 montserrat'])}
      >
        <Translate content="business.logo.remove" />
      </Typography>
      <div className={classes.modalActionContainer}>
        <Button
          id="abort"
          onClick={onAbort}
          variant="raised"
          color="primary"
          customClass={classes.modalButtons}
          style={{ marginRight: '10px' }}
        >
          <Translate content="common.abort" />
        </Button>
        <Button
          id="removeModalAction"
          onClick={onConfirm}
          variant="raised"
          color="red"
          customClass={classes.modalButtons}
          style={{ marginLeft: '10px' }}
        >
          <Translate content="common.remove" />
        </Button>
      </div>
    </CustomModal>
  );
});

export default withStyles(styles)(EditLogo);
