import React from 'react';
import sandboxTheme from '../../styles/sandboxModeTheme';
import defaultTheme from '../../styles/theme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import store from '../../store';

export const withSandboxModeUI = WrappedComponent => {
  return class extends React.Component {
    render() {
      const isSandboxMode = store.app.sandboxMode;
      return (
        <MuiThemeProvider theme={isSandboxMode ? sandboxTheme : defaultTheme}>
          <WrappedComponent {...this.props} />
        </MuiThemeProvider>
      );
    }
  };
};

export const withoutSandboxModeUI = WrappedComponent => {
  return class extends React.Component {
    render() {
      return (
        <MuiThemeProvider theme={defaultTheme}>
          <WrappedComponent {...this.props} />
        </MuiThemeProvider>
      );
    }
  };
};
