import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import classnames from 'classnames';
import Translate from 'react-translate-component';
import { ActionLink } from '../../../../common/Links';
import Button from '../../../../common/Button';
import React from 'react';
import { modalStyles } from './style';
import { WebsiteLink } from '../../../../common/WebsiteLink';

export const InvalidCallbackModal = withStyles(modalStyles)(props => {
  const { classes, callback_name, resetPayment } = props;
  return (
    <div className={classes.container}>
      <img
        src={require('../../../../../img/payment-testing/alert.png')}
        alt=""
        className={classes.topIcon}
      />
      <Typography
        variant="title"
        className={classnames([
          classes.invalidCallbacktitle,
          'f-22 monteserrat',
        ])}
      >
        <Translate content="payment.testing.invalidCallback.title" />
      </Typography>
      <Typography variant="body1">
        <Translate
          content="payment.testing.invalidCallback.text"
          with={{
            callback_name: callback_name,
          }}
        />
      </Typography>
      <div className={classes.helperTextContainer}>
        <Typography variant="body1" style={{ marginRight: '2px' }}>
          <Translate content="common.ifProblemTry" />
        </Typography>
        <ActionLink style={{ marginRight: '2px' }} onClick={resetPayment}>
          <Translate content="payment.testing.invalidCallback.sendToInitialState" />
        </ActionLink>
        <Typography variant="body1" style={{ marginRight: '2px' }}>
          <Translate content="common.or" />
        </Typography>
        <WebsiteLink withoutIcon to="contact_us">
          <Translate content="common.contactUsLowercase" />
        </WebsiteLink>
      </div>
      <Button
        variant="raised"
        color="primary"
        style={{ marginTop: '37px' }}
        onClick={() => {
          window.location.reload();
        }}
      >
        <Translate content="common.refreshPage" />
      </Button>
    </div>
  );
});
