import React from 'react';
import { Redirect, withRouter } from 'react-router';
import Button from '../common/Button';
import { withStyles } from '@material-ui/core/styles';
import TextField from '../common/Forms/TextField';
import { ROUTE_PARAMETERS, ROUTES } from '../../constants/routes';
import store from '../../store/index';
import UnauthenticatedPage from '../common/UnauthenticatedPage';
import translator from 'counterpart';
import Translate from 'react-translate-component';
import { withFormsValidation } from '../common/Forms/FormsCommon';

const styles = theme => ({
  textField: {
    width: '100%',
  },
  formControl: {
    width: '100%',
    margin: '10px 0px 20px',
  },
  submitButton: {
    width: '210px',
  },
  inputLabelFocused: {
    color: theme.palette.primary.main,
  },
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  mailSent: {
    padding: '10px 10px',
    width: '100%',
    margin: '15px 0px',
  },
  mailSendedMessage: {
    // color: theme.palette.font.green,
    fontWeight: '600',
  },
  login: {
    width: '100%',
    marginTop: '20px',
    display: 'inline-block',
  },
});

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
      newPassword: '',
      conferma_newPassword: '',
      errors: {},
      valid: false,
      submitting: false,
      submitted: false,
    };
  }

  componentDidMount() {
    const token = this.props.match.params[ROUTE_PARAMETERS.TOKEN];
    this.props.initObject({
      [this.props.form.ids.uri$activation_token]: token,
    });
  }

  submit = () => {
    this.setState({
      submitting: true,
    });

    this.props.send(
      () => {
        store.app.snackBar.open(
          translator('errors.changePasswordConfirm'),
          'success'
        );
        this.setState({
          submitting: false,
          submitted: true,
        });
      },
      () => {
        this.setState({
          submitting: false,
        });
      }
    );
  };

  render() {
    const { classes, object, validateField, handleChange, errors } = this.props;
    const { submitting, submitted } = this.state;

    if (submitted) {
      return <Redirect push to={ROUTES.LOGIN} />;
    }

    return (
      <UnauthenticatedPage
        title={
          <Translate content="common.recoveryPasswordSection.recovery.title" />
        }
        width={450}
      >
        <TextField
          id={this.props.form.ids.form$new_password}
          type="password"
          label={<Translate content="profile.newPassword" />}
          placeholder={translator('placeholder.newPassword')}
          disabled={submitting}
          style={{ marginTop: '45px' }}
          onChange={handleChange}
          value={object[this.props.form.ids.form$new_password]}
          error={Boolean(errors[this.props.form.ids.form$new_password])}
          helperText={errors[this.props.form.ids.form$new_password]}
          validateField={validateField}
        />
        <TextField
          id={this.props.form.ids.form$new_password_confirmation}
          type="password"
          label={<Translate content="profile.passwordConfirm" />}
          placeholder={translator('placeholder.passwordConfirm')}
          disabled={submitting}
          onChange={handleChange}
          value={object[this.props.form.ids.form$new_password_confirmation]}
          error={Boolean(
            errors[this.props.form.ids.form$new_password_confirmation]
          )}
          helperText={
            errors[this.props.form.ids.form$new_password_confirmation]
          }
          validateField={validateField}
        />
        <Button
          onClick={this.submit}
          variant="raised"
          color="primary"
          id="save"
          fullWidth
          loading={submitting}
          customClass={classes.submitButton}
        >
          <Translate content="common.confirm" />
        </Button>
      </UnauthenticatedPage>
    );
  }
}

export default withRouter(
  withFormsValidation('PasswordResetForm')(withStyles(styles)(ResetPassword))
);
