import { extendObservable, action } from 'mobx';

import translator from 'counterpart';

export default class Snackbar {
  constructor() {
    extendObservable(this, {
      visible: false,
      message: null,
      type: '',

      get isOpen() {
        return this.visible;
      },
    });
  }

  open = action((message, reason) => {
    this.message = message;
    this.visible = true;
    this.type = reason;
  });

  displayError = action(error => {
    let message;
    const genericErrorMessage = translator('messages.generic.ko');
    if (error && error.error_code) {
      message = translator(`errors.backend.${error.error_code}`, {
        fallback: genericErrorMessage,
      });
    } else {
      message = genericErrorMessage;
    }
    this.open(message, 'error');
  });

  close = action((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.message = null;
    this.visible = false;
    this.error = false;
    this.success = false;
  });
}
