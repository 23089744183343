import { StyleRulesCallback } from '@material-ui/core';

export const styles: StyleRulesCallback = (theme: { [key: string]: any }) => ({
  root: {
    overflowX: 'auto',
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  paymentStatusDot: {
    width: '10px',
    height: '10px',
    borderRadius: '10px',
    marginRight: '4px',
    display: 'inline-block',
    verticalAlign: '1px',
  },
  pending: {
    backgroundColor: theme.palette.primary.light,
  },
  partial: {
    backgroundColor: theme.palette.primary.light,
  },
  paid: {
    backgroundColor: theme.palette.green.light,
  },
  network_dispute: {
    backgroundColor: theme.palette.orange.main,
  },
  chargeback: {
    backgroundColor: theme.palette.red.main,
  },
  expired: {
    backgroundColor: theme.palette.primary.bluegreydark,
  },
  cancelled: {
    backgroundColor: theme.palette.grey[4],
  },
  created_at: {
    color: '#999999',
  },
  amount: {
    textAlign: 'right',
    color: theme.palette.grey[4],
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  pos_name: {
    color: '#999999',
  },
  reference: {
    color: '#999999',
  },
  iconFilter: {
    marginLeft: '10px',
    marginRight: '5px',
  },
  emptyListRow: {
    height: '50px',
    borderRadius: '6px',
    boxShadow: '0 1px 2px 0 #d7dfe5',
    border: 'solid 1px #d7dfe5',
  },
  shadowGrey: {
    backgroundColor: theme.palette.grey.main,
    height: '12px',
  },
  emptyListCol: {
    display: 'flex',
    alignItems: 'center',
  },
  faqSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '70px',
  },
  emptyListFiltered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  emptyListFilteredButton: {
    marginTop: '10px',
  },
  ellipsis: {
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
});
