import React from 'react';

import { observer } from 'mobx-react';

import { Redirect } from 'react-router';

import { ROUTE_PARAMETERS, ROUTES } from '../../constants/routes';

import store from '../../store';
import { buildPath } from '../../utils/routes';

class HomePage extends React.Component {
  render() {
    const isAuthenticated = store.app.isLoggedIn;

    if (!isAuthenticated) {
      return <Redirect to={ROUTES.LOGIN} />;
    }
    const hasBusiness = store.app.hasBusiness;

    if (!hasBusiness) {
      return <Redirect to={ROUTES.REGISTER_BUSINESS} />;
    }

    const isDelegated = store.app.isDelegated;

    if (!isDelegated) {
      return <Redirect to={ROUTES.PROFILE_VIEW} />;
    }

    const business_uuid = store.app.business_uuid;

    return (
      <Redirect
        to={buildPath(ROUTES.PAYMENT_ORDER_LIST, {
          [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
        })}
      />
    );
  }
}

export default observer(HomePage);
