import React from 'react';
import Modal from './CustomModal';
import Translate from 'react-translate-component';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Button from './Button';
import FaqLink from './FaqLink';
import { withCookies } from 'react-cookie';
import { withoutSandboxModeUI } from './SandboxHOC';
import store from '../../store';
import { buildWebsiteLink } from '../../utils/links';
import { COOKIE_POLICY_DOMAIN } from '../../config';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '610px',
  },
  title: {
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '10px',
  },
  mockDoc: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.primary.bluegrey,
    height: '50px',
    width: '100%',
    marginBottom: '10px',
    ...theme.shadow,
  },
});

class WelcomeModal extends React.Component {
  constructor(props) {
    super(props);

    const { cookies } = props;

    this.state = {
      open: true,
      hasCookie: cookies.get('welcomePopup'),
    };
  }

  closeModal = () => {
    const { cookies } = this.props;
    this.setState({ open: false }, () => {
      const today = new Date();
      const weekmillis = 7 * 24 * 60 * 60 * 1000;
      const expiringDate = new Date(today.getTime() + weekmillis);
      cookies.set('welcomePopup', true, {
        expires: expiringDate,
        path: '/',
        domain: COOKIE_POLICY_DOMAIN,
      });
    });
  };

  render() {
    const { open, hasCookie } = this.state;
    const { classes } = this.props;

    return (
      !store.app.sandboxMode && (
        <Modal open={open && !hasCookie} bluegrey handleClose={this.closeModal}>
          <div className={classes.container}>
            <Typography
              variant="subheading"
              className={classnames([classes.title, 'montserrat'])}
            >
              <Translate content="common.welcomeModal.title" />
            </Typography>
            <Typography
              variant="caption"
              className="color7"
              style={{ marginBottom: '30px', textAlign: 'center' }}
            >
              <Translate content="common.welcomeModal.subtitle" />
            </Typography>
            <Typography
              variant="caption"
              className="color7"
              style={{ marginBottom: '15px' }}
            >
              <Translate content="common.welcomeModal.help" />
            </Typography>
            <FaqLink
              text={<Translate content="common.welcomeModal.faq.1" />}
              url={buildWebsiteLink('chainside_pay_how_does_it_work')}
            />
            <FaqLink
              text={<Translate content="common.welcomeModal.faq.2" />}
              url={buildWebsiteLink('how_to_receive_bitcoin_website')}
            />
            <FaqLink
              text={<Translate content="common.welcomeModal.faq.3" />}
              url={buildWebsiteLink('how_to_receive_bitcoin_point_of_sale')}
            />
            <Typography
              variant="caption"
              className="montserrat color6 f-12"
              style={{ margin: '20px 0px 15px' }}
            >
              <Translate content="common.or" />
            </Typography>
            <Button
              variant="raised"
              color="primary"
              style={{ width: '260px' }}
              onClick={this.closeModal}
            >
              <Translate content="common.continue" />
            </Button>
          </div>
        </Modal>
      )
    );
  }
}

export const WelcomeModalSandboxMode = withoutSandboxModeUI(
  withCookies(
    withStyles(styles)(
      class extends React.Component {
        constructor(props) {
          super(props);

          const { cookies } = props;

          this.state = {
            open: true,
            hasCookie: cookies.get('welcomeSandboxModePopup'),
          };
        }

        closeModal = () => {
          const { cookies } = this.props;
          this.setState({ open: false }, () => {
            const today = new Date();
            const weekmillis = 7 * 24 * 60 * 60 * 1000;
            const expiringDate = new Date(today.getTime() + weekmillis);
            cookies.set('welcomeSandboxModePopup', true, {
              expires: expiringDate,
              path: '/',
            });
          });
        };
        render() {
          const { open, hasCookie } = this.state;
          const { classes } = this.props;
          const isSandboxMode = store.app.sandboxMode;
          return (
            <Modal
              open={open && !hasCookie && isSandboxMode}
              bluegrey
              handleClose={this.closeModal}
            >
              <div className={classes.container}>
                <Typography
                  variant="subheading"
                  className={classnames([classes.title, 'montserrat'])}
                >
                  <Translate content="common.sandboxModeModal.title" />
                </Typography>
                <Typography
                  variant="caption"
                  className="montserrat color7"
                  style={{ marginBottom: '30px', textAlign: 'center' }}
                >
                  <Translate content="common.sandboxModeModal.subtitle" />
                </Typography>
                <Typography
                  variant="caption"
                  className="montserrat color7"
                  style={{ marginBottom: '15px' }}
                >
                  <Translate content="common.sandboxModeModal.help" />
                </Typography>
                <FaqLink
                  text={<Translate content="common.sandboxModeModal.faq.1" />}
                  url={buildWebsiteLink('test_account_functionalities')}
                />
                <FaqLink
                  text={<Translate content="common.sandboxModeModal.faq.2" />}
                  url={buildWebsiteLink('test_account_user_impact')}
                />
                <Button
                  variant="raised"
                  color="primary"
                  style={{ width: '260px', marginTop: '60px' }}
                  onClick={this.closeModal}
                >
                  <Translate content="common.goOn" />
                </Button>
              </div>
            </Modal>
          );
        }
      }
    )
  )
);

export default withCookies(withStyles(styles)(WelcomeModal));
