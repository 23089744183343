import React, { FC } from 'react';
import { Map as MapComponent, Deactivated } from './partial';
import { MapProps } from './propTypes';

const Map: FC<MapProps> = ({ position }) => {
  if (position)
    return <MapComponent mapId="store_address" position={position} />;
  return <Deactivated />;
};

export default Map;
