export default {
  FAQ_SECTION: 'Need help?',
  more_info: 'More information',
  integrationProblems: 'Problems with integration?',
  createNow: 'create now',
  ok: 'Ok',
  moreInfo: 'More information',
  signUp: 'Register',
  goOn: 'Continue',
  goBack: 'Go back',
  confirm: 'Confirm',
  abort: 'Cancel',
  done: 'Done',
  save: 'Save',
  back: 'Back',
  edit: 'Edit',
  close: 'Close',
  deactivate: 'Disable',
  contactUs: 'Contact us',
  contactUsLowercase: 'contact us',
  choiche: 'Choose',
  show: 'Show',
  hide: 'Hide',
  addNow: 'Add it now',
  or: 'or',
  login: 'Login',
  continue: 'Continue',
  change: 'Change',
  remove: 'Remove',
  copied: 'Copied',
  utilsDoc: 'Useful documentation',
  createAccount: 'Create an account',
  send: 'Send',
  sendAgain: 'Send again',
  sending: 'Sending ...',
  inCorso: 'in progress ...',
  mailSended: 'Email sent',
  detail: 'Details',
  addBusiness: 'Add Company',
  addressHint: 'Select an address, including the house number:',
  documentation: 'documentation',
  searchingAddress: 'Searching for addresses ...',
  confirmEdit: 'Save your changes or cancel to go back to the details page.',
  verifiedEmail: 'Email address successfully verified!',
  alreadyVerified: 'Your account has already been verified!',
  errorEmail: 'There was a problem verifying the email',
  exitForm:
    'The data has not been saved, are you sure you want to exit the page?',
  ops: 'Ops ...',
  lastPage: 'previous page',
  homePage: 'Homepage',
  refreshPage: 'Reload the page',
  stop: 'Stop',
  loginSandbox: 'Access the sandbox',
  logoutSandbox: 'Exit the sandbox',
  otherBusiness: 'Other companies',
  nothingF: 'None',
  sandboxModeAccount:
    'You can use this sandbox for your tests, the changes will not be visible to your customers.',
  exitAlertMessage:
    'The data in the form will not be saved, sure you want to exit?',
  comingSoon: 'Soon available',
  amlCheck: 'Verification needed',
  normativaDati: 'Data regulation',
  yourBusiness: 'Your companies',
  goToPayments: 'Payment orders',
  cancel: 'Cancel',
  deleteOk: 'Profile successfully deleted',
  profileNotVerified:
    'Your account is not verified yet. Check it immediately by sending an email to the address provided. ',
  ifProblemTry: 'If the problem persists, try to',
  scroll: 'Scroll',
  readingCompleted: 'Reading completed',
  loginSection: {
    title: 'Login',
    passwordForget: 'I forgot my password',
    noAccount: "Don't have an account yet? ",
    signUp: 'Create an account',
  },

  signUpSection: {
    title: 'Create a free account',
    subtitle:
      'the first step to accept bitcoin on your website or in your stores. Do you already have an account?',
  },
  recoveryPasswordSection: {
    forgottenPassword: {
      title: 'Password Recovery',
      subtitle:
        'Have you lost your password? No problem, we will send the information to retrieve it at the address you registered with ',
      back: 'Back to login',
    },
    recovery: {
      title: 'Choose a new Password',
    },
  },
  trattamentoDeiDati: {
    title: 'Processing of your data',
    hint: 'Some info about the data.',
    firstConsenso: 'By registering on ChainsidePay, you accept ours ',
    secondConsenso: 'I agree that my data will be shared with third parties',
    thirdConsenso: 'I want to stay updated on news, offers, and events',
    privacyPolicy: 'Privacy Policy',
  },
  welcomeModal: {
    title: 'Welcome to ChainsidePay!',
    subtitle:
      'From here you can see and manage all your orders' + //TODO
      'payment, POS and accounts.',
    help:
      'Here are some guides to help you understand how to set up your account:',
    faq: {
      1: 'How does ChainsidePay work?',
      2: 'How to accept bitcoins on my website?',
      3: 'How to accept bitcoins in my store?',
    },
  },
  expiredSession: {
    title: 'The session has expired',
    subtitle: 'Update page to continue working in ChainsidePay',
  },
  sandboxModeModal: {
    title: 'Welcome to sandbox mode',
    subtitle:
      'This test account is a Sandbox that allows you to create and manage components without impacting your real account',
    help: 'For more information on what you can visit:',
    faq: {
      1: 'What can I do with the Test account?',
      2: 'Do the tests I do impact my users?',
    },
  },
  exitSandboxModal: {
    title: 'You are about to exit the Sandbox of %(companyName)s',
    text:
      'From now on the changes you make will have repercussions on the' +
      'your production environment and your real users',
  },
  emailNotVerified: {
    modal: {
      title: 'We sent you a verification email',
      titleBlocking: 'Ops ... you have not yet verified your email address',
      subtitle1: 'Click on the link in the verification email we have sent.',
      subtitle2: 'Did you not receive the verification email?',
    },
  },
  topbar: {
    myProfile: 'My profile',
    logout: 'Logout',
    businessDetail: 'Company details',
    staging: 'Staging account',
    production: 'Production account',
    sandboxInfo: 'Use the Sandbox to test POS and payment orders. ',
  },
  quote: {
    login: {
      quote:
        'I do think Bitcoin is the first encrypted money that has the potential to do something like change the world."',
      author: 'Peter Thiel, Co-Founder of Paypal',
    },
    signup: {
      quote:
        'I do think Bitcoin is the first encrypted money that has the potential to do something like change the world.',
      author: 'Peter Thiel, Co-Founder of Paypal',
    },
  },
  copyright:
    '© 2018 All Rights Reserved. ChainsidePay® is a registered trademark. ',
  menuBar: {
    account: 'Accounts',
    pos: 'POS',
    payment: 'Payments',
  },
  cookieAlert: {
    title: 'This site uses cookies',
    subtitle:
      'We use cookies to improve the experience of our users. By continuing to browse you accept their use in accordance with our Cookie Policy. ',
    action: 'Ok',
  },
};
