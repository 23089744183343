export default {
  supporto: {
    title: 'SUPPORTO',
    faqs: 'Faqs',
    documentazione: 'Documentazione',
  },
  condizioni: {
    title: 'CONDIZIONI',
    termini: 'Termini',
    privacy: 'Privacy',
  },
  lingua: {
    title: 'LINGUA',
    italiano: 'Italiano',
    inglese: 'Inglese',
  },
};
