import React, { FC } from 'react';
import styles from './theme';
import { StyledComponentProps, withStyles } from '@material-ui/core';

const AssociateDeviceContainer: FC<StyledComponentProps> = ({
  children,
  classes = {},
}) => <div className={classes.container}>{children}</div>;

export default withStyles(styles)(AssociateDeviceContainer);
