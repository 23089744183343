import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Translate from 'react-translate-component';
import classnames from 'classnames';
import Modal from '../../common/CustomModal';
import Typography from '@material-ui/core/Typography';
import GoToDetail from '../../common/GoToDetail';
import Button from '../../common/Button';

const styles = theme => ({
  innerContainer: {
    textAlign: 'center',
  },
  posContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '45px',
  },
  posItemClass: {
    width: '100%',
    marginTop: '10px',
  },
  modalContainer: {
    margin: '50px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  modalTitle: {
    fontSize: '22px',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '5px',
  },
});

class CannotRemoveAccountModal extends React.Component {
  render() {
    const {
      poses,
      open,
      handleClose,
      classes,
      submitting,
      ...otherProps
    } = this.props;

    return (
      <Modal {...otherProps} open={open} handleClose={handleClose}>
        <div style={{ textAlign: 'center' }}>
          <Typography
            variant="subheading"
            className={classnames([classes.modalTitle, 'montserrat'])}
          >
            <Translate content={'account.delete.notEmpty.title'} />
          </Typography>
          <Typography
            variant="body1"
            className="color7"
            style={{ fontSize: '16px' }}
          >
            <Translate content={'account.delete.notEmpty.subtitle'} />
            <br />
            <br />
            <Translate content={'account.delete.notEmpty.listHeading'} />
          </Typography>
          <div className={classes.posContainer}>
            {poses &&
              poses.map((pos, k) => (
                <GoToDetail
                  customClasses={classes.posItemClass}
                  name={pos.name}
                  id={pos.uuid}
                  type={pos.type}
                  key={k}
                  active={pos.active}
                  clickable={false}
                />
              ))}
          </div>
          <div className={classnames(classes.actionSectionCentered)}>
            <Button
              variant="raised"
              color="primary"
              onClick={handleClose}
              loading={submitting}
              style={{ flex: '1', marginRight: '10px', marginLeft: '20px' }}
            >
              <Translate content={'common.back'} />
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(CannotRemoveAccountModal);
