import React, { FC } from 'react';
import styles from './theme';
import { AssociateDeviceProps } from './propTypes';
import Typography from '@material-ui/core/Typography/Typography';
import classnames from 'classnames';
import Translate from 'react-translate-component';
import IconRow from '../../new/partial/IconRow';
import { DetailRow } from '../../../common/Confirm';
import CustomField from '../../../common/CustomField';
import { QRCode } from 'react-qr-svg';
import { withStyles } from '@material-ui/core';
import { LinkPrimary } from '../../../common/Links';
import {MOBILE_POS_LINKS} from "../../../../constants";

const AssociateDevice: FC<AssociateDeviceProps> = ({
  pos_id,
  secret,
  variant = 'primary',
  classes = {},
  leftColumnMinWidth = 500,
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    }}
  >
    <span>
      <Typography
        variant="subheading"
        className={classnames({
          [classes.sectionTitle]: true,
          montserrat: true,
          [classes.colorPrimary]: variant === 'primary',
          [classes.colorYellow]: variant === 'warning',
        })}
      >
        <Translate content="POS.confirmPage.associationTitle" />
      </Typography>
      <IconRow
        icon={require('../../../../img/mobilePos/activateSection/' +
          (variant === 'primary' ? 'confirm' : 'list') +
          '/scan.png')}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
          }}
        >
          <Typography variant="body2" style={{ alignSelf: 'center' }}>
            <Translate content="POS.confirmPage.mobile.merchant" />
            <LinkPrimary
              targetBlank
              to={MOBILE_POS_LINKS.playStore}
              external
            >
              Google Play Store
            </LinkPrimary>
          </Typography>
          {/*Waiting for app release - Coming soon*/}
          {/*<img*/}
          {/*  src={require('../../../../img/mobilePos/activateSection/' +*/}
          {/*    (variant === 'primary' ? 'confirm' : 'list') +*/}
          {/*    '/android.png')}*/}
          {/*  alt=""*/}
          {/*  style={{ height: '25px', width: '25px', marginLeft: '5px' }}*/}
          {/*/>*/}
          {/*<img*/}
          {/*  src={require('../../../../img/mobilePos/activateSection/' +*/}
          {/*    (variant === 'primary' ? 'confirm' : 'list') +*/}
          {/*    '/apple.png')}*/}
          {/*  alt=""*/}
          {/*  style={{ height: '25px', width: '25px', marginLeft: '5px' }}*/}
          {/*/>*/}
        </div>
      </IconRow>
      <div style={{ width: `${leftColumnMinWidth}px` }}>
        <IconRow
          icon={require('../../../../img/mobilePos/activateSection/' +
            (variant === 'primary' ? 'confirm' : 'list') +
            '/manual.png')}
          style={{ marginBottom: '0px' }}
        >
          <Typography variant="body2" style={{ marginBottom: '10px' }}>
            <Translate content="POS.confirmPage.mobile.manual" />
          </Typography>
          <DetailRow
            nearValue
            text={<Translate content="POS.detail.clientID" />}
            value={
              <CustomField backgroundWhite copiable>
                {pos_id}
              </CustomField>
            }
          />
          <DetailRow
            nearValue
            text={<Translate content="POS.detail.secret" />}
            value={
              <CustomField backgroundWhite hidden copiable>
                {secret}
              </CustomField>
            }
          />
        </IconRow>
      </div>
    </span>
    <QRCode
      bgColor="#FFFFFF"
      fgColor="#000000"
      level="Q"
      className={classes.qrCode}
      value={JSON.stringify({
        client_id: pos_id,
        secret: secret,
      })}
    />
  </div>
);

export default withStyles(styles)(AssociateDevice);
