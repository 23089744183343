import * as Sentry from '@sentry/browser';
import { ENVIRONMENT, VERSION } from '../config';
import { Event } from '@sentry/types/esm';

const sensitiveFields = ['password', 'pubkey', 'client_id', 'secret', 'passwd', 'api_key', 'apikey', 'access_token', 'auth_token', 'credentials', 'mysql_pwd', 'stripetoken'];

export const initSentry = () => {
  Sentry.init({
    dsn: 'https://d85ce3206f6f4f38a7f55181f632a79b@sentry.io/1783181',
    environment: ENVIRONMENT,
    release: `business-panel@${VERSION}`,
    beforeSend(event: Event): Event {
      return removeSensitive(event);
    },
  });
};

const removeSensitive = (input: any) => {
  if (!input) {
    return;
  }
  if (typeof input === 'object') {
    Object.keys(input).forEach(key => {
      if (
        key === 'data' &&
        typeof input[key] === 'string' &&
        sensitiveFields.some(field => input[key].includes(field))
      ) {
        input[key] = '[filtered_data]';
      } else if (sensitiveFields.includes(key)) {
        input[key] = '[filtered_data]';
      } else {
        removeSensitive(input[key]);
      }
    });
  }

  return input;
};
