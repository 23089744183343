import React from 'react';
import { Prompt } from 'react-router';
import Typography from '@material-ui/core/Typography';
import { Row, Col } from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core/styles';
import { ROUTE_PARAMETERS, ROUTES } from '../../../constants/routes';
import Button from '../../common/Button';
import InvalidFormMessage from '../../common/InvalidFormMessage';
import store from '../../../store/index';
import BusinessAPI from '../../../api/Business';
import ModificaBusinessForm from '../common/ModificaBusinessForm';
import PageContainer from '../../common/PageContainer';
import translator from 'counterpart';
import Translate from 'react-translate-component';
import classnames from 'classnames';
import { buildPath } from '../../../utils/routes';
import { withFormsValidation } from '../../common/Forms/FormsCommon';
import { FEATURES_DISABLED } from '../../../constants/featuresDisabled';
import ReadableTextArea from '../../common/ReadableTextArea';
import GDPRApi from '../../../api/GDPR';
import { FormSectionTitle } from '../../common/Forms/FormSectionTitle';
import CheckboxField from '../../common/Forms/CheckboxField';

const styles = theme => ({
  welcomeMessage: {
    marginTop: '40px',
  },
  welcomeSubtitle: {
    marginBottom: '50px',
  },
  sectionTitle: {
    fontSize: '16px',
    fontWeight: theme.typography.weights.normal,
    color: '#444444',
  },
  sectionSubtitle: {
    fontSize: '13px',
    fontWeight: theme.typography.weights.normal,
    color: '#999999',
    marginBottom: '18px',
  },
  buttonRow: {
    marginTop: '50px',
    marginBottom: '86px',
  },
  ruoloRow: {
    marginTop: '20px',
    marginBottom: '50px',
  },
  checkboxRow: {
    marginBottom: '16px',
  },
  submitButton: {
    width: '370px',
    marginBottom: '80px',
  },
});

class RegistraBusiness extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      submitted: false,
      positions: [],
      operationalFields: [],
      business_uuid: undefined,
      canConfirm: false,
      loadingLegalTerms: true,
    };
  }

  componentDidMount() {
    BusinessAPI.getPositions(result => {
      this.setState({
        positions: result.positions,
      });
      if (result.positions && result.positions.length === 1) {
        this.props.handleChangeGeneric(
          this.props.form.ids.form$position,
          result.positions[0].name
        );
      }
    }, this.props.onError);

    BusinessAPI.getOperationalFields(result => {
      this.setState({
        operationalFields: result,
      });
    }, this.props.onError);
    //initialize a null because sdk can't handle missing camp

    this.props.initObject({
      [this.props.form.ids.uri$person_uuid]: store.app.profile.uuid,
      [this.props.form.ids.form$logo]: null,
      [this.props.form.ids.form$operational_field$description]: null,
      [this.props.form.ids.form$position]: 'business_admin',
      [this.props.form.ids.form$no_vat_available]: false,
      [this.props.form.ids.form$vat]: '',
    });

    GDPRApi.getBusinessGdpr(result => {
      this.setState({
        legal_terms_uuid: result.legal_terms.uuid,
        loadingLegalTerms: false,
      });
    }, this.props.onError);
  }

  save = event => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    this.props.send(
      result => {
        store.app.snackBar.open(translator('messages.generic.ok'), 'success');
        this.setState(
          {
            loading: false,
            submitted: true,
          },
          () => {
            store.app.reloadInfo(() => {
              this.props.history.push(
                buildPath(ROUTES.PAYMENT_ORDER_LIST, {
                  [ROUTE_PARAMETERS.BUSINESS_ID]: result.uuid,
                })
              );
            });
          }
        );
      },
      () => {
        this.setState({
          loading: false,
        });
      }
    );
  };

  onReadLegalTerms = () => {
    this.setState({ canConfirm: true });
    this.props.handleChangeGeneric(this.props.form.ids.form$gdpr$legal_terms)(
      this.state.legal_terms_uuid
    );
  };

  render() {
    const nome = store.app.user_name;

    const {
      classes,
      errors,
      validateField,
      handleChange,
      handleChangeGeneric,
      object,
      form,
      signalError,
      toggleCheckbox,
    } = this.props;

    const { loading, submitted, positions, operationalFields } = this.state;

    const disabled = loading;

    const showSaveButton =
      FEATURES_DISABLED.multiBusinessType || object.tipo_azienda !== '';

    const ids = {
      legalRepresentative: this.props.form.ids
        .form$gdpr$is_legal_representative,
    };

    return (
      <PageContainer white main>
        <form onSubmit={this.save}>
          <Prompt
            message={translator('common.exitAlertMessage')}
            when={!submitted}
          />
          <Typography
            variant="title"
            className={classnames({
              'f-22 montserrat': true,
              [classes.welcomeMessage]: true,
              [classes.welcomeSubtitle]: store.app.hasBusiness,
            })}
          >
            {!store.app.hasBusiness && (
              <Translate
                with={{ nome: nome }}
                content="business.register.welcome"
              />
            )}
            {store.app.hasBusiness && (
              <Translate content="business.register.new" />
            )}
          </Typography>
          {!store.app.hasBusiness && (
            <Typography variant="caption" className={classes.welcomeSubtitle}>
              <Translate content="business.register.subtitle" />
            </Typography>
          )}
          <ModificaBusinessForm
            business={object}
            positions={positions}
            operationalFields={operationalFields}
            errors={errors}
            loading={loading}
            disabled={disabled}
            handleChange={handleChange}
            handleChangeGeneric={handleChangeGeneric}
            validateField={validateField}
            signalError={signalError}
            form={form}
          />
          <Row
            style={{
              marginTop: '80px',
            }}
          >
            <Col xs={12}>
              <FormSectionTitle>
                <Translate content="gdpr.privacyPolicy.title" />
              </FormSectionTitle>
              <Typography
                variant="caption"
                className="f-13"
                style={{ marginBottom: '15px' }}
              >
                <Translate content="gdpr.privacyPolicy.subtitle" />
              </Typography>
              <ReadableTextArea
                onComplete={this.onReadLegalTerms}
                loading={this.state.loadingLegalTerms}
              />
            </Col>
          </Row>
          <Row className={classes.ruoloRow}>
            <Col xs={12}>
              <CheckboxField
                disabled={disabled}
                error={Boolean(errors[ids.legalRepresentative])}
                helperText={errors[ids.legalRepresentative]}
                value={object[ids.legalRepresentative]}
                id={ids.legalRepresentative}
                name={ids.legalRepresentative}
                onChange={() => {
                  toggleCheckbox(ids.legalRepresentative);
                }}
                label={<Translate content="business.role" />}
                placeholder={translator('placeholder.ruolo')}
              />
            </Col>
          </Row>
          {showSaveButton && <InvalidFormMessage errors={errors} />}
          {showSaveButton && (
            <Button
              id="create_business"
              color="primary"
              variant="raised"
              type="submit"
              loading={loading}
              disabled={!this.state.canConfirm}
              onClick={this.save}
              customClass={classes.submitButton}
            >
              <Translate content="common.addBusiness" />
            </Button>
          )}
        </form>
      </PageContainer>
    );
  }
}

export default withFormsValidation('CreateBusinessForm')(
  withStyles(styles)(RegistraBusiness)
);
