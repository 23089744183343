import { extendObservable, action } from 'mobx';

import store from './index';
import BusinessAPI from '../api/Business';

export class BusinessSimplified {
  constructor(business) {
    extendObservable(this, {
      tipo: business.type,
      uuid: business.uuid,
      vat: business.vat,
      logo: business.logo,
      fiscal_code: business.fiscal_code,
      operational_field: business.operational_field,
      name: business.name,
      communications_name: business.communications_name,
    });

    this.getDetail(business.uuid);
  }
  getDetail = action(uuid => {
    BusinessAPI.getBusinessDetails(
      uuid,
      action(data => {
        this.logo = data.logo;
      })
    );
  });
}

// TODO: come trovo le persone associate a un business?
export class Business {
  constructor(tipo, business) {
    extendObservable(this, {
      tipo: tipo,
      uuid: business.uuid,
      vat: business.vat,
      fiscal_code: business.fiscal_code,
      operational_field: business.operational_field,
      name: business.name, // this can be undefined for Freelance
      communications_name: business.communications_name,
      logo: null,
      contacts: business.contacts
        ? {
            primario: business.contacts.legal_address
              ? {
                  street: business.contacts.legal_address.street,
                  city: business.contacts.legal_address.city,
                  zip: business.contacts.legal_address.zip,
                  country: business.contacts.legal_address.country,
                }
              : null,
            secondario: business.contacts.operational_address
              ? {
                  street: business.contacts.operational_address.street,
                  city: business.contacts.operational_address.city,
                  zip: business.contacts.operational_address.zip,
                  country: business.contacts.operational_address.country,
                }
              : null,
            email: business.contacts.email,
            phone: business.contacts.phone,
          }
        : null,
      get nome() {
        if (this.tipo === 'freelance') {
          return store.app.getProfile && store.app.getProfile.fullName;
        } else {
          return this.nome_azienda;
        }
      },
      get primary_address() {
        if (this.contacts && this.contacts.primario) {
          return (
            this.contacts.primario.street +
            ', ' +
            this.contacts.primario.city +
            ', ' +
            this.contacts.primario.country
          );
        } else {
          return null;
        }
      },
      get secondary_address() {
        if (this.contacts && this.contacts.secondario) {
          return (
            this.contacts.secondario.street +
            ', ' +
            this.contacts.secondario.city +
            ', ' +
            this.contacts.secondario.country
          );
        } else {
          return null;
        }
      },
      get phone() {
        if (this.contacts) {
          return this.contacts.phone;
        } else {
          return null;
        }
      },
      get email() {
        if (this.contacts) {
          return this.contacts.email;
        } else {
          return null;
        }
      },
    });
    this.getDetail(business.uuid);
  }
  getDetail = action(uuid => {
    BusinessAPI.getBusinessDetails(
      uuid,
      action(data => {
        this.logo = data.logo;
      })
    );
  });
}
