import React from 'react';

import { observer } from 'mobx-react';

import { Row, Col } from 'react-flexbox-grid';

import Button from '../../common/Button';
import { withStyles } from '@material-ui/core/styles';

import store from '../../../store/index';

import PageContainer from '../../common/PageContainer';
import Loader from '../../common/Loader';
import { DetailsRow } from '../../common/VisteDetails';

import { withGenericErrorHandling } from '../../common/ErrorHandling';

import { ROUTE_PARAMETERS, ROUTES } from '../../../constants/routes';

import { formatAddress } from '../../../utils/formatters';

import Typography from '@material-ui/core/Typography';

import ListaPersone from './ListaPersone';
import Translate from 'react-translate-component';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { buildPath } from '../../../utils/routes';
import BusinessAPI from '../../../api/Business';
import Avatar from '@material-ui/core/Avatar';
import { translateAnag } from '../../../utils/translate';

const styles = theme => ({
  iconBusiness: {
    width: '50px',
    height: '50px',
  },
  details: {
    marginTop: '40px',
  },
  title: {
    color: theme.palette.common.black,
  },
  editButton: {
    width: '200px',
  },
  ListaPersone: {
    marginTop: '70px',
  },
  marginBottomText: {
    marginBottom: '12px',
  },
});

class VistaBusiness extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      business_uuid: null,
      business: undefined,
      loading: false,
      businessLoading: true,
    };
  }

  componentDidMount() {
    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    this.loadBusiness(business_uuid);
  }

  componentDidUpdate(prevProps) {
    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    if (
      prevProps.match.params[ROUTE_PARAMETERS.BUSINESS_ID] !== business_uuid
    ) {
      this.loadBusiness(business_uuid);
    }
  }

  loadBusiness = business_uuid => {
    this.setState({ businessLoading: true });
    store.app
      .loadBusiness(business_uuid)
      .then(business => {
        this.setState({
          business: business,
          business_uuid: business_uuid,
          businessLoading: false,
        });
        BusinessAPI.getBusinessDetails(
          business_uuid,
          data => {
            this.setState({
              logo: data.logo,
            });
          },
          () => {
            console.error('Error getting business logo');
          }
        );
      })
      .catch(err => {
        this.props.onError(err);
        this.setState({ businessLoading: false });
      });
  };

  selectBusiness = business_uuid => {
    this.setState(
      {
        loading: true,
      },
      () => {
        store.app
          .selectBusiness(business_uuid)
          .then(() => {
            this.props.history.push(
              buildPath(ROUTES.PAYMENT_ORDER_LIST, {
                [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
              })
            );
          })
          .catch(() => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  };

  render() {
    const {
      business_uuid,
      business,
      loading,
      businessLoading,
      logo,
    } = this.state;
    const { classes } = this.props;

    if (businessLoading) {
      return (
        <PageContainer style={{ marginTop: '40px' }}>
          <Loader />
        </PageContainer>
      );
    }

    const company_name = business.business.name;
    const nome_comunicazioni = business.business.communications_name;
    const settore =
      business.business.operational_field.description ||
      translateAnag(
        'operational_field',
        business.business.operational_field.name
      );
    const p_iva = business.business.vat;
    const codice_fiscale = business.business.fiscal_code;
    const indirizzo_primario = formatAddress(business.contacts.legal_address);
    const indirizzo_secondario = business.contacts.operational_address
      ? formatAddress(business.contacts.operational_address)
      : null;
    const telefono = business.contacts.phone;
    const email = business.contacts.email;

    return (
      <PageContainer main white top>
        <Row middle="xs" start="xs" style={{ marginTop: '20px' }}>
          <Col xs={1}>
            <Avatar
              src={logo || require('../../../img/topbar/logo_default.png')}
              alt={company_name}
              classes={{ root: classes.iconBusiness }}
            />
          </Col>
          <Col xs={8}>
            <Typography variant="title">{company_name}</Typography>
          </Col>
          <Col xs={3}>
            <Button
              color="primary"
              variant="raised"
              id="business_delegate"
              fullWidth
              component={props => (
                <Link
                  to={buildPath(ROUTES.PAYMENT_ORDER_LIST, {
                    [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
                  })}
                  {...props}
                />
              )}
              loading={loading}
              small
            >
              <Translate content="common.goToPayments" />
            </Button>
          </Col>
        </Row>
        <div className={classes.details}>
          <Typography
            variant="body2"
            className={classnames([classes.marginBottomText, 'f-16'])}
          >
            <Translate content="business.details" />
          </Typography>
          <DetailsRow
            className={classes.marginBottomText}
            name={<Translate content="business.piva" />}
            value={p_iva}
          />
          {codice_fiscale && (
            <DetailsRow
              className={classes.marginBottomText}
              name={<Translate content="business.cf" />}
              value={codice_fiscale}
            />
          )}
          <DetailsRow
            className={classes.marginBottomText}
            name={<Translate content="business.sector" />}
            value={settore}
          />
          <DetailsRow
            className={classes.marginBottomText}
            name={<Translate content="business.phone" />}
            value={telefono}
          />
          <DetailsRow
            className={classes.marginBottomText}
            name={<Translate content="business.email" />}
            value={email}
          />
          <DetailsRow
            className={classes.marginBottomText}
            name={<Translate content="business.legalAddress" />}
            value={indirizzo_primario}
          />
          {indirizzo_secondario && (
            <DetailsRow
              className={classes.marginBottomText}
              name={<Translate content="business.actualAddress" />}
              value={indirizzo_secondario}
            />
          )}

          {nome_comunicazioni && (
            <DetailsRow
              className={classes.marginBottomText}
              name={<Translate content="business.nameComunication" />}
              value={nome_comunicazioni}
            />
          )}
          {!store.app.sandboxMode && (
            <Row style={{ marginTop: '20px' }}>
              <Col xs={3}>
                <Button
                  customClass={classes.editButton}
                  id="business_edit_details"
                  color="primary"
                  variant="outlined"
                  loading={loading}
                  small
                  component={props => (
                    <Link
                      to={buildPath(ROUTES.BUSINESS_EDIT, {
                        [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
                      })}
                      {...props}
                    />
                  )}
                >
                  <Translate content="common.edit" />
                </Button>
              </Col>
            </Row>
          )}
        </div>
        <div className={classes.ListaPersone}>
          <ListaPersone business_uuid={business_uuid} />
        </div>
      </PageContainer>
    );
  }
}

export default withGenericErrorHandling(
  withStyles(styles)(observer(VistaBusiness))
);
