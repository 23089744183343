export default {
  generic: {
    ok: 'Operazione completata con successo',
    ko: 'Operazione fallita. Per favore riprovare più tardi',
  },
  account: {
    new: {
      ok: 'Conto creato con successo',
    },
  },
};
