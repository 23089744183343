export const STATES = {
  LOADING_CALLBACKS: 'LOADING_CALLBACKS',
  ERROR_LOADING: 'ERROR_LOADING',
  NO_CALLBACK_SELECTED: 'NO_CALLBACK_SELECTED',
  CALLBACK_SELECTED: 'CALLBACK_SELECTED',
  WAITING_SOCKET: 'WAITING_SOCKET',
  SOCKET_OK: 'SOCKET_OK',
  SOCKET_KO: 'SOCKET_KO',
  SOCKET_FATAL: 'SOCKET_FATAL',
  INVALID_CALLBACK: 'INVALID_CALLBACK',
  RESETTING: 'RESETTING',
  RESET: 'RESET',
};
