export default {
  titles: {
    edit: 'Edit Details',
    editEmail: 'Edit Email',
    editPassword: 'Change Password',
    parent: 'My Profile',
    goBack: 'Back to my profile',
  },
  edit: {
    subtitle: 'Keep your profile details updated.',
    fields: {
      firstName: {
        label: 'Name',
        errors: {
          empty: 'Enter a name',
        },
      },
      lastName: {
        label: 'Surname',
        errors: {
          empty: 'Enter a surname',
        },
      },
      phone: {
        label: 'Phone',
        placeholder: 'Enter your phone number',
        errors: {
          empty: 'Enter a phone',
          invalid: 'Enter a valid phone',
        },
      },
      password: {
        label: 'Password',
        placeholder: 'Enter your password',
        errors: {
          empty: 'Enter password',
        },
      },
    },
    submit: {
      error: 'Error in editing details',
      success: 'Details changed successfully',
    },
  },
  editEmail: {
    subtitle:
      'Update your email and enter your password to' +
      ' confirm. We will send you a verification email to your new address. ',
    fields: {
      newEmail: {
        label: 'New Email',
        placeholder: 'Enter your new email',
        errors: {
          empty: 'Insert an email',
          invalid: 'Please enter a valid email',
        },
      },
      confirmEmail: {
        label: 'Confirm Email',
        placeholder: 'Confirm your new email',
        errors: {
          empty: 'Insert an email',
          differ: 'Emails do not match',
        },
      },
      password: {
        label: 'Password',
        placeholder: 'Enter your password',
        errors: {
          empty: 'Enter password',
        },
      },
    },
    submit: {
      error: 'Error in editing the email',
      success: 'Request to change email successfully sent',
    },
  },
  editPassword: {
    subtitle: 'Change your password, entering the current one to confirm.',
    fields: {
      oldPassword: {
        label: 'Old password',
        placeholder: 'Enter your old password',
        errors: {
          empty: 'Enter old password',
        },
      },
      newPassword: {
        label: 'New password',
        placeholder: 'Enter your new password',
        errors: {
          empty: 'Enter new password',
          short: 'The new password is too short',
        },
      },
      confirmPassword: {
        label: 'Confirm password',
        placeholder: 'Confirm your new password',
        errors: {
          empty: 'Enter new password confirmation',
          differ: 'Passwords do not match',
        },
      },
    },
    submit: {
      success: 'Password changed successfully',
      error: 'Error changing password',
    },
  },
  confirmMessage: 'Confirm your changes by entering your password',
  firstName: 'Name',
  lastName: 'Surname',
  phone: 'Phone',
  email: 'Email',
  password: 'Password',
  newPassword: 'New Password',
  passwordConfirm: 'Confirm Password',
  myCompanies: 'My companies',
  myDetails: 'My details',
  myCredentials: 'My credentials',
  noCompanyAvailable: 'No company available',
  piva: 'VAT number',
  address: 'Company address',
  role: 'Role',
  mailLanguage: 'Language for email communications',
  stepperProfile: {
    configurationState: 'Configuration progress',
    business: 'Register your company',
    firstBusiness:
      'Add your first company and immediately start accepting bitcoin payments',
    firstBusinessAction: 'ADD YOUR COMPANY',
    mail: 'Check your email',
    account: 'Create an account where to receive the money',
    pos: 'Create a POS to accept payments',
    nextStep: 'Next step',
    completeSignUp: 'Complete registration',
  },
  verifyMail: 'Verify email',
  actions: {
    login: 'Login',
    seeDetails: 'View Details',
    editDetails: 'Edit Details',
    editPassword: 'Change Password',
    editEmail: 'Edit Email',
    business: {
      edit: 'Edit',
    },
  },
};
