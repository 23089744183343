import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import GeoLookup from '../../../api/GeoLookup';

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import classnames from 'classnames';

const styles = theme => ({
  root: {
    marginBottom: '15px',
    zIndex: '0',
  },
  input: {
    width: '100%',
    ...theme.components.inputField,
    fontWeight: theme.typography.weights.normal,
    border: '0',
    background: 'none',
    outline: 'none',
    padding: '14px 12px 11px',
  },
  inputLabel: {
    fontSize: '14px',
    transform: 'translate(-10px, -5px) scale(1)',
    fontWeight: theme.typography.weights.normal,
    color: '#777777',
  },
  labelError: {
    color: '#dd4848',
  },
  error: {
    backgroundColor: theme.palette.red.bg + ' !important',
    borderColor: theme.palette.red.main + ' !important',
  },
  inputRoot: {
    padding: '1px',
    backgroundColor: theme.palette.primary.bg,
    color: 'rgba(0, 0, 0, 0.87)',
    border: 'solid 1px #d7dfe5',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '1rem',
    fontFamily: theme.typography.fontFamilies.primary,
    lineHeight: '1.1875em',
    borderRadius: '2px',
  },
});

class PhoneField extends React.Component {
  static propTypes = {
    validateField: PropTypes.func,
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
  };
  state = {
    visible: false,
    focused: false,
    number: this.props.defaultValue ? this.props.defaultValue : undefined,
  };

  onBlur = () => {
    this.setState(
      {
        focused: false,
      },
      () => {
        if (this.props.validateField) this.props.validateField(this.props.id);
      }
    );
  };

  onCountryChange = (value, countryData) => {
    this.onChange(true, value, countryData);
  };

  onChange = (isValid, value, countryData) => {
    let prefix = '+' + countryData.dialCode;

    if (this.props.onChange) {
      let parsedNumber = this.parseNumber(prefix, value);
      let changedValue = !parsedNumber.startsWith(prefix)
        ? prefix + parsedNumber
        : parsedNumber;

      changedValue =
        changedValue === prefix || !changedValue ? null : changedValue;

      this.props.onChange(changedValue);
    }
  };

  parseNumber = (dialCode, number) => {
    let numberWithoutWhitespace = number.replace(/\s/g, '');
    return numberWithoutWhitespace.startsWith(dialCode)
      ? this.replaceDialCode(dialCode, numberWithoutWhitespace)
      : numberWithoutWhitespace;
  };

  replaceDialCode = (dialCode, value) => {
    let re = new RegExp(
      dialCode.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1'),
      'g'
    );
    return value.replace(re, '');
  };

  onFocus = () => {
    this.setState({
      focused: true,
    });
  };

  countryLookup = callback => {
    GeoLookup.getCountryCode(callback);
  };

  render() {
    const { id, name, classes, error, helperText, placeholder } = this.props;

    const { focused, number } = this.state;

    let htmlError = null;
    if (error && helperText) {
      htmlError = (
        <div>
          {helperText.map((text, k) => {
            return (
              <span key={k}>
                {text}
                <br />
              </span>
            );
          })}
        </div>
      );
    }
    return (
      <div
        className={classes.root}
        style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        aria-describedby={this.props.name + '-helper-text'}
      >
        <InputLabel
          shrink={true}
          focused={focused}
          htmlFor={id}
          className={classnames(classes.inputLabel, {
            [classes.labelError]: error,
          })}
        >
          {this.props.label}
        </InputLabel>
        <div
          className={classnames(classes.inputRoot, { [classes.error]: error })}
        >
          <IntlTelInput
            placeholder={placeholder}
            fieldId={id}
            fieldName={name}
            style={{ width: '100%' }}
            nationalMode={true}
            autoHideDialCode={true}
            defaultCountry="it"
            onPhoneNumberBlur={this.onBlur}
            onPhoneNumberChange={this.onChange}
            onSelectFlag={this.onCountryChange}
            defaultValue={number}
            preferredCountries={['it', 'us', 'gb']}
            css={['intl-tel-input', classes.input]}
            telInputProps={{
              onFocus: this.onFocus,
            }}
            disabled={this.props.disabled}
          />
        </div>
        {htmlError && (
          <FormHelperText error={error} id={this.props.id + '-helper-text'}>
            {htmlError}
          </FormHelperText>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(PhoneField);
