export default {
  list: {
    subtitle:
      'Here are listed all the payment orders created through ' +
      'your POS. You will be able to view their status and details. ',
    loading: 'Loading payment orders in progress ...',
    no_more: 'You have come to the bottom!',
    faq: {
      emptyListTitle: 'In the meantime our FAQs might be helpful:',
      1: 'Who generates payment orders?',
      2: 'How can I receive my first payment?',
    },
    messages: {
      no_payments: {
        title: 'No payment order received',
        subtitle:
          'On this page you will be able to see all your payment orders ' +
          'as soon as you start to create them.',
      },
      filter_by_status: {
        completed: {
          title: 'No payment order completed',
          subtitle: 'You still have no payment order completed.',
        },
        not_completed: {
          title: 'No payment order present',
          subtitle: 'All your payment orders are completed. ',
        },
        disputed: {
          title: 'No payment order present',
          subtitle: 'You have no payment order in dispute',
        },
        other: {
          title: 'No payment order present',
          subtitle: 'There are no other payment orders',
        },
      },
      other_filter: {
        viewAll: 'View all payment orders',
        pos: {
          title: 'No payment order originated from this POS',
          subtitle: '',
          goBack: 'Back to POS details',
        },
        wallet: {
          title: 'No payment order received on the account',
          subtitle: '',
        },
        account: {
          title: 'No payment order received on this account',
          subtitle: '',
          goBack: 'Back to account details',
        },
      },
      show_all: 'View all payment orders',
    },
  },
  fields: {
    created_at: 'Data',
    amount: 'Amount',
    crypto_amount: 'Crypto Amount',
    status: 'State',
    pos_name: 'Created by',
    reference: 'Reference',
  },
  filters: {
    standard: {
      all: 'All',
      completed: 'Confirmed',
      not_completed: 'In progress',
      disputed: 'At risk',
      other: 'Others',
    },
    webpos: 'Payment orders originated by the POS',
    mobilepos: 'Payment orders originated by the POS',
    wallet: 'Payment orders received on account',
    account: 'Payment orders received on account',
    remove_filter: 'Remove filter',
  },
  detail: {
    titles: {
      title: 'Payment order details',
      parent: 'Back to the list of payment orders',
    },
    import: 'Amount',
    commission: 'Commission',
    totalImport: 'Total amount',
    status: {
      pending: 'Waiting',
      network_dispute: 'Dispute',
      chargeback: 'Chargeback',
      unconfirmed: 'Not Confirmed',
      paid: 'Paid',
      expired: 'Expired',
      cancelled: 'Canceled',
    },
    sections: {
      detail: 'Details',
      exchange: 'Exchange information',
      transaction: 'Transactions received',
    },
    paymentDate: 'Confirmation date',
    cancelledDate: 'Cancellation date',
    expiredDate: 'Due date',
    createdAt: 'Creation date',
    startDispute: 'Dispute start date',
    chargeback: 'chargeback date',
    createdByPOS: 'Created by the POS',
    account: 'Receiving account',
    paymentID: 'order ID',
    reference: 'Reference',
    description: 'Description',
    callback_url: 'Callback URL',
    exchange: 'Bitcoin Exchange',
    changeApplied: 'Change applied',
    address: '%(currency)s address',
    transaction: {
      confirmed: 'Confirmed',
      mempool: 'Waiting',
      unconfirmed: 'Waiting',
      reverted: 'Canceled',
      noTransaction: 'No transaction received so far',
      date: 'Date',
      amount: 'Amount',
      btc_amount: 'BTC amount',
      outs: 'Output',
      state: 'State',
      id: 'Identifier',
    },
    faq: {
      whoCreateID: 'Who created the order ID?',
      whatID: 'What is the order ID?',
      canAbort: 'Can I cancel a payment order?',
      howChangeWorks: 'How does the exchange work?',
      whatIsAdressBTC: 'What is the %(currency)s address?',
      whatTransactions: 'What are transactions?',
      stateTransactions: 'What state can a transaction have?',
      network_dispute:
        'What does it mean that the payment order is in Dispute?',
      chargeback:
        'What does it mean that the payment order is in the' +
        'chargeback state?',
    },
  },
  testing: {
    boxTitle: 'Test this payment order',
    boxSubtitle:
      'Callbacks notify payment order events, such as' +
      'the change of state. Manually send a callback to verify that the reception on your site is correctly handled. ',
    selectTitle: 'Select the callback to test',
    sendCallback: 'Send callback',
    sendToInitalState: 'Return the payment order to the initial state',
    modalLoading: 'Send callback %(callback_name)s in progress ...',
    resettingModal: 'Reset payment order in progress ...',
    seeRequests: 'View request payload',
    status: 'State',
    importReceived: 'Amount received:',
    invalidCallback: {
      title: 'Problem sending the callback',
      text:
        'It is not possible to send the callback %(callback_name)s in the' +
        'current state. This is often due to' +
        'sync problems, so please update the page. ',
      sendToInitialState: 'return the payment order to the initial state',
    },
    success: {
      title: 'Callback sent successfully',
      text:
        'The sending of the callback resulted in the following changes' +
        'to the payment order:',
      requestSend: 'Request sent:',
      response: 'Answer received:',
      httpCode: 'HTTP Code:',
    },
    resetted: {
      title: 'Reset the completed payment order',
      text: 'Here are the changes made to the payment order:',
    },
    error: {
      title: 'Integration problems',
      text:
        'We received an error in response to the callback sent. Check that you have complied with the integration instructions as per',
      chainsideProblem: 'If you think this is a Chainside problem',
    },
    fatal: {
      title: 'Problem sending the callback',
      text:
        'There was a problem sending the test callback and ' +
        'the payment order' +
        ' was reset and returned to its initial state. If the problem is still there',
    },
  },
};
