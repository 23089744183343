import React from 'react';

import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import store from '../../store';
import { Copyright } from './Copyright';

const styles = theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '60px',
    flex: '1 0 100%',
    '@media (max-width: 800px)': {
      marginTop: '30px',
    },
  },
  halfWidth: {
    justifyContent: 'flex-end',
    marginRight: '165px',
    '@media (max-width: 800px)': {
      marginRight: '20px',
      marginLeft: '20px',
      justifyContent: 'center',
    },
    '@media (min-width: 800px) and (max-width: 1200px)': {
      marginRight: '125px',
      marginLeft: '90px',
    },
  },
  fullWidth: {
    justifyContent: 'center',
    '@media (max-width: 800px)': {
      marginRight: '20px',
      marginLeft: '20px',
    },
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: '1 0 100%',
    paddingBottom: '60px',
  },
  logo: {
    width: '280px',
    marginBottom: '50px',
  },
  logoHeight: {
    height: '60px',
  },
  logoSandboxHeight: {
    height: '80px',
  },
  customIcon: {
    height: '60px',
    width: '60px',
    marginBottom: '50px',
  },
});

class UnauthenticatedPage extends React.Component {
  static propTypes = {
    align: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.arrayOf(PropTypes.object).isRequired,
    width: PropTypes.number,
    halfWidth: PropTypes.bool,
  };

  static defaultProps = {
    align: 'center',
    width: 370,
  };

  render() {
    const {
      classes,
      align,
      halfWidth,
      icon,
      title,
      children,
      width,
    } = this.props;
    return (
      <div
        className={classnames({
          [classes.mainContainer]: true,
          [classes.halfWidth]: halfWidth,
          [classes.fullWidth]: !halfWidth,
        })}
      >
        <div
          className={classes.innerContainer}
          style={{
            alignItems: align,
            textAlign: align === 'center' ? 'center' : null,
            maxWidth: width + 'px',
          }}
        >
          {!store.app.isLoggedIn && (
            <img
              src={
                store.app.sandboxMode
                  ? require('../../img/logo/CSpaySandboxDarkBlue.png')
                  : require('../../img/logo/CSpayBlue.png')
              }
              className={classnames({
                [classes.logo]: true,
                [classes.logoHeight]: !store.app.sandboxMode,
                [classes.logoSandboxHeight]: store.app.sandboxMode,
              })}
              alt="ChainsidePay"
            />
          )}
          {icon && <img src={icon} className={classes.customIcon} alt="" />}
          <Typography variant="title" className="f-22 montserrat">
            {title}
          </Typography>
          {React.Children.map(children, child => {
            return React.cloneElement(child);
          })}
          <Copyright className="f-13" style={{ marginTop: '120px' }} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(UnauthenticatedPage);
