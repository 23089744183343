import pathToRegexp from 'path-to-regexp';

function buildPath(route, params) {
  return pathToRegexp.compile(route)(params);
}

function isSubRoute(route, path) {
  return pathToRegexp(route, { end: false }).test(path);
}

function matchesRoute(route, path) {
  return pathToRegexp(route).test(path);
}

export { buildPath, isSubRoute, matchesRoute };
