import { STATES } from '../../constants/states';
import { ChargingModal } from './ChargingModal';
import { InvalidCallbackModal } from './InvalidCallbackModal';
import { SuccessCallbackModal } from './SuccessCallbackModal';
import { FatalErrorModal } from './FatalErrorModal';
import { ErrorCallbackModal } from './ErrorCallbackModal';
import { ResettingModal } from './ResettingModal';
import { ResettedModal } from './ResettedModal';

export const modalComponents = {
  [STATES.WAITING_SOCKET]: ChargingModal,
  [STATES.SOCKET_KO]: ErrorCallbackModal,
  [STATES.SOCKET_OK]: SuccessCallbackModal,
  [STATES.SOCKET_FATAL]: FatalErrorModal,
  [STATES.INVALID_CALLBACK]: InvalidCallbackModal,
  [STATES.RESETTING]: ResettingModal,
  [STATES.RESET]: ResettedModal,
};
