import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';

import classnames from 'classnames';
import ReactCustomFlagSelect from 'react-custom-flag-select';
import 'react-custom-flag-select/lib/react-custom-flag-select.min.css';
import countryList from 'react-select-country-list';
import GeoLookup from '../../../api/GeoLookup';

const DEFAULT_AREA_CODE = 'IT';

const styles = theme => ({
  root: {
    marginBottom: '15px',
    zIndex: '0',
  },
  input: {
    width: '100%',
    ...theme.components.inputField,
    fontWeight: theme.typography.weights.normal,
    border: '0',
    background: 'none',
    outline: 'none',
    padding: '14px 12px 11px',
  },
  inputLabel: {
    fontSize: '14px',
    transform: 'translate(-10px, -5px) scale(1)',
    fontWeight: theme.typography.weights.normal,
    color: '#777777',
  },
  labelError: {
    color: '#dd4848',
  },
  error: {
    backgroundColor: theme.palette.red.bg + ' !important',
    borderColor: theme.palette.red.main + ' !important',
  },
  inputRoot: {
    padding: '2px',
    backgroundColor: theme.palette.primary.bg,
    color: 'rgba(0, 0, 0, 0.87)',
    border: 'solid 1px #d7dfe5',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '1rem',
    fontFamily: theme.typography.fontFamilies.primary,
    lineHeight: '1.1875em',
    borderRadius: '2px',
    height: '40px',
  },
  wrapper: {
    width: '100px',
    display: 'table',
    backgroundColor: 'rgba(0,0,0,.05)',
    zIndex: 1,
    position: 'relative',
    height: '100%',
    padding: '0 0 0 8px',
  },
  containerDisabled: {
    opacity: '0.4',
  },
  container: {
    border: 'none',
    fontSize: '12px',
    height: '100%',
    display: 'table',
  },
  selectContainer: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  listContainer: {
    maxHeight: '100px',
    overflow: 'auto',
    width: '300px',
    marginTop: '66%',
    left: '141px',
  },
  dropdownIcon: {
    '&:before': {
      position: 'absolute',
      top: '50%',
      marginTop: '0',
      right: '6px',
      width: 0,
      height: 0,
      borderLeft: '3px solid transparent',
      borderRight: '3px solid transparent',
      borderTop: '4px solid #555',
    },
  },
  listItem: {
    '&:hover': {
      backgroundColor: '#1a6796',
    },
    '& div:first-child': {
      width: '32px !important',
    },
  },
});

const find = (arr, obj) => {
  const res = [];
  arr.filter(o => {
    let match = false;
    Object.keys(obj).map(i => {
      if (obj[i] === o[i]) {
        match = true;
      }
      return match;
    });
    if (match) {
      res.push(o);
    }

    return match;
  });
  return res;
};

class VatField extends React.Component {
  static propTypes = {
    validateField: PropTypes.func,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    value: '',
  };

  state = {
    areaCode: DEFAULT_AREA_CODE,
    focused: false,
    vat: this.props.value,
    inputValue: null,
    countries: [
      {
        id: 'UNKNOWN',
        name: 'UNKNOWN',
        displayText: 'N/A',
        flag: require('../../../img/flags/flags-iso/flat/32/_unknown.png'),
      },
    ],
  };

  componentWillReceiveProps(nextProps, nextContext) {
    this.detectVat(nextProps.value);
  }

  componentDidMount() {
    let countries = countryList().getData();

    let mappedCountries = [];
    for (let i = 0; i < countries.length; i++) {
      let country = countries[i];

      let flag = '';
      try {
        flag = require('../../../img/flags/flags-iso/flat/32/' +
          country.value +
          '.png');
      } catch (ex) {
        flag = require('../../../img/flags/flags-iso/flat/32/_unknown.png');
      }

      mappedCountries.push({
        id: country.value,
        name: country.value,
        displayText: country.label,
        flag: flag,
      });
    }

    if (!this.state.vat) {
      this.loadAutoCountry();
    }

    this.setState(
      {
        countries: mappedCountries,
      },
      () => {
        this.detectVat(this.state.vat);
      }
    );
  }

  detectVat = vat => {
    let detectedVat = this.parseVat(vat);
    this.setState({
      areaCode: detectedVat.areaCode,
      vat: detectedVat.value,
    });
  };

  onBlur = e => {
    this.setState({
      focused: false,
    });

    if (this.props.validateField) this.props.validateField(this.props.id);
  };

  onCountryChange = areaCode => {
    this.setState(
      {
        areaCode: areaCode,
      },
      () => {
        this.onChange({
          target: {
            id: this.props.id,
            value: this.state.vat,
          },
        });
      }
    );
  };

  onChange = e => {
    let parsedVat = this.parseVat(e.target.value);

    let targetId = e.target.id;

    if (this.props.onChange) {
      this.props.onChange({
        target: {
          id: targetId,
          value: parsedVat.value ? parsedVat.areaCode + parsedVat.value : '',
        },
      });
    }
  };

  onFocus = () => {
    this.setState({
      focused: true,
    });
  };

  parseVat = vat => {
    if (!vat)
      return {
        value: '',
        areaCode: this.state.areaCode,
      };

    let typedCountryCode = find(this.state.countries, {
      id: vat.substring(0, 2),
    })[0];

    if (typedCountryCode) {
      return {
        value: vat.substring(2, vat.length),
        areaCode: typedCountryCode.id,
      };
    }

    return {
      value: vat,
      areaCode: this.state.areaCode,
    };
  };

  countryLookup = callback => {
    GeoLookup.getCountryCode(callback);
  };

  loadAutoCountry = () => {
    const lsAutoCountry =
      window.localStorage !== undefined
        ? window.localStorage.getItem('vatAutoCountry')
        : '';

    if (!lsAutoCountry) {
      this.countryLookup(country => {
        this.autoCountryLoaded(country);
      });
    } else {
      this.autoCountryLoaded(lsAutoCountry);
    }
  };

  autoCountryLoaded = country => {
    if (window.localStorage !== undefined) {
      window.localStorage.setItem('vatAutoCountry', country);
    }

    this.setState({
      areaCode: country.toUpperCase(),
    });
  };

  render() {
    const {
      classes,
      value,
      error,
      helperText,
      validateField,
      ...otherProps
    } = this.props;

    const { focused, countries, vat, areaCode } = this.state;

    const currentItem = find(countries, { id: areaCode })[0];
    const defaultItem = {
      id: 'UNKNOWN',
      name: 'UNKNOWN',
      displayText: 'N/A',
      flag: require('../../../img/flags/flags-iso/flat/32/_unknown.png'),
    };
    const valueString = vat;

    let htmlError = null;
    if (error && helperText) {
      htmlError = (
        <div>
          {helperText.map((text, k) => {
            return (
              <span key={k}>
                {text}
                <br />
              </span>
            );
          })}
        </div>
      );
    }
    return (
      <div
        className={classes.root}
        style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        aria-describedby={this.props.name + '-helper-text'}
      >
        <InputLabel
          shrink={true}
          focused={focused}
          htmlFor={this.props.name}
          className={classnames(classes.inputLabel, {
            [classes.labelError]: error,
          })}
        >
          {this.props.label}
        </InputLabel>
        <div
          className={classnames(classes.inputRoot, { [classes.error]: error })}
        >
          <div style={{ height: 'inherit', width: '100%', display: 'flex' }}>
            <ReactCustomFlagSelect
              tabIndex={'1'}
              value={currentItem ? currentItem.id : defaultItem.id}
              optionList={countries}
              classNameWrapper={classnames(classes.wrapper, {
                [classes.containerDisabled]: otherProps.disabled,
              })}
              classNameContainer={classnames(classes.container, {
                [classes.containerDisabled]: otherProps.disabled,
              })}
              classNameSelect={classes.selectContainer}
              classNameOptionListContainer={classes.listContainer}
              classNameOptionListItem={classes.listItem}
              classNameDropdownIconOptionListItem={classes.dropdownIcon}
              onChange={this.onCountryChange}
              disabled={otherProps.disabled}
            />
            <Input
              {...otherProps}
              tabIndex={'2'}
              name={this.props.name}
              id={this.props.id}
              disableUnderline={true}
              className={classes.input}
              onChange={this.onChange}
              value={valueString}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
            />
          </div>
        </div>
        {htmlError && (
          <FormHelperText error={error} id={this.props.id + '-helper-text'}>
            {htmlError}
          </FormHelperText>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(VatField);
