import React from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import classnames from 'classnames';
import { ROUTE_PARAMETERS_VALUE } from '../../../constants/routes';

const accountIconByType = {
  [ROUTE_PARAMETERS_VALUE.ACCOUNT
    .bank]: require('../../../img/bancario/small.png'),
  [ROUTE_PARAMETERS_VALUE.ACCOUNT
    .bitcoin]: require('../../../img/bitcoin/small.png'),
};

const styles = theme => ({
  accountIconAvatar: {
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'middle',
    margin: '-10px 7px -10px 0',
    ...theme.icon.small,
  },
});

const AccountIcon = withStyles(styles)(props => {
  const { className, classes, accountType } = props;
  const icon = accountIconByType[accountType];
  return (
    <img
      className={classnames([classes.accountIconAvatar, className])}
      src={icon}
      alt="account"
    />
  );
});

AccountIcon.propTypes = {
  accountType: PropTypes.string.isRequired,
};

export default AccountIcon;
