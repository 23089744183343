import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import classnames from 'classnames';

import { observer } from 'mobx-react';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import store from '../../store';
import { ROUTE_PARAMETERS, ROUTES } from '../../constants/routes';

import PageContainer from './PageContainer';
import HeaderDropDown, {
  CustomMenuItem,
  CustomBusinessMenuItem,
} from './HeaderDropDown';
import Translate from 'react-translate-component';
import Avatar from '@material-ui/core/Avatar';
import Button from '../common/Button';
import translator from 'counterpart';
import { buildPath } from '../../utils/routes';
const styles = theme => ({
  root: {
    width: '100%',
  },
  colorTopBar: {
    backgroundColor: theme.palette.primary.bgtopBarFooter,
  },
  list: {
    flex: 0.5,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  menuActions: {
    margin: '0px 10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  container: {
    marginTop: '15px',
    marginBottom: '25px',
  },
  textField: {
    width: '100%',
  },
  removeShadow: {
    boxShadow: 'none',
  },
  avatarAndName: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  businessLogo: {
    width: '30px',
    height: '30px',
    marginRight: '5px',
  },
  addBusinessLogo: {
    width: '28px',
    marginRight: '5px',
  },
  logoLive: {
    width: '167.4px',
    height: '38px',
  },
  logoSandbox: {
    width: '167.4px',
    height: '48px',
    marginBottom: '-12px',
  },
});

class Header extends React.Component {
  logout = () => {
    store.app.logout();
    this.props.history.push(ROUTES.LOGIN);
  };

  resetRoute = () => {
    const currentLocation = this.props.location.pathname;
    let path = currentLocation.substr(1);
    if (path.indexOf('/') !== -1) {
      path = path.substr(0, path.indexOf('/'));
    }
    this.props.history.push('/' + path);
    //reload the page
    this.props.history.go(0);
  };

  redirectToPayments = () => {
    const business_uuid = store.app.business_uuid;
    if (business_uuid) {
      this.props.history.push(
        buildPath(ROUTES.PAYMENT_ORDER_LIST, {
          [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
        })
      );
    }
  };

  profilo = [
    new CustomMenuItem(
      <Translate content="common.topbar.myProfile" />,
      require('../../img/topbar/profile.png'),
      () => {
        this.props.history.push(ROUTES.PROFILE_VIEW);
      },
      '',
      false
    ),
    new CustomMenuItem(
      <Translate content="common.topbar.logout" />,
      require('../../img/topbar/logout.png'),
      this.logout,
      '',
      false
    ),
  ];

  render() {
    const { classes, fatherClass } = this.props;

    const user_name = store.app.user_fullName;

    const hasProfile = store.app.hasProfile;
    const isDelegated = store.app.isDelegated;
    const business_name = store.app.business_name;
    const business_uuid = store.app.business_uuid;
    let businessIcon = isDelegated
      ? require('../../img/topbar/logo_default.png')
      : require('../../img/topbar/business-white.png');
    const hasBusiness = store.app.hasBusiness;
    const aziende = hasBusiness ? store.app.businesses : [];

    const aziendeList = aziende
      ? aziende.filter(item => item.uuid !== business_uuid)
      : [];
    const active_business =
      store.app.business_uuid && aziende
        ? aziende.filter(item => item.uuid === business_uuid)[0]
        : null;

    businessIcon = active_business
      ? active_business.logo || businessIcon
      : businessIcon;

    const businessMenu = aziendeList.map(item => {
      return item.uuid !== business_uuid
        ? new CustomBusinessMenuItem(
            item.name,
            item.uuid,
            item.logo || require('../../img/topbar/logo_default.png')
          )
        : null;
    });

    const headerTitle = business_name;

    return (
      <div className={classnames([classes.root, fatherClass])}>
        <AppBar
          position="static"
          className={classnames([classes.removeShadow, classes.colorTopBar])}
        >
          <PageContainer>
            <Toolbar className={classes.menuContainer} disableGutters>
              <div>
                <Link to={`/`}>
                  <img
                    src={
                      store.app.sandboxMode
                        ? require('../../img/logo/CSpaySandboxWhite.png')
                        : require('../../img/logo/CSpayWhite.png')
                    }
                    className={classnames({
                      [classes.logoLive]: !store.app.sandboxMode,
                      [classes.logoSandbox]: store.app.sandboxMode,
                    })}
                    alt="Home"
                  />
                </Link>
              </div>
              <div className={classes.menuActions}>
                {hasBusiness && hasProfile && (
                  <HeaderDropDown
                    isBusiness
                    activeBusiness={
                      isDelegated
                        ? new CustomBusinessMenuItem(
                            business_name,
                            business_uuid,
                            businessIcon
                          )
                        : null
                    }
                    title={
                      <div className={classes.avatarAndName}>
                        <Avatar
                          src={businessIcon}
                          className={classes.businessLogo}
                        />
                        {isDelegated
                          ? headerTitle
                          : translator('common.yourBusiness')}
                      </div>
                    }
                  >
                    {businessMenu}
                  </HeaderDropDown>
                )}
                {!hasBusiness && hasProfile && (
                  <Button
                    variant="raised"
                    color="primary"
                    small
                    component={props => (
                      <Link to={ROUTES.REGISTER_BUSINESS} {...props} />
                    )}
                  >
                    <img
                      src={require('../../img/topbar/add-white.png')}
                      alt="add"
                      className={classes.addBusinessLogo}
                    />
                    <Translate content="common.addBusiness" />{' '}
                  </Button>
                )}
                <HeaderDropDown title={user_name}>
                  {this.profilo}
                </HeaderDropDown>
                {/**<Logout logout={this.logout} />**/}
              </div>
            </Toolbar>
          </PageContainer>
        </AppBar>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(observer(Header)));
