import React from 'react';

import { observer } from 'mobx-react';

import { Col, Row } from 'react-flexbox-grid';

import Translate from 'react-translate-component';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import store from '../../../store/index';

import { ROUTES } from '../../../constants/routes';

import PageContainer from '../../common/PageContainer';
import Loader from '../../common/Loader';
import PageTitle from '../../common/PageTitle';
import Button from '../../common/Button';
import {
  DetailsColumn,
  DetailsRow,
  LanguageRow,
} from '../../common/VisteDetails';
import { withGenericErrorHandling } from '../../common/ErrorHandling';

import ListaAziende from './ListaAziende';
import authAPI from '../../../api/Authentication';
import translator from 'counterpart';

const styles = theme => ({
  gdprTitle: {
    margin: '50px 0px 15px',
  },
  gdprSubtitle: {
    marginBottom: '15px',
  },
  gdprAction: {
    minWidth: '200px',
  },
  buttonsRow: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  adviserContiSandboxMode: {
    borderRadius: '5px',
    border: '1px solid',
    borderColor: theme.palette.orange.light,
    backgroundColor: theme.palette.orange.bg,

    alignItems: 'center',
    margin: '-20px 0px 50px auto',
  },
  adviseText: {
    margin: '5px',
  },
  innerContainerAdvise: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

class VistaProfilo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      profile: null,
      aziende: null,
    };
  }

  render() {
    const { classes } = this.props;

    const { loading } = this.state;

    const button_disabled = loading;

    const profilo = store.app.getProfile;
    return (
      <PageContainer main white style={{ paddingTop: '40px' }}>
        <PageTitle title={<Translate content="profile.titles.parent" />} />
        {!store.app.isVerified && profilo && <AdviseNotVerified />}
        <Row>
          <Col xs>
            {!profilo && <Loader />}
            {profilo !== null && profilo !== undefined && (
              <Row>
                <DetailsColumn
                  title={<Translate content="profile.myDetails" />}
                >
                  <DetailsRow
                    name={<Translate content="profile.firstName" />}
                    value={profilo.first_name}
                  />
                  <DetailsRow
                    name={<Translate content="profile.lastName" />}
                    value={profilo.last_name}
                  />
                  <DetailsRow
                    name={<Translate content="profile.phone" />}
                    value={profilo.phone ? profilo.phone : '-'}
                  />
                  <LanguageRow
                    value={profilo.lang}
                    person_uuid={profilo.uuid}
                  />
                  {!store.app.sandboxMode && (
                    <div
                      className={classes.buttonsRow}
                      style={{ width: '50%' }}
                    >
                      <Button
                        id="profile_edit_info"
                        component={props => (
                          <Link to={ROUTES.PROFILE_EDIT_INFO} {...props} />
                        )}
                        disabled={button_disabled}
                        variant="outlined"
                        color="primary"
                        small
                      >
                        <Translate content="profile.actions.editDetails" />
                      </Button>
                    </div>
                  )}
                </DetailsColumn>
                <DetailsColumn
                  title={<Translate content="profile.myCredentials" />}
                >
                  <DetailsRow
                    name={<Translate content="profile.email" />}
                    value={profilo.email}
                  />
                  <DetailsRow
                    name={<Translate content="profile.password" />}
                    value="********"
                  />
                  {!store.app.sandboxMode && (
                    <div className={classes.buttonsRow}>
                      <Button
                        id="profile_edit_email"
                        component={props => (
                          <Link to={ROUTES.PROFILE_EDIT_EMAIL} {...props} />
                        )}
                        disabled={button_disabled}
                        variant="outlined"
                        color="primary"
                        small
                        style={{ marginRight: '20px' }}
                      >
                        <Translate content="profile.actions.editEmail" />
                      </Button>
                      <Button
                        id="profile_edit_password"
                        component={props => (
                          <Link to={ROUTES.PROFILE_EDIT_PASSWORD} {...props} />
                        )}
                        disabled={button_disabled}
                        variant="outlined"
                        color="primary"
                        small
                      >
                        <Translate content="profile.actions.editPassword" />
                      </Button>
                    </div>
                  )}
                </DetailsColumn>
              </Row>
            )}
            {profilo && <ListaAziende aziende={store.app.businesses} />}
          </Col>
        </Row>
        {profilo && !store.app.sandboxMode && (
          <React.Fragment>
            <div className={classes.gdprContainer}>
              <Typography
                variant="body2"
                className={classnames([classes.gdprTitle, 'f-16'])}
              >
                <Translate content="gdpr.privacy.title" />
              </Typography>
              <Typography variant="caption" className={classes.gdprSubtitle}>
                <Translate content="gdpr.privacy.subtitle" />
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                component={props => (
                  <Link to={ROUTES.GESTIONE_CONSENSI} {...props} />
                )}
                customClass={classes.gdprAction}
                small
              >
                <Translate content="gdpr.privacy.action" />
              </Button>
            </div>
            <div className={classes.gdprContainer}>
              <Typography
                variant="body2"
                className={classnames([classes.gdprTitle, 'f-16'])}
              >
                <Translate content="gdpr.account.title" />
              </Typography>
              <Typography variant="caption" className={classes.gdprSubtitle}>
                <Translate content="gdpr.account.subtitle" />
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                component={props => (
                  <Link to={ROUTES.DELETE_ACCOUNT} {...props} />
                )}
                customClass={classes.gdprAction}
                small
              >
                <Translate content="gdpr.account.action" />
              </Button>
            </div>
          </React.Fragment>
        )}
      </PageContainer>
    );
  }
}

const AdviseNotVerified = withStyles(styles)(
  class extends React.Component {
    state = {
      mailSending: false,
      openModal: false,
    };

    inviaMail = () => {
      this.setState({ mailSending: true }, () => {
        authAPI.resendEmail(
          store.app.getProfile.uuid,
          () => {
            this.setState({ mailSending: false });
            store.app.snackBar.open(translator('common.mailSended'), 'success');
          },
          error => {
            store.app.snackBar.displayError(error);
            this.setState({ mailSending: false });
          }
        );
      });
    };

    render() {
      const { classes } = this.props;

      return (
        <React.Fragment>
          <div className={classes.adviserContiSandboxMode}>
            <div className={classes.innerContainerAdvise}>
              <Typography variant="body2">
                <Translate content="common.profileNotVerified" />
              </Typography>
              <Button
                id="send_mail"
                onClick={this.inviaMail}
                variant="raised"
                color="orange"
                small
                loading={this.state.mailSending}
              >
                <Translate content="common.sendAgain" />
              </Button>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
);

export default withGenericErrorHandling(
  withStyles(styles)(observer(VistaProfilo))
);
