import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import Modal from './CustomModal';
import Translate from 'react-translate-component';
import Button from '../common/Button';

const styles = theme => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  successIcon: {
    ...theme.icon.medium,
  },
  successTitle: {
    color: theme.palette.green.main,
    fontSize: '22px',
    fontWeight: theme.typography.fontWeightBold,
    marginTop: '12px',
    marginBottom: '33px',
  },
  sectionContainer: {
    backgroundColor: theme.palette.primary.bg,
    border: '1px solid',
    borderColor: theme.palette.primary.light,
    borderRadius: '6px',
    marginTop: '20px',
    minWidth: '80%',
  },
  sectionInnerContainer: {
    padding: '30px 40px 40px',
  },
  sectionTitle: {
    fontSize: '22px',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '20px',
    color: theme.palette.primary.main,
  },
  detailRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    marginBottom: '12px',
  },
  modalContainer: {
    margin: '50px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  modalTitle: {
    fontSize: '22px',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '5px',
    color: theme.palette.primary.main,
  },
  mainIcon: {
    width: '180px',
    height: '180px',
  },
  actionSection: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '50%',
  },
  actionSectionCentered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '45px',
  },
  actionSectionWithMarginTop: {
    marginTop: '45px',
  },
  containerDetail: {
    minWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  accountRow: {
    display: 'flex',
    alignItems: 'center',
  },
  avatarAccount: {
    marginRight: '5px',
    ...theme.icon.small,
  },
  innerContainer: {
    width: '600px',
    paddingRight: '45px',
    paddingLeft: '45px',
  },
});

export const ConfirmTitle = withStyles(styles)(props => {
  const { title, subtitle, classes } = props;
  return (
    <div className={classes.titleContainer}>
      <img
        src={require('../../img/message/success.png')}
        className={classes.successIcon}
        alt="ok"
      />
      <Typography
        variant="subheading"
        className={classnames([classes.successTitle, 'montserrat'])}
      >
        {title}
      </Typography>
      <Typography variant="caption">{subtitle}</Typography>
    </div>
  );
});

export const ConfirmSection = withStyles(styles)(props => {
  const { classes, children, title } = props;
  return (
    <div className={classes.sectionContainer}>
      <div className={classes.sectionInnerContainer}>
        {title && (
          <Typography
            variant="subheading"
            className={classnames([classes.sectionTitle, 'montserrat'])}
          >
            {title}
          </Typography>
        )}
        {children}
      </div>
    </div>
  );
});

export const DetailRow = withStyles(styles)(props => {
  const { text, value, nearValue, classes } = props;
  return (
    <div className={classes.detailRow}>
      <Typography style={{ flex: 1 }} variant="caption">
        {text}
      </Typography>
      <Typography
        style={{ flex: nearValue ? 3 : 2, marginLeft: '20px' }}
        variant="body2"
      >
        {value}
      </Typography>
    </div>
  );
});

export const ConfirmModal = withStyles(styles)(props => {
  const {
    id,
    open,
    handleClose,
    classes,
    onConfirm,
    title,
    subtitle,
    children,
    submitting,
  } = props;
  return (
    <Modal id={id} open={open} handleClose={handleClose}>
      <div>
        <Typography
          variant="subheading"
          className={classnames([classes.modalTitle, 'montserrat'])}
        >
          {title}
        </Typography>
        <Typography variant="body1" className="color7">
          {subtitle}
        </Typography>
        <div className={classes.modalContainer}>
          {children}
          <img
            src={require('../../img/form/confirmation.png')}
            alt="confirm"
            className={classes.mainIcon}
          />
        </div>
        <div className={classes.actionSection}>
          <Button
            variant="raised"
            color="primary"
            onClick={onConfirm}
            loading={submitting}
            style={{ flex: '2', marginRight: '10px' }}
          >
            <Translate content="common.confirm" />
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            style={{ flex: '1' }}
            loading={submitting}
          >
            <Translate content="common.back" />
          </Button>
        </div>
      </div>
    </Modal>
  );
});

export const ConfirmSensitiveActionModal = withStyles(styles)(props => {
  const {
    id,
    open,
    handleClose,
    classes,
    onConfirm,
    title,
    subtitle,
    submitting,
    confirmButtonText,
  } = props;

  return (
    <Modal id={id} open={open} handleClose={handleClose}>
      <div style={{ textAlign: 'center' }}>
        <Typography
          variant="subheading"
          className={classnames([classes.modalTitle, 'montserrat'])}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          className="color7"
          style={{ fontSize: '16px' }}
        >
          {subtitle}
        </Typography>
        <div className={classnames(classes.actionSectionCentered)}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            loading={submitting}
            style={{ flex: '1', marginRight: '10px', marginLeft: '20px' }}
          >
            <Translate content="common.abort" />
          </Button>
          <Button
            variant="raised"
            color="red"
            onClick={onConfirm}
            loading={submitting}
            style={{ flex: '1', marginRight: '20px' }}
          >
            {confirmButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
});

export const ActivateModal = withStyles(styles)(props => {
  const {
    id,
    open,
    handleClose,
    classes,
    onConfirm,
    title,
    subtitle,
    confirmButtonText,
    submitting,
  } = props;

  return (
    <Modal id={id} open={open} handleClose={handleClose}>
      <div style={{ textAlign: 'center' }}>
        <Typography
          variant="subheading"
          className={classnames([classes.modalTitle, 'montserrat'])}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          className="color7"
          style={{ fontSize: '16px' }}
        >
          {subtitle}
        </Typography>
        <div className={classnames(classes.actionSectionCentered)}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            loading={submitting}
            style={{ flex: '1', marginRight: '10px', marginLeft: '20px' }}
          >
            <Translate content="common.abort" />
          </Button>
          <Button
            variant="raised"
            color="primary"
            onClick={onConfirm}
            loading={submitting}
            style={{ flex: '1', marginRight: '20px' }}
          >
            {confirmButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
});

export const NoAccountModal = withStyles(styles)(props => {
  const {
    id,
    open,
    handleClose,
    classes,
    onConfirm,
    title,
    subtitle,
    confirmButtonText,
    submitting,
  } = props;

  return (
    <Modal id={id} open={open} handleClose={handleClose}>
      <div style={{ textAlign: 'center' }}>
        <Typography
          variant="subheading"
          className={classnames([classes.modalTitle, 'montserrat'])}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          className="color7"
          style={{ fontSize: '16px' }}
        >
          {subtitle}
        </Typography>
        <div className={classnames(classes.actionSectionCentered)}>
          <Button
            variant="raised"
            color="primary"
            onClick={onConfirm}
            loading={submitting}
            style={{ flex: '1', marginRight: '10px', marginLeft: '20px' }}
          >
            {confirmButtonText}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            loading={submitting}
            style={{ flex: '1', marginRight: '20px' }}
          >
            <Translate content="common.goBack" />
          </Button>
        </div>
      </div>
    </Modal>
  );
});

NoAccountModal.PropTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

ActivateModal.PropTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

ConfirmSensitiveActionModal.PropTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  confirmButtonText: PropTypes.string.isRequired,
};

ConfirmModal.PropTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};
