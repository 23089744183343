import React from 'react';

import { Switch } from 'react-router-dom';

import { ROUTES } from './constants/routes';

import { NotFound } from './components/ErrorPages/NotFound';

import {
  AuthenticatedRoute,
  BaseRoute,
  DelegatedRoute,
  UnAuthenticatedRoute,
} from './components/common/routes';

import { GeneralError } from './components/ErrorPages/GeneralError';

import HomePage from './components/HomePage/HomePage';
import VistaProfilo from './components/Profilo/view/VistaProfilo';
import ModificaDettagliUtente from './components/Profilo/edit/ModificaDettagliUtente';
import ModificaEmail from './components/Profilo/edit/ModificaEmail';
import ModificaPassword from './components/Profilo/edit/ModificaPassword';
import VistaBusiness from './components/Business/view/VistaBusiness';
import ModificaBusiness from './components/Business/edit/ModificaBusiness';
import ResetPassword from './components/Setup/ResetPassword';
import RequestResetPassword from './components/Setup/RequestResetPassword';
import SignUp from './components/Setup/SignUp';
import VerificaEmail from './components/Setup/VerificaEmail';
import RegistraBusiness from './components/Business/new/RegistraBusiness';
import Login from './components/Setup/Login';
import ListaPagamenti from './components/Pagamenti/list/ListaPagamenti';
import DettagliPagamento from './components/Pagamenti/view/DettagliPagamento';
import ListaConti from './components/Conti/list/ListaConti';
import ListaPos from './components/POS/list/ListaPos';
import NuovoWebPOS from './components/POS/new/WebPOS';
import NuovoMobilePOS from './components/POS/new/MobilePOS';
import NewPOS from './components/POS/new/NewPos';
import NewConto from './components/Conti/new/NewConto';
import EditAccount from './components/Conti/edit/Modifica';
import { WebPosDetail, MobilePosDetail } from './components/POS/detail';
import GestioneConsensi from './components/GDPR/GestioneConsensi';
import DeleteAccount from './components/GDPR/DeleteAccount';

const ContiRouteGroup = () => {
  return (
    <Switch>
      <BaseRoute path={ROUTES.ACCOUNT_EDIT} exact component={EditAccount} />
      <BaseRoute path={ROUTES.ACCOUNT_LIST} exact component={ListaConti} />
      <BaseRoute path={ROUTES.ACCOUNT_VIEW} exact component={ListaConti} />
      <BaseRoute path={ROUTES.ACCOUNT_NEW} exact component={NewConto} />
      <BaseRoute component={NotFound} />
    </Switch>
  );
};

const BusinessRouteGroup = () => {
  return (
    <Switch>
      <BaseRoute
        path={ROUTES.BUSINESS_EDIT}
        exitSandbox
        exact
        component={ModificaBusiness}
      />
      <BaseRoute path={ROUTES.BUSINESS_VIEW} exact component={VistaBusiness} />
      <BaseRoute component={NotFound} />
    </Switch>
  );
};

const PagamentiRouteGroup = () => {
  return (
    <Switch>
      <BaseRoute
        path={ROUTES.PAYMENT_ORDER_LIST}
        exact
        component={ListaPagamenti}
      />
      <BaseRoute
        path={ROUTES.PAYMENT_ORDER_LIST_WITH_FILTER}
        exact
        component={ListaPagamenti}
      />
      <BaseRoute
        path={ROUTES.PAYMENT_ORDER_VIEW}
        exact
        component={DettagliPagamento}
      />
      <BaseRoute component={NotFound} />
    </Switch>
  );
};

const PoSRouteGroup = () => {
  return (
    <Switch>
      <BaseRoute path={ROUTES.POS_VIEW} exact component={ListaPos} />
      <BaseRoute path={ROUTES.POS_LIST} exact component={ListaPos} />
      <BaseRoute path={ROUTES.POS_NEW_WEB} exact component={NuovoWebPOS} />
      <BaseRoute
        path={ROUTES.POS_NEW_MOBILE}
        exact
        component={NuovoMobilePOS}
      />
      <BaseRoute path={ROUTES.POS_NEW} exact component={NewPOS} />
      <BaseRoute path={ROUTES.POS_EDIT_WEB} exact component={WebPosDetail} />
      <BaseRoute
        path={ROUTES.POS_EDIT_MOBILE}
        exact
        component={MobilePosDetail}
      />
      <BaseRoute component={NotFound} />
    </Switch>
  );
};

const ProfiloRouteGroup = () => {
  return (
    <Switch>
      <BaseRoute path={ROUTES.PROFILE_VIEW} exact component={VistaProfilo} />
      <BaseRoute
        path={ROUTES.PROFILE_EDIT_INFO}
        exitSandbox
        exact
        component={ModificaDettagliUtente}
      />
      <BaseRoute
        path={ROUTES.PROFILE_EDIT_EMAIL}
        exitSandbox
        exact
        component={ModificaEmail}
      />
      <BaseRoute
        path={ROUTES.PROFILE_EDIT_PASSWORD}
        exitSandbox
        exact
        component={ModificaPassword}
      />
      <BaseRoute
        path={ROUTES.GESTIONE_CONSENSI}
        exitSandbox
        exact
        component={GestioneConsensi}
      />
      <BaseRoute
        path={ROUTES.DELETE_ACCOUNT}
        exitSandbox
        exact
        component={DeleteAccount}
      />
      <BaseRoute component={NotFound} />
    </Switch>
  );
};

const RecuperoPasswordRouteGroup = () => {
  return (
    <Switch>
      <BaseRoute
        path={ROUTES.RESET_PASSWORD_REQUEST}
        exitSandbox
        exact
        component={RequestResetPassword}
      />
      <BaseRoute
        path={ROUTES.RESET_PASSWORD_CONFIRM}
        exitSandbox
        exact
        component={ResetPassword}
      />
      <BaseRoute component={NotFound} />
    </Switch>
  );
};

class RoutesComponent extends React.Component {
  render() {
    return (
      <Switch>
        <DelegatedRoute
          path={ROUTES.ACCOUNT_PREFIX}
          component={ContiRouteGroup}
        />
        <DelegatedRoute
          path={ROUTES.PAYMENT_ORDER_PREFIX}
          component={PagamentiRouteGroup}
        />
        <DelegatedRoute path={ROUTES.POS_PREFIX} component={PoSRouteGroup} />
        <DelegatedRoute
          path={ROUTES.BUSINESS_PREFIX}
          component={BusinessRouteGroup}
        />
        <AuthenticatedRoute
          path={ROUTES.PROFILE_PREFIX}
          component={ProfiloRouteGroup}
        />
        <UnAuthenticatedRoute
          path={ROUTES.RESET_PASSWORD_PREFIX}
          component={RecuperoPasswordRouteGroup}
        />
        <UnAuthenticatedRoute path={ROUTES.LOGIN} exact component={Login} />
        <UnAuthenticatedRoute
          path={ROUTES.REGISTER}
          exitSandbox
          exact
          component={SignUp}
        />
        <AuthenticatedRoute
          path={ROUTES.VERIFY_EMAIL}
          exitSandbox
          exact
          component={VerificaEmail}
        />
        <AuthenticatedRoute
          path={ROUTES.CONFIRM_EMAIL}
          exitSandbox
          exact
          component={VerificaEmail}
        />
        <AuthenticatedRoute
          path={ROUTES.REGISTER_BUSINESS}
          exitSandbox
          exact
          component={RegistraBusiness}
        />
        <BaseRoute path={ROUTES.GENERIC_ERROR} exact component={GeneralError} />
        <BaseRoute path="/" exact component={HomePage} />
        <BaseRoute component={NotFound} />
      </Switch>
    );
  }
}

export { RoutesComponent };
