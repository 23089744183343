import { StyleRulesCallback } from '@material-ui/core';

const styles: StyleRulesCallback = ({ palette }: { [key: string]: any }) => ({
  containerMap: {
    width: '100%',
    border: '1px solid',
    borderColor: palette.primary.bluegrey,
    height: '205px',
  },
});

export default styles;
