import 'regenerator-runtime/runtime';
import React from 'react';
import Header from './components/common/Header';
import classnames from 'classnames';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import store from './store';
import { observer } from 'mobx-react';

import { ScrollToTop } from './components/common/scrolling';
import MenuBar from './components/common/MenuBar';
import defaultTheme from './styles/theme';

import Footer from './components/common/Footer';
import ProfileAdvance from './components/common/ProfileAdvance';
import { CookiesProvider } from 'react-cookie';
import counterpart from 'counterpart';

import { CookiesDisabled } from './components/ErrorPages/CookiesDisabled';

import { SnackBarStyled } from './components/common/SnackBar';

import { RoutesComponent } from './routes';
import sandboxModeTheme from './styles/sandboxModeTheme';
import ExpiredSessionModal from './components/common/ExpiredSessionModal';
import Loader from './components/common/Loader';
import GenericErrorPage from './static/GenericErrorPage';
import { APP_STATUS } from './constants/appStatus';
import { ExpiredSessionError } from './utils/errors';
import {
  BusinessPanelValidationError,
  BusinessPanelError,
} from 'business-panel-sdk/lib/errors';
import translator from 'counterpart';
import { PRODUCTION_MODE, SANDBOX_HOSTNAME } from './config';
import { initDictionaries } from './constants/supportedLanguages';
import Analytics from 'react-router-ga';
import { ANALYTICS_PROPERTY } from './constants/analytics';
import * as Sentry from '@sentry/browser';

initDictionaries();
if (!PRODUCTION_MODE) {
  counterpart.onTranslationNotFound(function(locale, key, fallback) {
    if (!fallback) {
      throw new Error(
        `Missing translation for key ${key} for locale ${locale}.`
      );
    }
  });
}

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  app: {
    minHeight: '100vh',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
    width: '100%',
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },
  topContainerLoggedIn: {
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      paddingBottom: '200px',
    },
  },
  drawerPaper: {
    position: 'relative',
    width: '30%',
    maxWidth: '250px',
  },
  toolbar: theme.mixins.toolbar,
  backgroundColorMain: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    height: '100%',
  },
  snackBarSUccess: {
    backgroundColor: '#daecd0',
    color: '#619823',
    border: 'solid 1px #bacba6',
  },
});

class App extends React.Component {
  state = {
    cookieEnabled: false,
    openSnackBar: false,
    snackBarMessage: '',
  };

  componentDidMount() {
    let cookieEnabled = navigator.cookieEnabled;
    if (!cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled = document.cookie.indexOf('testcookie') >= 0;
    }
    store.app.setSandboxMode(window.location.hostname === SANDBOX_HOSTNAME);
    this.setState(
      {
        cookieEnabled: cookieEnabled,
      },
      store.app.initApp
    );
  }

  componentDidCatch(error, info) {
    Sentry.captureException(error);
    if (
      error instanceof BusinessPanelError ||
      error instanceof BusinessPanelValidationError
    ) {
      store.app.snackBar.displayError(error);
    } else if (error instanceof ExpiredSessionError) {
      // alert(JSON.stringify(store.app))
    } else {
      if (PRODUCTION_MODE) {
        store.app.snackBar.open(translator('messages.generic.ko'), 'error');
      } else {
        throw error;
      }
    }
  }

  render() {
    if (store.app.status === APP_STATUS.LOADING) {
      return (
        <MuiThemeProvider theme={defaultTheme}>
          <div style={{ marginTop: '40px' }}>
            <Loader />
          </div>
        </MuiThemeProvider>
      );
    }

    if (store.app.status === APP_STATUS.ERROR) {
      return (
        <MuiThemeProvider theme={defaultTheme}>
          <GenericErrorPage />
        </MuiThemeProvider>
      );
    }

    const { classes } = this.props;
    const { cookieEnabled } = this.state;

    const isAuthenticated = store.app.isLoggedIn;
    const isSandboxMode = store.app.sandboxMode;

    return (
      <CookiesProvider>
        <MuiThemeProvider
          theme={isSandboxMode ? sandboxModeTheme : defaultTheme}
        >
          <div className={classes.app}>
            <Router>
              <Analytics id={ANALYTICS_PROPERTY}>
                <ScrollToTop>
                  <div className={classes.mainContainer}>
                    <div
                      className={classnames({
                        [classes.topContainer]: true,
                        [classes.topContainerLoggedIn]: isAuthenticated,
                      })}
                    >
                      {isAuthenticated && (
                        <Header fatherClass={classes.appBar} />
                      )}
                      {!cookieEnabled && <CookiesDisabled />}
                      {cookieEnabled && (
                        <React.Fragment>
                          <MenuBar isSandboxMode={store.app.sandboxMode} />
                          <RoutesComponent />
                        </React.Fragment>
                      )}
                    </div>
                    {isAuthenticated && <ProfileAdvance store={store} />}
                    {isAuthenticated && <Footer />}
                  </div>
                </ScrollToTop>
              </Analytics>
            </Router>
            <SnackBarStyled
              snackBar={store.app.snackBar}
              open={store.app.snackBar.isOpen}
            />
            <ExpiredSessionModal open={store.app.expiredSession} />
          </div>
        </MuiThemeProvider>
      </CookiesProvider>
    );
  }
}

export default withStyles(styles)(observer(App));
