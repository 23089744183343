import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { PRODUCTION_HOSTNAME, SANDBOX_HOSTNAME } from '../../../config';

const BaseRoute = ({ exitSandbox, ...rest }) => {
  if (exitSandbox && window.location.hostname === SANDBOX_HOSTNAME) {
    window.location = window.location.href.replace(
      SANDBOX_HOSTNAME,
      PRODUCTION_HOSTNAME
    );
    return null;
  }
  return <Route {...rest} />;
};

BaseRoute.propTypes = {
  exitSandbox: PropTypes.bool,
};

export { BaseRoute };
