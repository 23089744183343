import BusinessPanelSDK from 'business-panel-sdk';

import store from '../store';
import translator from 'counterpart';
import { onErrorNotification, onSuccessNotification } from './common';

export default {
  getList: (business_uuid, onSuccess, onError) => {
    const getAll = store.app.factory.make('GetDepositAccountsAction');
    getAll.routeParams = { business_uuid: business_uuid };

    getAll
      .run()
      .then(onSuccess)
      .catch(onError);
  },
  getBitcoinAccount: (business_uuid, account_uuid, onSuccess, onError) => {
    const getBitcoinAccount = store.app.factory.make('GetBitcoinAccountAction');
    getBitcoinAccount.routeParams = {
      business_uuid: business_uuid,
      account_uuid: account_uuid,
    };

    getBitcoinAccount
      .run()
      .then(onSuccess)
      .catch(onError);
  },
  createBankAccount: (business_uuid, onSuccess, onError, data) => {
    const createBank = store.app.factory.make('CreateBankAccountAction');

    createBank.url_params = { uuid: business_uuid };
    createBank.body_params = BusinessPanelSDK.sendables.BankAccount.fromData(
      data
    );

    createBank
      .run()
      .then(onSuccessNotification(onSuccess))
      .catch(onErrorNotification(onError));
  },
  getBankAccount: (business_uuid, account_uuid, onSuccess, onError) => {
    const getBank = store.app.factory.make('GetBankAccountAction');
    getBank.routeParams = {
      business_uuid: business_uuid,
      account_uuid: account_uuid,
    };

    getBank
      .run()
      .then(onSuccess)
      .catch(onError);
  },
  editBankAccount: (account_uuid, business_uuid, onSuccess, onError, data) => {
    const editBank = store.app.factory.make('EditBankAccountAction');

    editBank.url_params = {
      business_uuid: business_uuid,
      account_uuid: account_uuid,
    };
    editBank.body_params = BusinessPanelSDK.sendables.EditBankAccount.fromData(
      data
    );

    editBank
      .run()
      .then(onSuccessNotification(onSuccess))
      .catch(onErrorNotification(onError));
  },
  deleteBankAccount: (business_uuid, account_uuid, onSuccess, onError) => {
    const deleteBankAccount = store.app.factory.make('DeleteBankAccountAction');

    deleteBankAccount.routeParams = {
      business_uuid: business_uuid,
      account_uuid: account_uuid,
    };

    deleteBankAccount
      .run()
      .then(
        onSuccessNotification(
          onSuccess,
          translator('account.delete.success.message')
        )
      )
      .catch(onErrorNotification(onError));
  },
  getSupportedWallets: (onSuccess, onError) => {
    const getSupportedWallets = store.app.factory.make(
      'GetSupportedWalletsAction'
    );

    getSupportedWallets
      .run()
      .then(onSuccess)
      .catch(onError);
  },
  getAccountPoses: (account_uuid, onSuccess, onError) => {
    const getAccountPoses = store.app.factory.make(
      'GetDepositAccountPosesAction'
    );

    getAccountPoses.routeParams = {
      account_uuid: account_uuid,
    };

    getAccountPoses
      .run()
      .then(onSuccess)
      .catch(onError);
  },
};
