import React from 'react';

import Translate from 'react-translate-component';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import classnames from 'classnames';

import ErrorPage from '../components/common/ErrorPage';

import { buildWebsiteLink } from '../utils/links';

const styles = theme => ({
  link: {
    color: theme.palette.primary.link,
    fontWeight: theme.typography.fontWeightBold,
    ...theme.typography.link,
  },
  link_wrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px',
  },
  utils_message: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    width: '70%',
    alignSelf: 'center',
  },
});

const GenericErrorPage = props => {
  const { classes } = props;
  return (
    <ErrorPage
      title={<Translate content="errors.genericErrorPage.title" />}
      // showLogo={true}
    >
      <Typography
        className={classnames([classes.utils_message, 'f-18'])}
        variant="caption"
      >
        <Translate content="errors.genericErrorPage.utils" />
      </Typography>
      <Typography
        className={classnames([classes.link_wrapper, 'f-18'])}
        variant="caption"
      >
        <a
          className={classnames([classes.link, 'f-18'])}
          href={buildWebsiteLink('contact_us')}
        >
          <Translate content="common.contactUs" /> >
        </a>
      </Typography>
    </ErrorPage>
  );
};

export default withStyles(styles)(GenericErrorPage);
