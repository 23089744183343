import { StyleRulesCallback } from '@material-ui/core';

const styles: StyleRulesCallback = ({
  typography,
}: {
  [key: string]: any;
}) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: '30px',
    width: '100%',
  },
  icon: {
    marginRight: '10px',
    width: '20px',
    height: '20px',
  },
  labelContainer: {
    width: '100%',
  },
});

export default styles;
