import { withStyles } from '@material-ui/core';
import { Col } from 'react-flexbox-grid';
import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';
import styles from './theme';
import { DetailsColumnProps } from './propTypes';

const DetailsColumn: FC<DetailsColumnProps> = ({
  classes,
  title,
  children,
}) => (
  <Col xs>
    <Typography className={classes!.columnTitle}>{title}</Typography>
    {children}
  </Col>
);

const DecoratedDetailsColumn = withStyles(styles)(DetailsColumn);

export default DecoratedDetailsColumn;
