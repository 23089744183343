import translator from 'counterpart';
import ErrorPage from '../common/ErrorPage';
import React from 'react';
import Translate from 'react-translate-component';
import Typography from '@material-ui/core/Typography';
import { LinkPrimary } from '../common/Links';
import { WebsiteLink } from '../common/WebsiteLink';

export const CookiesDisabled = props => {
  const enableCookiesUrl = translator(
    'errors.noCookies.links.enableCookies.url'
  );
  return (
    <ErrorPage
      title={
        <React.Fragment>
          <Translate content="errors.noCookies.title.row1" />
          <br />
          <Translate content="errors.noCookies.title.row2" />
        </React.Fragment>
      }
    >
      <Typography
        className="f-18"
        variant="caption"
        style={{ marginBottom: '10px' }}
      >
        <Translate content="errors.noCookies.utils.row1" />
        <br />
        <Translate content="errors.noCookies.utils.row2" />
      </Typography>
      <LinkPrimary customClasses="f-18" to={enableCookiesUrl} targetBlank>
        <Translate content="errors.noCookies.links.enableCookies.text" />
      </LinkPrimary>
      <WebsiteLink className="f-18" withoutIcon to="contact_us">
        <Translate content="common.contactUs" />
      </WebsiteLink>
    </ErrorPage>
  );
};
