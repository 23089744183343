import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ConfirmSensitiveActionModal } from '../../common/Confirm';
import Translate from 'react-translate-component';

const styles = theme => ({
  innerContainer: {
    textAlign: 'center',
  },
});

class Deactivate extends React.Component {
  render() {
    const {
      pos,
      open,
      handleClose,
      classes,
      onConfirm,
      submitting,
      ...otherProps
    } = this.props;

    return (
      <ConfirmSensitiveActionModal
        {...otherProps}
        open={open}
        handleClose={handleClose}
        title={<Translate content="POS.deactivate.confirm.web.titles.title" />}
        subtitle={
          pos.type === 'mobile' ? (
            <Translate
              content="POS.deactivate.confirm.mobile.titles.subtitle"
              with={{ posName: pos.name, device: pos.device }}
            />
          ) : (
            <Translate
              content="POS.deactivate.confirm.web.titles.subtitle"
              with={{ posName: pos.name, website: pos.website }}
            />
          )
        }
        confirmButtonText={<Translate content="common.deactivate" />}
        onConfirm={onConfirm}
        submitting={submitting}
      />
    );
  }
}

export default withStyles(styles)(Deactivate);
