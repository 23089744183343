import { extendObservable, action } from 'mobx';
import LocalStorage from './LocalStorage';
import Profile from './Profile';
import { BusinessSimplified } from './Business';
import Token from './Token';
import SnackBar from './SnackBar';
import AuthorizationAPI from '../api/Authentication';
import BusinessAPI from '../api/Business';
import ProfileAPI from '../api/Profile';
import PosAPI from '../api/POS';
import MobilePosAPI from '../api/MobilePoS';
import AccountsAPI from '../api/Accounts';
import {
  BusinessPanelActionsFactory,
  BusinessPanelSocketsFactory,
  BusinessPanelFormsFactory,
} from 'business-panel-sdk';
import ErrorCodes from '../constants/SDKerrorCodes';
import {
  TOKEN_STORAGE_KEY,
  LANGUAGE_STORAGE_KEY,
} from '../constants/localStorageKeys';
import moment from 'moment';
import counterpart from 'counterpart';
import {
  API_URL,
  COOKIE_POLICY_DOMAIN,
  SANDBOX_API_URL,
  SDK_AUTH_SKIP_HTTPS_VALIDATION,
  SDK_AUTH_WITH_CREDENTIALS,
  SOCKET_URL,
} from '../config';
import { APP_STATUS } from '../constants/appStatus';
import { ExpiredSessionError } from '../utils/errors';
import { supportedLanguages } from '../constants/supportedLanguages';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';

class App {
  constructor() {
    extendObservable(this, {
      status: APP_STATUS.LOADING,
      token: new Token(),
      profile: null,
      businesses: null,
      webPoses: null,
      mobilePoses: null,
      accounts: null,
      snackBar: new SnackBar(),
      sandboxMode: false,
      websocket_profile: null,
      websocket_business: null,
      websocket_payments: null,
      websocket_mobilePos: null,
      factory: null,
      sandboxFactory: null,
      productionFactory: null,
      formFactory: null,
      sandboxFormFactory: null,
      productionFormFactory: null,
      expiredSession: false,
      language: null,
      // get user status
      get hasBankAccounts() {
        return this.sandboxMode ? false : this.token.hasBankAccounts;
      },
      get isLoggedIn() {
        return this.token.isLoggedIn;
      },
      get isDelegated() {
        return this.token.isDelegated;
      },
      get isVerified() {
        return this.token.isVerified;
      },
      get hasBusiness() {
        if (!this.isLoggedIn) {
          return false;
        }
        return this.token.hasBusiness;
      },
      // get basic user info
      get user_name() {
        return this.token.user_name;
      },
      get user_fullName() {
        return this.token.user_fullName;
      },
      get user_uuid() {
        return this.token.user_uuid;
      },
      get business_name() {
        return this.token.business_name;
      },
      get business_uuid() {
        return this.token.business_uuid;
      },
      get position() {
        return this.token.position;
      },
      // get detailed user info
      get getProfile() {
        return this.profile;
      },
      get getFactory() {
        return this.factory;
      },
      get getFormFactory() {
        return this.formFactory;
      },
      get getLanguage() {
        return this.language;
      },
      get hasProfile() {
        return Boolean(this.profile);
      },
      get hasPos() {
        return this.hasWebPos || this.hasMobilePos;
      },
      get hasWebPos() {
        if (this.webPoses) {
          return this.webPoses.length > 0;
        } else {
          return false;
        }
      },
      get hasMobilePos() {
        if (this.mobilePoses) {
          return this.mobilePoses.length > 0;
        } else {
          return false;
        }
      },
      get hasAccount() {
        if (this.accounts) {
          return this.accounts.bank.length > 0;
        } else {
          return false;
        }
      },
    });

    this.initFactories(this.getSandboxMode);
    this.initSockets();
  }

  getBusinesses = action(() => {
    if (!this.isLoggedIn || !this.hasBusiness) {
      return null;
    }
    return this.businesses ? this.businesses : null;
  });

  /**
   * Checks that the session is still valid, by checking that the token
   * currently in the local storage is equivalent to the token in memory.
   *
   * If the stored token is not equivalent, the method expires the session.
   *
   * If the token is equivalent but not equal, the token is reloaded from
   * local storage.
   */
  checkSessionValidity = action(() => {
    const token = LocalStorage.get(TOKEN_STORAGE_KEY);
    if (!this.token.isEquivalent(token)) {
      this.expiredSession = true;
      return false;
    } else if (!this.token.isEqual(token)) {
      this.setToken(token);
    }
    return true;
  });

  setToken = action((token, callback) => {
    LocalStorage.set(TOKEN_STORAGE_KEY, token);
    this.token.setToken(token);
    if (this.isLoggedIn) {
      this.createUserSocket();
    }
    if (callback) {
      callback();
    }
  });

  refreshToken = action(callback => {
    AuthorizationAPI.refreshToken(
      action(token => {
        this.setToken(token, callback);
      })
    );
  });

  initApp = action(() => {
    const defaultLang =
      supportedLanguages[navigator.language] == null
        ? 'en'
        : navigator.language;
    const language = LocalStorage.get(LANGUAGE_STORAGE_KEY) || defaultLang;
    // const language = 'it';
    this.setLanguage(language);
    this.initAuth(() => {
      this.loadInfo();
    });
  });

  reloadInfoAndKeepToken = action(callback => {
    this.status = APP_STATUS.LOADING;
    this.loadInfo(callback);
  });

  reloadInfo = action(callback => {
    this.status = APP_STATUS.LOADING;
    this.refreshToken(() => {
      this.loadInfo(callback);
    });
  });

  loadInfo = action(callback => {
    const promises = [];
    if (this.isDelegated) {
      this.destroyAllMobilePosSockets();
      promises.push(this.loadAccounts());
      promises.push(this.loadPoses());
    }
    if (this.isLoggedIn) {
      promises.push(this.loadBusinesses());
      promises.push(this.loadProfile());
    }
    Promise.all(promises)
      .then(
        action(() => {
          this.status = APP_STATUS.LOADED;
          if (callback) {
            callback();
          }
        })
      )
      .catch(
        action((...errors) => {
          if (
            errors.reduce(
              (acc, e) => acc && ErrorCodes.LOGOUT.includes(e.error_code),
              true
            )
          ) {
            this.status = APP_STATUS.LOADED;
          } else {
            Sentry.withScope(scope => {
              scope.setExtras({
                location: 'loadInfo',
              });
              Sentry.captureException({ errors });
            });
            this.status = APP_STATUS.ERROR;
          }
        })
      );
  });

  initFactories = action(callback => {
    const withCredentials = SDK_AUTH_WITH_CREDENTIALS;
    const skipHTTPSvalidation = SDK_AUTH_SKIP_HTTPS_VALIDATION;
    const hostname = API_URL;
    const sandboxHostname = SANDBOX_API_URL;
    const preSendHooks = [
      action((action, cache, context, request) => {
        if (!this.checkSessionValidity()) {
          throw new ExpiredSessionError();
        }
        return request;
      }),
    ];
    const failureHooks = [
      (action, cache, context, response) => {
        if (
          response.data.error_code &&
          !ErrorCodes.LOGOUT.includes(response.data.error_code)
        ) {
          Sentry.withScope(scope => {
            scope.setLevel('info');
            scope.setExtras({
              location: 'request',
              verb: action.constructor.verb,
              route: action.constructor.route,
              event: {
                request: {
                  method: response.config.method,
                  url: response.config.url,
                  headers: response.config.headers,
                  params: response.config.params,
                  data: response.config.data,
                },
                response: {
                  status: response.status,
                  headers: response.headers,
                  data: response.data,
                },
              },
            });
            scope.setLevel(Severity.Error);
            Sentry.captureMessage(
              response.data && response.data.message
                ? response.data.message
                : `request ${action.constructor.route}`
            );
          });
        }
        return response;
      },
      action((action, cache, context, response) => {
        if (ErrorCodes.LOGOUT.includes(response.data.error_code)) {
          this.logout();
        }
        return response;
      }),
    ];
    const setup_params = {
      context: {
        hostname: hostname,
        skipHTTPSvalidation: skipHTTPSvalidation,
        withCredentials: withCredentials,
      },
      preSendHooks,
      failureHooks,
    };
    const setup_params_sandbox = {
      context: {
        hostname: sandboxHostname,
        skipHTTPSvalidation: skipHTTPSvalidation,
        withCredentials: withCredentials,
      },
      preSendHooks,
      failureHooks,
    };
    this.sandboxFactory = new BusinessPanelActionsFactory(setup_params_sandbox);
    this.sandboxFormFactory = new BusinessPanelFormsFactory(
      this.sandboxFactory
    );

    this.productionFactory = new BusinessPanelActionsFactory(setup_params);
    this.productionFormFactory = new BusinessPanelFormsFactory(
      this.productionFactory
    );

    callback();
  });

  initSockets = action(() => {
    const context = {
      hostname: SOCKET_URL,
    };
    const socketsFactory = new BusinessPanelSocketsFactory(context);
    this.websocket_profile = socketsFactory.make('ProfileSocket');
    this.websocket_payments = socketsFactory.make('PaymentOrderSocket');
    this.websocket_mobilePos = socketsFactory.make('PosSocket');
  });

  onEmailVerified = action(callback => {
    this.websocket_profile.on(
      'user.verified',
      action(newToken => {
        this.setToken(newToken, callback);
      }),
      action(error => {
        Sentry.withScope(scope => {
          scope.setExtras({
            location: 'onEmailVerified',
            ...error,
          });
          Sentry.captureMessage(`Error on email verified`, Severity.Warning);
        });
        console.error(error);
      })
    );
  });

  onPosActivate = action(callback => {
    this.websocket_mobilePos.on('pos.activated', callback);
  });

  initAuth = action(callback => {
    document.onvisibilitychange = this.checkSessionValidity;
    const token = LocalStorage.get(TOKEN_STORAGE_KEY);
    if (token) {
      this.setToken(token, callback);
    } else {
      AuthorizationAPI.refreshToken(
        action(token => {
          this.setToken(token, callback);
        }),
        () => {
          AuthorizationAPI.getGuestToken(
            action(token => {
              this.setToken(token, callback);
            }),
            action(error => {
              console.error(error);
              Sentry.withScope(scope => {
                scope.setExtras(
                  Object.assign({}, error, {
                    location: 'inithAuth',
                    ...error,
                  })
                );
                Sentry.captureMessage(`Error on init auth`, Severity.Error);
              });
              this.status = APP_STATUS.ERROR;
            })
          );
        }
      );
    }
  });

  verify = action(verification_token => {
    const promise = (resolve, reject) => {
      AuthorizationAPI.verify(
        verification_token,
        token => {
          this.setToken(token, resolve);
        },
        error => {
          Sentry.withScope(scope => {
            scope.setExtras(
              Object.assign({}, error, {
                location: 'onverify',
              })
            );
            Sentry.captureMessage(`Error on verify`, Severity.Error);
          });
          reject(error);
        }
      );
    };
    return new Promise(promise);
  });

  confirmEmailChange = action(verification_token => {
    const promise = (resolve, reject) => {
      AuthorizationAPI.confirmEmailChange(
        verification_token,
        token => {
          this.setToken(token, resolve);
        },
        error => {
          Sentry.withScope(scope => {
            scope.setExtras(
              Object.assign({}, error, {
                location: 'onEmailChange',
              })
            );
            Sentry.captureMessage(`Error on emailChange`, Severity.Error);
          });
          reject();
        }
      );
    };
    return new Promise(promise);
  });

  confirmEmail = action(token => {
    const promise = (resolve, reject) => {
      AuthorizationAPI.confirmEmail(
        token,
        token => {
          this.setToken(token, resolve);
        },
        error => {
          Sentry.withScope(scope => {
            scope.setExtras(
              Object.assign({}, error, {
                location: 'onConfirmEmail',
              })
            );
            Sentry.captureMessage(`Error on onConfirmEmail`, Severity.Error);
          });
          this.snackBar.displayError(error);
          reject();
        }
      );
    };
    return new Promise(promise);
  });

  onLogin = action((token, callback) => {
    this.status = APP_STATUS.LOADING;
    this.setToken(token, () => {
      this.setCookiePolicy();
      this.loadInfo(callback);
    });
  });

  onRegister = action((token, callback) => {
    this.status = APP_STATUS.LOADING;
    this.setToken(token, () => {
      this.setCookiePolicy();
      this.loadInfo(callback);
    });
  });

  login = action((username, password) => {
    const promise = (resolve, reject) => {
      AuthorizationAPI.login(
        username,
        password,
        false, // TODO: this is the remember me flag
        action(token => {
          this.setToken(token, resolve);
        }),
        error => {
          Sentry.withScope(scope => {
            scope.setExtras(
              Object.assign({}, error, {
                location: 'onLogin',
              })
            );
            Sentry.captureMessage(`Error on onLogin`, Severity.Error);
          });
          this.snackBar.displayError(error);
          reject(error);
        }
      );
    };
    return new Promise(promise);
  });

  logout = action(() => {
    this.token.resetToken();
    LocalStorage.set(TOKEN_STORAGE_KEY, null);
    this.destroyUserSocket();
    this.destroyAllMobilePosSockets();
    this.profile = null;
    this.businesses = null;
    this.webPoses = null;
    this.mobilePoses = null;
    this.accounts = null;
    AuthorizationAPI.getGuestToken(
      action(token => {
        this.setToken(token);
      }),
      error => {
        Sentry.withScope(scope => {
          scope.setExtras(
            Object.assign({}, error, {
              location: 'onLogout',
            })
          );
          Sentry.captureMessage(`Error on onLogout`, Severity.Error);
        });
        console.error(error);
      }
    );
    Sentry.configureScope(scope => {
      scope.setUser(null);
    });
  });

  selectBusiness = action((business_uuid, callback) => {
    const promise = (resolve, reject) => {
      AuthorizationAPI.delegate(
        business_uuid,
        action(token => {
          this.setToken(token, () => {
            this.reloadInfoAndKeepToken(() => {
              if (callback) {
                callback();
              }
              resolve();
            });
          });
        }),
        error => {
          Sentry.withScope(scope => {
            scope.setExtras(
              Object.assign({}, error, {
                location: 'onSelectBusiness',
              })
            );
            Sentry.captureMessage(`Error on onSelectBusiness`, Severity.Error);
          });
          this.snackBar.displayError(error);
          reject(error);
        }
      );
    };
    return new Promise(promise);
  });

  // Web Sockets

  createUserSocket = action(() => {
    this.destroyUserSocket();
    AuthorizationAPI.getWebsocketRoom(
      response => {
        this.websocket_profile.subscribe(response.room_id);
      },
      error => {
        Sentry.withScope(scope => {
          scope.setExtras(
            Object.assign({}, error, {
              location: 'get roomid',
            })
          );
          Sentry.captureMessage(`Error on getting roomId`, Severity.Error);
          console.error(error);
        });
      }
    );
  });

  destroyUserSocket = action(() => {
    this.websocket_profile.unsubscribeAll();
  });

  createMobilePosSocket = action(posId => {
    this.destroyMobilePosSocket(posId);
    this.websocket_mobilePos.subscribe(posId);
  });

  destroyMobilePosSocket = action(posId => {
    this.websocket_mobilePos.unsubscribe(posId);
  });

  destroyAllMobilePosSockets = action(() => {
    this.websocket_mobilePos.unsubscribeAll();
  });

  // Resource Loading Methods
  //
  // These methods return a promise that resolves to the requested object or rejects
  // in case of error.
  //
  // Mobx's observable/observer paradigm only works if the observable object is accessed
  // inside the render method of a component, so if a resource is loaded asynchronously
  // and is needed, e.g., while mounting a component, it could resolve to null if accessed
  // directly. A Promise that waits for the resource to be ready before resolving avoids
  // this problem.

  loadBusinesses = action(() => {
    const promise = action((resolve, reject) => {
      BusinessAPI.getBusinesses(
        action(result => {
          this.businesses = result.businesses.map(business => {
            return new BusinessSimplified(business);
          });
          resolve(this.businesses);
        }),
        reject
      );
    });
    return new Promise(promise);
  });

  loadProfile = action(() => {
    const promise = (resolve, reject) => {
      ProfileAPI.getDetails(
        this.user_uuid,
        action(result => {
          this.profile = new Profile(result);
          resolve(this.profile);
        }),
        reject
      );
    };
    return new Promise(promise);
  });

  loadAccounts = action(() => {
    const promise = (resolve, reject) => {
      AccountsAPI.getList(
        this.token.business_uuid,
        action(result => {
          this.accounts = result;
          resolve();
        }),
        reject
      );
    };
    return new Promise(promise);
  });

  loadPoses = action(() => {
    const webPromise = (resolve, reject) => {
      PosAPI.getListWeb(
        action(result => {
          this.webPoses = result.poses;
          resolve();
        }),
        reject
      );
    };
    const mobilePromise = (resolve, reject) => {
      MobilePosAPI.getListMobile(
        action(result => {
          this.mobilePoses = result.poses;

          // if not active register socket to get the activaction callback
          // this.mobilePoses.map(mobilePos => {
          //   // this.createMobilePosSocket(mobilePos.uuid);
          //   return mobilePos;
          // });
          resolve();
        }),
        reject
      );
    };
    return Promise.all([new Promise(webPromise), new Promise(mobilePromise)]);
  });

  loadBusiness = action(business_uuid => {
    const promise = (resolve, reject) => {
      BusinessAPI.getBusiness(
        business_uuid,
        result => {
          resolve(result);
        },
        error => {
          Sentry.withScope(scope => {
            scope.setExtras(
              Object.assign({}, error, {
                location: 'onLoadBusiness',
              })
            );
            Sentry.captureMessage(`Error on onLoadBusiness`, Severity.Error);
          });
          this.snackBar.displayError(error);
          reject(error);
        }
      );
    };
    return new Promise(promise);
  });

  getSandboxMode = action(() => {
    if (this.sandboxMode) {
      this.factory = this.sandboxFactory;
      this.formFactory = this.sandboxFormFactory;
    } else {
      this.factory = this.productionFactory;
      this.formFactory = this.productionFormFactory;
    }
  });

  setSandboxMode = action(isSandboxMode => {
    this.sandboxMode = isSandboxMode;
    if (this.sandboxMode) {
      this.factory = this.sandboxFactory;
      this.formFactory = this.sandboxFormFactory;
    } else {
      this.factory = this.productionFactory;
      this.formFactory = this.productionFormFactory;
    }
  });

  setLanguage = action(language => {
    this.language = language;
    counterpart.setLocale(language);
    moment.locale(language);
    LocalStorage.set(LANGUAGE_STORAGE_KEY, language);
  });

  deleteAccountCallback = action(callback => {
    this.logout();
    callback();
  });

  onActivateMobilePos = action(({ pos_uuid }) => {
    const newList = this.mobilePoses.map(pos => {
      if (pos.uuid === pos_uuid) {
        return {
          ...pos,
          active: true,
        };
      }
      return pos;
    });
    this.mobilePoses = newList;
  });

  setCookiePolicy = action(() => {
    const today = new Date();
    const weekmillis = 30 * 24 * 60 * 60 * 1000;
    const expiringDate = new Date(today.getTime() + weekmillis);
    document.cookie =
      'cookie_notice_accepted=true; path=' +
      COOKIE_POLICY_DOMAIN +
      ';expires=' +
      expiringDate.toUTCString();
  });
}

export { App };
