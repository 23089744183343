import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { ROUTE_PARAMETERS, ROUTES } from '../../constants/routes';
import classnames from 'classnames';
import { buildPath } from '../../utils/routes';

const styles = theme => ({
  GoToDetailOuterContainer: {
    border: '1px solid',
    borderColor: theme.palette.primary.bluegrey,
    borderRadius: '6px',
    maxWidth: '290px',
    height: '36px',
    whiteSpace: 'nowrap',
  },
  clickable: {
    cursor: 'pointer',
  },
  goToDetailContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 12px 6px 8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  iconSmall: theme.icon.small,
  arrowSmall: theme.icon.arrowSmall,
});

class GoToDetail extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    clickable: PropTypes.bool,
    active: PropTypes.bool,
  };

  static defaultProps = {
    clickable: true,
    active: true,
  };

  onClick = () => {
    if (!this.props.clickable) return;

    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    if (this.props.type === 'web' || this.props.type === 'mobile') {
      this.props.history.push(
        buildPath(ROUTES.POS_VIEW, {
          [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
          [ROUTE_PARAMETERS.POS_ID]: this.props.id,
        })
      );
    } else if (this.props.type === 'bank' || this.props.type === 'bitcoin') {
      this.props.history.push(
        buildPath(ROUTES.ACCOUNT_VIEW, {
          [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
          [ROUTE_PARAMETERS.ACCOUNT_ID]: this.props.id,
        })
      );
    }
  };

  render() {
    const {
      id,
      name,
      classes,
      type,
      active,
      clickable,
      customClasses,
    } = this.props;

    const goToDetailIcon = {
      web: (
        <img
          src={require('../../img/webPos/' +
            (active ? 'active' : 'unactive') +
            '/small.png')}
          alt="webpos"
        />
      ),
      mobile: (
        <img
          src={require('../../img/mobilePos/' +
            (active ? 'active' : 'unactive') +
            '/small.png')}
          alt="mobilePos"
        />
      ),
      bank: <img src={require('../../img/bancario/small.png')} alt="bank" />,
      bitcoin: <img src={require('../../img/bitcoin/small.png')} alt="btc" />,
    };

    return (
      <div
        id={id}
        className={classnames([
          classes.GoToDetailOuterContainer,
          customClasses,
          {
            [classes.clickable]: clickable,
          },
        ])}
        onClick={this.onClick}
      >
        <div className={classes.goToDetailContainer}>
          <div
            style={{ display: 'flex', alignItems: 'center', maxWidth: '90%' }}
          >
            {React.cloneElement(goToDetailIcon[type], {
              className: classes.iconSmall,
            })}
            <Typography
              variant="body2"
              style={{
                marginLeft: '5px',
                maxWidth: '90%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {name}
            </Typography>
          </div>
          {clickable && (
            <img
              src={require('../../img/arrow/small.png')}
              alt="go"
              className={classes.arrowSmall}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(GoToDetail));
