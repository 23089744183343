import ACCOUNT from './account';
import BUSINESS from './business';
import COMMON from './common';
import ERRORS from './errors';
import FOOTER from './footer';
import MESSAGES from './messages';
import PAYMENT from './payment';
import PLACEHOLDER from './placeholder';
import POS from './pos';
import PROFILE from './profile';
import ANAG from './anag';
import GDPR from './gdpr';
import { LINKS } from './links';

export default {
  POS: POS,
  account: ACCOUNT,
  business: BUSINESS,
  common: COMMON,
  errors: ERRORS,
  footer: FOOTER,
  messages: MESSAGES,
  payment: PAYMENT,
  placeholder: PLACEHOLDER,
  profile: PROFILE,
  anag: ANAG,
  gdpr: GDPR,
  links: LINKS,
};
