import React from 'react';

import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  loadingComponent: {
    textAlign: 'center',
    marginTop: '73px',
  },
  circularProgress: {
    width: '40px',
    height: '40px',
    marginBottom: '15px',
  },
  text: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#777777',
  },
});

const LoadingComponent = props => {
  const { classes } = props;
  const { text } = props;
  return (
    <div className={classes.loadingComponent}>
      <CircularProgress className={classes.circularProgress} />
      <Typography className={classes.text}>{text}</Typography>
    </div>
  );
};

LoadingComponent.propTypes = {
  text: PropTypes.string.isRequired,
};

export default withStyles(styles)(LoadingComponent);
