export const status_list = {
  pending: {
    labelTranslation: 'payment.detail.status.pending',
    type: 'primaryLight',
    order: 1,
  },
  partial: {
    labelTranslation: 'payment.detail.status.pending',
    type: 'primaryLight',
    order: 2,
  },
  paid: {
    labelTranslation: 'payment.detail.status.paid',
    type: 'success',
    order: 3,
  },
  network_dispute: {
    labelTranslation: 'payment.detail.status.network_dispute',
    type: 'warning',
    order: 4,
    faq: {
      text: 'payment.detail.faq.network_dispute',
      link: 'payment_order_network_dispute',
    },
  },
  chargeback: {
    labelTranslation: 'payment.detail.status.chargeback',
    type: 'error',
    order: 5,
    faq: {
      text: 'payment.detail.faq.chargeback',
      link: 'payment_order_chargeback',
    },
  },
  expired: {
    labelTranslation: 'payment.detail.status.expired',
    type: 'info',
    order: 6,
  },
  cancelled: {
    labelTranslation: 'payment.detail.status.cancelled',
    type: 'info',
    order: 7,
  },
};

export const example_data = [
  {
    uuid: '7e8e81b2-cccb-4627-bc45-fbac32c9b78a',
    address: '1BoatSLRHtKNngkdXEeobR76b53LETtpyT',
    created_at: '01-02-2018 15:23:44',
    state: {
      status: 'unconfirmed',
      paid: {
        EUR: '60.00',
        BTC: 1034482,
      },
      in_confirmation: {
        EUR: '40.00',
        BTC: 689655,
      },
      unpaid: {
        EUR: '50.00',
        BTC: 862068,
      },
    },
    rate: {
      value: '5800.00',
      source: 'tinklit',
      created_at: '2018-18-04 15:23:44',
    },
    pos: {
      name: 'pos1',
      type: 'webpos',
      uuid: 'fa3949a1-da9f-4489-8b76-52a1c62df981',
    },
    amount: '460.00',
    btc_amount: 2586205,
    expiration_time: '31-12-2018 15:23:44',
    expires_in: 3600,
    required_confirmations: 6,
    details: 'payment-order details',
    transactions: [
      {
        txid:
          'c3d1d8bff0a417485b419f226968416295f298b247a52559c1922be54e1a860e',
        normalized_txid:
          'c3d1d8bff0a417485b419f226968416295f298b247a52559c1922be54e1a860e',
        confirmed: true,
        outs_sum: 0,
        outs: [
          {
            n: 40,
            amount: 2320,
          },
        ],
        created_at: '01-01-2018 15:23:44',
      },
    ],
    currency: 'EUR',
    uri:
      'bitcoin:12A1MyfXbW6RhdRAZEqofac5jCQQjwEPBu?amount=0.02586206&message=payment_details',
    confirm_url: 'http://confirmation_url',
    cancel_url: 'http://cancel_url',
    redirect_url: 'http://redirect_url',
  },
  {
    uuid: '7e8e81b2-cccb-4627-bc45-fbac32c9b78b',
    address: '1BoatSLRHtKNngkdXEeobR76b53LETtpyT',
    created_at: '01-03-2018 15:23:44',
    state: {
      status: 'completed',
      paid: {
        EUR: '60.00',
        BTC: 1034482,
      },
      in_confirmation: {
        EUR: '40.00',
        BTC: 689655,
      },
      unpaid: {
        EUR: '50.00',
        BTC: 862068,
      },
    },
    rate: {
      value: '5800.00',
      source: 'tinklit',
      created_at: '2018-18-04 15:23:44',
    },
    pos: {
      name: 'pos2',
      type: 'mobilepos',
      uuid: 'f123',
    },
    amount: '90.00',
    btc_amount: 2586205,
    expiration_time: '31-12-2018 15:23:44',
    expires_in: 3600,
    required_confirmations: 6,
    reference: '5e8e71b2-cccb-4627-dc39-ebcd32c9b78b',
    transactions: [],
    currency: 'EUR',
    uri:
      'bitcoin:12A1MyfXbW6RhdRAZEqofac5jCQQjwEPBu?amount=0.02586206&message=payment_details',
    confirm_url: 'http://confirmation_url',
    cancel_url: 'http://cancel_url',
    redirect_url: 'http://redirect_url',
  },
  {
    uuid: '7e8e81b2-cccb-4627-bc45-fbac32c9b78c',
    address: '1BoatSLRHtKNngkdXEeobR76b53LETtpyT',
    created_at: '01-03-2018 15:23:44',
    state: {
      status: 'cancelled',
      paid: {
        EUR: '60.00',
        BTC: 1034482,
      },
      in_confirmation: {
        EUR: '40.00',
        BTC: 689655,
      },
      unpaid: {
        EUR: '50.00',
        BTC: 862068,
      },
    },
    rate: {
      value: '5800.00',
      source: 'tinklit',
      created_at: '2018-18-04 15:23:44',
    },
    pos: {
      name: 'pos2',
      type: 'mobilepos',
      uuid: 'f123',
    },
    amount: '90.00',
    btc_amount: 2586205,
    expiration_time: '31-12-2018 15:23:44',
    expires_in: 3600,
    required_confirmations: 6,
    reference: '5e8e71b2-cccb-4627-dc39-ebcd32c9b78b',
    transactions: [
      {
        txid:
          'c3d1d8bff0a417485b419f226968416295f298b247a52559c1922be54e1a860e',
        normalized_txid:
          'c3d1d8bff0a417485b419f226968416295f298b247a52559c1922be54e1a860e',
        confirmed: true,
        outs_sum: 0,
        outs: [
          {
            n: 40,
            amount: 2320,
          },
        ],
        created_at: '01-01-2018 15:23:44',
      },
      {
        txid:
          'c3d1d8bff0a417485b419f226968416295f298b247a52559c1922be54e1a860d',
        normalized_txid:
          'c3d1d8bff0a417485b419f226968416295f298b247a52559c1922be54e1a860d',
        confirmed: false,
        outs_sum: 0,
        outs: [
          {
            n: 40,
            amount: 2320,
          },
        ],
        created_at: '01-01-2018 12:23:44',
      },
    ],
    currency: 'EUR',
    uri:
      'bitcoin:12A1MyfXbW6RhdRAZEqofac5jCQQjwEPBu?amount=0.02586206&message=payment_details',
    confirm_url: 'http://confirmation_url',
    cancel_url: 'http://cancel_url',
    redirect_url: 'http://redirect_url',
  },
  {
    uuid: '7e8e81b2-cccb-4627-bc45-fbac32c9b78d',
    address: '1BoatSLRHtKNngkdXEeobR76b53LETtpyT',
    created_at: '01-03-2017 15:23:44',
    state: {
      status: 'expired',
      paid: {
        EUR: '60.00',
        BTC: 1034482,
      },
      in_confirmation: {
        EUR: '40.00',
        BTC: 689655,
      },
      unpaid: {
        EUR: '50.00',
        BTC: 862068,
      },
    },
    rate: {
      value: '5800.00',
      source: 'tinklit',
      created_at: '2018-18-04 15:23:44',
    },
    pos: {
      name: 'pos2',
      type: 'mobilepos',
      uuid: 'f123',
    },
    amount: '90.00',
    btc_amount: 2586205,
    expiration_time: '31-12-2018 15:23:44',
    expires_in: 3600,
    required_confirmations: 6,
    reference: '5e8e71b2-cccb-4627-dc39-ebcd32c9b78b',
    transactions: [
      {
        txid:
          'c3d1d8bff0a417485b419f226968416295f298b247a52559c1922be54e1a860e',
        normalized_txid:
          'c3d1d8bff0a417485b419f226968416295f298b247a52559c1922be54e1a860e',
        confirmed: true,
        outs_sum: 0,
        outs: [
          {
            n: 40,
            amount: 2320,
          },
        ],
        created_at: '01-01-2018 15:23:44',
      },
      {
        txid:
          'c3d1d8bff0a417485b419f226968416295f298b247a52559c1922be54e1a860d',
        normalized_txid:
          'c3d1d8bff0a417485b419f226968416295f298b247a52559c1922be54e1a860d',
        confirmed: false,
        outs_sum: 0,
        outs: [
          {
            n: 40,
            amount: 2320,
          },
        ],
        created_at: '01-01-2018 12:23:44',
      },
    ],
    currency: 'EUR',
    uri:
      'bitcoin:12A1MyfXbW6RhdRAZEqofac5jCQQjwEPBu?amount=0.02586206&message=payment_details',
    confirm_url: 'http://confirmation_url',
    cancel_url: 'http://cancel_url',
    redirect_url: 'http://redirect_url',
  },
  {
    uuid: '7e8e81b2-cccb-4627-bc45-fbac32c9b78e',
    address: '1BoatSLRHtKNngkdXEeobR76b53LETtpyT',
    created_at: '01-01-2018 15:23:44',
    state: {
      status: 'pending',
      paid: {
        EUR: '60.00',
        BTC: 1034482,
      },
      in_confirmation: {
        EUR: '40.00',
        BTC: 689655,
      },
      unpaid: {
        EUR: '50.00',
        BTC: 862068,
      },
    },
    rate: {
      value: '5800.00',
      source: 'tinklit',
      created_at: '2018-18-04 15:23:44',
    },
    pos: {
      name: 'pos2',
      type: 'mobilepos',
      uuid: 'f123',
    },
    amount: '90.00',
    btc_amount: 2586205,
    expiration_time: '31-12-2018 15:23:44',
    expires_in: 3600,
    required_confirmations: 6,
    reference: '5e8e71b2-cccb-4627-dc39-ebcd32c9b78b',
    transactions: [
      {
        txid:
          'c3d1d8bff0a417485b419f226968416295f298b247a52559c1922be54e1a860e',
        normalized_txid:
          'c3d1d8bff0a417485b419f226968416295f298b247a52559c1922be54e1a860e',
        confirmed: true,
        outs_sum: 0,
        outs: [
          {
            n: 40,
            amount: 2320,
          },
        ],
        created_at: '01-01-2018 15:23:44',
      },
      {
        txid:
          'c3d1d8bff0a417485b419f226968416295f298b247a52559c1922be54e1a860d',
        normalized_txid:
          'c3d1d8bff0a417485b419f226968416295f298b247a52559c1922be54e1a860d',
        confirmed: false,
        outs_sum: 0,
        outs: [
          {
            n: 40,
            amount: 2320,
          },
        ],
        created_at: '01-01-2018 12:23:44',
      },
    ],
    currency: 'EUR',
    uri:
      'bitcoin:12A1MyfXbW6RhdRAZEqofac5jCQQjwEPBu?amount=0.02586206&message=payment_details',
    confirm_url: 'http://confirmation_url',
    cancel_url: 'http://cancel_url',
    redirect_url: 'http://redirect_url',
  },
];
