export default {
  operational_field: {
    food_and_drinks_services: 'Food and Beverages',
    constructions: 'Constructions',
    education: 'Education',
    financial_services: 'Financial Services',
    retail_trade_and_shops: 'Retail Trade',
    information_and_communication: 'Information and Communication',
    technologies: 'Tech',
    other: 'Other',
  },
  position: {
    business_admin: 'Administrator',
  },
};
