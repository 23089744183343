import store from '../store';
import translator from 'counterpart';
import { translateAnag } from '../utils/translate';
import * as Sentry from '@sentry/browser';

export const onErrorNotification = (onError, message) => error => {
  Sentry.captureException(error);
  store.app.snackBar.displayError(message);
  if (onError) {
    onError(error);
  }
};

export const onSuccessNotification = (onSuccess, message) => data => {
  onSuccess(data);
  store.app.snackBar.open(
    message ? message : translator('messages.generic.ok'),
    'success'
  );
};

export const decodeBackendField = (object, field) => {
  if (!object) {
    return null;
  }
  try {
    const object_decoded = object.map(item => {
      return {
        id: item.name,
        name: translateAnag(field, item.name),
      };
    });
    return object_decoded;
  } catch (e) {}
};
