import { StyleRulesCallback } from '@material-ui/core';

const styles: StyleRulesCallback = ({ palette }: { [key: string]: any }) => ({
  container: {
    border: '1px solid',
    borderLeft: '6px solid',
    backgroundColor: palette.pos.associateDevice.bg,
    borderColor: palette.pos.associateDevice.border,
    borderLeftColor: palette.orange.main,
    padding: '20px 20px 30px 30px',
  },
});

export default styles;
