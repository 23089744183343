import React from 'react';

import { Prompt } from 'react-router-dom';

import Button from '../../common/Button';
import { withStyles } from '@material-ui/core/styles';

import Translate from 'react-translate-component';
import translator from 'counterpart';

import BusinessAPI from '../../../api/Business';

import PageContainer from '../../common/PageContainer';
import PageTitle from '../../common/PageTitle';
import Loader from '../../common/Loader';

import store from '../../../store/index';

import { ROUTE_PARAMETERS, ROUTES } from '../../../constants/routes';

import ModificaBusinessForm from '../common/ModificaBusinessForm';
import Typography from '@material-ui/core/Typography';
import { withGenericErrorHandling } from '../../common/ErrorHandling';

import { formatAddress } from '../../../utils/formatters';
import { buildPath } from '../../../utils/routes';
import { withFormsValidation } from '../../common/Forms/FormsCommon';
import InvalidFormMessage from '../../common/InvalidFormMessage';
import { WebsiteLink } from '../../common/WebsiteLink';

const styles = theme => ({
  confirmEdit: {
    marginTop: '20px',
    marginBottom: '50px',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '0px',
    width: '50%',
  },
});

class ModificaBusiness extends React.Component {
  constructor(props) {
    super(props);
    // init state
    this.state = {
      positions: [],
      operationalFields: [],
      errors: {},
      loading_logo: true,
      loading_data: true,
      loading: false,
      submitted: false,
      valid: false,
      failed: false,
    };
  }

  componentDidMount() {
    const business_uuid = this.props.match.params[ROUTE_PARAMETERS.BUSINESS_ID];
    store.app
      .loadBusiness(business_uuid)
      .then(business => {
        const { ids } = this.props.form;
        this.setState({
          initField: {
            nome_azienda_comunicazioni_presente: Boolean(
              business.business.communications_name
            ),
            legalAddress: formatAddress(business.contacts.legal_address),
            operationalAddress: formatAddress(
              business.contacts.operational_address
            ),

            indirizzo_secondario_presente: Boolean(
              business.contacts.operational_address
            ),
          },
        });
        this.props.initObject(
          {
            [ids.uri$business_uuid]: business.business.uuid,

            [ids.form$communications_name]:
              business.business.communications_name,

            form$contacts$legal_address: business.contacts.legal_address,

            form$contacts$operational_address:
              business.contacts.operational_address,
            [ids.form$contacts$phone]: business.contacts.phone,
            [ids.form$contacts$email]: business.contacts.email,
            [ids.form$operational_field$name]:
              business.business.operational_field.name,
            [ids.form$operational_field$description]:
              business.business.operational_field.description,
            [ids.form$logo]: business.business.logo
              ? business.business.logo
              : null,
          },
          () => {
            this.setState({
              uuid: business_uuid,
              otherField: {
                nome: business.business.name,
                vat: business.business.vat,
              },
            });
            BusinessAPI.getOperationalFields(result => {
              this.setState({
                operationalFields: result,
              });
            }, this.props.onError);
            BusinessAPI.getBusinessDetails(
              business_uuid,
              result => {
                this.props.handleChange({
                  target: {
                    id: this.props.form.ids.form$logo,
                    value: result.logo,
                  },
                });
                this.setState({
                  originalImage: result.logo,
                  loading_logo: false,
                });
              },
              this.props.onError
            );
          }
        );
      })
      .catch(this.props.onError)
      .finally(() => {
        this.setState({ loading_data: false });
      });
  }

  save = event => {
    event.preventDefault();
    this.props.send(
      () => {
        this.setState(
          {
            loading: false,
            submitted: true,
          },
          () => {
            store.app.reloadInfo(() => {
              this.props.history.push(
                buildPath(ROUTES.BUSINESS_VIEW, {
                  [ROUTE_PARAMETERS.BUSINESS_ID]: this.state.uuid,
                })
              );
            });
          }
        );
      },
      () => {
        this.setState({
          loading: false,
        });
      }
    );
  };

  render() {
    const {
      classes,
      handleChange,
      errors,
      validateField,
      handleChangeGeneric,
      object,
      signalError,
    } = this.props;

    const business = {
      ...object,
      ...this.state.otherField,
    };

    const { loading, loading_data, loading_logo, submitted } = this.state;

    const { operationalFields } = this.state;

    const disabled = loading || loading_data;

    return (
      <PageContainer main white top>
        <Prompt
          message={translator('common.exitAlertMessage')}
          when={!submitted}
        />
        {(loading_data || loading_logo) && <Loader />}
        {!loading_data && !loading_logo && (
          <React.Fragment>
            <PageTitle
              title={<Translate content="business.titles.editBusiness" />}
              parent={{
                text: (
                  <Translate content="business.titles.goBackEditBusiness" />
                ),
                url: buildPath(ROUTES.BUSINESS_VIEW, {
                  [ROUTE_PARAMETERS.BUSINESS_ID]: this.state.uuid,
                }),
              }}
            />
            <Typography
              variant="caption"
              style={{ marginBottom: '40px', marginTop: '-25px' }}
            >
              <Translate content="business.editSubtitle" />{' '}
              <WebsiteLink withoutIcon to="contact_us">
                <Translate content="common.contactUsLowercase" />
              </WebsiteLink>
              .
            </Typography>
            <form onSubmit={this.save}>
              <ModificaBusinessForm
                business={business}
                operationalFields={operationalFields}
                errors={errors}
                loading={loading || this.state.loading_logo}
                disabled={disabled}
                handleChange={handleChange}
                onError={this.handleError}
                editMode
                originalImage={this.state.originalImage}
                validateField={validateField}
                form={this.props.form}
                handleChangeGeneric={handleChangeGeneric}
                initField={this.state.initField}
                signalError={signalError}
              />
              <Typography variant="body2" className={classes.confirmEdit}>
                <Translate content="common.confirmEdit" />
              </Typography>
              <InvalidFormMessage errors={errors} />
              <div className={classes.actions}>
                <Button
                  id="save"
                  variant="raised"
                  color="primary"
                  type="submit"
                  style={{ flex: 2, marginRight: '20px' }}
                  loading={loading}
                  onClick={this.save}
                >
                  <Translate content="common.save" />
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  loading={loading}
                  style={{ flex: 1 }}
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  <Translate content="common.back" />
                </Button>
              </div>
            </form>
          </React.Fragment>
        )}
      </PageContainer>
    );
  }
}

export default withFormsValidation('BusinessUpdateForm')(
  withGenericErrorHandling(withStyles(styles)(ModificaBusiness))
);
