import { withStyles } from '@material-ui/core';
import { styles } from './styles';
import * as React from 'react';
import { Message } from './Messages';
import translator from 'counterpart';
import { Col, Row } from 'react-flexbox-grid';
import Translate from 'react-translate-component';
import Typography from '@material-ui/core/Typography';
import { buildWebsiteLink } from '../../../utils/links';
import FaqLink from '../../common/FaqLink';

export const EmptyList = withStyles(styles)(props => {
  const { classes } = props;
  return (
    <React.Fragment>
      <Message
        title={translator('payment.list.messages.no_payments.title')}
        subtitle={translator('payment.list.messages.no_payments.subtitle')}
      />
      <Row className={classes.emptyListRow} style={{ marginTop: '25px' }}>
        <Col xs={2} className={classes.emptyListCol}>
          <div
            className={classes.shadowGrey}
            style={{ marginLeft: '10px', width: '85px' }}
          />
        </Col>
        <Col
          xs={2}
          className={classes.emptyListCol}
          style={{ justifyContent: 'flex-end' }}
        >
          <div className={classes.shadowGrey} style={{ width: '45px' }} />
        </Col>
        <Col xs={2} className={classes.emptyListCol}>
          <div className={classes.shadowGrey} style={{ width: '85px' }} />
        </Col>
        <Col xs={3} className={classes.emptyListCol}>
          <div className={classes.shadowGrey} style={{ width: '125px' }} />
        </Col>
        <Col xs={3} className={classes.emptyListCol}>
          <div className={classes.shadowGrey} style={{ width: '100px' }} />
        </Col>
      </Row>
      <Row className={classes.emptyListRow} style={{ marginTop: '10px' }}>
        <Col xs={2} className={classes.emptyListCol}>
          <div
            className={classes.shadowGrey}
            style={{ marginLeft: '10px', width: '85px' }}
          />
        </Col>
        <Col
          xs={2}
          className={classes.emptyListCol}
          style={{ justifyContent: 'flex-end' }}
        >
          <div className={classes.shadowGrey} style={{ width: '45px' }} />
        </Col>
        <Col xs={2} className={classes.emptyListCol}>
          <div className={classes.shadowGrey} style={{ width: '85px' }} />
        </Col>
        <Col xs={3} className={classes.emptyListCol}>
          <div className={classes.shadowGrey} style={{ width: '125px' }} />
        </Col>
        <Col xs={3} className={classes.emptyListCol}>
          <div className={classes.shadowGrey} style={{ width: '100px' }} />
        </Col>
      </Row>
      <div className={classes.faqSection}>
        <Typography variant="body2" style={{ marginBottom: '10px' }}>
          <Translate content="payment.list.faq.emptyListTitle" />
        </Typography>
        {[
          {
            text: <Translate content="payment.list.faq.2" />,
            url: buildWebsiteLink('payment_order_how_to_receive_first_payment'),
          },
        ].map((item, k) => {
          return <FaqLink width={70} text={item.text} url={item.url} key={k} />;
        })}
      </div>
    </React.Fragment>
  );
});
