import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Col } from 'react-flexbox-grid';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const styles = theme => ({
  mainContainer: {
    flex: '1 0',
    display: 'inline-flex',
    flexDirection: 'column',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      display: 'inline-block',
    },
  },
  container: {
    borderRadius: '5px',
    border: '1px solid',
    borderColor: theme.palette.primary.bluegrey,
    ...theme.shadow,
  },
  noMarginLeft: {
    margin: '10px 20px 10px 0px',
  },
  marginFull: {
    margin: '10px',
  },
  buttonSelectable: {
    cursor: 'pointer',
  },
  buttonDisable: {
    opacity: '0.5',
    backgroundColor: theme.palette.primary.bg,
    filter: 'grayscale(100%)',
  },
  buttonSelected: {
    border: '1px solid',
    borderColor: theme.palette.green.main,
    backgroundColor: theme.palette.genericSelectable.selected,
  },
  buttonUnselected: {
    backgroundColor: theme.palette.common.white,
    cursor: 'pointer',
  },
  invalidButton: {
    border: '1px solid',
    cursor: 'pointer',
    borderColor: theme.palette.red.main,
    backgroundColor: theme.palette.red.bg,
  },
  arrowUpSelected: {
    display: 'block',
    borderTop: '8px solid #f4f7f8',
    borderLeft: '50px solid transparent',
    height: 0,
    width: 0,
    margin: '-8px auto 0',
    '&:before': {
      content: "''",
      position: 'absolute',
      height: '20px',
      width: '20px',
      WebkitTransformOrigin: '0% 0%',
      MsTransformOrigin: '0% 0%',
      transformOrigin: '55% -13%',
      WebkitTransform: 'rotate(135deg)',
      MsTransform: 'rotate(135deg)',
      transform: 'rotate(135deg)',
      backgroundColor: theme.palette.genericSelectable.selected,
      borderTop: '1px solid',
      borderTopColor: theme.palette.green.main,
      borderRight: '1px solid',
      borderRightColor: theme.palette.green.main,
    },
  },
});

const GenericSelectable = props => {
  const {
    classes,
    value,
    isInvalid,
    disabled,
    children,
    isActive,
    id,
    width,
    noMarginLeft,
    handleChange,
    withArrow,
    className,
    ...otherProps
  } = props;
  const onChange = () => {
    if (!disabled) {
      handleChange(value);
    }
  };

  return (
    <div
      className={classnames({
        [classes.mainContainer]: true,
        [classes.marginFull]: !noMarginLeft,
        [classes.noMarginLeft]: noMarginLeft,
        [className]: className && true,
      })}
      style={{
        width: width + '%',
      }}
    >
      <Col
        id={id}
        name={id}
        onClick={onChange}
        aria-selected={isActive}
        {...otherProps}
        className={classnames({
          [classes.container]: true,
          [classes.buttonSelectable]: !disabled,
          [classes.buttonDisable]: disabled,
          [classes.buttonUnselected]: !isActive && !disabled,
          [classes.buttonSelected]: isActive && !disabled,
          [classes.invalidButton]: isInvalid && !disabled,
        })}
        xs
      >
        {children}
      </Col>
      {isActive && withArrow && <div className={classes.arrowUpSelected} />}
    </div>
  );
};

GenericSelectable.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  handleChange: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  isInvalid: PropTypes.bool,
  actual: PropTypes.bool,
  width: PropTypes.number,
  noMarginLeft: PropTypes.bool,
  className: PropTypes.string,
};

GenericSelectable.defaultProps = {
  width: 50,
};

export default withStyles(styles)(GenericSelectable);
