export default {
  FAQ_SECTION: 'Serve aiuto?',
  more_info: 'Maggiori informazioni',
  integrationProblems: "Problemi con l'integrazione?",
  createNow: 'crea ora',
  ok: 'Ok',
  moreInfo: 'Maggiori informazioni',
  signUp: 'Registrati',
  goOn: 'Prosegui',
  goBack: 'Torna indietro',
  confirm: 'Conferma',
  abort: 'Annulla',
  done: 'Fatto',
  save: 'Salva',
  back: 'Indietro',
  edit: 'Modifica',
  close: 'Chiudi',
  deactivate: 'Disattiva',
  contactUs: 'Contattaci',
  contactUsLowercase: 'contattaci',
  choiche: 'Scegli',
  show: 'Mostra',
  hide: 'Nascondi',
  addNow: 'Aggiungilo subito',
  or: 'oppure',
  login: 'Accedi',
  continue: 'Continua',
  change: 'Cambia',
  remove: 'Rimuovi',
  copied: 'Copiato',
  utilsDoc: 'Documentazione utile',
  createAccount: 'Crea un conto',
  send: 'Invia',
  sendAgain: 'Invia di nuovo',
  sending: 'Invio in corso...',
  inCorso: 'in corso...',
  mailSended: 'Email inviata',
  detail: 'Dettagli',
  addBusiness: 'Aggiungi Azienda',
  addressHint: 'Seleziona un indirizzo, comprensivo di numero civico:',
  documentation: 'documentazione',
  searchingAddress: 'Ricerca degli indirizzi in corso… ',
  confirmEdit:
    'Salva le modifiche effettuate oppure annulla per tornare indietro alla pagina dei dettagli.',
  verifiedEmail: 'Indirizzo email verificato con successo!',
  alreadyVerified: 'Il tuo account è gia stato verificato',
  errorEmail: "Si è verificato un problema durante la verifica dell'email",
  exitForm:
    'I dati non sono stati salvati, sei sicuro di voler uscire dalla pagina?',
  ops: 'Ops...',
  lastPage: 'Pagina precedente',
  homePage: 'Homepage',
  refreshPage: 'Ricaricare la pagina',
  stop: 'Stop',
  loginSandbox: 'Accedi alla sandbox',
  logoutSandbox: 'Esci dalla sandbox',
  otherBusiness: 'Altre aziende',
  nothingF: 'Nessuna',
  sandboxModeAccount:
    'Puoi usare questa Sandbox per i tuoi test, le modifiche non saranno visibili  dai tuoi clienti.',
  exitAlertMessage:
    'I dati presenti nel form non saranno salvati, sicuro di voler uscire?',
  comingSoon: 'Presto disponibile',
  amlCheck: 'Verifica necessaria',
  normativaDati: 'Normativa sui dati',
  yourBusiness: 'Le tue aziende',
  goToPayments: 'Ordini di pagamento',
  cancel: 'Cancella',
  deleteOk: 'Profilo eliminato correttamente',
  profileNotVerified:
    'Il tuo account non è ancora verificato. Verificalo subito inviando una mail all’indirizzo fornito.',
  ifProblemTry: 'Se il problema persiste prova a ',
  scroll: 'Scorri',
  readingCompleted: 'Lettura completata',
  loginSection: {
    title: 'Login',
    passwordForget: 'Ho dimenticato la password',
    noAccount: 'Non hai ancora un account? ',
    signUp: 'Crea un account',
  },

  signUpSection: {
    title: 'Crea un account gratuito',
    subtitle:
      'il primo passo per accettare bitcoin nel tuo sito web o nei tuoi punti vendita. Hai già un account?',
  },
  recoveryPasswordSection: {
    forgottenPassword: {
      title: 'Recupero della Password',
      subtitle:
        'Hai smarrito la password? Nessun problema, invieremo le informazioni per recuperarla all’indirizzo con cui ti sei registrato',
      back: 'Torna al login',
    },
    recovery: {
      title: 'Scegli una nuova Password',
    },
  },
  trattamentoDeiDati: {
    title: 'Trattamento dei tuoi dati',
    hint: 'Qualche info riguardo i dati.',
    firstConsenso: 'Registrandoti su ChainsidePay, accetti la nostra ',
    secondConsenso: 'Accetto che i miei dati vengano condivisi con terze parti',
    thirdConsenso: 'Voglio rimanere aggiornato su novità, offerte, ed eventi',
    privacyPolicy: 'Privacy Policy',
  },
  welcomeModal: {
    title: 'Benvenuto in ChainsidePay!',
    subtitle:
      'Da qui potrai vedere e gestire tutti i tuoi ordini di' +
      ' pagamento, POS e conti.',
    help:
      'Ecco qualche guida per aiutarti a capire come configurare il tuo account:',
    faq: {
      1: 'Come funziona ChainsidePay?',
      2: 'Come fare per accettare bitcoin nel mio sito web?',
      3: 'Come fare per accettare bitcoin nel mio punto vendita?',
    },
  },
  expiredSession: {
    title: 'La sessione è scaduta',
    subtitle: 'Aggiorna la pagina per continuare a lavorare in ChainsidePay',
  },
  sandboxModeModal: {
    title: 'Benvenuto nella modalità sandbox',
    subtitle:
      'Questo account di test è una Sandbox che ti permette di creare e gestire componenti senza impattare sul tuo vero account',
    help: 'Per maggiori informazioni su cosa puoi fare visita:',
    faq: {
      1: "Cosa posso fare con l'account di Test?",
      2: 'I test che faccio impattano i miei utenti?',
    },
  },
  exitSandboxModal: {
    title: 'Stai per uscire dalla Sandbox di %(companyName)s',
    text:
      'D’ora in poi le modifiche che apporterai avranno ripercussioni sul' +
      ' tuo ambiente di produzione e i tuoi utenti reali ',
  },
  emailNotVerified: {
    modal: {
      title: 'Ti abbiamo inviato una mail di verifica',
      titleBlocking: 'Ops... non hai ancora verificato il tuo indirizzo email',
      subtitle1:
        'Clicca sul link presente nella mail di verifica che ti abbiamo' +
        ' inviato.',
      subtitle2: 'Non hai ricevuto la mail di verifica?',
    },
  },
  topbar: {
    myProfile: 'Il mio profilo',
    logout: 'Logout',
    businessDetail: 'Dettagli azienda',
    staging: 'Account di staging',
    production: 'Account di produzione',
    sandboxInfo: 'Usa la Sandbox per testare POS e ordini di pagamento. ',
  },
  quote: {
    login: {
      quote:
        'Bitcoin is a remarkable cryptographic achievement... Lots of people will build businesses on top of that.”',
      author: 'Eric Schmidt, CEO of Google',
    },
    signup: {
      quote:
        'I do think Bitcoin is the first encrypted money that has the potential to do something like change the world.',
      author: 'Peter Thiel, Co-Founder of Paypal',
    },
  },
  copyright:
    '©2018 All Rights Reserved. ChainsidePay® è un marchio registrato.',
  menuBar: {
    account: 'Conti',
    pos: 'POS',
    payment: 'Pagamenti',
  },
  cookieAlert: {
    title: 'Questo sito utilizza cookies',
    subtitle:
      'Utilizziamo i cookies per migliorare l’esperienza dei nostri utenti. Continuando la navigazione accetti il loro uso in conformità con la nostra Cookie Policy.',
    action: 'Ok',
  },
};
