import React from 'react';
import classnames from 'classnames';
import Translate from 'react-translate-component';

import { observer } from 'mobx-react';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import BusinessApi from '../../../api/Business';
import Loader from '../../common/Loader';
import { translateAnag } from '../../../utils/translate';

const styles = theme => ({
  peopleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  cardContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '6px',
    boxShadow: '0 1px 2px 0 #d7dfe5',
    border: 'solid 1px',
    borderColor: theme.palette.primary.bluegrey,
    width: '100%',
    marginBottom: '10px',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '20px',
  },
  marginBottomText: {
    marginBottom: '12px',
  },
});

class ListaPersone extends React.Component {
  static propTypes = {
    business_uuid: PropTypes.string.isRequired,
  };
  state = {
    persons: null,
    loading: true,
  };

  componentDidMount() {
    if (!this.props.business_uuid) {
      this.setState({ loading: false });
      return;
    } else {
      BusinessApi.getPersons(
        this.props.business_uuid,
        result => {
          this.setState({
            persons: result.persons,
            loading: false,
          });
        },
        error => {
          this.setState({
            loading: true,
          });
        }
      );
    }
  }
  render() {
    const { classes } = this.props;
    const { persons, loading } = this.state;
    if (loading) {
      return <Loader />;
    }
    return (
      <div className={classes.peopleContainer}>
        {persons && (
          <React.Fragment>
            <Typography
              variant="body2"
              className={classnames([classes.marginBottomText, 'f-16'])}
            >
              <Translate content="business.people" />
            </Typography>
            {persons.map((person, k) => (
              <div className={classes.cardContainer} key={k}>
                <div className={classes.innerContainer}>
                  <Typography
                    variant="body2"
                    className="f-16"
                    style={{ flex: 1 }}
                  >
                    {person.name + ' ' + person.surname}
                  </Typography>
                  <Typography variant="caption" style={{ flex: 2 }}>
                    {translateAnag('position', person.position)}
                  </Typography>
                </div>
              </div>
            ))}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(observer(ListaPersone));
