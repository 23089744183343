const styles = ({ typography }: { [key: string]: any }) => ({
  columnTitle: {
    marginBottom: '10px',
    fontSize: '16px',
    color: '#444444',
    fontWeight: typography.weights.normal,
  },
});

export default styles;
