import React, { FC, Fragment } from 'react';
import { IntegrationSectionProps } from './propTypes';
import Typography from '@material-ui/core/Typography/Typography';
import Translate from 'react-translate-component';
import { Col, Row } from 'react-flexbox-grid';
import CustomField from '../../../../common/CustomField';
import Info from '../../../../common/Info';
import classnames from 'classnames';
import styles from './theme';
import { withStyles } from '@material-ui/core';

const IntegrationSection: FC<IntegrationSectionProps> = ({
  epos,
  classes = {},
}) => (
  <Fragment>
    <Typography
      variant="subheading"
      className={classnames([
        'w600',
        classes.marginBottom,
        classes.detailTitle,
      ])}
    >
      <Translate content="POS.detail.urls.title" />
    </Typography>
    <Row>
      <Col xs={8} sm={8} md={8} lg={8}>
        <React.Fragment>
          <Row>
            <Col xs={4} sm={4} md={4} lg={4}>
              <Typography variant="caption">
                <Translate content="POS.detail.urls.continue" />
              </Typography>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8}>
              <Typography variant="body2" className={classes.marginBottom}>
                {epos.default_continue_url}
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col xs={4} sm={4} md={4} lg={4}>
              <Typography variant="caption">
                <Translate content="POS.detail.urls.failure" />
              </Typography>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8}>
              <Typography variant="body2" className={classes.marginBottom}>
                {epos.default_cancel_url}
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col xs={4} sm={4} md={4} lg={4}>
              <Typography variant="caption">
                <Translate content="POS.detail.urls.callback" />
              </Typography>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8}>
              <Typography variant="body2" className={classes.marginBottom}>
                {epos.callback_url}
              </Typography>
            </Col>
          </Row>
        </React.Fragment>
        <Row>
          <Col xs={4} sm={4} md={4} lg={4}>
            <Typography variant="caption">
              <Translate content="POS.detail.clientID" />
            </Typography>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8}>
            <Typography variant="body2" className={classes.marginBottom}>
              <CustomField copiable>{epos.pos_id}</CustomField>
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col xs={4} sm={4} md={4} lg={4}>
            <Typography variant="caption">
              <Translate content="POS.detail.secret" />
            </Typography>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8}>
            <Typography variant="body2" className={classes.marginBottom}>
              <CustomField hidden copiable>
                {epos.secret}
              </CustomField>
            </Typography>
          </Col>
        </Row>
      </Col>
      <Col xs={4} sm={4} md={4} lg={4}>
        <Info
          title={<Translate content="common.integrationProblems" />}
          faq={[
            {
              text: <Translate content="POS.list.faq.goToDocumentation" />,
              url: 'documentation',
            },
          ]}
        />
      </Col>
    </Row>
  </Fragment>
);

export default withStyles(styles)(IntegrationSection);
