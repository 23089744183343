import React from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

const style = theme => ({
  copyButton: {
    position: 'absolute',
    top: 0,
    right: '10px',
    zIndex: 999,
    height: '16px',
    fontSize: '13px',
    fontWeight: theme.typography.weights.normal,
    fontStyle: 'italic',
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

const CopyButton = withStyles(style)(props => {
  const { classes, onClick, text } = props;
  return (
    <div className={classes.copyButton} onClick={onClick}>
      {text}
    </div>
  );
});

CopyButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default CopyButton;
