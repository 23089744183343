import Moment from 'moment';

const formatAddress = (address: { [key: string]: string }) => {
  if (!address || !address.street || !address.city) {
    return '';
  }
  const street = address.street;
  const street_number = address.street_number || '';
  const zip = address.zip;
  const city = address.city;
  const country = address.country;
  return `${street} ${street_number}, ${zip} ${city}, ${country}`;
};

const formatDate = (date: Date) => {
  const momentDate = Moment.utc(date).local();
  return momentDate.format('D MMM YYYY, H:mm');
};

export { formatAddress, formatDate };
