/**
 * BP components routes.
 */

/* URL parameters */

const ROUTE_PARAMETERS = {
  ACCOUNT_ID: 'accountId',
  ACCOUNT_TYPE: 'accountType',
  BUSINESS_ID: 'businessId',
  PAYMENT_ORDER_FILTER_ID: 'paymentOrderFilterId',
  PAYMENT_ORDER_FILTER_TYPE: 'paymentOrderFilterType',
  PAYMENT_ORDER_ID: 'paymentOrderId',
  POS_ID: 'posId',
  TOKEN: 'token',
};

/* URL prefixes */

const PROFILE_PREFIX = '/profile';
const DELEGATED_PREFIX = `/business/:${ROUTE_PARAMETERS.BUSINESS_ID}`;
const BUSINESS_PREFIX = `${DELEGATED_PREFIX}`;
const ACCOUNT_PREFIX = `${DELEGATED_PREFIX}/accounts`;
const PAYMENT_ORDER_PREFIX = `${DELEGATED_PREFIX}/payments`;
const POS_PREFIX = `${DELEGATED_PREFIX}/pos`;
const RESET_PASSWORD_PREFIX = `/password/reset`;

/* Routes definition */

const PROFILE_ROUTES = {
  PROFILE_PREFIX,
  PROFILE_VIEW: `${PROFILE_PREFIX}/view`,
  PROFILE_EDIT_INFO: `${PROFILE_PREFIX}/edit/info`,
  PROFILE_EDIT_EMAIL: `${PROFILE_PREFIX}/edit/email`,
  PROFILE_EDIT_PASSWORD: `${PROFILE_PREFIX}/edit/password`,
  GESTIONE_CONSENSI: `${PROFILE_PREFIX}/consents`,
  DELETE_ACCOUNT: `${PROFILE_PREFIX}/delete`,
};

const BUSINESS_ROUTES = {
  BUSINESS_PREFIX,
  BUSINESS_VIEW: `${BUSINESS_PREFIX}/view`,
  BUSINESS_EDIT: `${BUSINESS_PREFIX}/edit`,
};

const ACCOUNT_ROUTES = {
  ACCOUNT_PREFIX,
  ACCOUNT_LIST: `${ACCOUNT_PREFIX}/list`,
  ACCOUNT_NEW: `${ACCOUNT_PREFIX}/create`,
  ACCOUNT_NEW_WITH_TYPE: `${ACCOUNT_PREFIX}/create/:${
    ROUTE_PARAMETERS.ACCOUNT_TYPE
  }`,
  ACCOUNT_VIEW: `${ACCOUNT_PREFIX}/view/:${ROUTE_PARAMETERS.ACCOUNT_ID}`,
  ACCOUNT_EDIT: `${ACCOUNT_PREFIX}/edit/:${ROUTE_PARAMETERS.ACCOUNT_ID}`,
};

const PAYMENT_ORDER_ROUTES = {
  PAYMENT_ORDER_PREFIX,
  PAYMENT_ORDER_LIST: `${PAYMENT_ORDER_PREFIX}/list`,
  PAYMENT_ORDER_LIST_WITH_FILTER: `${PAYMENT_ORDER_PREFIX}/list/filter/:${
    ROUTE_PARAMETERS.PAYMENT_ORDER_FILTER_TYPE
  }/:${ROUTE_PARAMETERS.PAYMENT_ORDER_FILTER_ID}`,
  PAYMENT_ORDER_VIEW: `${PAYMENT_ORDER_PREFIX}/view/:${
    ROUTE_PARAMETERS.PAYMENT_ORDER_ID
  }`,
};

const POS_ROUTES = {
  POS_PREFIX,
  POS_NEW: `${POS_PREFIX}/create`,
  POS_NEW_WEB: `${POS_PREFIX}/create/web`,
  POS_NEW_MOBILE: `${POS_PREFIX}/create/mobile`,
  POS_LIST: `${POS_PREFIX}/list`,
  POS_VIEW: `${POS_PREFIX}/view/:${ROUTE_PARAMETERS.POS_ID}`,
  POS_EDIT_WEB: `${POS_PREFIX}/edit/web/:${ROUTE_PARAMETERS.POS_ID}`,
  POS_EDIT_MOBILE: `${POS_PREFIX}/edit/mobile/:${ROUTE_PARAMETERS.POS_ID}`,
};

const RESET_PASSWORD_ROUTES = {
  RESET_PASSWORD_PREFIX: RESET_PASSWORD_PREFIX,
  RESET_PASSWORD_REQUEST: `${RESET_PASSWORD_PREFIX}`,
  RESET_PASSWORD_CONFIRM: `${RESET_PASSWORD_PREFIX}/:${ROUTE_PARAMETERS.TOKEN}`,
};

const ERROR_ROUTES = {
  GENERIC_ERROR: '/error',
  NOT_FOUND: '/not-found',
};

const ROUTES = {
  LOGIN: `/login`,
  REGISTER: `/signup`,
  REGISTER_BUSINESS: `/signup/business`,
  VERIFY_EMAIL: `/email/verify/:${ROUTE_PARAMETERS.TOKEN}`,
  CONFIRM_EMAIL: `/email/update/:${ROUTE_PARAMETERS.TOKEN}`,
  ...RESET_PASSWORD_ROUTES,
  ...PROFILE_ROUTES,
  ...BUSINESS_ROUTES,
  ...ACCOUNT_ROUTES,
  ...PAYMENT_ORDER_ROUTES,
  ...POS_ROUTES,
  ...ERROR_ROUTES,
};

/* Filters definition */

const PAYMENT_ORDER_FILTERS = {
  btc: `wallet`,
  bank: `account`,
  webpos: `webpos`,
  mobilepos: `mobilepos`,
};

const ROUTE_FILTERS = {
  PAYMENT_ORDER: PAYMENT_ORDER_FILTERS,
};

/* Export */

const ACCOUNT_TYPES = {
  bitcoin: 'btc',
  bank: 'bank',
};

const ROUTE_PARAMETERS_VALUE = {
  ACCOUNT: ACCOUNT_TYPES,
};

export { ROUTES, ROUTE_PARAMETERS, ROUTE_FILTERS, ROUTE_PARAMETERS_VALUE };
