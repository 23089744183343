import React, { FC, Fragment } from 'react';
import styles from './theme';
import { DeviceAssociatedProps } from './propTypes';
import Typography from '@material-ui/core/Typography/Typography';
import classnames from 'classnames';
import Translate from 'react-translate-component';
import { Col, Row } from 'react-flexbox-grid';
import CustomField from '../../../../common/CustomField';
import Info from '../../../../common/Info';
import { withStyles } from '@material-ui/core';
import { formatDate } from '../../../../../utils/formatters';

const DeviceAssociated: FC<DeviceAssociatedProps> = ({
  mobilePos,
  classes = {},
}) => (
  <Fragment>
    <Typography
      variant="subheading"
      className={classnames([
        'w600',
        classes.marginBottom,
        classes.detailTitle,
      ])}
    >
      <Translate content="POS.detail.urls.title" />
    </Typography>
    <Row>
      <Col xs={8} sm={8} md={8} lg={8}>
        <React.Fragment>
          <Row>
            <Col xs={4} sm={4} md={4} lg={4}>
              <Typography variant="caption">
                <Translate content="POS.detail.device" />
              </Typography>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8}>
              <Typography variant="body2" className={classes.marginBottom}>
                {mobilePos.device}
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col xs={4} sm={4} md={4} lg={4}>
              <Typography variant="caption">
                <Translate content="POS.detail.activationDate" />
              </Typography>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8}>
              <Typography variant="body2" className={classes.marginBottom}>
                {formatDate(mobilePos.activation_date)}
              </Typography>
            </Col>
          </Row>
        </React.Fragment>
        <Row>
          <Col xs={4} sm={4} md={4} lg={4}>
            <Typography variant="caption">
              <Translate content="POS.detail.clientID" />
            </Typography>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8}>
            <Typography variant="body2" className={classes.marginBottom}>
              <CustomField copiable>{mobilePos.pos_id}</CustomField>
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col xs={4} sm={4} md={4} lg={4}>
            <Typography variant="caption">
              <Translate content="POS.detail.secret" />
            </Typography>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8}>
            <Typography variant="body2" className={classes.marginBottom}>
              <CustomField hidden copiable>
                {mobilePos.secret}
              </CustomField>
            </Typography>
          </Col>
        </Row>
      </Col>
      <Col xs={4} sm={4} md={4} lg={4}>
        <Info
          title={<Translate content="common.integrationProblems" />}
          faq={[
            {
              text: <Translate content="POS.list.faq.goToDocumentation" />,
              url: 'documentation',
            },
          ]}
        />
      </Col>
    </Row>
  </Fragment>
);

export default withStyles(styles)(DeviceAssociated);
