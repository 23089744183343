import React from 'react';

const terms = ()=> <React.Fragment>
{/* Terms v1 */}

<ol>

  <li id="finalita">
    <h3>Finalità</h3>

    <ol>
      <li>
        <p>Le presenti Condizioni Generali di Contratto regolano l’erogazione all’Utente dei Servizi di Chainside, resi attraverso un software che facilita l’effettuazione di pagamenti in bitcoin in favore di piattaforme di commercio elettronico.</p>
      </li>
      <li>
        <p>Chainside è di proprietà di Chainside S.r.l., con sede legale in Roma, Via Sebino n. 11, iscritta al Registro delle Imprese di Roma, REA n. RM-1500988, P.IVA n. 14154691001.</p>
      </li>
    </ol>
  </li>

  <li id="definizioni">
    <h3>Definizioni</h3>

    <ol>
      <li>
        <p>I seguenti termini hanno il significato qui di seguito indicato, restando inteso che i termini definiti al plurale si intendono definiti anche al singolare e viceversa.</p>

        <dl>
          <dt>Account</dt>
          <dd>indica l’insieme di Servizi attribuiti all’Utente sulla Piattaforma, e attivati dopo la Registrazione;</dd>

          <dt>Anomalia</dt>
          <dd>indica un comportamento non previsto da Chainside, e risultante in una limitazione di funzionamento lato Utente, di una o più funzionalità dei Servizi;</dd>

          <dt>Area Riservata</dt>
          <dd>indica l’area del Sito accessibile esclusivamente dall'Utente utilizzando le Credenziali;</dd>

          <dt>Avviso</dt>
          <dd>indica un’informazione diffusa tramite il Sito mediante una procedura, predisposta da Chainside, che ne impone la visualizzazione all’Utente;</dd>

          <dt>Chainside</dt>
          <dd>indica Chainside S.r.l., con sede legale in Roma, Via Sebino n. 11, iscritta al Registro delle Imprese di Roma, REA n. RM-1500988, P.IVA n. 14154691001;</dd>

          <dt>CGC</dt>
          <dd>indica le presenti Condizioni Generali del Contratto Chainside;</dd>

          <dt>Contratto</dt>
          <dd>indica cumulativamente (i) i Termini del Servizio, (ii) le Condizioni Generali del Contratto, (iii) la Privacy Policy e la Cookie Policy, nonché la eventuale ulteriore documentazione anche implicitamente richiamata;</dd>

          <dt>Corrispettivo</dt>
          <dd>indica la somma di denaro dovuta dall’Utente per la fruizione di uno o più Servizi;</dd>

          <dt>Credenziali</dt>
          <dd>indica le chiavi logiche (ad esempio, l’indirizzo e-mail e la password) che il Visitatore utilizza per la Registrazione e l’Utente utilizza per l’accesso al proprio Account nell’Area Riservata del Sito;</dd>

          <dt>Dati</dt>
          <dd>indica le informazioni inviate dal dispositivo con cui l’Utente naviga sulla Piattaforma e le operazioni compiute (oggetto di memorizzazione anche temporanea su Server Chainside), nonché le informazioni relative all’Utente stesso presenti nel Profilo all’interno dell’Area Riservata;</dd>

          <dt>Diritti PI</dt>
          <dd>indica, in via esemplificativa e non esaustiva, i diritti d’autore, diritti di immagine e/o della personalità in genere, diritti di marchio, brevetto ed altri diritti di privativa, diritti di tutela della riservatezza, diritti relativi al ritratto, i diritti contenuti in informazioni riservate, tra cui il <i>know-how</i>e i segreti commerciali e industriali, i diritti morali o altri diritti simili in qualunque Paese e, che siano o meno registrati, le eventuali domande di registrazione di uno dei precedenti diritti e tutti i diritti relativi alla presentazione delle domande di registrazione per uno dei precedenti diritti;</dd>

          <dt>Documenti Privacy</dt>
          <dd>indica le informative sul trattamento dei dati personali presenti sul Sito;</dd>

          <dt>Livelli di Servizio</dt>
          <dd>indica le disposizioni del Contratto che definiscono modalità e Tempi di Presa in Carico di una Segnalazione;</dd>

          <dt>Modalità di Connessione</dt>
          <dd>indica le interfacce (web, software o di altro tipo) funzionanti su certe configurazioni di alcuni sistemi operativi mediante le quali l'Utente può utilizzare i Servizi collegandosi all’Area Riservata del Sito in remoto attraverso la rete Internet;</dd>

          <dt>Normativa Applicabile</dt>
          <dd>indica una qualunque disposizione, di qualunque rango, appartenente al diritto italiano o a quello dell’Unione Europea, in qualunque modo o misura applicabile all’oggetto del Contratto e/o ai Servizi;</dd>

          <dt>Normativa Privacy</dt>
          <dd>indica il D. Lgs. 196/2003 e successive modificazioni e/o integrazioni, nonché i Provvedimenti Generali del Garante per la Protezione dei Dati Personali emessi ai sensi dell’art. 154 comma 1 lett. c) e h), il Regolamento UE 2016/679 (“<b>GDPR</b>”) e l’ulteriore normativa applicabile, di qualunque rango, inclusi i pareri del WP29 e, dal 25/5/2018, del Comitato;</dd>

          <dt>Netiquette</dt>
          <dd>indica l’insieme di regole che disciplinano il comportamento di un utente di Internet nel rapportarsi agli altri utenti attraverso risorse come newsgroup, mailing list, forum, blog, reti sociali o email in genere (da <a href="https://it.wikipedia.org/wiki/Netiquette">https://it.wikipedia.org/wiki/Netiquette</a>);</dd>

          <dt>Parte</dt>
          <dd>indica, a seconda dei casi, l’Utente, Chainside, uno dei due o entrambi;</dd>

          <dt>Piattaforma</dt>
          <dd>indica il programma informatico dal nome ChainsidePay che si integra sulle piattaforme di commercio elettronico delle imprese e che permette di (i) incanalare i pagamenti effettuati dagli acquirenti in bitcoin verso uno specifico wallet e (ii) di monitorare tali pagamenti. Tale programma è erogato in modalità SaaS e accessibile a seguito di attivazione di un Account attraverso il quale vengono erogati i Servizi;</dd>

          <dt>Presa in Carico</dt>
          <dd>indica la comunicazione da parte di Chainside, a seguito della ricezione della Segnalazione: a) della soluzione dell’Anomalia, oppure b) delle informazioni utili alla soluzione dell’Anomalia, oppure c) dei primi riscontri sull’Anomalia e degli interventi che Chainside intende pianificare per la soluzione;</dd>

          <dt>Profilo</dt>
          <dd>indica la sezione dell’Area Riservata contenente i Dati dell’Utente;</dd>

          <dt>Pubblicazione</dt>
          <dd>indica l’azione con la quale Chainside comunica un’informazione sul Sito, senza l’attuazione di procedure che ne impongano all’Utente la visualizzazione;</dd>

          <dt>Pubblici Elenchi</dt>
          <dd>indica gli elenchi di indirizzi PEC di cui all'art. 16-ter D.L. 179/2012;</dd>

          <dt>Registrazione</dt>
          <dd>indica l’operazione con la quale il Visitatore, nell’apposita sezione del Sito, inserisce i propri Dati per proporre a Chainside di fruire dei Servizi;</dd>

          <dt>Revoca</dt>
          <dd>indica l’azione con la quale Chainside, a propria insindacabile discrezione, rifiuta la Registrazione e/o revoca l’Account dell’Utente;</dd>

          <dt>Sede</dt>
          <dd>indica il luogo dal quale l’Utente, attraverso propri dispositivi e servizi informatici, accede alla Piattaforma;</dd>

          <dt>Segnalazione</dt>
          <dd>indica la descrizione dettagliata da parte dell’Utente dell’Anomalia riscontrata, completa di tutte le corrette informazioni tecniche necessarie a Chainside per poterla riprodurre e gestire correttamente;</dd>

          <dt>Segnalazione Incompleta o Errata</dt>
          <dd>indica una errata descrizione dell’Anomalia, o una descrizione mancante di uno o più dati ritenuti essenziali da Chainside, a propria insindacabile discrezione, per la Presa in Carico;</dd>

          <dt>Servizi</dt>
          <dd>indica le funzionalità erogate da Chainside tramite l’Account;</dd>

          <dt>Sito</dt>
          <dd>indica le pagine web esposte attraverso <a href="http://www.chainside.net">www.chainside.net</a> e <a href="http://www.chainsidepay.com">www.chainsidepay.com</a>, sottodomini inclusi, o altri URL volta a volta decisi in via unilaterale da Chainside;</dd>

          <dt>SaaS</dt>
          <dd>indica il <i>"modello di distribuzione del software applicativo dove un produttore di software sviluppa, opera (diretta mente o tramite terze parti) e gestisce un’applicazione web che mette a disposizione dei propri clienti via internet"</i> (definizione completa su <a href="https://it.wikipedia.org/wiki/Software_as_a_service">https://it.wikipedia.org/wiki/Software_as_a_service</a>);</dd>

          <dt>Sospensione</dt>
          <dd>indica l’azione con la quale Chainside, a propria insindacabile discrezione, sospende la Registrazione e/o l’Account dell’Utente;</dd>

          <dt>Tempo di Presa in Carico</dt>
          <dd>indica il lasso di tempo intercorrente tra la ricezione della Segnalazione e l’intervento di Chainside;</dd>

          <dt>Termini del Servizio</dt>
          <dd>indica i termini del servizio del Sito;</dd>

          <dt>Terzo</dt>
          <dd>indica qualunque soggetto diverso da Chainside e/o dall’Utente;</dd>

          <dt>Utente</dt>
          <dd>indica il soggetto, business e-commerce, che accede ai Servizi tramite il proprio Account e compie operazioni all’interno dell’Area Riservata;</dd>

          <dt>Visitatore</dt>
          <dd>indica la persona fisica che utilizza un dispositivo e naviga, attraverso la rete Internet, sulle pagine pubbliche del Sito.</dd>
        </dl>
      </li>

      <li>
        <p>Per quanto non espressamente definito nelle CGC, si rinvia alle definizioni contenuti nei Termini del Servizio.</p>
      </li>
      <li>
        <p>In caso di eventuale contrasto tra definizioni, quelle contenute nelle CGC prevalgono su quelle contenute nei Termini del Servizio.</p>
      </li>
    </ol>
  </li>

  <li id="accettazione">
    <h3>Accettazione del Contratto</h3>

    <ol>
      <li>
        <p>Il Visitatore, mediante le Modalità di Connessione, accede all’apposita sezione del Sito e provvede alla Registrazione seguendo la procedura ivi disponibile.</p>
      </li>
      <li>
        <p>La Registrazione costituisce proposta di acquisto (o di fruizione gratuita) della licenza d’uso dei Servizi da parte dell’Utente a Chainside, la quale può accettarla o rifiutarla, o provvedere alla Revoca della stessa, a propria insindacabile discrezione.</p>
      </li>
      <li>
        <p>Con la Registrazione, Chainside attiva l’Account dell’Utente, realizzando la conclusione del Contratto, e da tale momento si impegna ad adempiere alle obbligazioni previste nel Contratto.</p>
      </li>
    </ol>
  </li>

  <li id="licenza">
    <h3>Licenza</h3>

    <ol>
      <li>
        <p>Con l’attivazione dell’Account viene concesso all’Utente il diritto di utilizzare, per la durata del Contratto, i Servizi, in regime di licenza d’uso non esclusiva, temporanea e non trasferibile.</p>
      </li>
      <li>
        <p>L’Utente accetta espressamente di non compiere, e di non autorizzare altri a compiere, le seguenti operazioni sul Sito o su parte di esso (a titolo esemplificativo e non esaustivo: la veste grafica), ivi compresi eventuali aggiornamenti, miglioramenti e/o modifiche: copia (su un qualunque supporto o risorsa logica), decompilazione, disassemblaggio, riassemblaggio, tentativo di ricavare il codice sorgente, decodifica, modifica, realizzazione di software derivati, concessione in locazione, in leasing, in prestito, vendita, ridistribuzione, concessione in sublicenza.</p>
      </li>
      <li>
        <p>L’Utente accetta espressamente di non compiere (e di non autorizzare altri a compiere) le predette operazioni, anche con riferimento alla documentazione tecnica di corredo, ai marchi e ai nomi registrati.</p>
      </li>
      <li>
        <p>Il compimento di una o più delle sopra riportate operazioni costituisce inadempimento contrattuale e comporta la risoluzione di diritto del Contratto nonché l’obbligo dell’Utente a risarcire il danno; comporta, inoltre, la violazione delle norme che tutelano i Diritti PI di Chainside, nonché, in generale, la proprietà intellettuale di autore, di brevetto e di invenzione industriale.</p>
      </li>
      <li>
        <p>Il regime di titolarità di eventuali componenti software necessari e/o utili al funzionamento dei Servizi è specificato nelle ulteriori e diverse condizioni di utilizzo che l’Utente è tenuto ad accettare qualora intenda fruire della piena funzionalità di uno o più dei Servizi; tali condizioni non costituiscono elemento del Contratto.</p>
      </li>
      <li>
        <p>Tutti i marchi e i loghi riprodotti sulla Piattaforma appartengono ai legittimi proprietari, e sono citati sulla Piattaforma a solo scopo informativo; Chainside non vanta alcun diritto su tali marchi e loghi, eccetto i propri.</p>
      </li>
    </ol>
  </li>

  <li id="servizi">
    <h3>Servizi</h3>

    <ol>
      <li>
        <p>L’Utente riconosce che la Piattaforma assolve alle seguenti due funzioni:</p>

        <ol className="latin">
          <li>
            <p>incanalare i pagamenti in bitcoin effettuati dagli acquirenti sulla piattaforma di commercio elettronico dell’Utente verso un wallet bitcoin di proprietà dell’Utente medesimo;</p>
          </li>
          <li>
            <p>monitorare tali pagamenti.</p>
          </li>
        </ol>
      </li>

      <li>
        <p>L’Utente riconosce che è opportuno segnalare agli acquirenti che intendono acquistare, tramite bitcoin, i beni  e i servizi tramite la piattaforma di commercio elettronico dell’Utente quanto segue <i>"Selezionando questa opzione potrai pagare tramite Bitcoin, il cui tasso di conversione dipende dalla quotazione decisa dal provider di conversione al momento dell’acquisto. Verrai inoltrato automaticamente al modulo di pagamento sicuro e riceverai le indicazioni sul wallet su cui fare il pagamento. L'ordine verrà subito registrato e successivamente confermato"</i>.</p>
      </li>

    </ol>
  </li>

  <li id="pagamento">
    <h3>Oggetto, Durata, Corrispettivo e Modalità di Pagamento</h3>

    <ol>
      <li>
        <p>Il Contratto ha a oggetto la fornitura dei Servizi, a titolo gratuito o dietro Corrispettivo, e con funzionalità variabili a seconda del tipo di Account scelto dall’Utente, il tutto nei termini meglio descritti nel Sito</p>
      </li>
      <li>
        <p>Il Contratto ha durata indeterminata. Ciascuna Parte ha il diritto di recedere dal Contratto in qualunque momento inviando all’altra parte apposita comunicazione scritta, inclusa la posta elettronica, con effetto immediato. Il Corrispettivo, nonché i termini e le modalità di pagamento, sono riportati nel Sito.</p>
      </li>
      <li>
        <p>Chainside, a propria insindacabile discrezione, può prevedere l’erogazione della licenza d’uso di uno o più Servizi a titolo gratuito, per un periodo determinato; in tale periodo l’Utente è soggetto a tutte le obbligazioni dedotte in Contratto, salvo il pagamento del Corrispettivo.</p>
      </li>

    </ol>
  </li>

  <li id="dichiarazioni-utente">
    <h3>Dichiarazioni dell'utente</h3>

    <ol>
      <li>
        <p>L’Utente dichiara, sotto la propria responsabilità:</p>

        <ol className="latin">
          <li>
            <p>se persona fisica, di avere l’età legale richiesta dalla Normativa Applicabile italiana, del Paese in cui si trova o del Paese in cui risiede, per concludere un contratto vincolante;</p>
          </li>
          <li>
            <p>se persona giuridica, che la persona fisica che richiede la Registrazione è dotata della rappresentanza legale ed è provvista di idonea documentazione al riguardo.</p>
          </li>
        </ol>

      </li>
      <li>
        <p>L’Utente riconosce e accetta:</p>

        <ol className="latin">
          <li>
            <p>che il Contratto prevale su qualunque eventuale diversa pattuizione (orale e/o scritta) fra le Parti e sulle informazioni contenute nelle pagine del Sito, ove discordanti;</p>
          </li>
          <li>
            <p>che il Contratto regola esclusivamente il rapporto con Chainside e non è suscettibile di far sorgere diritti in favore di soggetti Terzi;</p>
          </li>
          <li>
            <p>che Chainside tratterà i Dati dell’Utente in conformità a quanto previsto nei Documenti Privacy;</p>
          </li>
          <li>
            <p>le limitazioni ed esclusioni di garanzia e responsabilità di Chainside contenuti nei Termini del Servizio e nelle Condizioni e nell’ulteriore documentazione di cui al Contratto;</p>
          </li>
          <li>
            <p>che le informazioni contenute nel Sito non costituiscono offerta di servizi ma esclusivamente invito a provvedere alla Registrazione, e che la fruizione dei Servizi è regolata esclusivamente dal Contratto;</p>
          </li>
          <li>
            <p>che i costi di connessione alla rete Internet e quelli eventualmente relativi alle Modalità di Connessione sono a proprio carico.</p>
          </li>

        </ol>
      </li>

    </ol>
  </li>

  <li id="obblighi-utente">
    <h3>Obblighi dell'utente</h3>

    <ol>
      <li>
        <p>Con la Registrazione, e in ogni caso al momento dell’attivazione dell’Account, l’Utente si obbliga a:</p>

        <ol className="latin">
          <li>
            <p>pagare il Corrispettivo eventualmente dovuto per la fruizione dei Servizi;</p>
          </li>
          <li>
            <p>non accedere, o tentare di accedere, o far accedere Terzi, in modo illecito, ai Servizi e al Sito utilizzando, a mero titolo di esempio, sistemi di decriptazione, di scansione di risorse di rete, di ricerca di dati di login, od in altro modo che non sia l'uso delle interfacce messe a disposizione da Chainside.</p>
          </li>
          <li>
            <p>non danneggiare in qualunque modo il Sito;</p>
          </li>
          <li>
            <p>non violare:</p>

            <ol className="roman">
              <li>
                <p>le politiche della Rete, compresa la Netiquette;</p>
              </li>
              <li>
                <p>i Termini del Servizio e le CGC, e in ogni caso il Contratto;</p>
              </li>
              <li>
                <p>la Normativa Applicabile e la Normativa Privacy italiane, e/o del Paese in cui si trova, e/o del Paese in cui risiede, o comunque applicabili alle attività che compie;</p>
              </li>
              <li>
                <p>i Diritti PI di Chainside e/o di Terzi;</p>
              </li>
              <li>
                <p>accordi, con chiunque stipulati, incompatibili con l’uso dei Servizi.</p>
              </li>
            </ol>

          </li>
          <li>
            <p>mantenere segrete le Credenziali;</p>
          </li>
          <li>
            <p>non consentire a Terzi l’accesso all’Area Riservata con le proprie Credenziali;</p>
          </li>
          <li>
            <p>comunicare a Chainside:</p>

            <ol className="roman">
              <li>
                <p>Dati corretti e veritieri, e ogni eventuale loro variazione senza ritardo, e comunque entro i tempi eventualmente stabiliti dalla Normativa Applicabile;</p>
              </li>
              <li>
                <p>qualsiasi perdita di riservatezza della password di accesso all’Area Riservata;</p>
              </li>
              <li>
                <p>qualsiasi utilizzo e/o pericolo di utilizzo da parte di Terzi delle proprie Credenziali;</p>
              </li>
              <li>
                <p>qualsiasi violazione a lui nota delle misure di sicurezza previste per i Servizi;</p>
              </li>
            </ol>

          </li>
          <li>
            <p>impostare una password adeguatamente sicura e complessa, e cambiarla periodicamente;</p>
          </li>
          <li>
            <p>non nascondere, rendere irriconoscibile o falsificare la propria identità e/o i propri Dati;</p>
          </li>
          <li>
            <p>non simulare di essere Chainside o altri;</p>
          </li>
          <li>
            <p>mantenere riservata e non divulgare a Terzi, in tutto o anche solo in parte, il contenuto della documentazione di cui sia espressamente vietata la diffusione;</p>
          </li>
          <li>
            <p>prestare la più ampia collaborazione nei confronti di Chainside nel rispondere prontamente e compiutamente a eventuali richieste di Chainside stessa e/o di altri Utenti e/o di Terzi e nel fornire ogni informazione rilevante, a insindacabile discrezione di Chainside, ai fini dell’esecuzione del Contratto;</p>
          </li>
          <li>
            <p>non cedere a Terzi il Contratto.</p>
          </li>

        </ol>
      </li>

    </ol>
  </li>

  <li id="responsabilità-utente">
    <h3>Responsabilità dell'utente</h3>

    <ol>
      <li>
        <p>L’Utente risponde, manlevando e tenendo indenne Chainside, per ogni azione, inclusi ragionevoli onorari legali, promossa da altri Utenti e/o da Terzi e volta a ottenere il risarcimento di danni riguardanti le violazioni degli obblighi di cui al precedente articolo.</p>
      </li>

    </ol>
  </li>

  <li id="obblighi-chainside">
    <h3>Responsabilità dell'utente</h3>

    <ol>
      <li>
        <p>Con l’attivazione dell’Account dell’Utente, Chainside si obbliga a:</p>

        <ol className="latin">
          <li>
            <p>fornire all’Utente i Servizi, nelle forme e nei termini indicati nel Contratto;</p>
          </li>
          <li>
            <p>operare la massima diligenza nell’erogazione dei Servizi, compatibilmente con le necessità connesse ad eventuali interventi di manutenzione programmata o straordinaria e indifferibile;</p>
          </li>
          <li>
            <p>informare l’Utente, mediante Avviso sul Sito, circa le interruzioni programmate, se riguardanti la totalità degli Utenti di uno o più Servizi, nonché circa i Tempi di Presa in Carico delle richieste di soluzione delle Anomalie, nonché circa gli ulteriori eventi e/o situazioni che interessino l’attività di Chainside e/o la totalità degli Utenti;</p>
          </li>
          <li>
            <p>eseguire la Presa in Carico delle richieste di soluzione di Anomalie secondo i Livelli di Servizio stabiliti alla Clausola 14;</p>
          </li>
          <li>
            <p>informare l’Utente di eventuali modificazioni, aggiunte e/o rimozioni di disposizioni del Contratto, nonché di aggiunta e/o rimozione e/o modificazioni di uno o più funzionalità e/o Servizi e/o delle loro modalità di erogazione, secondo quanto stabilito nella Clausola 12;</p>
          </li>
          <li>
            <p>osservare tutte le prescrizioni di cui alla Normativa Privacy e, per quanto ad essa applicabile, quelle di cui alla Normativa Applicabile.</p>
          </li>
        </ol>
      </li>

    </ol>
  </li>

  <li id="facoltà-parti">
    <h3>Facoltà delle parti</h3>

    <ol>
      <li>
        <p>L’Utente ha facoltà di:</p>

        <ol className="latin">
          <li>
            <p>utilizzare l’Area Riservata e il relativo Account per fruire dei Servizi;</p>
          </li>
          <li>
            <p>modificare i propri Dati e/o cancellare il proprio Account, nonché sceglierne le impostazioni di visibilità, con le modalità indicate nell’Area Riservata;</p>
          </li>
          <li>
            <p>recedere dal Contratto secondo quanto stabilito nella Clausola 15;</p>
          </li>
          <li>
            <p>eseguire la Presa in Carico delle richieste di soluzione di Anomalie secondo i Livelli di Servizio stabiliti alla Clausola 14;</p>
          </li>
          <li>
            <p>ottenere copia dei propri Dati attraverso contattando Chainside nelle ipotesi di cui alla Clausola 15.1 lett. (d) e (f);</p>
          </li>
          <li>
            <p>effettuare in qualsiasi momento - con adeguato preavviso comunque non inferiore a 5 giorni lavorativi, anche a mezzo di Terzi designati da Chainside, e comunque alla presenza di rappresentanti della stessa, e con oneri a proprio carico e senza recare pregiudizio ad Chainside - i controlli che riterrà necessari per verificare il rispetto degli impegni di cui ai Documenti Privacy.</p>
          </li>
        </ol>
      </li>

      <li>
        <p>Chainside ha facoltà di:</p>

        <ol className="latin">
          <li>
            <p>modificare, aggiungere e/o rimuovere disposizioni del Contratto, nonché di aggiungere e/o rimuovere e/o modificare uno o più funzionalità e/o Servizi e/o le loro modalità di erogazione, ivi compreso il nome a dominio del Sito, nonché modificare il Corrispettivo, ai sensi della Clausola 12;</p>
          </li>
          <li>
            <p>provvedere alla Revoca o alla Sospensione della Registrazione e/o dell’Account, a propria insindacabile discrezione;</p>
          </li>
          <li>
            <p>risolvere il Contratto secondo quanto stabilito nella Clausola 15.3;</p>
          </li>
          <li>
            <p>rifiutare una nuova Registrazione di un Utente in caso di risoluzione del Contratto ai sensi della Clausola 15.3;</p>
          </li>
          <li>
            <p>a richiesta dell’Utente, inviare la fattura relativa a un Servizio per cui sia previsto il pagamento del Corrispettivo;</p>
          </li>
          <li>
            <p>avvalersi in tutto o in parte, nell’erogazione dei Servizi e di quanto ad essi connesso, di Terzi (a titolo esemplificativo e non esaustivo: società di hosting, collaboratori, consulenti).</p>
          </li>
        </ol>
      </li>

      <li>
        <p>Le Parti hanno facoltà di concordare e formalizzare per iscritto condizioni particolari in relazione ad uno o più Servizi (a mero titolo di esempio, sotto il profilo delle modalità di fornitura e/o del Corrispettivo).</p>
      </li>

    </ol>
  </li>

  <li id="modificazioni">
    <h3>Modificazioni delle CGC e/o dei servizi</h3>

    <ol>
      <li>
        <p>Chainside comunica all’Utente la modificazione e/o aggiunta e/o rimozione di disposizioni delle CGC, e/o il Corrispettivo, mediante Pubblicazione sul Sito, entro 30 (trenta) giorni dalla modificazione e/o aggiunta e/o rimozione.</p>
      </li>

      <li>
        <p>Le modifiche si applicano anche ai Contratti già conclusi, salva la facoltà di recesso dell’Utente, ai sensi della Clausola 15.1 let. (c).</p>
      </li>

    </ol>
  </li>

  <li id="limitazioni">
    <h3>Limitazioni ed esclusioni di garanzia e responsabilità</h3>

    <ol>
      <li>
        <p>Chainside non rilascia dichiarazioni e/o attestazioni di alcun genere riguardo alla Piattaforma e al Sito.</p>
      </li>

      <li>
        <p>Chainside non garantisce che:</p>

        <ol className="latin">
          <li>
            <p>il Sito e/o i Servizi siano accessibili da qualunque zona geografica e/o in qualunque momento;</p>
          </li>
          <li>
            <p>le Modalità di Connessione utilizzate dall’Utente siano perfettamente compatibili con il Sito, e/o le funzionalità e/o i Servizi;</p>
          </li>
          <li>
            <p>il Sito e/o i Servizi siano rispondenti alle esigenze e/o alle aspettative dell’Utente;</p>
          </li>
          <li>
            <p>il Sito e/o i Servizi siano totalmente esenti da errori e/o Anomalie, e che essi possano sempre e comunque essere risolti.</p>
          </li>
        </ol>
      </li>

      <li>
        <p>Chainside non è responsabile per danni diretti, indiretti, consequenziali o incidentali, speciali, punitivi, o per perdite di qualunque tipo (profitti, contratti, dati) che l’Utente e/o Terzi possano subire (a titolo esemplificativo e non esaustivo) da:</p>

        <ol className="latin">
          <li>
            <p>ogni operazione, informazione, consiglio o altro comportamento messo in atto dall’Utente nei confronti di Terzi;</p>
          </li>
          <li>
            <p>caso fortuito o forza maggiore (es. eventi catastrofici, incendi, terremoti, eruzioni vulcaniche, tumulti, insurrezioni, scioperi, ecc.);</p>
          </li>
          <li>
            <p>condotte dolose e/o colpose, e/o rilevanti dal punto di vista disciplinare, o comunque non conformi al Contratto da parte dell’Utente e/o da parte di suoi incaricati;</p>
          </li>
          <li>
            <p>condotte e/o atti di Terzi, compresi gli incaricati;</p>
          </li>
          <li>
            <p>intervento e/o decisione di una qualunque autorità (amministrativa, giudiziaria, di vigilanza, ecc.) in contrasto con i diritti e le facoltà concessi all’Utente in forza del Contratto;</p>
          </li>
          <li>
            <p>Revoca e/o Sospensione della Registrazione e/o dell’Account;</p>
          </li>
          <li>
            <p>forma e/o sostanza e/o interpretazione del contenuto di siti e/o risorse riferibili a Terze parti in qualunque modo richiamati dal Sito, o in esso incorporati;</p>
          </li>
          <li>
            <p>guasti alla rete e/o ai server di Chainside o in uso ad Chainside dovuti a caso fortuito, forza maggiore o fatto di Terzi;</p>
          </li>
          <li>
            <p>guasti e/o malfunzionamenti di hardware e/o software, delle apparecchiature, impianti e/o dei sistemi, di qualsiasi tipo,in uso presso la Sede dell’Utente e necessario e/o utile per il funzionamento di una o più componenti del Sito; </p>
          </li>
          <li>
            <p>malfunzionamenti e/o indisponibilità e/o modificazione di configurazione totali e/o parziali dei servizi erogati da Terze parti e resi disponibili attraverso uno o più Servizi e/o parte di essi e/o di funzionalità del Sito;</p>
          </li>
          <li>
            <p>mancata e/o inesatta fruizione di uno o più Servizi in caso di inadempimento dell’Utente delle obbligazioni a suo carico previste dal Contratto;</p>
          </li>
          <li>
            <p>mancata verifica da parte dell’Utente dei risultati delle elaborazioni di uno o più Servizi e/o parte di essi e/o di funzionalità del Sito; </p>
          </li>
          <li>
            <p>malfunzionamento, utilizzo e/o inutilizzo dei modelli documentali e/o di utilità di precompilazione di dati e/o altre funzionalità accessorie messi a disposizione attraverso uno o più Servizi;</p>
          </li>
          <li>
            <p>mancato e/o ritardato supporto all’Utente al di fuori dei Livelli di Servizio stabiliti;</p>
          </li>
          <li>
            <p>qualunque forma di accesso non autorizzato al Sito e/o all’Account;</p>
          </li>
          <li>
            <p>ritardi, sospensioni, interruzioni, difetti e/o malfunzionamenti di uno o più dei Servizi, totali e/o parziali, temporanei e/o definitivi, derivanti da qualsiasi altra causa ad essa non imputabile;</p>
          </li>
          <li>
            <p>sospensione e/o interruzione del servizio di collegamento internet da parte del gestore;</p>
          </li>
          <li>
            <p>utilizzo non conforme al presente Contratto, da parte dell’Utente, di uno o più Servizi e/o parte di essi e/o di funzionalità del Sito;</p>
          </li>
          <li>
            <p>utilizzo o non utilizzo di uno o più Servizi e/o parte di essi e/o di funzionalità del Sito;</p>
          </li>
          <li>
            <p>violazioni della Normativa Applicabile e/o della Normativa Privacy commesse dall’Utente e/o da Terzi;</p>
          </li>
          <li>
            <p>mancato e/o ritardato supporto da parte di Incaricati dell’Utente.</p>
          </li>
        </ol>
      </li>

      <li>
        <p>Chainside non è responsabile neanche quando sia stata preventivamente informata da chiunque di una qualunque violazione e/o danno e/o pericolo.</p>
      </li>
      <li>
        <p>Le limitazioni ed esclusioni di cui al presente articolo si applicano nella misura massima consentita dalla legge.</p>
      </li>
      <li>
        <p>Fermo quanto sopra e fatti salvi i limiti inderogabili di legge, la responsabilità di Chainside è in ogni caso limitata ad un importo massimo corrispondente al Corrispettivo annuo pagato dall’Utente.</p>
      </li>

    </ol>
  </li>

  <li id="livelli">
    <h3>Livelli di servizio</h3>

    <ol>
      <li>
        <p>La Presa in Carico delle Segnalazioni avviene con le modalità e negli orari che possono essere oggetto di Pubblicazione nelle pagine interne del Sito.</p>
      </li>

      <li>
        <p>I Tempi di Presa in Carico decorrono dall’inizio dell’orario di servizio volta a volta indicato, o dalla prima ora lavorativa utile, se la Segnalazione è stata aperta nottetempo o in un giorno festivo.</p>
      </li>

      <li>
        <p>Chainside effettua la Presa in Carico delle Segnalazioni nel più breve tempo possibile, compatibilmente con il proprio carico di lavoro. Non garantisce tuttavia che vengano lavorate entro uno specifico lasso temporale.</p>
      </li>

      <li>
        <p>Da una Segnalazione Incompleta o Errata non decorrono i Tempi di Presa in Carico.</p>
      </li>

    </ol>
  </li>

  <li id="cessazione">
    <h3>Cessazione del Contratto</h3>

    <ol>
      <li>
        <p>L’Utente può recedere dal Contratto:</p>

        <ol className="latin">
          <li>
            <p>in caso di fruizione esclusivamente di Servizi gratuiti, in ogni tempo, provvedendo alla chiusura del proprio Account con le modalità indicate nell’Area Riservata;</p>
          </li>
          <li>
            <p>in caso di fruizione di uno o più Servizi a pagamento, entro trenta giorni dalla conclusione del Contratto, senza penalità e senza/con diritto al rimborso del Corrispettivo eventualmente pagato;</p>
          </li>
          <li>
            <p>nel caso in cui Chainside modifichi, aggiunga e/o rimuova disposizioni del Contratto, entro 10 (dieci) giorni dalla Pubblicazione sul Sito della versione modificata dei Termini del Servizio, e/o delle CGC e/o di qualunque altro documento di cui al Contratto, senza diritto al rimborso del Corrispettivo eventualmente pagato;</p>
          </li>
          <li>
            <p>nel caso in cui Chainside sia impossibilitata o in difficoltà a fornire uno o più Servizi, senza diritto al rimborso del Corrispettivo eventualmente pagato;</p>
          </li>
          <li>
            <p>nel caso in cui Chainside ceda l’attività ad altri soggetti che eroghino Servizi simili, senza diritto al rimborso del Corrispettivo eventualmente pagato;</p>
          </li>
          <li>
            <p>nel caso in cui Chainside cessi la propria attività, senza diritto al rimborso del Corrispettivo eventualmente pagato.</p>
          </li>
        </ol>
      </li>

      <li>
        <p>Nei casi di cui alla Clausola 15.1 lett. (d), (e) e (f), il recesso deve essere esercitato entro 30 (trenta) giorni dall’Avviso sul Sito da parte di Chainside; in difetto, il Contratto si intende tacitamente rinnovato per un periodo di uguale durata a quello volta a volta in corso.</p>
      </li>

      <li>
        <p>Chainside potrà risolvere il Contratto ai sensi e per gli effetti dell’art. 1456 c.c. nel caso in cui l’Utente non adempia violi quanto previsto alle Clausole 7 e 8.</p>
      </li>

      <li>
        <p>In ogni caso di recesso, risoluzione o scioglimento rimangono applicabili, salvo per le parti oggettivamente incompatibili con la causa di cessazione, le disposizioni previste nelle seguenti Clausole: 6, 8, 9, 14, 15, 16, 17, 18 e 19.</p>
      </li>

    </ol>
  </li>

  <li id="comunicazioni">
    <h3>Comunicazioni</h3>

    <ol>
      <li>
        <p>Qualsiasi comunicazione tra le Parti nell’esecuzione del Contratto può essere effettuata, volta a volta, mediante:</p>

        <ol className="latin">
          <li>
            <p>Pubblicazione sul Sito;</p>
          </li>
          <li>
            <p>Avviso sul Sito;</p>
          </li>
          <li>
            <p>e-mail, o PEC, o lettera raccomandata con avviso di ricevimento, indirizzata all’altra parte agli indirizzi indicati nell’Area Riservata (per l’Utente) o sul Sito (per Chainside), o sui Pubblici Elenchi.</p>
          </li>
        </ol>
      </li>

      <li>
        <p>Qualsiasi Avviso sul Sito si intende visualizzato, compreso ed accettato quando l'Utente lo visualizza sul proprio computer in base alla procedura, predisposta da Chainside, che ne impone la visualizzazione.</p>
      </li>

    </ol>
  </li>

  <li id="dati-personali">
    <h3>Comunicazioni</h3>

    <ol>
      <li>
        <p>Le Parti si danno reciprocamente atto che l’Utente è il titolare del trattamento dei dati personali in qualunque forma comunicati ad Chainside ai fini dell’erogazione dei Servizi.</p>
      </li>

      <li>
        <p>Chainside si impegna a fornire all’Utente i Documenti Privacy.</p>
      </li>

      <li>
        <p>Chainside acconsente che i propri Dati siano comunicati nell’informativa che l’Utente debba eventualmente fornire agli interessati.</p>
      </li>

    </ol>
  </li>

  <li id="disposizioni-generali">
    <h3>Disposizioni Generali</h3>

    <ol>
      <li>
        <p>L’eventuale tolleranza di Chainside ai comportamenti dell’Utente posti in essere in violazione delle disposizioni contenute nel Contratto non costituisce rinuncia ai diritti derivanti dalle disposizioni violate, né al diritto di esigere l’esatto adempimento di tutti i termini e di tutte le condizioni ivi previste.</p>
      </li>

      <li>
        <p>I diritti e i rimedi qui inclusi non hanno carattere esclusivo, ma si aggiungono agli altri diritti e rimedi disponibili secondo la legge applicabile.</p>
      </li>

      <li>
        <p>Se una delle disposizioni del Contratto fosse giudicata nulla o inapplicabile da una corte o da altra autorità competente, tale disposizione dovrà ritenersi cancellata dal Contratto e le rimanenti disposizioni del Contratto rimarranno e continueranno a rimanere pienamente valide ed efficaci.</p>
      </li>

    </ol>
  </li>

  <li id="foro">
    <h3>Legge applicabile e foro competente</h3>

    <ol>
      <li>
        <p>Il Contratto è sottoposto alla legge italiana, e alla normativa europea ove direttamente applicabile.</p>
      </li>

      <li>
        <p>In caso di controversia relativamente all’interpretazione ed all’esecuzione del Contratto, le Parti convengono la giurisdizione esclusiva del Giudice italiano e la competenza esclusiva del Foro di Roma.</p>
      </li>

    </ol>
  </li>

</ol><div id = "acceptance">
  <p> Pursuant to and for the purposes of the articles 1341 and 1342 of the Italian Civil Code, the User expressly accepts the following provisions of the Contract: Clause 11.2 (Faculty of the Parties), Clause 12 (Modifications to the GTCs and / or Services), Clause 13 (Limitations and Exclusions of Warranty and Liability) and Clause 19 (Applicable Law and Jurisdiction). </p>
</div>

</React.Fragment>

export {terms};