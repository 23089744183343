export default {
  list: {
    subtitle:
      'Qui sono elencati tutti gli ordini di pagamento creati attraverso i' +
      ' tuoi POS. Potrai visualizzare il loro stato e i loro dettagli.',
    loading: 'Caricamento ordini di pagamento in corso...',
    no_more: 'Sei arrivato in fondo!',
    faq: {
      emptyListTitle:
        'Nel frattempo potrebbero esserti di aiuto le nostre FAQ:',
      1: 'Chi genera gli ordini di pagamento?',
      2: 'Come faccio a ricevere il mio primo pagamento?',
    },
    messages: {
      no_payments: {
        title: 'Nessun ordine di pagamento ricevuto',
        subtitle:
          'In questa pagina potrai vedere tutti i tuoi ordini di pagamento' +
          ' non appena inizierai a crearne.',
      },
      filter_by_status: {
        completed: {
          title: 'Nessun ordine di pagamento completato',
          subtitle: 'Non hai ancora nessun ordine di pagamento completato.',
        },
        not_completed: {
          title: 'Nessun ordine di pagamento presente',
          subtitle: 'Tutti i tuoi ordini di pagamento sono completati. ',
        },
        disputed: {
          title: 'Nessun ordine di pagamento presente',
          subtitle: 'Non hai nessun ordine di pagamento in disputa',
        },
        other: {
          title: 'Nessun ordine di pagamento presente',
          subtitle: 'Non ci sono altri ordini di pagamento',
        },
      },
      other_filter: {
        viewAll: 'Visualizza tutti gli ordini di pagamento',
        pos: {
          title: 'Nessun ordine di pagamento originato da questo POS',
          goBack: 'Torna ai dettagli del POS',
        },
        wallet: {
          title: 'Nessun ordine di pagamento ricevuto sul conto',
        },
        account: {
          title: 'Nessun ordine di pagamento ricevuto su questo conto',
          goBack: 'Torna ai dettagli del conto',
        },
      },
      show_all: 'Visualizza tutti gli ordini di pagamento',
    },
  },
  fields: {
    created_at: 'Data',
    amount: 'Importo',
    crypto_amount: 'Importo crypto',
    status: 'Stato',
    pos_name: 'Creato da',
    reference: 'Riferimento',
  },
  filters: {
    standard: {
      all: 'Tutti',
      completed: 'Confermati',
      not_completed: 'In corso',
      disputed: 'A rischio',
      other: 'Altri',
    },
    webpos: 'Ordini di pagamento originati dal POS',
    mobilepos: 'Ordini di pagamento originati dal POS',
    wallet: 'Ordini di pagamento ricevuti sul conto',
    account: 'Ordini di pagamento ricevuti sul conto',
    remove_filter: 'Rimuovi filtro',
  },
  detail: {
    titles: {
      title: 'Dettaglio ordine di pagamento',
      parent: 'Torna alla lista degli ordini di pagamento',
    },
    import: 'Importo',
    commission: 'Commissione',
    totalImport: 'Importo totale',
    status: {
      pending: 'In Attesa',
      network_dispute: 'Disputa',
      chargeback: 'Chargeback',
      unconfirmed: 'Non Confermato',
      paid: 'Pagato',
      expired: 'Scaduto',
      cancelled: 'Cancellato',
    },
    sections: {
      detail: 'Dettagli',
      exchange: 'Informazioni sul cambio',
      transaction: 'Transazioni ricevute',
    },
    paymentDate: 'Data di conferma',
    cancelledDate: 'Data di cancellazione',
    expiredDate: 'Data di scadenza',
    createdAt: 'Data di creazione',
    startDispute: 'Data di inizio disputa',
    chargeback: 'Data di chargeback',
    createdByPOS: 'Creato dal POS',
    account: 'Conto di ricezione',
    paymentID: "ID dell'ordine",
    reference: 'Riferimento',
    description: 'Descrizione',
    callback_url: 'URL Callback',
    exchange: 'Bitcoin Exchange',
    changeApplied: 'Cambio applicato',
    address: 'Indirizzo %(currency)s',
    transaction: {
      confirmed: 'Confermata',
      mempool: 'In attesa',
      unconfirmed: 'In attesa',
      reverted: 'Annullata',
      noTransaction: 'Nessuna transazione ricevuta finora',
      date: 'Data',
      amount: 'Importo',
      btc_amount: 'Importo BTC',
      outs: 'Output',
      state: 'Stato',
      id: 'Identificativo',
    },
    faq: {
      whoCreateID: "Chi ha creato l'ID dell'ordine?",
      whatID: "Che cos'è l'ID dell'ordine?",
      canAbort: 'Posso annullare un ordine di pagamento?',
      howChangeWorks: 'Come funziona il cambio?',
      whatIsAdressBTC: "Che cosa è l'indirizzo %(currency)s?",
      whatTransactions: 'Cosa sono le transazioni?',
      stateTransactions: 'Che stato può avere una transazione?',
      network_dispute:
        "Cosa significa che l'ordine di pagamento è nello stato Disputa?",
      chargeback:
        "Cosa significa che l'ordine di pagamento è nello stato" +
        ' Chargeback?',
    },
  },
  testing: {
    boxTitle: 'Testa questo ordine di pagamento',
    boxSubtitle:
      "Le callback notificano eventi relativi all'ordine di pagamento, come" +
      ' ad esempio il cambio di stato. Invia manualmente una callback per verificare che la ricezione sul tuo sito sia gestita correttamente. ',
    selectTitle: 'Seleziona la callback da testare',
    sendCallback: 'Invia callback',
    sendToInitalState: "Riporta l'ordine di pagamento allo stato iniziale",
    modalLoading: 'Invio della callback %(callback_name)s in corso...',
    resettingModal: "Reset dell'ordine di pagamento in corso...",
    seeRequests: 'Visualizza payload della richiesta',
    status: 'Stato',
    importReceived: 'Importo ricevuto: ',
    invalidCallback: {
      title: 'Problema durante l’invio della callback',
      text:
        'Non è possibile inviare la callback %(callback_name)s nello stato' +
        " in cui si trova l'ordine di pagamento. Questo è spesso dovuto a" +
        ' problemi di sincronizzazione per cui ti invitiamo ad aggiornare la pagina. ',
      sendToInitialState: "riportare l'ordine di pagamento allo stato iniziale",
    },
    success: {
      title: 'Callback inviata con successo',
      text:
        'L’invio della callback ha comportato le seguenti modifiche' +
        " all'ordine di pagamento:",
      requestSend: 'Richiesta inviata:',
      response: 'Risposta ricevuta:',
      httpCode: 'Codice HTTP:',
    },
    resetted: {
      title: "Reset dell'ordine di pagamento completato",
      text: "Ecco le modifiiche apportate all'ordine di pagamento:",
    },
    error: {
      title: 'Problemi di integrazione ',
      text:
        'Abbiamo ricevuto un errore in risposta alla callback inviata. Verifica di aver rispettato le indicazioni di integrazione come da ',
      chainsideProblem: 'Se pensi questo sia un problema di Chainside ',
    },
    fatal: {
      title: 'Problema durante l’invio della callback',
      text:
        'C’è stato un problema durante l’invio della callback di test e' +
        " l'ordine di pagamento è" +
        ' stato resettato e riportato allo stato iniziale. Se il problema sussiste',
    },
  },
};
