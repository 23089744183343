import React from 'react';
import Translate from 'react-translate-component';

export const TIPO_POS = [
  {
    type: 'webPOS',
    text: <Translate content="POS.new.types.webstore.name" component="b" />,
    icon: require('../../../../img/business-type/website.png'),
  },
  {
    type: 'mobilePOS',
    text: <Translate content="POS.new.types.store.name" component="b" />,
    icon: require('../../../../img/business-type/store.png'),
  },
];
