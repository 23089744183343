export default {
  list: {
    title: 'Your POS',
    subtitle:
      'A POS allows you to receive bitcoin payments from one of your ' +
      'e-commerces or physical stores. You can create as many POS that your company requires. ',
    add: 'Create POS',
    faq: {
      WHAT: 'What is a POS?',
      HOW: 'How to integrate a POS?',
      MORE_SITES: 'Do you have multiple websites or physical stores?',
      goToDocumentation: 'Go to the documentation',
    },
    suggestion: {
      generic: {
        title: 'Create your first POS',
        faq: 'Do you need more information about the POS? ',
      },
      web: {
        hint: 'Accepting bitcoin on your website is simple. Start now.',
        title: 'Create your first webPOS',
        subtitle: 'Accept bitcoin payments on your site or on your e-commerce',
        faq: 'Do you want more information about the webPOS? ',
      },
      mobile: {
        hint: 'Accepting bitcoins in your stores is simple. It starts now.',
        title: 'Create your first mobilePOS',
        subtitle: 'Receive bitcoin payments in your stores',
        faq: 'Do you want more information about the mobilePOS? ',
      },
      faqLink: 'Check out our FAQs',
    },
  },
  detail: {
    actions: {
      viewPayment: 'Payment Orders',
      edit: 'edit',
      deactivate: 'disable',
      activate: 'activate',
      associateAccount: 'Associate account',
      createAccount: 'Create an account',
    },
    detail: 'Details',
    posID: 'POS ID',
    clientID: 'Client ID',
    secret: 'Secret',
    currency: 'Currency',
    exchange: 'Exchange',
    noExchange: 'No exchange associated',
    account: 'Receipt account',
    confirmations: 'Confirmation requests',
    device: 'Device',
    activationDate: 'Activation date',
    urls: {
      title: 'Integration',
      continue: 'Confirmation wait URL',
      failure: 'URL cancellation',
      callback: 'callback URL',
    },
    devices: {
      title: 'Associate a device',
      1: 'Scan the QR code to the side using the Chainside Pay Merchant app',
      2: 'Or manually enter Client ID and Secret',
      3: '3. The POS is working! You can receive payments',
      suggestion:
        "If you can't scan from your smartphone or tablet, enter the CLIENT ID and SECRET you find on the top manually",
    },
    state: {
      notActive: 'This POS is not active',
      noAccountAssociated: 'No associated account',
      noAccountsAvailable: 'No account available',
    },
  },
  new: {
    noAccount: {
      title: "Ops ... you haven't created any accounts yet",
      subtitle:
        'Before creating a POS to manage payments it is necessary' +
        'to set up an account where you want to receive money',
      buttons: {
        createAccount: 'Create an account',
      },
    },
    titles: {
      parent: 'Return to the POS list',
      title: 'Create a new POS',
      suggestion:
        'A POS allows you to accept bitcoin payments. Select the type of store and we will guide you through the creation process. ',
    },
    types: {
      title: 'What kind of store do you want to create this POS for?',
      hint:
        'Select the type of store and we will show you the most suitable POS to create',
      store: {
        name: 'Physical Shop',
        title: 'The POS more suitable for you:',
        detail: 'mobilePOS',
        suggestion: 'Receive bitcoin payments in your store',
      },
      webstore: {
        name: 'Website / e-commerce',
        title: 'The POS more suitable for you:',
        detail: 'webPOS',
        suggestion: 'Accept bitcoin payments from your website or  e-commerce',
      },
    },
    mobile: {
      titles: {
        title: 'New mobile POS',
        parent: 'POS',
      },
      1: {
        text: 'Create your mobile POS and configure it in just a few clicks',
        hint: 'Create now',
      },
      2: {
        text:
          'After creating it, connect your mobile POS with a smartphone or tablet',
        hint: 'What do I need? ',
      },
      3: {
        text: 'Accept bitcoin payments from your customers in seconds',
        hint: 'Start immediately',
      },
    },
    web: {
      titles: {
        title: 'Create a webPOS',
        parent: 'Return to the POS list',
      },
      1: {
        text: 'Create your webPOS and configure it in a few clicks',
        hint: 'Create now',
      },
      2: {
        text: 'Proceed to integrate the webPOS on your website',
        hint: 'What do I need? ',
      },
      3: {
        text:
          'Conveniently monitor all incoming bitcoin payments from your site',
        hint: 'Start immediately',
      },
    },
    confirm: {
      web: {
        titles: {
          title: 'Confirm webPOS data',
          subtitle:
            'Confirm the data entered and proceed with the creation of the POS',
        },
      },
      mobile: {
        titles: {
          title: 'Confirm mobilePOS data',
        },
      },
    },
  },
  deactivate: {
    confirm: {
      web: {
        titles: {
          title: 'Are you sure you want to disable this POS?',
          subtitle:
            'By deactivating the POS "%(posName)s" you will no longer be able to create payment orders on the site "%(website)s"',
        },
      },
      mobile: {
        titles: {
          subtitle:
            'By deactivating the POS "%(posName)s" you will no longer be able to create payment orders on the device "%(device)s"',
        },
      },
    },

    loading: {
      title: 'Deactivation in progress ...',
    },
    success: {
      message: 'POS disabled successfully!',
    },
  },
  activate: {
    confirm: {
      web: {
        withAccount: {
          titles: {
            title: 'POS activation',
            subtitle:
              'Once activated the POS "%(posName)s" you can proceed with the integration on your site "%(website)s" and start receiving bitcoin payments',
          },
          buttons: {
            confirm: 'Activate',
          },
        },
        noAssignedAccount: {
          titles: {
            title: 'Account not assigned',
            subtitle:
              'It is not possible to activate the POS without an associated account for receiving the money. Assign an account now to enable POS ',
          },
          buttons: {
            confirm: 'Assign an account',
          },
        },
        noAccountsCreated: {
          titles: {
            title: 'No account available',
            subtitle:
              'It is not possible to activate the POS without an associated account to receive the money. Now create an Account and associate it with the POS to continue with the activation',
          },
          buttons: {
            confirm: 'Create an account',
          },
        },
      },
    },
    loading: {
      title: 'Activation in progress ...',
    },
    success: {
      message: 'POS successfully activated!', //TODO
    },
  },
  edit: {
    parent: 'Return to the POS list',
    web: {
      title: 'Edit webPOS',
    },
    mobile: {
      title: 'MobilePOS modification',
    },
    generateSecret: 'Generate new credentials',
    state: {
      notActive:
        'This POS is not active. To use this POS with the chosen configuration remember to activate the POS after saving the changes. ',
      noDepositAccount: 'Attention! No associated account ',
    },
    toggleWebPosWithoutDeposit:
      'For now I prefer not to associate this POS with any account. I understand that with this choice the POS cannot be activated. ',
    noAccountSelectedError:
      'No account selected. To proceed select an account from the list or check the box below. ',
  },
  currency: {
    title: 'In which currency are the prices shown?',
    subtitle: 'Choose the currency used on your site',
  },
  exchange: {
    title: 'Choose the source of the exchange rate',
    suggestion:
      'An exchange is a service that deals with converting the price from' +
      'bitcoin to traditional currencies. ',
  },
  account: 'On which account do you want to receive payments?',
  newAccount:
    "Can't find the account where you would like to receive the money? %(link)s",
  newAccountEmptyAccounts:
    'There are no accounts to be associated with this POS. Create a%(link)s immediately on which you can receive  money. ',
  newAccountLiteral: 'Create one ',
  noDepositNewAccount:
    'This POS is not yet associated with any account. %(link)s' +
    'or immediately select the one on which to receive the money among those' +
    'available:',
  mobile: {
    where: {
      title: 'Where do you intend to use this mobilePOS?',
      subtitle:
        'Specify the name and address of your store where you want it ' +
        'use the POS',
      //TODO remove next three camp in where - after mobile pos merge
      suggestion:
        'Specify the name and address of your store where you want it ' +
        'use the POS',
      name: 'Recognizable name of the store',
      address: "Store's address",
    },
    integration: {
      title: 'Your POS credentials',
      suggestion:
        'These data allow ChainsidePay to identify ' +
        'your POS. Keep them safe. ',
      more_info: 'More information',
    },
    //TODO REMOVE EXCHANGE WITH MOBILE POS MERGE
    exchange: {
      title: 'Choose the source of the exchange rate',
      suggestion:
        'An exchange is a service that deals with converting the price from' +
        'bitcoin to traditional currencies. ',
    },
    name: 'Recognizable name of the store',
    address: "Store's address",
    placeholderMap: 'Enter address to view the map',
    disableAddress: 'This POS does not have a fixed address',
    currency: 'Select currency',
    generateSecretModal: {
      title: 'Attention: the pos is active',
      subtitle:
        "It's not allowed to generate new credentials. Deactivate the pos to continue with this operation",
    },
    transaction: {
      title: 'How safe and fast do you want transactions to be?',
      suggestion:
        'We recommend to set alwasys at least one confirmation. Set zero confirmation only if absolutely necessary',
      //TODO REMOVE WITH  high - low keys with MOBILE POS INTEGRATION
      high: {
        title: 'Sicurezza elevata',
        suggestion:
          'Accetta solo transazioni garantite da portafogli Chainside.',
      },
      low: {
        title: 'Sicurezza standard',
        suggestion:
          'Accetta transazioni da tutti i portafogli bitcoin con qualche possibile rischio.',
      },
      counter: {
        top: 'Consider a payment completed after',
        bottom: 'confirmations from the bitcoin network',
      },

      confirmations: {
        moreSpeed: 'Increase Speed',
        moreSecurity: 'Increases Security',
        0: {
          title: '0 confirmations',
          text: 'Security level: Low',
          text2: 'Maximum speed',
        },
        1: {
          title: '1 confirms',
          text: 'Security level: Medium',
          text2: 'High speed (about 10 minutes)',
        },
        3: {
          title: '3 confirmations',
          text: 'Security level: High',
          text2: 'Average speed (about 30 minutes)',
        },
        6: {
          title: '6 confirmations',
          text: 'Security level: Maximum',
          text2: 'Low speed (about 60 minutes)',
        },
      },
    },
    urls: {
      title: 'What are the default URLs for the integration?',
      subtitle:
        'These URLs are the meeting point between ChainsidePay and your' +
        'system, and will by default be assigned to all ' +
        ' the payment orders created by this POS. To the creation of every order ' +
        'you can still specify different URLs.',
      continue: 'Destination URL for confirmation pending',
      failure: 'Destination URL in case of cancellation',
      callback: 'URL where callbacks will be sent',
      more_info: 'Learn more in the developer area',
    },
  },
  web: {
    where: {
      title: 'Where do you want to use this webPOS?',
      subtitle:
        'Specify the name and url of your website where you want to integrate the POS',
    },
    integration: {
      title: 'Your POS credentials',
      suggestion:
        'These data allow ChainsidePay to identify the ' +
        'your webPOS. Keep them safe. ',
      more_info: 'More information',
    },
    name: 'Website name',
    site: 'Website address',
    currency: 'Select currency',
    transaction: {
      title: 'How safe and fast do you want transactions to be?',
      suggestion:
        'The lower the number of confirmations required for a Bitcoin ' +
        'transaction, the faster your order will be confirmed. Remember ' +
        'that too few confirmations can expose to ' +
        'security risks.',
      counter: {
        top: 'Consider a payment completed after',
        bottom: 'confirmations from the Bitcoin network',
      },

      confirmations: {
        moreSpeed: 'Increase Speed',
        moreSecurity: 'Increases Security',
        0: {
          title: '0 confirmations',
          text: 'Security level: Low',
          text2: 'Maximum speed',
        },
        1: {
          title: '1 confirms',
          text: 'Security level: Medium',
          text2: 'High speed (about 10 minutes)',
        },
        3: {
          title: '3 confirmations',
          text: 'Security level: High',
          text2: 'Average speed (about 30 minutes)',
        },
        6: {
          title: '6 confirmations',
          text: 'Security level: Maximum',
          text2: 'Low speed (about 60 minutes)',
        },
      },
    },
    urls: {
      title: 'What are the default URLs for integration?',
      subtitle:
        'These URLs are the meeting point between ChainsidePay and your' +
        'system, and will be assigned by default to all ' +
        ' payment orders created by this POS. At the creation of every order ' +
        'you can still specify different URLs.',
      continue: 'Destination URL for confirmation pending',
      failure: 'Destination URL in case of cancellation',
      callback: 'URL where callbacks will be sent',
      more_info: 'Learn more in the developer area',
    },
  },
  filter: {
    all: 'All',
    web: 'webPOS',
    mobile: 'mobilePOS',
  },
  confirmation: {
    0: '0 confirmations (low security level, maximum speed)',
    1: '1 confirmation (medium security level, high speed)',
    3: '3 confirmations (high security level, medium speed)',
    6: '6 confirmations (maximum security level, low speed)',
  },
  resume: {
    web: {
      name: 'Site name',
      site: 'URL of the site',
    },
    mobile: {
      name: 'Name of the Store',
      address: "Store's address",
      missingAddress: 'Unspecified',
    },
    currency: 'Currency',
    account: 'Receiving account',
    exchange: 'Bitcoin Exchange',
    confirm: 'Number of confirmations',
    urlSuccess: 'URL Success',
    urlError: 'Cancellation URL',
    urlCallback: 'Callback URL',
  },
  confirmPage: {
    web: {
      title: 'The webPOS "%(posName)s" was created successfully!',
      subtitle:
        'Proceed to integration on your website. Here are the data and documentation you will need: ',
      faq: {
        0: 'How to integrate a webPOS on my website?',
        1: 'SDK for webPOS integration',
      },
    },
    mobile: {
      title: 'The mobilePOS "%(posName)s" was created successfully!',
      subtitle:
        'Now that the mobilePOS has been created, connect it to a device:',
      merchant:
        'Scan the QR code on the side using the ChainsidePay Merchant app available on ',
      manual: 'Or manually enter Client ID and Secret',
    },
    integrationTitle: 'Your POS credentials',
    associationTitle: 'Associate a device',
  },
};
