import React, { FC } from 'react';
import Translate from 'react-translate-component';
import Modal from '../../../common/CustomModal';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import Button from '../../../common/Button';
import { StyledComponentProps } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export interface ConfirmGenerateSecretMobileModalProps
  extends StyledComponentProps {
  handleClose: () => void;
  open: boolean;
  id?: string;
}
const PosIsActiveOnGenerateModal: FC<ConfirmGenerateSecretMobileModalProps> = ({
  id,
  handleClose,
  open,
  classes = {},
}) => (
  <Modal id={id} open={open} handleClose={handleClose} fitContent>
    <div style={{ textAlign: 'center', width: '550px' }}>
      <Typography
        variant="subheading"
        className={classnames([classes.modalTitle, 'montserrat'])}
      >
        <Translate content="POS.mobile.generateSecretModal.title" />
      </Typography>
      <Typography
        variant="body1"
        className="color7"
        style={{ fontSize: '16px' }}
      >
        {<Translate content="POS.mobile.generateSecretModal.subtitle" />}
      </Typography>
      <div className={classnames(classes.actionSectionCentered)}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClose}
          style={{ width: '200px' }}
        >
          <Translate content="common.close" />
        </Button>
      </div>
    </div>
  </Modal>
);

const styles = (theme: { [key: string]: any }) => ({
  actionSectionCentered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '45px',
  },
  modalTitle: {
    fontSize: '22px',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '5px',
    color: theme.palette.primary.main,
  },
});

export default withStyles(styles)(PosIsActiveOnGenerateModal);
