export default {
  titles: {
    edit: 'Edit details',
    editBusiness: 'Edit company details',
    goBackEditBusiness: 'Back to Company Details',
  },
  people: 'People',
  editSubtitle:
    'For security reasons, it is not possible to change the fundamental data' +
    'of the company such as Name and VAT number. In case you want to ' +
    'change to such data',
  details: 'Details',
  businessData: 'Your business data',
  piva: 'VAT number',
  pivaCf: 'VAT number / tax code',
  cf: 'Tax Code',
  equalsToPiva: 'equal to VAT number',
  address: 'Residence',
  phone: 'Phone',
  email: 'Email',
  businessName: 'Company Name',
  differentNameMessage:
    'I want to use a different company name for communications with my clients',
  hintDifferentName:
    'This name will be the one shown to your customers and used in the' +
    'payment orders and receipts. The official name you entered above will be visible only to Chainside ',
  differentName: 'Company name for communications with customers',
  legalAddress: 'Registered Office',
  actualAddressMessage: 'Operating office other than registered office',
  actualAddress: 'Operational headquarters',
  personRole: 'Your role within the company',
  role:
    'I confirm that I am a legal representative of the company and can act on its behalf.',
  businessType: 'Company Type',
  nameComunication: 'Communication Name',
  sector: 'Sector',
  sector_description: 'Sector of your company',
  missingName: 'Missing name',
  goToDetail: 'Company details',
  excludeVat: 'I do not have a VAT number',
  logo: {
    title: 'Your company logo (optional)',
    subtitle:
      'Upload your company logo in jpeg, png or bmp format. Max size:%(maxSize)s',
    editText:
      'Your company logo is visible to users during the payment process.',
    restore: 'keep the old logo',
    remove: 'Are you sure you want to remove the logo?',
  },
  positions: {
    business_admin: 'Business Admin',
  },
  register: {
    welcome: 'Hello %(nome)s, welcome to ChainsidePay!',
    new: 'Register a new company',
    sendedMail:
      'We have sent you an email to verify the address you provided. Check your mail!',
    subtitle: 'To start accepting bitcoin, register your company',
  },
};
