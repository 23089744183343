import store from '../store';

export default {
  getCurrency: (onSuccess, onError, onlyFiat) => {
    const getCurrencies = store.app.factory.make('GetCurrenciesAction');

    getCurrencies.queryParams = { fiat: onlyFiat };
    getCurrencies
      .run()
      .then(onSuccess)
      .catch(onError);
  },

  getSingleCurrency: (uuid, onSuccess, onError) => {
    const getCurrencies = store.app.factory.make('GetCurrencyAction');

    getCurrencies.routeParams = { currency_uuid: uuid };

    getCurrencies
      .run()
      .then(onSuccess)
      .catch(onError);
  },
};
