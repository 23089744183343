import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import store from '../../store/index';
import { ROUTE_PARAMETERS, ROUTES } from '../../constants/routes';
import { withStyles } from '@material-ui/core/styles';
import PageContainer from './PageContainer';
import classnames from 'classnames';
import Translate from 'react-translate-component';
import Typography from '@material-ui/core/Typography';
import { buildPath, isSubRoute } from '../../utils/routes';
import { WebsiteLink } from './WebsiteLink';

const MENU_TITLES = {
  POS: 'common.menuBar.pos',
  PAYMENT_ORDER: 'common.menuBar.payment',
  ACCOUNT: 'common.menuBar.account',
};

const MENU_ROUTES = {
  POS: {
    prefix: ROUTES.POS_PREFIX,
    route: ROUTES.POS_LIST,
  },
  PAYMENT_ORDER: {
    prefix: ROUTES.PAYMENT_ORDER_PREFIX,
    route: ROUTES.PAYMENT_ORDER_LIST,
  },
  ACCOUNT: {
    prefix: ROUTES.ACCOUNT_PREFIX,
    route: ROUTES.ACCOUNT_LIST,
  },
};

const styles = theme => ({
  appBar: {
    minHeight: '55px',
    boxShadow: theme.appBarShadow,
    backgroundColor: theme.palette.primary.bgNavBar,
  },
  tabsRoot: {
    height: '55px',
    opacity: '0.6',
    minWidth: '0px',
    margin: '0px 30px 0px 22px',
  },
  tabRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '0px 15px',
  },
  labelContainer: {
    padding: '0px 3px',
    fontSize: '16px !important',
    fontWeight: '600',
    color: theme.palette.primary.navbarText,
  },
  label: {
    fontSize: '1rem',
  },
  tabSelected: {
    opacity: '1',
  },
  labelIcon: {
    minHeight: '55px',
  },
  deleteLeftMargin: { marginLeft: '0px !important' },
  tabIndicator: {
    height: '3px',
    backgroundColor: theme.palette.primary.navbarText,
  },
  adviserContiSandboxMode: {
    borderRadius: '5px',
    border: '1px solid',
    borderColor: theme.palette.orange.main,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '30px',
    alignSelf: 'center',
    margin: '10px 0px 10px auto',
  },
  adviseText: {
    color: theme.palette.orange.main,
  },
  icon: {
    height: '20px',
    width: '20px',
    margin: '0px 5px 0px 15px',
  },
  sandboxIcon: {
    height: '20px',
    margin: '0px 5px 0px 10px',
  },
  moreInfoLink: {
    color: theme.palette.orange.main,
    marginLeft: 'auto',
    marginRight: '10px',
  },
});

class MenuBar extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    display: false,
    tabs: [],
  };

  checkPageLocation = path => {
    let value = Object.values(MENU_ROUTES).find(route =>
      isSubRoute(route.prefix, path)
    );
    if (value) {
      value = value.route;
    }
    const ERROR_ROUTES = [ROUTES.GENERIC_ERROR, ROUTES.NOT_FOUND];
    const display = value !== undefined || ERROR_ROUTES.includes(path);
    return {
      display: display,
      value: value,
    };
  };

  componentDidMount() {
    this.getTabs();
  }

  handleChange = (event, path) => {
    const business_uuid = store.app.business_uuid;
    const value = buildPath(path, {
      [ROUTE_PARAMETERS.BUSINESS_ID]: business_uuid,
    });
    this.props.history.push(value);
  };

  getTabs = () => {
    let tabs = [];

    tabs.push(
      new TabItem(
        MENU_TITLES.PAYMENT_ORDER,
        ROUTES.PAYMENT_ORDER_LIST,
        false,
        require('../../img/menuBar/payments.png'),
        require('../../img/menuBar/payments_sandbox.png'),
        true
      )
    );
    tabs.push(
      new TabItem(
        MENU_TITLES.POS,
        ROUTES.POS_LIST,
        false,
        require('../../img/menuBar/pos.png'),
        require('../../img/menuBar/pos_sandbox.png')
      )
    );
    tabs.push(
      new TabItem(
        MENU_TITLES.ACCOUNT,
        ROUTES.ACCOUNT_LIST,
        false,
        require('../../img/menuBar/conti.png'),
        require('../../img/menuBar/conti_sandbox.png')
      )
    );

    this.setState({
      tabs: tabs,
    });
  };

  render() {
    const { display, value } = this.checkPageLocation(
      this.props.location.pathname
    );
    const { classes } = this.props;

    const isAuthenticated = store.app.isLoggedIn && store.app.isDelegated;

    const isSandboxMode = store.app.sandboxMode;

    if (!isAuthenticated || !display) {
      return null;
    }

    return (
      <AppBar position="static" color="default" className={classes.appBar}>
        <PageContainer>
          {isSandboxMode && (
            <div className={classes.adviserContiSandboxMode}>
              <img
                className={classes.sandboxIcon}
                src={require('../../img/topbar/sandbox_orange.png')}
                alt=""
              />
              &nbsp;
              <Typography
                className={classnames([classes.adviseText, 'montserrat f-13'])}
                variant="body1"
              >
                <Translate content="common.sandboxModeAccount" />
              </Typography>
              <WebsiteLink
                to="sandbox_more_info"
                withoutIcon
                customClasses={classnames([classes.moreInfoLink, 'montserrat'])}
              >
                <Translate content="common.moreInfo" />
              </WebsiteLink>
            </div>
          )}

          <Tabs
            value={value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            className={classes.appBar}
            classes={{
              indicator: classes.tabIndicator,
            }}
          >
            {this.state.tabs.map((tab, key) => {
              return (
                <Tab
                  key={key}
                  id={tab.label}
                  disabled={tab.disabled}
                  label={<Translate content={tab.label} />}
                  icon={store.app.sandboxMode ? tab.sandboxIcon : tab.icon}
                  value={tab.path}
                  classes={{
                    root: classnames({
                      [classes.tabsRoot]: true,
                      [classes.deleteLeftMargin]: tab.first,
                    }),
                    wrapper: classes.tabRoot,
                    labelContainer: classes.labelContainer,
                    selected: classes.tabSelected,
                    labelIcon: classes.labelIcon,
                    label: classes.label,
                  }}
                />
              );
            })}
          </Tabs>
        </PageContainer>
      </AppBar>
    );
  }
}

class TabItem {
  constructor(label, path, disabled, icon, sandboxIcon, first) {
    this.label = label;
    this.path = path;
    this.disabled = disabled;
    this.icon = (
      <img
        src={icon}
        alt={label}
        style={{
          width: '20px',
          height: '20px',
        }}
      />
    );
    this.sandboxIcon = (
      <img
        src={sandboxIcon}
        alt={label}
        style={{
          width: '20px',
          height: '20px',
        }}
      />
    );
    this.first = first;
  }
}

export default withRouter(withStyles(styles)(observer(MenuBar)));
