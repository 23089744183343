import BusinessPanelSDK from 'business-panel-sdk';

import store from '../store';
import { onErrorNotification, onSuccessNotification } from './common';

export default {
  getGuestToken: (onSuccess, onError) => {
    const guestLoginForm = store.app.formFactory.make('GuestLoginForm');

    const body = {
      form$username: 'guest',
      form$password: 'guest',
      form$grant_type: 'password',
      form$scope: '*',
    };

    guestLoginForm
      .send(body)
      .then(onSuccess)
      .catch(onError);
  },
  login: (username, password, remember_me, onSuccess, onError) => {
    const loginAction = store.app.factory.make('CookiePasswordLoginAction');

    loginAction.body_params = BusinessPanelSDK.sendables.LoginRequest.fromData({
      grant_type: 'password',
      username: username,
      password: password,
      scope: '*',
    });

    loginAction.query_params = {
      remember_me: remember_me,
      cookie: true,
    };

    loginAction
      .run()
      .then(onSuccess)
      .catch(onErrorNotification(onError));
  },
  requestPasswordRecovery: (email, onSuccess, onError) => {
    const requestPasswordRecovery = store.app.factory.make(
      'PasswordRecoveryRequestAction'
    );

    requestPasswordRecovery.body_params = BusinessPanelSDK.sendables.PasswordRecoveryRequest.fromData(
      {
        email: email,
      }
    );

    requestPasswordRecovery
      .run()
      .then(onSuccessNotification(onSuccess))
      .catch(onErrorNotification(onError));
  },
  passwordRecovery: (token, newPassword, onSuccess, onError) => {
    const passwordRecovery = store.app.factory.make('NewPasswordAction');

    passwordRecovery.body_params = BusinessPanelSDK.sendables.NewPasswordRequest.fromData(
      {
        new_password: newPassword,
      }
    );

    passwordRecovery.query_params = { activation_token: token };

    passwordRecovery
      .run()
      .then(onSuccessNotification(onSuccess))
      .catch(onErrorNotification(onError));
  },
  verify: (token, onSuccess, onError) => {
    const verifyAction = store.app.factory.make('VerifyRegistrationAction');

    verifyAction.routeParams = { activation_token: token };

    verifyAction
      .run()
      .then(onSuccess)
      .catch(onError);
  },
  confirmEmailChange: (token, onSuccess, onError) => {
    const verifyAction = store.app.factory.make('ChangeEmailAction');

    verifyAction.routeParams = { activation_token: token };

    verifyAction
      .run()
      .then(onSuccess)
      .catch(onError);
  },
  confirmEmail: (token, onSuccess, onError) => {
    const confirmeEmailAction = store.app.factory.make('ConfirmEmailAction');

    confirmeEmailAction.query_params = { activation_token: token };

    confirmeEmailAction
      .run()
      .then(onSuccessNotification(onSuccess))
      .catch(onErrorNotification(onError));
  },
  delegate: (business_uuid, onSuccess, onError) => {
    const delegateForm = store.app.formFactory.make('DelegateForm');

    const body = {
      uri$business_uuid: business_uuid,
      form$scope: '*',
    };

    delegateForm
      .send(body)
      .then(onSuccess)
      .catch(onError);
  },
  refreshToken: (onSuccess, onError) => {
    const refreshToken = store.app.formFactory.make('CookieTokenRefreshForm');

    const data = {
      [refreshToken.ids.form$grant_type]: 'refresh_token',
      [refreshToken.ids.form$scope]: '*',
      [refreshToken.ids.query$remember_me]: 'true',
    };

    refreshToken
      .send(data)
      .then(onSuccess)
      .catch(onError);
  },
  resendEmail: (person_uuid, onSuccess, onError) => {
    const resendEmailAction = store.app.factory.make(
      'VerificationEmailRequestAction'
    );

    resendEmailAction.routeParams = {
      person_uuid: person_uuid,
    };

    resendEmailAction
      .run()
      .then(onSuccess)
      .catch(onError);
  },

  getWebsocketRoom: (onSuccess, onError) => {
    const getWebsocketRoom = store.app.factory.make('GetWesocketRoomAction');

    getWebsocketRoom
      .run()
      .then(onSuccess)
      .catch(onError);
  },
};
