import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import TextField from './TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Translate from 'react-translate-component';
import FormHelperText from '@material-ui/core/FormHelperText';
import { formatAddress } from '../../../utils/formatters';
import classnames from 'classnames';

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    zIndex: 10,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.primary.bg,
    borderColor: theme.palette.primary.bluegrey,
    marginBottom: '10px',
  },
  selectedContainer: {
    borderRadius: '2px',
    border: 'solid 1px #d7dfe5',
    ...theme.shadow,
    margin: '10px 0px',
  },
  selectedInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 10px',
  },
  errorText: {
    color: theme.palette.red.main,
    marginTop: '-4px',
    fontSize: '0.75rem',
    fontWeight: '400',
    marginBottom: '8px',
  },
  errorContainer: {
    borderColor: theme.palette.red.main,
    backgroundColor: theme.palette.red.bg,
  },
  searchIcon: {
    height: '18px',
    width: '18px',
    marginLeft: '12px',
    marginTop: '6px',
  },
  closeIcon: {
    cursor: 'pointer',
    ...theme.icon.small,
  },
  searchHint: {
    padding: '10px 0px 0px 15px',
  },
  loadingIcon: {
    height: '14px',
    width: '14px',
  },
  changeText: {
    marginRight: '15px',
    color: theme.palette.primary.link,
    textTransform: 'uppercase',
    cursor: 'pointer',
    marginTop: '10px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  error: {
    color: theme.palette.red.main,
    marginBottom: '10px',
  },
  disabled: {
    cursor: 'default',
    opacity: '0.5',
    '&:hover': {
      textDecoration: 'none',
    },
  },
});

export const AddressField = withStyles(styles)(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        address: '',
        addressObject: null,
        selected: false,
      };
    }

    componentDidMount() {
      if (this.props.value) {
        this.setState({
          address: formatAddress(this.props.value),
          selected: true,
        });
      }
    }

    componentDidUpdate() {
      if (!this.props.visible && Boolean(this.state.address)) {
        this.setState({
          address: '',
          selected: false,
        });
      }
    }
    handleChange = address => {
      this.setState({ address });
    };

    handleSelect = address => {
      this.setState({
        selecting: true,
      });
      geocodeByAddress(address)
        .then(result => {
          this.setState(
            {
              address: result[0].formatted_address,
              addressObject: result[0],
              selected: true,
            },
            () => {
              this.props.handleChange(result[0]);
            }
          );
        })
        .catch(error => console.error('Error', error))
        .finally(
          this.setState({
            selecting: false,
          })
        );
    };

    handleCancel = () => {
      this.setState(
        {
          selected: false,
        },
        () => {
          this.props.handleChange(null);
        }
      );
    };

    render() {
      const { selected, address, selecting } = this.state;
      const {
        classes,
        error,
        helperText,
        handleChange,
        disabled,
        ...customProps
      } = this.props;
      let errorTextHtml = null;
      if (helperText) {
        errorTextHtml = (
          <div>
            {helperText.map((text, k) => {
              return (
                <span key={k}>
                  {text}
                  <br />
                </span>
              );
            })}
          </div>
        );
      }
      return (
        <React.Fragment>
          <PlacesAutocomplete
            value={this.state.address}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
            debounce={600}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => {
              const { value, ...otherProps } = getInputProps();
              if (loading && suggestions && suggestions.length === 0) {
                suggestions = [{}, {}, {}, {}, {}];
              }
              return (
                <div
                  className={classes.container}
                  name={customProps.name + '_container'}
                >
                  <TextField
                    {...otherProps}
                    {...customProps}
                    value={selected ? address : value}
                    error={error}
                    InputProps={{
                      endAdornment: selected && (
                        <InputAdornment position="end">
                          <Typography
                            onClick={event => {
                              if (!disabled) {
                                this.handleCancel(event);
                              }
                            }}
                            variant="body1"
                            className={classnames({
                              [classes.changeText]: true,
                              [classes.disabled]: customProps.disabled,
                            })}
                          >
                            <Translate content="common.change" />
                          </Typography>
                        </InputAdornment>
                      ),
                      startAdornment: !selected && (
                        <InputAdornment position="start">
                          <img
                            src={require('../../../img/form/search.png')}
                            alt="search"
                            id="search_icon"
                            className={classes.searchIcon}
                          />
                        </InputAdornment>
                      ),
                    }}
                    disabled={selecting || selected || disabled}
                  />
                  {((suggestions && suggestions.length > 0) || loading) && (
                    <Paper id="google_suggestions" className={classes.paper}>
                      <React.Fragment>
                        {loading ? (
                          <Typography
                            variant="caption"
                            className={classes.searchHint}
                          >
                            <Translate content="common.searchingAddress" />
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            className={classes.searchHint}
                          >
                            <Translate content="common.addressHint" />
                          </Typography>
                        )}

                        {suggestions.map((suggestion, k) => {
                          return (
                            <MenuItem
                              key={k}
                              disabled={selecting || loading}
                              className="suggestion"
                              {...getSuggestionItemProps(suggestion)}
                            >
                              {loading ? (
                                <CircularProgress size={14} />
                              ) : (
                                suggestion.description
                              )}
                            </MenuItem>
                          );
                        })}
                      </React.Fragment>
                    </Paper>
                  )}
                  {error && (
                    <FormHelperText
                      className={classes.error}
                      style={{
                        marginTop:
                          suggestions && suggestions.length > 0
                            ? '10px'
                            : '-10px',
                      }}
                      id={customProps.id + '-helper-text'}
                      name={customProps.name + '-helper-text'}
                    >
                      {errorTextHtml}
                    </FormHelperText>
                  )}
                </div>
              );
            }}
          </PlacesAutocomplete>
        </React.Fragment>
      );
    }
  }
);
