import React from 'react';
import Modal from './CustomModal';
import Translate from 'react-translate-component';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Button from './Button';
import { withCookies } from 'react-cookie';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '610px',
  },
  title: {
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '10px',
  },
  refreshButton: {
    marginTop: '50px',
    minWidth: '200px',
  },
});

class ExpiredSessionModal extends React.Component {
  render() {
    const { classes, open } = this.props;
    return (
      <Modal open={open} bluegrey fitContent id="expiredSession">
        <div className={classes.container}>
          <Typography
            variant="subheading"
            className={classnames([classes.title, 'montserrat'])}
          >
            <Translate content="common.expiredSession.title" />
          </Typography>
          <Typography
            variant="caption"
            className="montserrat color7"
            style={{ marginBottom: '30px', textAlign: 'center' }}
          >
            <Translate content="common.expiredSession.subtitle" />
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            customClass={classes.refreshButton}
            onClick={() => {
              window.location.reload(true);
            }}
          >
            <Translate content="common.refreshPage" />
          </Button>
        </div>
      </Modal>
    );
  }
}

export default withCookies(withStyles(styles)(ExpiredSessionModal));
