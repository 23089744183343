import { StyledComponentProps } from '@material-ui/core';
import { FormComponentProps } from '../../../../types/Forms';

export interface LanguageRowProps
  extends StyledComponentProps,
    FormComponentProps {
  value: string;
  person_uuid: string;
}


export enum StateLanguageRow {
    INIT = 'LANGUAGE_ROW/INIT',
    EDITING = 'LANGUAGE_ROW/EDITING',
    WAITING = 'LANGUAGE_ROW/WAITING',
}