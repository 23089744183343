import React from 'react';
import CustomModal from './CustomModal';
import Translate from 'react-translate-component';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Button from './Button';
import PropTypes from 'prop-types';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
  },
  title: {
    fontSize: '22px',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    marginBottom: '10px',
    textAlign: 'center',
  },
  text: {
    color: theme.palette.grey[7],
    maxWidth: '600px',
    textAlign: 'center',
    marginBottom: '45px',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 'auto',
  },
  actionButton: {
    width: '220px',
  },
});

const SandboxModal = props => {
  const { classes, open, nomeAzienda, handleClose, onConfirm } = props;
  return (
    <CustomModal open={open} handleClose={handleClose} fitContent>
      <div className={classes.container}>
        <Typography
          variant="subheading"
          className={classnames([classes.title, 'montserrat'])}
        >
          <Translate
            content="common.exitSandboxModal.title"
            with={{ companyName: nomeAzienda }}
          />
        </Typography>
        <Typography
          variant="body1"
          className={classnames([classes.text, 'f-16'])}
        >
          <Translate content="common.exitSandboxModal.text" />
        </Typography>
        <div className={classes.actionContainer}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            customClass={classes.actionButton}
            style={{
              marginRight: '25px',
            }}
          >
            <Translate content="common.goBack" />
          </Button>
          <Button
            variant="raised"
            color="primary"
            onClick={onConfirm}
            customClass={classes.actionButton}
          >
            <Translate content="common.goOn" />
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

SandboxModal.propTypes = {
  open: PropTypes.bool.isRequired,
  nomeAzienda: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default withStyles(styles)(SandboxModal);
